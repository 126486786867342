import React, { ReactElement } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import { useEnumStrings } from "../../enumStrings";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => {};
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidError: {
      textAlign: "center",
      color: "red",
    },
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      width: "500px",
      minHeight: "300px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    customButton: {
      margin: "10px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    okButton: {
      backgroundColor: "#37474f",
      margin: "10px",
      width: "100px",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
  })
);

const CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal =
  ({
    open,
    handleClose,
    handleUseSuggestedPlusAddressedAlternative,
    currentAdminConsoleLoginEmail,
    suggestedPlusAddressedEmail,
  }: {
    open: boolean;
    handleClose: () => void;
    handleUseSuggestedPlusAddressedAlternative: () => void;
    currentAdminConsoleLoginEmail: string;
    suggestedPlusAddressedEmail: string;
  }): ReactElement => {
    const classes = useStyles();

    const enumStrings = useEnumStrings();

    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h3
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {
              enumStrings.createChamberMemberSuggested
                .dashboardAccountExistsText
            }{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentAdminConsoleLoginEmail}
            </span>{" "}
            {enumStrings.createChamberMemberSuggested.alreadyExists}
            {"\n\n"}
            {enumStrings.createChamberMemberSuggested.alternativeEmail}{" "}
            <span style={{ fontWeight: "bold" }}>
              {suggestedPlusAddressedEmail}
            </span>
            {"\n"}
            {enumStrings.createChamberMemberSuggested.alternativeEmailPlus}
            {"\n\n"}
            {enumStrings.createChamberMemberSuggested.click}{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {enumStrings.createChamberMemberSuggested.useAlternative}
            </span>{" "}
            {enumStrings.createChamberMemberSuggested.toCreateMember}{" "}
            <span style={{ fontWeight: "bold" }}>Back</span> to try another
            {enumStrings.createChamberMemberSuggested.emailUsername}
          </h3>
          <br />
          <Button
            onClick={handleUseSuggestedPlusAddressedAlternative}
            className={classes.customButton}
            variant="contained"
          >
            {enumStrings.createChamberMemberSuggested.useAlternative}
          </Button>
          <Button
            onClick={handleClose}
            className={classes.okButton}
            variant="contained"
          >
            {enumStrings.createChamberMemberSuggested.back}
          </Button>
        </div>
      </Modal>
    );
  };

export default CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal;
