import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// utils

//
import { defaultLang } from "./config-lang";
//
import translationEn from "./langs/en.json";
import translationFr from "./langs/fr.json";

import { localStorageGetItem } from "../components/common/utilities/storage-available";

// ----------------------------------------------------------------------

const lng = localStorageGetItem("i18nextLng", defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      fr: { translations: translationFr },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    ns: ["translations"],
    defaultNS: "translations",
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
