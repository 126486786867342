import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import {
  GetUserQuery,
  UserType,
  useGetUserQuery,
  LanguageCodeEnum,
} from "../../../graphql/types";
import { UserTypeContext } from "../../../UserTypeContext";

const useGetLoggedInUserLanguageFromUserIdInAuth = ():
  | GetUserQuery["getUser"]["languageCode"]
  | undefined => {
  const [cognitoSub, setCognitoSub] = useState(undefined);
  useEffect(() => {
    const main = async (): Promise<void> => {
      const user = await Auth.currentAuthenticatedUser();
      setCognitoSub(user.attributes.sub);
    };
    main();
  }, []);

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const { data } = useGetUserQuery({
    variables: { userId: cognitoSub || "" },
    skip:
      !cognitoSub ||
      userType === "" || // need to check for empty string here because its set to empty string at logout... and this query shouldn't run while its empty string
      userType === UserType.PerkPartnerDiscountAdmin ||
      userType === UserType.RootAdmin,
  });

  if (data?.getUser) {
    // default language is english if user hasn't set a language ever
    return data?.getUser?.languageCode || LanguageCodeEnum.En;
  }

  return undefined;
};

export default useGetLoggedInUserLanguageFromUserIdInAuth;
