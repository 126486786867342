import { makeStyles } from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useEnumStrings } from "../../enumStrings";
import theme from "../../../themes";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles(() => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    font: "inherit",
    fontSize: "1.18676em",
  },
}));

const LogoPicker = ({
  logo,
  deleteLogo,
  base64,
  label,
}: {
  logo: any;
  deleteLogo: any;
  base64: any;
  label?: string;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const uniqueId = `contained-button-file-${uuidv4()}`;

  return (
    <div
      className={classes.root}
      style={{
        borderRadius: "15px",
        marginTop: "55px",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        border: "1px solid black",
        backgroundColor: "#F2F2F6",
        color: "#6F6F71",
      }}
    >
      <h1
        style={{
          marginBottom: "0px",
          marginTop: "0px",
          color: "#6F6F71",
        }}
      >
        {label ? label : "Logo"}
      </h1>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <label className={classes.labelLogo} htmlFor={uniqueId}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FolderOpen
                style={{
                  fontSize: "40px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
              <p style={{ margin: "auto", marginLeft: "5px" }}>
                {logo
                  ? enumStrings.profile.changeLogoTitle
                  : `${enumStrings.profile.uploadLogo} ${
                      label ? (label || "").toLowerCase() : "logo"
                    }`}
              </p>
            </div>
            {logo ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <DeleteOutlineIcon
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={deleteLogo}
                />
                <p
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ margin: "auto" }}
                >
                  {enumStrings.removeALogoText}
                </p>
              </div>
            ) : null}
          </div>
          {logo ? (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "5px",
                flex: 1.5,
              }}
            >
              <div
                style={{
                  height: "125px",
                  width: "125px",
                  borderRadius: "5px",
                  background: "white",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundSize: "contain",
                  border: "1px solid #6F6F71",
                  margin: "auto",
                  cursor: "pointer",
                }}
              >
                <img
                  src={logo}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt=""
                />
              </div>
            </span>
          ) : (
            <p style={{ margin: "auto", marginRight: "10px" }}></p>
          )}
        </label>
      </div>
      <input
        id={uniqueId}
        type="file"
        accept="image/*, png, jpeg, jpg"
        style={{ display: "none" }}
        name={`logo-${uuidv4()}`}
        onChange={base64}
      />
    </div>
  );
};

export default LogoPicker;
