import React, { ReactElement } from "react";
import {
  makeStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import HoverToolTip from "./utilities/HoverToolTip";
import { useEnumStrings } from "../enumStrings";
import { FormikErrors } from "formik";
import { ChamberMemberInput } from "../../graphql/types";

const useStyles = makeStyles(() => ({
  notificationRadioGroup: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "5px",
    width: "100%",
  },
  radio: {
    color: "#E1A731!important",
  },
}));

const ChamberMemberIsSuspendedRadioGroup = ({
  formikSetFieldValue,
  formikValues,
  formikErrors,
}: {
  formikSetFieldValue: (a: string, b: boolean | any[]) => void;
  formikValues: ChamberMemberInput;
  formikErrors: FormikErrors<ChamberMemberInput>;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "475px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <h1 style={{ marginBottom: "0px", textAlign: "center" }}>
          {enumStrings.chamberMember.memberStatus}
        </h1>
        <HoverToolTip
          description={
            enumStrings.createChamberMemberText.tooltip.membershipStatus.title
          }
          showIt
        />
      </div>
      <RadioGroup
        row
        aria-label="isSuspended"
        name="isSuspended"
        id="isSuspended"
        onChange={(e) => {
          formikSetFieldValue(
            "isSuspended",
            e.target.value === "0" ? true : false
          );
        }}
        className={classes.notificationRadioGroup}
        value={formikValues.isSuspended ? 0 : 1}
      >
        {formikValues.isSuspended === undefined ? (
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              paddingTop: "0px",
              textAlign: "center",
            }}
          >
            {formikErrors.isSuspended}
          </FormHelperText>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={1}
            labelPlacement="end"
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {enumStrings.CreateChamberMember.Active}
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={
                    enumStrings.createChamberMemberText.tooltip.membershipStatus
                      .active
                  }
                  showIt
                />
              </div>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={0}
            style={{ marginRight: "0px" }}
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                {enumStrings.CreateChamberMember.Suspended}
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={
                    enumStrings.createChamberMemberText.tooltip.membershipStatus
                      .suspended
                  }
                  showIt
                />
              </div>
            }
            labelPlacement="end"
          />
        </div>
      </RadioGroup>
    </div>
  );
};

export default ChamberMemberIsSuspendedRadioGroup;
