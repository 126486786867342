import React, { ReactElement } from "react";
import { Button, makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import SadFace from "../../../images/sadFace.svg";
import useRedirect from "../../common/Hooks/useRedirect";

import "react-toastify/dist/ReactToastify.css";
const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },

  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
  },

  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    paddingBottom: "33px",
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  errrorTextContainer: {
    maxWidth: "500px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
  },
  sadFaceImage: {
    width: "300px",
    height: "300px",
    margin: "auto",
  },
}));

const GeneralError = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  // @ts-ignore
  const redirectToHomePage = useRedirect(RedirectUri.home);

  return (
    <span>
      <div className={classes.content}>
        <div className={classes.errrorTextContainer}>
          <img src={SadFace} alt="sadFace" className={classes.sadFaceImage} />
          <h1>{enumStrings.errorPageText.title}</h1>
          <p>{enumStrings.errorPageText.text}</p>
        </div>
        <div className={classes.settingsButtonContainer}>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#E1A731",
              fontSize: "23px",
              fontWeight: "bold",
              borderRadius: "10px",
              width: "307px",
              margin: "39px",
              height: "116px",
            }}
            onClick={redirectToHomePage}
          >
            {enumStrings.errorPageText.homeButtonText}
          </Button>{" "}
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#E1A731",
              fontSize: "23px",
              fontWeight: "bold",
              borderRadius: "10px",
              width: "307px",
              margin: "39px",
              height: "116px",
            }}
            onClick={() =>
              (window.location.href = `mailto:${enumStrings.supportContactEmail}?subject=Chamber Perks Dashboard Error Support&body=Please%20state%20your%20issue%20below.`)
            }
          >
            {enumStrings.errorPageText.contactSupportButtonText}
          </Button>{" "}
        </div>
      </div>
    </span>
  );
};

export default GeneralError;
