import React, { ReactElement, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserType } from "../../../graphql/types";
import { UserTypeContext } from "../../../UserTypeContext";
import { RedirectUri, useEnumStrings } from "../../enumStrings";

// User can only access this if logged in so no need to check if logged in here
const Root = (): ReactElement => {
  const history = useHistory();
  const enumStrings = useEnumStrings();

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  if (userType === UserType.ChamberMemberAdmin) {
    history.push(RedirectUri.updateAMemberChamberAdmin);
  } else if (userType === UserType.ChamberAdmin) {
    history.push(RedirectUri.chamberAdmin);
  } else if (userType === UserType.RootAdmin) {
    history.push(RedirectUri.rootAdmin);
  } else if (userType === UserType.PerkPartnerDiscountAdmin) {
    history.push(RedirectUri.editperkPartnerDiscountPageChamberAdmin);
  } else {
    history.push(RedirectUri.login);
  }

  return <div>{enumStrings.pleaseWait}</div>;
};

export default Root;
