import { FormControl, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCallback, useState } from "react";
import { useMemo } from "react";
import { GetAllChamberMembersQuery } from "../../../graphql/types";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import { useEnumStrings } from "../../enumStrings";

const useStyles = makeStyles((theme: any) => ({
  categoryAdvertisingBannerSelectorInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    height: "55px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white !important",
      borderRadius: "15px !important",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "#37474f",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
      color: "black",
      opacity: 1,
      "-webkit-text-fill-color": "black",
    },
  },
  chamberInput: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    borderRadius: "15px",
  },
  selectedValue: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const ChamberMemberSelector = ({
  chamberMemberId,
  setChamberMemberId,
  allChamberMemberData,
  isFilledVariant,
  disabled,
  isRewardCampaignSelector,
}: {
  chamberMemberId: string | null;
  setChamberMemberId: any;
  allChamberMemberData: GetAllChamberMembersQuery | undefined;
  isFilledVariant?: boolean;
  disabled?: boolean;
  isRewardCampaignSelector?: boolean;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [loading, setLoading] = useState(true);

  const { chamberMemberIdToChamberMemberName, selectorOptions } =
    useMemo(() => {
      const chamberMemberIdToChamberMemberName = {} as {
        [key: string]: { chamberMemberId: string; name: string };
      };

      let selectorOptions = [] as { chamberMemberId: string; name: string }[];

      if (
        allChamberMemberData?.getAllChamberMembers &&
        allChamberMemberData.getAllChamberMembers.length > 0
      ) {
        for (const chamberMember of allChamberMemberData.getAllChamberMembers) {
          chamberMemberIdToChamberMemberName[chamberMember.id] = {
            chamberMemberId: chamberMember.id,
            name: chamberMember.name,
          };
        }

        // alphabetized list of chamber members with object containing chamberMemberId and name
        selectorOptions = (allChamberMemberData?.getAllChamberMembers || [])
          .map((chamberMember) => ({
            chamberMemberId: chamberMember.id,
            name: chamberMember.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      }

      setLoading(false);
      return { chamberMemberIdToChamberMemberName, selectorOptions };
    }, [allChamberMemberData]);

  const handleChamberMemberChange = useCallback(
    (_, selectedDropdownOption) => {
      setChamberMemberId(
        selectedDropdownOption ? selectedDropdownOption.chamberMemberId : ""
      );
    },
    [setChamberMemberId]
  );

  if (loading) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: isFilledVariant ? 0 : "30px",
      }}
    >
      <FormControl
        className={classes.chamberInput}
        style={{
          marginTop: isFilledVariant ? 0 : "50px",
        }}
      >
        <Autocomplete
          id="chamberSelector"
          disabled={disabled}
          value={
            !chamberMemberId
              ? { chamberMemberId: "", name: "" }
              : chamberMemberIdToChamberMemberName[chamberMemberId]
          }
          {...(disabled ? { popupIcon: false } : {})}
          onChange={handleChamberMemberChange}
          className={classes.selectedValue}
          options={selectorOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => {
            if (isFilledVariant) {
              return (
                <TextField
                  {...params}
                  className={classes.categoryAdvertisingBannerSelectorInput}
                  style={{
                    backgroundColor: "white",
                  }}
                  variant="filled"
                  sx={{
                    cursor: disabled ? "unset" : "pointer",
                    "& .MuiFilledInput-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-root:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                  label={`${enumStrings.manageChamberEngage.memberCompanyName}`}
                />
              );
            }

            return (
              <TextField
                {...params}
                label={
                  isRewardCampaignSelector
                    ? enumStrings.ChamberMemberSelector.sponsorCompanyName
                    : enumStrings.ChamberMemberSelector.memberCompanyName
                }
              />
            );
          }}
        />
      </FormControl>
      {!isFilledVariant && (
        <div
          style={{
            marginTop: "50px",
          }}
        >
          <HoverToolTip
            description={
              isRewardCampaignSelector
                ? enumStrings.rewardCampaign.tooltip.sponsoringMember
                : enumStrings.selectMemberCompany
            }
            showIt
          />
        </div>
      )}
    </div>
  );
};

export default ChamberMemberSelector;
