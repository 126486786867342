import React, { ReactElement } from "react";
import { makeStyles, FormControl, TextField } from "@material-ui/core";
import theme from "../../themes";
import { useEnumStrings } from "../enumStrings";
import { Autocomplete } from "@material-ui/lab";
import CSVButtons, { DeleteButtonProps } from "./CSVButtons";
import { Maybe } from "yup/lib/types";

export const setPrivateEmails = (
  privateEmails: any[],
  executeSet: (privateEmails: any[]) => void
) => {
  let emailsToSet = [...privateEmails];

  if (
    privateEmails &&
    Array.isArray(privateEmails) &&
    privateEmails.length > 0 &&
    privateEmails[privateEmails.length - 1].includes(",")
  ) {
    const emailsToAdd =
      // @ts-ignore
      privateEmails[privateEmails.length - 1].split(",");

    emailsToSet = [...privateEmails.slice(0, -1)];

    for (const email of emailsToAdd) {
      if (email.length > 0 && !emailsToSet.includes(email.toLowerCase())) {
        emailsToSet.push(email.toLowerCase());
      }
    }
  }
  executeSet(emailsToSet);
};

export const setDataAfterCSVImport = (
  csvData: any,
  setPrivateEmailsCB: (privateEmails: any[]) => void
) => {
  if (
    csvData &&
    csvData?.data &&
    Array.isArray(csvData?.data) &&
    csvData?.data.length > 0
  ) {
    const lowerCaserizedColumnNames = csvData?.data[0].map((s: any) =>
      typeof s === "string" || s instanceof String ? s.toLowerCase().trim() : s
    );
    let emailIndex = 0;
    if (lowerCaserizedColumnNames.includes("email")) {
      emailIndex = lowerCaserizedColumnNames.indexOf("email");
    }

    const privateEmails = [];

    for (let i = 1; i < csvData?.data.length; i += 1) {
      privateEmails.push(csvData?.data[i][emailIndex]);
    }

    setPrivateEmails(privateEmails, setPrivateEmailsCB);
  }
};

const useStyles = makeStyles(() => ({
  privateEmailsContainer: {
    backgroundColor: "#F2F2F6",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
    },
  },
}));

const PrivatePerk = ({
  show,
  formikSetFieldValue,
  formikValuesPrivateEmails,
  dataToExportAsCSV,
}: {
  show: boolean;
  formikSetFieldValue: (a: string, b: any[]) => void;
  formikValuesPrivateEmails: undefined | Maybe<any[]>;
  dataToExportAsCSV?: DeleteButtonProps["dataToExportAsCSV"];
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  return (
    <>
      {show ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <h1 style={{ marginBottom: "0px", marginTop: "15px" }}>
            {enumStrings.discountText.privateEmails.title}
          </h1>
          <h4
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "0px",
              paddingRight: "30px",
              paddingLeft: "30px",
            }}
          >
            {enumStrings.discountText.privateEmails.mainDesc}
          </h4>
          <h5
            style={{
              marginBottom: "0px",
              marginTop: "0px",
              paddingRight: "30px",
              paddingLeft: "30px",
              fontSize: "12px",
            }}
          >
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.privatePerk.instructions.oneByOneEmail}
                </span>{" "}
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.privatePerk.instructions.csvImport}
                </span>{" "}
                <ul>
                  <li style={{ maxWidth: "300px" }}>
                    <span style={{ fontWeight: "bold" }}>Note: </span>{" "}
                    {enumStrings.privatePerk.instructions.csvNote}
                  </li>
                </ul>
              </li>
              <li style={{ fontWeight: "bold" }}>
                {enumStrings.privatePerk.instructions.removeEmail}
              </li>
            </ul>
          </h5>
          <CSVButtons
            setDataAfterCSVImport={(data) =>
              setDataAfterCSVImport(data, (emailsToSet) =>
                formikSetFieldValue("privateEmails", emailsToSet)
              )
            }
            dataToExportAsCSV={dataToExportAsCSV}
          />
          <h4
            style={{
              marginBottom: "0px",
              marginTop: "2px",
              fontWeight: "bold",
              paddingRight: "30px",
              paddingLeft: "30px",
            }}
          >
            {(formikValuesPrivateEmails || []).length}{" "}
            <span style={{ fontWeight: "normal" }}>
              email
              {(formikValuesPrivateEmails || []).length === 1 ? "" : "s"}{" "}
              {enumStrings.listed}
            </span>
          </h4>
          <FormControl className={classes.privateEmailsContainer}>
            <Autocomplete
              multiple
              disableClearable
              id="privateEmailsAutoComplete"
              options={[]}
              freeSolo
              onChange={(_, privateEmails) => {
                setPrivateEmails(privateEmails, (emailsToSet) =>
                  formikSetFieldValue("privateEmails", emailsToSet)
                );
              }}
              style={{ textTransform: "lowercase" }}
              value={formikValuesPrivateEmails || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  name="privateEmails"
                  variant="outlined"
                  id="privateEmails"
                  placeholder={enumStrings.privatePerk.autocomplete.placeholder}
                />
              )}
            />
          </FormControl>
          <h4
            style={{
              marginBottom: "0px",
              marginTop: "2px",
              fontWeight: "bold",
              paddingRight: "30px",
              paddingLeft: "30px",
            }}
          >
            <span
              style={{
                color: "green",
              }}
            >
              {enumStrings.privatePerk.importantNote.prefix}{" "}
            </span>
            <span
              style={{
                fontWeight: "normal",
              }}
            >
              {enumStrings.privatePerk.importantNote.message}{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {enumStrings.privatePerk.importantNote.action}
              </span>{" "}
              {enumStrings.privatePerk.importantNote.postActionMessage}
            </span>
          </h4>
        </div>
      ) : null}
    </>
  );
};

export default PrivatePerk;
