import {
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BannerContentTypeEnum,
  BannerInput,
  BannerReturn,
} from "../../graphql/types";
import theme from "../../themes";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/ImageOutlined";
import VideoIcon from "@material-ui/icons/PlayCircleOutline";
import FolderIcon from "@material-ui/icons/FolderOpen";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { v4 as uuidv4 } from "uuid";
import { useEnumStrings } from "../enumStrings";
import urlParser from "js-video-url-parser";
import { YouTubeParseResult } from "js-video-url-parser/lib/provider/youtube";
import Carousel from "react-material-ui-carousel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HoverToolTip from "./utilities/HoverToolTip";
import { validWebsiteRegEx } from "./utilities/regexes";
import imageCompression from "browser-image-compression";
import AutoApproveBannerContentToggle from "./utilities/AutoApproveBannerContentToggle";

const useStyles = makeStyles(() => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "510px",
    },
    "@media (min-width:815px)": {
      width: "680px",
    },
  },
  slideBoxRoot: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  slideBoxContent: {
    background: "white",
    borderRadius: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "contain",
    border: "1px solid #6F6F71",
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "61px",
      width: "111px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "63px",
      width: "115px",
    },
    "@media (min-width:815px)": {
      height: "87px",
      width: "157px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "5px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "white",
    position: "relative",
    width: "550px",
    height: "625px",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90%",
    },
  },
  yesButton: {
    width: "230px",
    backgroundColor: "#e1a731",
    color: "white",
    fontWeight: "bold",
    height: "40px",
    fontSize: "16px",
  },
  okButton: {
    width: "230px",
    color: "white",
    fontWeight: "bold",
    height: "45px",
    fontSize: "16px",
    backgroundColor: "#37474f",
  },
  mediaUploadModalTabRoot: {
    display: "flex",
    flex: 1,
    "& .MuiTabs-indicator": {
      backgroundColor: "#E1A731",
      height: 5,
    },
  },
  mediaUploadModalIndividualTab: {
    "&.Mui-selected": {
      backgroundColor: "rgba(225, 167, 49, 0.18)",
      opacity: 3,
    },
  },
  youtubeUrlInput: {
    backgroundColor: "white",
    borderRadius: "15px",
    height: "55px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#6F6F71",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "#37474f",
    },
  },
  imageLinkInput: {
    marginTop: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: "15px",
    height: "45px",
    width: "80%",
    "& .MuiOutlinedInput-root": {
      height: "45px",
      "& fieldset": {
        borderColor: "#6F6F71",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      color: "#37474f",
    },
  },
  previewCarousel: {
    height: "100%",
    width: "100%",
    "& .MuiSvgIcon-root circle": {
      fill: "white !important",
    },
    "& button.jss81 .MuiSvgIcon-root circle, & button[class*='Carousel-active'] .MuiSvgIcon-root circle":
      {
        fill: "#E1A731 !important",
      },
  },
}));

export const maxBannerSlides = 4;

export const marginTopPx = 55;

const BannerPicker = ({
  chamberName,
  pickerType = "default",
  formikVal: banners,
  formikSetFieldValue,
  pickerLabel,
  disabled,
  bannerUpdateInProgress,
  parentOverrideForceMediaUploadModalClosed,
  setParentOverrideForceMediaUploadModalClosed,
  handleAutoApproveBannerContentUpdate,
  autoApproveBannerContentUpdate,
  changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading,
  previewLogo,
  previewLogoNameAlt,
}: {
  chamberName?: string;
  pickerType?:
    | "default"
    | "categoryAdvertisingChamberAdmin"
    | "categoryAdvertisingChamberMemberAdmin"
    | "memberProfile"
    | "perk";
  formikVal?: BannerInput[] | null;
  formikSetFieldValue: (
    value: BannerInput[],
    isSlidePositioningUpdate?: boolean
  ) => any;
  pickerLabel: string;
  disabled?: boolean;
  bannerUpdateInProgress?: boolean;
  parentOverrideForceMediaUploadModalClosed?: boolean;
  setParentOverrideForceMediaUploadModalClosed?: (value: boolean) => void;
  handleAutoApproveBannerContentUpdate?: (value: boolean) => void;
  autoApproveBannerContentUpdate?: boolean | null;
  changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading?: boolean;
  previewLogo?: string | null;
  previewLogoNameAlt?: string | null;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const [mediaUploadModalOpen, setMediaUploadModalOpen] = useState(false);
  const [
    mediaUploadModalOpenForSlideInPosition,
    setMediaUploadModalOpenInPosition,
  ] = useState(0);
  const [modalOpenedForBannerSlide, setModalOpenedForBannerSlide] = useState<
    BannerInput | undefined
  >(undefined);

  const handleMediaUploadModalOpen = useCallback(
    ({ slidePosition }: { slidePosition: number }) => {
      if (disabled) {
        return;
      }

      setMediaUploadModalOpen(true);
      setMediaUploadModalOpenInPosition(slidePosition);
      setModalOpenedForBannerSlide(
        banners
          ? banners.filter((banner) => banner.position === slidePosition)[0]
          : undefined
      );
    },
    [banners, disabled]
  );

  const [
    slidePositionToDeleteAfterConifrmingInAreYouSureModal,
    setSlidePositionToDeleteAfterConifrmingInAreYouSureModal,
  ] = useState<undefined | number>(undefined);

  const hanleMediaUploadModalClose = useCallback(
    (forceClose?: boolean) => {
      if (
        pickerType === "default" ||
        pickerType === "perk" ||
        pickerType === "memberProfile" ||
        forceClose
      ) {
        setMediaUploadModalOpen(false);
        setSlidePositionToDeleteAfterConifrmingInAreYouSureModal(undefined);
      }
    },
    [pickerType]
  );

  // create useEffect to close media upload modal from a variable from parent
  useEffect(() => {
    if (parentOverrideForceMediaUploadModalClosed) {
      hanleMediaUploadModalClose(true);
      if (setParentOverrideForceMediaUploadModalClosed) {
        setParentOverrideForceMediaUploadModalClosed(false);
      }
    }
  }, [
    parentOverrideForceMediaUploadModalClosed,
    hanleMediaUploadModalClose,
    setParentOverrideForceMediaUploadModalClosed,
  ]);

  const handleBannerUpdate = useCallback(
    ({
      updatedBanner,
      slidePosition = 1,
    }: {
      updatedBanner?: BannerInput | BannerReturn | undefined | null;
      slidePosition?: number;
    }) => {
      if (bannerUpdateInProgress) {
        return;
      }

      const currentBanners = banners ? [...banners] : [];
      const bannerIndex = currentBanners.findIndex(
        (banner) => banner.position === slidePosition
      );

      // deleting a banner
      if (!updatedBanner) {
        // check banners array for slide with slidePosition, if it exists, remove it
        if (bannerIndex !== -1) {
          currentBanners.splice(bannerIndex, 1);

          // loop through current banner slides and any slide with a position greater than the deleted slide should have its position decremented by 1
          currentBanners.forEach((banner) => {
            if (banner.position > slidePosition) {
              banner.position = banner.position - 1;
            }
          });

          hanleMediaUploadModalClose();
          formikSetFieldValue(currentBanners);
        }
        return;
      }

      // if banner exists, update it
      if (bannerIndex !== -1) {
        currentBanners[bannerIndex] = {
          ...currentBanners[bannerIndex],
          ...updatedBanner,
        };
        hanleMediaUploadModalClose();
        formikSetFieldValue(currentBanners);
        return;
      }

      // if banner does not exist, add it ... ensure that the position is correct and an empty space isn't left between banners
      let positionToPlaceBanner = 1;
      if (currentBanners.length > 0) {
        positionToPlaceBanner =
          currentBanners[currentBanners.length - 1].position + 1;
        if (positionToPlaceBanner > maxBannerSlides) {
          positionToPlaceBanner = maxBannerSlides;
        }
      }
      currentBanners.push({
        ...updatedBanner,
        position: positionToPlaceBanner,
      });

      hanleMediaUploadModalClose();
      formikSetFieldValue(currentBanners);
    },
    [
      bannerUpdateInProgress,
      banners,
      formikSetFieldValue,
      hanleMediaUploadModalClose,
    ]
  );

  const handleAreYouSureDeleteSlideModalOpen = useCallback(
    ({ slidePositionToDelete }: { slidePositionToDelete: number }) => {
      setSlidePositionToDeleteAfterConifrmingInAreYouSureModal(
        slidePositionToDelete
      );
    },
    []
  );

  const highestPosition = useMemo(() => {
    if (!banners) {
      return 1;
    }

    return Math.max(...banners.map((banner) => banner.position));
  }, [banners]);

  const handleMoveSlidePosition = useCallback(
    ({
      move,
      slidePosition,
    }: {
      move: "left" | "right";
      slidePosition: number;
    }) => {
      if (!banners) {
        return;
      }

      const currentBanners = banners ? [...banners] : [];
      const bannerIndex = currentBanners.findIndex(
        (banner) => banner.position === slidePosition
      );

      if (bannerIndex === -1) {
        return;
      }

      if (move === "left") {
        if (slidePosition === 1) {
          return;
        }

        // shift current slide to the left and the slide to the left to the right
        const slidePositionToSplitWith = slidePosition - 1;

        // find the slide to the left of the current slide
        const slideToSplitWithIndex = currentBanners.findIndex(
          (banner) => banner.position === slidePositionToSplitWith
        );

        currentBanners[slideToSplitWithIndex].position = slidePosition;
        currentBanners[bannerIndex].position = slidePositionToSplitWith;
      }

      if (move === "right") {
        if (
          slidePosition === maxBannerSlides ||
          slidePosition === highestPosition
        ) {
          return;
        }

        // shift current slide to the left and the slide to the left to the right
        const slidePositionToSplitWith = slidePosition + 1;

        // find the slide to the right of the current slide
        const slideToSplitWithIndex = currentBanners.findIndex(
          (banner) => banner.position === slidePositionToSplitWith
        );

        currentBanners[slideToSplitWithIndex].position = slidePosition;
        currentBanners[bannerIndex].position = slidePositionToSplitWith;
      }

      formikSetFieldValue(currentBanners, true);
    },
    [banners, formikSetFieldValue, highestPosition]
  );

  const handleApproveBannerSlide = useCallback(
    async ({ slidePosition }: { slidePosition: number }) => {
      const currentBanners = banners ? [...banners] : [];
      const bannerIndex = currentBanners.findIndex(
        (banner) => banner.position === slidePosition
      );

      if (bannerIndex === -1) {
        return;
      }

      currentBanners[bannerIndex].waitingForApprovalByChamberAdmin = false;

      formikSetFieldValue(currentBanners);
    },
    [banners, formikSetFieldValue]
  );

  const slideBoxes = useMemo(() => {
    if (!banners) {
      const res = [];

      for (let i = 1; i <= maxBannerSlides; i++) {
        res.push(
          <SlideBox
            key={i}
            position={i}
            highestPosition={highestPosition}
            handleMediaUploadModalOpen={handleMediaUploadModalOpen}
            handleAreYouSureDeleteSlideModalOpen={
              handleAreYouSureDeleteSlideModalOpen
            }
            handleMoveSlidePosition={handleMoveSlidePosition}
          />
        );
      }

      return res;
    }

    const res = [];

    for (let i = 1; i <= maxBannerSlides; i++) {
      const banner = banners.filter((banner) => banner.position === i)[0];
      res.push(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SlideBox
            key={i}
            position={i}
            highestPosition={highestPosition}
            bannerSlide={banner}
            handleMediaUploadModalOpen={handleMediaUploadModalOpen}
            handleAreYouSureDeleteSlideModalOpen={
              handleAreYouSureDeleteSlideModalOpen
            }
            handleMoveSlidePosition={handleMoveSlidePosition}
            atLeastOneBannerSlide
          />
          {pickerType === "categoryAdvertisingChamberMemberAdmin" &&
            banner?.contentUrl &&
            (banner?.waitingForApprovalByChamberAdmin ? (
              <span
                style={{
                  marginTop: 5,
                  color: "#6F6F71",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {enumStrings.waitingForApproval}
              </span>
            ) : (
              <span
                style={{
                  marginTop: 5,
                  color: "#4CB230",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {enumStrings.approved}
              </span>
            ))}
          {pickerType === "categoryAdvertisingChamberAdmin" &&
            banner?.contentUrl &&
            (banner?.waitingForApprovalByChamberAdmin ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className={classes.yesButton}
                  style={{
                    marginTop: 5,
                    width: "110px",
                    height: "25px",
                    fontSize: "14px",
                    marginRight: 5,
                  }}
                  onClick={() =>
                    handleApproveBannerSlide({
                      slidePosition: banner?.position,
                    })
                  }
                  variant="contained"
                  disabled={bannerUpdateInProgress}
                >
                  {bannerUpdateInProgress ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    "Approve"
                  )}
                </Button>
                <div
                  style={{
                    paddingTop: 5,
                    marginRight: -5,
                  }}
                >
                  <HoverToolTip
                    disablePadding
                    description={enumStrings.hoverTooltip.description}
                    showIt
                  />
                </div>
                <div
                  style={{
                    paddingTop: 5,
                    marginRight: -5,
                  }}
                >
                  <HoverToolTip
                    disablePadding
                    description={`${enumStrings.hoverTooltip.description}`}
                    showIt
                  />
                </div>
              </div>
            ) : (
              <span
                style={{
                  marginTop: 7,
                  color: "#4CB230",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {enumStrings.approved}
              </span>
            ))}
        </div>
      );
    }

    return res;
  }, [
    bannerUpdateInProgress,
    banners,
    classes.yesButton,
    enumStrings.approved,
    enumStrings.hoverTooltip.description,
    enumStrings.waitingForApproval,
    handleApproveBannerSlide,
    handleAreYouSureDeleteSlideModalOpen,
    handleMediaUploadModalOpen,
    handleMoveSlidePosition,
    highestPosition,
    pickerType,
  ]);

  const [bannerPreviewOpen, setBannerPreviewOpen] = useState(false);

  const previewLogoOverlay = useMemo(() => {
    if (!previewLogo && !previewLogoNameAlt) {
      return null;
    }

    return (
      <>
        {(previewLogo || previewLogoNameAlt) && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              padding: 8,
              borderRadius: 4,
              zIndex: 10,
              width: 80,
              height: 80,
            }}
          >
            {previewLogo ? (
              <img
                src={previewLogo}
                alt={previewLogoNameAlt || "Logo"}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            ) : previewLogoNameAlt ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#e1a731",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {previewLogoNameAlt.charAt(0).toUpperCase()}
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }, [previewLogo, previewLogoNameAlt]);

  const bannerPreviewCarousel = useMemo(() => {
    if (!banners) {
      return null;
    }

    const bannersSortedByPosition = [...banners].sort(
      (a, b) => a.position - b.position
    );

    return (
      <Carousel
        navButtonsAlwaysInvisible
        animation="slide"
        // @ts-ignore
        duration={3000}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: 50,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            margin: "0 5px",
            color: "white", // This controls the inactive indicator color
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#E1A731", // This controls the active indicator color
          },
        }}
        className={classes.previewCarousel}
      >
        {bannersSortedByPosition.map((item, i) => {
          if (item.contentType === BannerContentTypeEnum.Image) {
            return (
              <div
                style={{
                  marginBottom: theme.spacing(2.5),
                  height: "80%",
                  background: "white",
                  border: "0.5px solid #6F6F71",
                  borderRadius: "5px",
                  overflow: "hidden",
                  position: "relative",
                  aspectRatio: "16 / 9",
                }}
              >
                <img
                  src={item.contentUrl}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
                {previewLogoOverlay}
              </div>
            );
          }

          const youtubeEmbedUrl = `https://www.youtube.com/embed/${
            urlParser.parse(item?.contentUrl || "")?.id
          }?mute=1&autoplay=0&fs=0&loop=1&playsinline=1&color=white&rel=0`;

          return (
            <div
              key={i}
              style={{
                marginBottom: theme.spacing(2.5),
                width: "100%",
                background: "white",
                border: "0.5px solid #6F6F71",
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <iframe
                src={youtubeEmbedUrl}
                title="YouTube video player"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  border: "unset",
                }}
              />

              {previewLogoOverlay}
            </div>
          );
        })}
      </Carousel>
    );
  }, [banners, classes.previewCarousel, previewLogoOverlay]);

  useEffect(() => {
    // Add a global style to ensure SVG circles inherit their parent button's color
    const style = document.createElement("style");
    style.innerHTML = `
      .Carousel-indicatorIcon svg circle, .MuiSvgIcon-root circle {
        fill: currentColor !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        borderRadius: "15px",
        marginTop:
          pickerType === "default" ||
          pickerType === "perk" ||
          pickerType === "memberProfile"
            ? `${marginTopPx}px`
            : 0,
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        border: "1px solid black",
        backgroundColor: "#F2F2F6",
        color: "#6F6F71",
      }}
    >
      {mediaUploadModalOpen && (
        <MediaUploadModal
          bannerUpdateInProgress={bannerUpdateInProgress}
          modalOpenedForBannerSlide={modalOpenedForBannerSlide}
          handleBannerUpdate={handleBannerUpdate}
          modalOpen={mediaUploadModalOpen}
          handleClose={hanleMediaUploadModalClose}
          slidePosition={mediaUploadModalOpenForSlideInPosition}
          saveButtonText={
            pickerType === "default" ||
            pickerType === "perk" ||
            pickerType === "memberProfile"
              ? "Save Banner"
              : "Update Banner"
          }
        />
      )}
      {slidePositionToDeleteAfterConifrmingInAreYouSureModal !== undefined && (
        <Modal
          className={classes.modal}
          open={
            slidePositionToDeleteAfterConifrmingInAreYouSureModal !== undefined
          }
        >
          <div
            className={classes.paper}
            style={{
              height: "350px",
              backgroundColor: "#cfd8dc",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(5),
                paddingTop: theme.spacing(8),
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {enumStrings.BannerPicker.delete.deleteTitle}
                {pickerType !== "categoryAdvertisingChamberAdmin" &&
                pickerType !== "categoryAdvertisingChamberMemberAdmin" ? (
                  <>
                    {"\n\n"}
                    <span style={{ fontWeight: "bold" }}>
                      {enumStrings.BannerPicker.delete.deleteText}
                    </span>{" "}
                    {enumStrings.BannerPicker.delete.deleteDescription}
                  </>
                ) : null}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                padding: theme.spacing(3),
                paddingBottom: theme.spacing(5),
                paddingTop: 0,
              }}
            >
              <Button
                disabled={bannerUpdateInProgress}
                className={classes.yesButton}
                style={{
                  maxWidth: "200px",
                }}
                onClick={() => {
                  handleBannerUpdate({
                    updatedBanner: undefined,
                    slidePosition:
                      slidePositionToDeleteAfterConifrmingInAreYouSureModal,
                  });
                }}
                variant="contained"
              >
                {bannerUpdateInProgress ? (
                  <CircularProgress
                    size={30}
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  "Delete"
                )}
              </Button>
              <Button
                disabled={bannerUpdateInProgress}
                className={classes.okButton}
                style={{
                  maxWidth: "200px",
                }}
                onClick={() =>
                  setSlidePositionToDeleteAfterConifrmingInAreYouSureModal(
                    undefined
                  )
                }
                variant="contained"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {bannerPreviewOpen && (
        <Modal className={classes.modal} open={bannerPreviewOpen}>
          <div
            className={classes.paper}
            style={{
              height: "440px",
              backgroundColor: "#cfd8dc",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(5),
                paddingTop: theme.spacing(7),
                paddingBottom: 0,
                textAlign: "center",
              }}
            >
              {bannerPreviewCarousel}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                padding: theme.spacing(3),
                paddingBottom: theme.spacing(5),
                paddingTop: 0,
              }}
            >
              <Button
                className={classes.okButton}
                style={{
                  maxWidth: "200px",
                }}
                onClick={() => setBannerPreviewOpen(false)}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <h1
        style={{
          marginBottom: "0px",
          marginTop: "0px",
          marginLeft: -2,
          color: "#6F6F71",
        }}
      >
        {pickerLabel}
      </h1>
      <h3
        style={{
          marginBottom: "0px",
          marginTop: "0px",
          marginLeft: 0,
          color: "#6F6F71",
        }}
      >
        {enumStrings.BannerPicker.upload.uploadImageVideo.replace(
          "{{maxBannerSlides}}",
          maxBannerSlides.toString()
        )}
        {pickerType === "categoryAdvertisingChamberMemberAdmin"
          ? `. ${enumStrings.hoverTooltip.imageDescription.replace(
              "{{chamberName}}",
              chamberName || ""
            )}`
          : ""}
        {pickerType === enumStrings.hoverTooltip.memberProfile
          ? enumStrings.hoverTooltip.profile
          : ""}
        {pickerType === enumStrings.hoverTooltip.perk
          ? enumStrings.hoverTooltip.perkPromote
          : ""}
        . {enumStrings.bannerPicker.minSizeRequirementForBanner}{" "}
        {enumStrings.hoverTooltip.recommend}
      </h3>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {slideBoxes}
      </div>
      {pickerType === "categoryAdvertisingChamberAdmin" &&
      handleAutoApproveBannerContentUpdate ? (
        <>
          <div style={{ marginTop: "20px" }} />
          <AutoApproveBannerContentToggle
            checked={autoApproveBannerContentUpdate ?? false}
            onChange={handleAutoApproveBannerContentUpdate}
            showConfirmationDialog
            disabled={
              changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading
            }
          />
        </>
      ) : null}

      {(banners || []).length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: theme.spacing(3.5),
            marginBottom: theme.spacing(1.2),
          }}
        >
          <Button
            className={classes.okButton}
            style={{
              maxWidth: "245px",
              width: "245px",
            }}
            onClick={() => {
              setBannerPreviewOpen(true);
            }}
            variant="contained"
          >
            {enumStrings.BannerPicker.previewBanners}
          </Button>
        </div>
      )}
    </div>
  );
};

const SlideBox = ({
  bannerSlide,
  position = 1,
  highestPosition = 1,
  handleMediaUploadModalOpen,
  handleAreYouSureDeleteSlideModalOpen,
  handleMoveSlidePosition,
  hideHeading,
  type = "standard",
  atLeastOneBannerSlide = false,
}: {
  bannerSlide?: BannerInput | BannerReturn | undefined | null;
  position?: number;
  highestPosition?: number;
  handleMediaUploadModalOpen: ({
    slidePosition,
  }: {
    slidePosition: number;
  }) => void;
  handleAreYouSureDeleteSlideModalOpen: ({
    slidePositionToDelete,
  }: {
    slidePositionToDelete: number;
  }) => void;
  handleMoveSlidePosition?: ({
    move,
    slidePosition,
  }: {
    move: "left" | "right";
    slidePosition: number;
  }) => void;
  hideHeading?: boolean;
  type?: "standard" | "categoryAdvertising";
  atLeastOneBannerSlide?: boolean;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  return (
    <div
      className={classes.slideBoxRoot}
      style={{
        ...(type === "categoryAdvertising"
          ? {
              width: "113px",
            }
          : {}),
      }}
    >
      {hideHeading ? null : (
        <h2
          style={{
            margin: 0,
            color: "#6F6F71",
            textAlign: "center",
          }}
        >
          {enumStrings.bannerPicker.banner} {position}
        </h2>
      )}
      <div
        className={classes.slideBoxContent}
        style={{
          borderBottomLeftRadius: bannerSlide ? 0 : 5,
          borderBottomRightRadius: bannerSlide ? 0 : 5,
          ...(type === "categoryAdvertising"
            ? {
                height: "63.5px",
                width: "113px",
              }
            : {}),
        }}
        onClick={() =>
          handleMediaUploadModalOpen({
            slidePosition: position,
          })
        }
      >
        <div>
          {!bannerSlide ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddIcon
                style={{
                  color: "#6F6F71",
                  fontSize: type === "categoryAdvertising" ? 35 : 50,
                  marginTop: 2,
                }}
              />
              <h4 style={{ marginTop: -7, color: "#6F6F71" }}>
                {enumStrings.manageMemberProfile.clicktoUpload}
              </h4>
            </div>
          ) : (
            <>
              {bannerSlide.contentType === BannerContentTypeEnum.Image ? (
                <div
                  style={{
                    height: "100%",
                    background: "white",
                    borderTop: "0.5px solid #6F6F71",
                    borderBottom: "0.5px solid #6F6F71",
                    overflow: "hidden",
                    position: "relative",
                    aspectRatio: "16 / 9",
                  }}
                >
                  <img
                    src={bannerSlide.contentUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </div>
              ) : (
                <h2 style={{ color: "#6F6F71", margin: 0 }}>Video</h2>
              )}
            </>
          )}
        </div>
      </div>
      {bannerSlide ? (
        <div
          style={{
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            border: "1px solid #6F6F71",
            borderTop: "unset",
          }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: 5,
              paddingRight: 5,
              paddingTop: 3,
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <EditIcon
              onClick={() =>
                handleMediaUploadModalOpen({
                  slidePosition: position,
                })
              }
              style={{
                cursor: "pointer",
                color: "#6F6F71",
                fontSize: type === "categoryAdvertising" ? 25 : 30,
                margin: 0,
              }}
            />
            <DeleteIcon
              onClick={() =>
                handleAreYouSureDeleteSlideModalOpen({
                  slidePositionToDelete: position,
                })
              }
              style={{
                cursor: "pointer",
                color: "#6F6F71",
                fontSize: type === "categoryAdvertising" ? 25 : 30,
                margin: 0,
              }}
            />
          </div>
          {type === "standard" && handleMoveSlidePosition && (
            <div
              style={{
                display: "flex",
                paddingLeft: 5,
                paddingRight: 5,
                flexDirection: "row",
                justifyContent: "space-around",

                alignItems: "center",
              }}
            >
              <ChevronLeftIcon
                onClick={() => {
                  if (position > 1) {
                    handleMoveSlidePosition({
                      move: "left",
                      slidePosition: position,
                    });
                  }
                }}
                style={{
                  cursor: position === 1 ? "unset" : "pointer",
                  opacity: position === 1 ? 0.4 : 1,
                  fontSize: 30,
                  margin: 0,
                }}
              />
              <ChevronRightIcon
                onClick={() => {
                  if (position < maxBannerSlides) {
                    handleMoveSlidePosition({
                      move: "right",
                      slidePosition: position,
                    });
                  }
                }}
                style={{
                  cursor:
                    position === maxBannerSlides || position === highestPosition
                      ? "unset"
                      : "pointer",
                  opacity:
                    position === maxBannerSlides || position === highestPosition
                      ? 0.4
                      : 1,
                  fontSize: 30,
                  margin: 0,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {type === "standard" && (
            <div
              style={{
                height: atLeastOneBannerSlide ? 64 : 5,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

const maxImageMb = 1;

const convertToBase64 = async (imageFile: any) => {
  const options = {
    maxSizeMB: maxImageMb,
  };
  try {
    const imageSizeInMb = imageFile.size / 1024 / 1024;

    // compressing files greater than 5MB to ensure they can be uploaded since aws api gateway has a 10MB limit
    if (imageSizeInMb > maxImageMb) {
      const compressedFile = await imageCompression(imageFile, options);
      return await imageCompression.getDataUrlFromFile(compressedFile);
    }

    return await imageCompression.getDataUrlFromFile(imageFile);
  } catch (error) {
    console.log(error);
  }
};

const MediaUploadModal = ({
  modalOpenedForBannerSlide,
  handleBannerUpdate,
  modalOpen,
  handleClose,
  slidePosition = 1,
  bannerUpdateInProgress,
  saveButtonText = "Save Banner",
}: {
  modalOpenedForBannerSlide?: BannerInput | BannerReturn | undefined | null;
  handleBannerUpdate: ({
    updatedBanner,
    slidePosition,
  }: {
    updatedBanner?: BannerInput | BannerReturn | undefined | null;
    slidePosition?: number;
  }) => void;
  modalOpen: boolean;
  handleClose: (forceClose?: boolean) => void;
  slidePosition?: number;
  bannerUpdateInProgress?: boolean;
  saveButtonText?: "Save Banner" | "Update Banner";
}) => {
  const classes = useStyles();

  const [tempBannerToSeeBeforeUpdating, setTempBannerToSeeBeforeUpdating] =
    useState<BannerInput | BannerReturn | undefined | null>(
      modalOpenedForBannerSlide
    );

  const [showBadYouTubeUrlError, setShowBadYouTubeUrlError] = useState(false);
  const [showBadImageLinkError, setShowBadImageLinkError] = useState(false);

  const handleSaveSlideClick = useCallback(() => {
    if (
      tempBannerToSeeBeforeUpdating?.contentType ===
        BannerContentTypeEnum.Video &&
      (tempBannerToSeeBeforeUpdating?.contentUrl || "").length > 0
    ) {
      // validate that provided video url is a valid youtube video url
      const info = urlParser.parse(
        tempBannerToSeeBeforeUpdating.contentUrl
      ) as YouTubeParseResult;

      if (
        !info ||
        info?.provider !== "youtube" ||
        info?.mediaType !== "video" ||
        (info?.id || "").length === 0
      ) {
        setShowBadYouTubeUrlError(true);
        return;
      }
    }

    if (
      tempBannerToSeeBeforeUpdating?.contentType ===
        BannerContentTypeEnum.Image &&
      (tempBannerToSeeBeforeUpdating?.imageLink || "").length > 0
    ) {
      if (
        !validWebsiteRegEx.test(tempBannerToSeeBeforeUpdating?.imageLink || "")
      ) {
        setShowBadImageLinkError(true);
        return;
      }
    }

    let bannerToSet = tempBannerToSeeBeforeUpdating;
    if ((tempBannerToSeeBeforeUpdating?.contentUrl || "").length === 0) {
      bannerToSet = undefined;
    }

    handleBannerUpdate({
      updatedBanner: bannerToSet,
      slidePosition,
    });
  }, [handleBannerUpdate, slidePosition, tempBannerToSeeBeforeUpdating]);

  const initialTopBarActive = useMemo(() => {
    if (
      modalOpenedForBannerSlide?.contentType === BannerContentTypeEnum.Image
    ) {
      return 0;
    }

    if (
      modalOpenedForBannerSlide?.contentType === BannerContentTypeEnum.Video
    ) {
      return 1;
    }

    return 0;
  }, [modalOpenedForBannerSlide?.contentType]);

  const [tabValue, setTabValue] = useState(initialTopBarActive);

  const handleTabChange = (event: any, newTabValue: any) => {
    setTabValue(newTabValue);
  };

  const uniqueId = `contained-button-file-${uuidv4()}`;

  const [newImageLoading, setNewImageLoading] = useState(false);

  const handleSetNewImage = useCallback(
    async (e) => {
      setNewImageLoading(false);
      setShowBadYouTubeUrlError(false);

      const file = e.target.files[0];

      // only show loader if image size larger than 5mb
      if (file && file.size / 1024 / 1024 > maxImageMb) {
        setNewImageLoading(true);
      }

      const base64 = await convertToBase64(file);

      setNewImageLoading(false);

      if (!tempBannerToSeeBeforeUpdating) {
        setTempBannerToSeeBeforeUpdating({
          contentUrl: base64 as any as string,
          contentType: BannerContentTypeEnum.Image,
          position: slidePosition,
          imageLink: null,
        });
        return;
      }

      setTempBannerToSeeBeforeUpdating({
        ...tempBannerToSeeBeforeUpdating,
        contentUrl: base64 as any as string,
        contentType: BannerContentTypeEnum.Image,
        imageLink: null,
      });
    },
    [slidePosition, tempBannerToSeeBeforeUpdating]
  );

  const handleDeleteImage = useCallback(() => {
    setTempBannerToSeeBeforeUpdating(undefined);
  }, []);

  const handleChangeYoutubeVideoUrl = useCallback(
    ({ vidUrl }: { vidUrl: string }) => {
      setShowBadImageLinkError(false);

      setTempBannerToSeeBeforeUpdating({
        contentUrl: vidUrl,
        contentType: BannerContentTypeEnum.Video,
        position: slidePosition,
        imageLink: null,
      });
    },
    [slidePosition]
  );

  const handleChangeImageLink = useCallback(
    ({ imageLink }: { imageLink: string }) => {
      setTempBannerToSeeBeforeUpdating({
        contentUrl: tempBannerToSeeBeforeUpdating?.contentUrl || "",
        contentType: BannerContentTypeEnum.Image,
        position: slidePosition,
        imageLink,
      });
    },
    [slidePosition, tempBannerToSeeBeforeUpdating?.contentUrl]
  );

  const imagePresentInSlide = useMemo(() => {
    return (
      (tempBannerToSeeBeforeUpdating?.contentUrl || "").length > 0 &&
      tempBannerToSeeBeforeUpdating?.contentType === BannerContentTypeEnum.Image
    );
  }, [
    tempBannerToSeeBeforeUpdating?.contentUrl,
    tempBannerToSeeBeforeUpdating?.contentType,
  ]);

  const videoPresentInSlide = useMemo(() => {
    return (
      (tempBannerToSeeBeforeUpdating?.contentUrl || "").length > 0 &&
      tempBannerToSeeBeforeUpdating?.contentType === BannerContentTypeEnum.Video
    );
  }, [
    tempBannerToSeeBeforeUpdating?.contentUrl,
    tempBannerToSeeBeforeUpdating?.contentType,
  ]);
  const enumStrings = useEnumStrings();

  const middleComponent = useMemo(() => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            marginTop: -theme.spacing(1.5),
            color: "#6F6F71",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {enumStrings.bannerPicker.minSizeRequirementForBanner}{" "}
          {enumStrings.BannerPicker.upload.uploadText}
        </h4>
        <div
          style={{
            flex: 1,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {tabValue === 0 ? (
            <>
              {(imagePresentInSlide || newImageLoading) && (
                <div
                  style={{
                    marginBottom: theme.spacing(2),
                    height: "80%",
                    background: "white",
                    border: "0.5px solid #6F6F71",
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                    aspectRatio: "16 / 9",
                  }}
                >
                  {newImageLoading ? (
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CircularProgress
                        size={30}
                        style={{
                          color: "#E1A731",
                        }}
                      />
                      <span style={{ marginTop: 20 }}>
                        Loading please wait...
                      </span>
                    </div>
                  ) : (
                    <img
                      src={tempBannerToSeeBeforeUpdating?.contentUrl}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <label htmlFor={uniqueId}>
                  <div
                    style={{
                      cursor: videoPresentInSlide ? "unset" : "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "fit-content",
                    }}
                    onClick={(e) => {
                      if (videoPresentInSlide) {
                        e.preventDefault();
                        return;
                      }
                    }}
                  >
                    <FolderIcon
                      style={{
                        fontSize: "40px",
                        color: videoPresentInSlide ? "#6F6F71" : "#37474f",
                      }}
                    />
                    <h2
                      style={{
                        margin: 0,
                        marginLeft: 10,
                        color: videoPresentInSlide ? "#6F6F71" : "#37474f",
                      }}
                    >
                      {imagePresentInSlide
                        ? enumStrings.BannerPicker.upload.changeImage
                        : enumStrings.BannerPicker.upload.clickToUpload}
                    </h2>
                  </div>
                </label>
                {videoPresentInSlide && (
                  <h3
                    style={{
                      maxWidth: "80%",
                      margin: 0,
                      marginTop: theme.spacing(1),
                      color: "#37474f",
                    }}
                  >
                    {enumStrings.BannerPicker.upload.youtubeDeleteDescription}
                  </h3>
                )}
                {imagePresentInSlide && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "fit-content",
                    }}
                    onClick={handleDeleteImage}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: "40px",
                        color: "black",
                      }}
                    />
                    <h2 style={{ margin: 0, marginLeft: 10, color: "#37474f" }}>
                      {enumStrings.BannerPicker.upload.deleteImage}
                    </h2>
                  </div>
                )}
              </div>
              {imagePresentInSlide && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    type="text"
                    label={enumStrings.BannerPicker.upload.ImageLink}
                    className={classes.imageLinkInput}
                    variant="outlined"
                    id="name"
                    name="name"
                    disabled={videoPresentInSlide}
                    value={
                      tempBannerToSeeBeforeUpdating?.contentType ===
                      BannerContentTypeEnum.Image
                        ? tempBannerToSeeBeforeUpdating?.imageLink || ""
                        : ""
                    }
                    onChange={(e) => {
                      setShowBadImageLinkError(false);
                      handleChangeImageLink({ imageLink: e.target.value });
                    }}
                  />
                  <div style={{ marginTop: "14px" }}>
                    <HoverToolTip
                      description={enumStrings.bannerPicker.imageLink}
                      showIt
                    />
                  </div>
                </div>
              )}
              {showBadImageLinkError && (
                <h3
                  style={{
                    margin: 0,
                    marginTop: theme.spacing(1),
                    color: "red",
                  }}
                >
                  {enumStrings.validImageLink}
                </h3>
              )}
              <input
                id={uniqueId}
                type="file"
                accept="image/*, png, jpeg, jpg"
                style={{ display: "none" }}
                name={`logo-${uuidv4()}`}
                onChange={handleSetNewImage}
              />
            </>
          ) : (
            <div
              style={{
                width: "80%",
              }}
            >
              <TextField
                type="text"
                label={enumStrings.BannerPicker.upload.youtubeVideo}
                className={classes.youtubeUrlInput}
                variant="outlined"
                id="name"
                name="name"
                disabled={imagePresentInSlide}
                value={
                  tempBannerToSeeBeforeUpdating?.contentType ===
                  BannerContentTypeEnum.Video
                    ? tempBannerToSeeBeforeUpdating?.contentUrl
                    : ""
                }
                onChange={(e) => {
                  setShowBadYouTubeUrlError(false);
                  handleChangeYoutubeVideoUrl({ vidUrl: e.target.value });
                }}
              />
              {imagePresentInSlide && (
                <h3
                  style={{
                    margin: 0,
                    marginTop: theme.spacing(1),
                    color: "#37474f",
                  }}
                >
                  {enumStrings.BannerPicker.upload.youtubeDescription}
                </h3>
              )}
              {showBadYouTubeUrlError && (
                <h3
                  style={{
                    margin: 0,
                    marginTop: theme.spacing(1),
                    color: "red",
                  }}
                >
                  {enumStrings.hoverTooltip.youtubeURL}
                </h3>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    classes.imageLinkInput,
    classes.youtubeUrlInput,
    enumStrings.BannerPicker.upload.ImageLink,
    enumStrings.BannerPicker.upload.changeImage,
    enumStrings.BannerPicker.upload.clickToUpload,
    enumStrings.BannerPicker.upload.deleteImage,
    enumStrings.BannerPicker.upload.uploadText,
    enumStrings.BannerPicker.upload.youtubeDeleteDescription,
    enumStrings.BannerPicker.upload.youtubeDescription,
    enumStrings.BannerPicker.upload.youtubeVideo,
    enumStrings.bannerPicker.imageLink,
    enumStrings.bannerPicker.minSizeRequirementForBanner,
    enumStrings.hoverTooltip.youtubeURL,
    enumStrings.validImageLink,
    handleChangeImageLink,
    handleChangeYoutubeVideoUrl,
    handleDeleteImage,
    handleSetNewImage,
    imagePresentInSlide,
    newImageLoading,
    showBadImageLinkError,
    showBadYouTubeUrlError,
    tabValue,
    tempBannerToSeeBeforeUpdating?.contentType,
    tempBannerToSeeBeforeUpdating?.contentUrl,
    tempBannerToSeeBeforeUpdating?.imageLink,
    uniqueId,
    videoPresentInSlide,
  ]);

  if (!modalOpen) {
    return null;
  }

  return (
    <Modal className={classes.modal} open={modalOpen}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: 75,
          }}
        >
          <Tabs
            centered
            className={classes.mediaUploadModalTabRoot}
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab
              className={classes.mediaUploadModalIndividualTab}
              icon={
                <ImageIcon
                  style={{ color: "#6F6F71", fontSize: 35, margin: 0 }}
                />
              }
              label="IMAGE"
            />
            <Tab
              className={classes.mediaUploadModalIndividualTab}
              icon={
                <VideoIcon
                  style={{ color: "#6F6F71", fontSize: 35, margin: 0 }}
                />
              }
              label="VIDEO"
            />
          </Tabs>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: theme.spacing(2),
              paddingRight: theme.spacing(2),
              position: "absolute",
              right: 0,
            }}
            onClick={() => {
              if (!bannerUpdateInProgress) {
                handleClose(true);
              }
            }}
          >
            <CloseIcon style={{ color: "#6F6F71", fontSize: 40, margin: 0 }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              borderTop: "0.5px solid lightgray",
              backgroundColor: "#F2F2F6",
              color: "#6F6F71",
            }}
          >
            {middleComponent}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
            paddingTop: 0,
          }}
        >
          <h4
            style={{
              marginTop: theme.spacing(1.5),
              marginBottom: theme.spacing(1.5),
              color: "#6F6F71",
            }}
          >
            {enumStrings.BannerPicker.upload.description} {saveButtonText}
            {saveButtonText === "Save Banner"
              ? enumStrings.BannerPicker.upload.clickDescription
              : ""}
            .
          </h4>
          <Button
            className={classes.yesButton}
            onClick={handleSaveSlideClick}
            variant="contained"
            disabled={bannerUpdateInProgress}
          >
            {bannerUpdateInProgress ? (
              <CircularProgress
                size={30}
                style={{
                  color: "white",
                }}
              />
            ) : (
              saveButtonText
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BannerPicker;
