import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  InputLabel,
  makeStyles,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  SwitchProps,
  styled,
  CircularProgress,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  DayEnum,
  DiscountInput,
  DiscountReturn,
  useGetDiscountQuery,
  useDeleteDiscountMutation,
  UserType,
  useUpdateDiscountMutation,
  WhereToRedeemEnum,
  useGetActiveShopLocalCampaignForChamberMemberQuery,
} from "../../../graphql/types";
import useRedirect from "../../common/Hooks/useRedirect";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import ConfirmationModal, {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import { UserTypeContext } from "../../../UserTypeContext";
import RedeemOfferForm from "../../common/utilities/RedeemOffer";
import omitDeep from "omit-deep-lodash";
import DiscountOfferedToRadioGroup from "../../common/DiscountOfferedToRadioGroup";
import DiscountRedemptionOptionRadioGroup from "../../common/DiscountRedemptionOptionRadioGroup";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import PerkNotificationSwitch, {
  checkIfPerkNotificationIsEnabledForSelectedAvailability,
} from "../../common/PerkNotificationSwitch";
import { oneOfContactEmailOrContactNumberRequiredYupValidationFunction } from "./CreateDiscount";
import OnlyOfferDuringCertianMonthsForm from "../../common/utilities/OnlyOfferDuringCertainMonthsForm";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import useIsMemberProfileAndPerksBannersActiveForChamberMember from "../../common/Hooks/useCheckIfMemberProfileAndPerksBannersActiveForChamberMember";
import BannerPicker from "../../common/BannerPicker";
import BannerPickerAgreementOverlay from "../../common/BannerPickerAgreementOverlay";
import useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement from "../../common/Hooks/useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement";
import ChamberMemberMemberProfileAndPerksBannersAgreementModal from "../../common/ChamberMemberMemberProfileAndPerksBannersAgreementModal";
import { validWebsiteRegEx } from "../../common/utilities/regexes";

const useStyles = makeStyles((themes: any) => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  notificationDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#00000000",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      transform: "scaleX(0)",
    },
    "& .MuiFilledInput-underline:before": {
      content: "none",
    },
    "& .MuiFilledInput-underline:after": {
      content: "none",
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: "yellow",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",

    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    paddingBottom: "33px",
    minHeight: "85vh",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },

  templateSubtitle: {
    textAlign: "center",
    fontSize: "2.5vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5vw",
    },
  },

  notificationRadioGroup: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "5px",
  },
  radio: {
    color: "#E1A731!important",
  },
  notificationCreationContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    background: "#F2F2F6",
    alignContent: "center",
    textAlign: "center",
    width: "265px",
    borderRadius: "7px 7px 0px 0px",
    margin: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  individualNotificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    background: "#F2F2F6",
  },
  individualNotificationType: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationDate: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationTrashIcon: {
    fontSize: "21px",
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    height: "52px",
    width: "56px",
  },
  notificationTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  notificationContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "35px",
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  toggleSwitchWithTitle: {
    display: "flex",
    margin: "auto",
    alignItems: "baseline",
    marginTop: "35px",
    justifyContent: "center",
    textAlign: "center",
  },
  dayCheckbox: {
    color: "#E1A731!important",
    alignItems: "center",
    textAlign: "center",
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));
const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const paddingLeftPx = 33;

// Helper function to check if two timestamps are on the same day
const isSameDay = (timestamp1: number, timestamp2: number): boolean => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const ChamberMemberUpdateDiscount = (): ReactElement => {
  const enumStrings = useEnumStrings();

  const validationSchema = yup
    .object({
      title: yup.string().required(enumStrings.perkTitleText),
      description: yup.string().nullable(),
      memberOnly: yup.boolean(),
      isReoccuring: yup.boolean(),
      daysAvailable: yup.lazy((value) => {
        switch (typeof value) {
          case "undefined":
            return yup.mixed().nullable();
          default:
            return yup.array().min(1, "Select at least one day").nullable();
        }
      }),
      monthsAvailable: yup.lazy((value) => {
        switch (typeof value) {
          case "undefined":
            return yup.mixed().nullable();
          default:
            return yup.array().min(1, "Select at least one month").nullable();
        }
      }),
      redeemSettings: yup.object({
        whereToRedeem: yup.string().required("Where to redeem is required"),
        howToRedeem: yup
          .object()
          .when("whereToRedeem", {
            is: WhereToRedeemEnum.Online,
            then: yup
              .object({
                code: yup.string().required("Code is required"),
                websiteToEnterCodeAt: yup
                  .string()
                  .matches(validWebsiteRegEx, "Enter a valid website")
                  .required("Website is required"),
              })
              .required(),
          })
          .when("whereToRedeem", {
            is: WhereToRedeemEnum.ContactCompany,
            then: yup
              .object({
                contactEmail: yup
                  .string()
                  .matches(emailRegex, "Enter a valid email"),
                contactNumber: yup
                  .string()
                  .matches(phoneRegExp, "Enter a valid phone number"),
                contactName: yup.string().required("Contact name is required"),
              })
              .required(),
          })
          .when("whereToRedeem", {
            is: WhereToRedeemEnum.InPerson,
            then: yup.object(),
          }),
      }),
    })
    .test(
      "",
      "",
      oneOfContactEmailOrContactNumberRequiredYupValidationFunction
    );
  const location = useLocation();
  const discountId = useMemo(() => {
    return (location.state as DiscountReturn).id;
  }, [location.state]);

  const chamberMemberName = useMemo(() => {
    return (location.state as DiscountReturn)?.chamberMemberInfo?.name || "";
  }, [location.state]);
  const activeMemberProfileAndPerksBannersSettings = useMemo(() => {
    return (
      (location.state as DiscountReturn)?.chamberMemberInfo
        ?.activeMemberProfileAndPerksBannersSettings || undefined
    );
  }, [location.state]);

  const classes = useStyles();
  const CHARACTER_LIMIT_TITLE = 50;
  const CHARACTER_LIMIT_DESCRIPTION = 250;
  const {
    state: { userType },
  } = useContext(UserTypeContext);
  const { data, loading: dataLoading } = useGetDiscountQuery({
    variables: {
      discountId,
    },
  });

  const updateDiscountObj = useMemo(() => {
    if (userType === UserType.ChamberAdmin) {
      return enumStrings.groups[userType][
        // @ts-ignore
        RedirectUri.editDiscountPageChamberAdmin
      ];
    }
    return enumStrings.groups[UserType.ChamberMemberAdmin][
      RedirectUri.editDiscountChamberMemberAdmin
    ];
  }, [enumStrings.groups, userType]);

  const [isDayToggleChecked, setDayToggle] = useState(false);
  const [isMonthToggleChecked, setMonthToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [invalidError, setInvalidError] = useState(false);
  const [updateButtonText, setUpdateButtonText] = useState("Update");

  useEffect(() => {
    setUpdateButtonText(enumStrings.manageChamberEngage.update);
  }, [enumStrings.manageChamberEngage.update]);

  const [open, setOpen] = useState(false);
  const customError =
    enumStrings.shopLocal.updateChamberShopLocalCampaign.anErrorOccurred;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const redirectToDiscountList = useRedirect(
    userType === UserType.ChamberAdmin
      ? RedirectUri.memberDiscountPageChamberAdmin
      : RedirectUri.manageDiscountChamberMemberAdmin
  );

  const [deleteDiscount] = useDeleteDiscountMutation({
    variables: { id: discountId },
  });

  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoading(true);
    await deleteDiscount();
    setOpen(false);
    redirectToDiscountList();
  }, [deleteDiscount, redirectToDiscountList]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleClose,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const [updateDiscount] = useUpdateDiscountMutation();

  // Check if notification should be disabled (updated today with notification sent)
  const wasUpdatedTodayWithNotification = useMemo(() => {
    // Access updatedAtUtcMilli and notify using any type assertion
    const updatedAtUtcMilli = data?.getDiscount
      ? (data.getDiscount as any).updatedAtUtcMilli
      : null;
    const notifyWasSent = data?.getDiscount
      ? (data.getDiscount as any).notify
      : false;

    // If either condition isn't met, return false
    if (!updatedAtUtcMilli || !notifyWasSent) {
      return false;
    }

    // Check if update happened today
    const isUpdateFromToday = isSameDay(
      updatedAtUtcMilli,
      new Date().getTime()
    );

    // Return true ONLY if both conditions are met:
    // 1. Update happened today
    // 2. Notify was true
    return isUpdateFromToday && notifyWasSent;
  }, [data?.getDiscount]);

  const intitalValuesMemo = useMemo(() => {
    const initialValues: DiscountInput = {
      id: data?.getDiscount?.id || "",
      title: data?.getDiscount?.title || "",
      description: data?.getDiscount?.description,
      chamberId: data?.getDiscount?.chamberId || "",
      chamberMemberId: data?.getDiscount?.chamberMemberId || "",
      memberOnly: data?.getDiscount?.memberOnly || false,
      isAvailableToAllMembers:
        data?.getDiscount?.isAvailableToAllMembers || false,
      isReoccuring: data?.getDiscount?.isReoccuring || false,
      daysAvailable: data?.getDiscount?.daysAvailable,
      monthsAvailable: data?.getDiscount?.monthsAvailable,
      isPrivate: data?.getDiscount?.isPrivate || false,
      privateEmails: data?.getDiscount?.privateEmails || [],
      redeemSettings: data?.getDiscount?.redeemSettings || {
        whereToRedeem: WhereToRedeemEnum.InPerson,
        howToRedeem: {
          code: "",
          websiteToEnterCodeAt: "",
          contactEmail: "",
          contactName: "",
          contactNumber: "",
        },
      },
      startTimeUtcMilli: data?.getDiscount?.startTimeUtcMilli || undefined,
      expiryTimeUtcMilli: data?.getDiscount?.expiryTimeUtcMilli || undefined,
      notify: wasUpdatedTodayWithNotification === false,
      banner: data?.getDiscount?.banner || undefined,
    };
    return omitDeep(initialValues, "__typename");
  }, [
    data?.getDiscount?.banner,
    data?.getDiscount?.chamberId,
    data?.getDiscount?.chamberMemberId,
    data?.getDiscount?.daysAvailable,
    data?.getDiscount?.description,
    data?.getDiscount?.expiryTimeUtcMilli,
    data?.getDiscount?.id,
    data?.getDiscount?.isAvailableToAllMembers,
    data?.getDiscount?.isPrivate,
    data?.getDiscount?.isReoccuring,
    data?.getDiscount?.memberOnly,
    data?.getDiscount?.monthsAvailable,
    data?.getDiscount?.privateEmails,
    data?.getDiscount?.redeemSettings,
    data?.getDiscount?.startTimeUtcMilli,
    data?.getDiscount?.title,
    wasUpdatedTodayWithNotification,
  ]) as unknown as DiscountInput;

  const { data: activeShopLocalCampaignForChamberMemberData } =
    useGetActiveShopLocalCampaignForChamberMemberQuery({
      skip: userType !== UserType.ChamberMemberAdmin,
    });

  const [
    activeShopLocalCampaignModalOpen,
    setActiveShopLocalCampaignModalOpen,
  ] = useState(false);

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (input: DiscountInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        const isPerkNotificationEnabledForSelectedAvailability =
          checkIfPerkNotificationIsEnabledForSelectedAvailability({
            daysAvailable: input.daysAvailable,
            monthsAvailable: input.monthsAvailable,
            startTimeUtcMilli: input.startTimeUtcMilli,
            expiryTimeUtcMilli: input.expiryTimeUtcMilli,
          });

        await updateDiscount({
          variables: {
            id: discountId,
            input: {
              chamberId: data?.getDiscount?.chamberId || "",
              chamberMemberId: data?.getDiscount?.chamberMemberId || "",
              id: discountId,
              isReoccuring: input.isReoccuring || false,
              memberOnly: input.isAvailableToAllMembers || input.memberOnly,
              isAvailableToAllMembers: input.isAvailableToAllMembers,
              title: input.title.trim(),
              daysAvailable: input.daysAvailable,
              description:
                input.description && typeof input.description === "string"
                  ? input.description.trim()
                  : undefined,
              monthsAvailable: input.monthsAvailable,
              isPrivate: input.isPrivate,
              privateEmails: input.isPrivate
                ? input.privateEmails || []
                : undefined,
              redeemSettings: {
                whereToRedeem: input.redeemSettings.whereToRedeem,
                howToRedeem: input.redeemSettings.howToRedeem || undefined,
              },
              // startTimeUtcMilli & expiryTimeUtcMilli can only be set if BOTH input.startTimeUtcMilli and input.expiryTimeUtcMilli are given
              startTimeUtcMilli:
                input.startTimeUtcMilli && input.expiryTimeUtcMilli
                  ? input.startTimeUtcMilli
                  : undefined,
              expiryTimeUtcMilli:
                input.startTimeUtcMilli && input.expiryTimeUtcMilli
                  ? input.expiryTimeUtcMilli
                  : undefined,
              notify: !isPerkNotificationEnabledForSelectedAvailability
                ? false
                : input.notify || false,
              banner: input.banner || undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            "getAllDiscountsFromChamberMemberId",
            "getAllDiscountsFromChamberId",
            "getDiscount",
          ],
        });
        setLoader(false);
        setButtonDisabled(false);
        toast.success(enumStrings.toastPerkUpdatedText);

        if (
          userType === UserType.ChamberMemberAdmin &&
          activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber &&
          Date.now() >
            (activeShopLocalCampaignForChamberMemberData
              ?.getActiveShopLocalCampaignForChamber?.startDateUtcMilli || 0)
        ) {
          setActiveShopLocalCampaignModalOpen(true);
        }
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
        setUpdateButtonText("Error");
      }
    },
  });

  useEffect(() => {
    if (!dataLoading) {
      setDayToggle(data?.getDiscount?.daysAvailable ? true : false);
      setMonthToggle(data?.getDiscount?.monthsAvailable ? true : false);
    }
  }, [
    dataLoading,
    data?.getDiscount?.daysAvailable,
    data?.getDiscount?.monthsAvailable,
  ]);

  const handleDayToggleSwitch = () => {
    if (!isDayToggleChecked === false) {
      formik.setFieldValue("daysAvailable", undefined);
    }
    setDayToggle(!isDayToggleChecked);
  };

  const handleDaySelection = (isChecked: boolean, day: DayEnum) => {
    const daysInFormik = formik.values.daysAvailable
      ? [...formik.values.daysAvailable]
      : [];
    // selected
    if (isChecked) {
      daysInFormik.push(day);
    }
    if (!isChecked) {
      daysInFormik.splice(daysInFormik.indexOf(day), 1);
    }
    formik.setFieldValue("daysAvailable", daysInFormik);
  };

  const isMemberProfileAndPerksBannersActiveForChamberMember =
    useIsMemberProfileAndPerksBannersActiveForChamberMember({
      activeMemberProfileAndPerksBannersSettings,
    });

  const {
    handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
    chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
  } = useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement({
    activeMemberProfileAndPerksBannersSettings,
    userType,
  });

  const [
    isMemberProfileAndPerksBannersAgreementModalOpen,
    setIsMemberProfileAndPerksBannersAgreementModalOpen,
  ] = useState(false);

  const openChamberMemberMemberProfileAndPerksBannersAgreementModal =
    useCallback(() => {
      setIsMemberProfileAndPerksBannersAgreementModalOpen(true);
    }, []);

  const dataToExportAsCSV = useMemo(() => {
    return (formik.values.privateEmails || []).map((email) => ({
      email,
    }));
  }, [formik.values.privateEmails]);

  return (
    <span>
      <ChamberMemberMemberProfileAndPerksBannersAgreementModal
        open={isMemberProfileAndPerksBannersAgreementModalOpen}
        handleClose={() =>
          setIsMemberProfileAndPerksBannersAgreementModalOpen(false)
        }
        handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement={
          handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement
        }
        chamberName={chamberInfo?.name || ""}
      />
      {activeShopLocalCampaignModalOpen && (
        <Modal
          className={classes.modal}
          open={activeShopLocalCampaignModalOpen}
        >
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              {enumStrings.modal.shopLocalContestActive}
            </h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {enumStrings.modal.perkUpdatedSuccessfully}
              <br />
              <br />
              {`${enumStrings.modal.companyParticipation} ${chamberInfo?.name}.`}
              <br />
              <br />
              {enumStrings.modal.redeemPrizeChance}{" "}
              {
                activeShopLocalCampaignForChamberMemberData
                  ?.getActiveShopLocalCampaignForChamber?.prizeDescription
              }{" "}
              {enumStrings.modal.participationCode}{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {
                  (activeShopLocalCampaignForChamberMemberData
                    ?.getActiveShopLocalCampaignForChamber
                    ?.participatingMembers || [
                    {
                      participationCode: "",
                    },
                  ])[0].participationCode
                }
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={redirectToDiscountList}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.titleContent}>
        {/* @ts-ignore */}
        <span className={classes.title}>
          {updateDiscountObj?.title}
          {userType === UserType.ChamberAdmin
            ? ` for ${chamberMemberName}`
            : null}
          <PositionedTooltips
            //  @ts-ignore
            title={updateDiscountObj?.title}
            //  @ts-ignore
            description={updateDiscountObj?.toolTipDescription}
          />{" "}
        </span>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {data ? (
          <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            {open && (
              <ConfirmationModal
                open={open}
                loading={loading}
                handleClose={handleClose}
                message="Are you sure you want to delete?"
                buttonConfig={buttonConfig}
              />
            )}
            <div className={classes.companyInputWithToolTipContainer}>
              <FormControl className={classes.notificationDescriptionInput}>
                <TextField
                  className={classes.selectedValue}
                  maxRows={2}
                  label={enumStrings.UpdateDiscount.PerkTitle}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      maxLength: CHARACTER_LIMIT_TITLE,
                    },
                  }}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  multiline
                  name="title"
                  id="title"
                />
              </FormControl>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.discountText.tooltip.title}
                  showIt
                />
              </div>
            </div>
            <InputLabel style={{ margin: "auto" }}>
              {`${formik.values.title.length}/${CHARACTER_LIMIT_TITLE} ${enumStrings.UpdateDiscount.CharactersRemaining}`}
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {formik.errors.title}
              </FormHelperText>
            </InputLabel>
            <div className={classes.companyInputWithToolTipContainer}>
              <FormControl className={classes.notificationDescriptionInput}>
                <TextField
                  className={classes.selectedValue}
                  maxRows={5}
                  label={enumStrings.UpdateDiscount.PerkDescription}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      maxLength: CHARACTER_LIMIT_DESCRIPTION,
                    },
                  }}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  multiline
                  id="description"
                  name="description"
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FormControl>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.discountText.tooltip.description}
                  showIt
                />
              </div>
            </div>
            <InputLabel style={{ margin: "auto" }}>
              {`${
                (formik.values.description || "").length
              }/${CHARACTER_LIMIT_DESCRIPTION} ${
                enumStrings.UpdateDiscount.CharactersRemaining
              }`}
            </InputLabel>
            {isMemberProfileAndPerksBannersActiveForChamberMember && (
              <div
                className={classes.companyInputWithToolTipContainer}
                style={{
                  paddingLeft: paddingLeftPx,
                  position: "relative",
                }}
              >
                <BannerPickerAgreementOverlay
                  chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted={
                    chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                  }
                  openChamberMemberMemberProfileAndPerksBannersAgreementModal={
                    openChamberMemberMemberProfileAndPerksBannersAgreementModal
                  }
                  paddingLeftPx={paddingLeftPx}
                  userType={userType}
                />
                <BannerPicker
                  // disable here to prevent user from going into inspector console and hiding banner agreement overlay and still being able to add banner
                  disabled={
                    !chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                  }
                  key="banner"
                  pickerType="perk"
                  formikVal={formik.values.banner}
                  formikSetFieldValue={(updatedBanner) =>
                    formik.setFieldValue("banner", updatedBanner)
                  }
                  pickerLabel={enumStrings.createDiscount.bannerPickerLabel}
                />
                <div>
                  <HoverToolTip
                    description={`These banners will be shown when this perk is opened in the Chamber Perks App™. \n\nFor best quality of both videos and images, ${
                      enumStrings.bannerPicker.minSizeRequirementForBanner
                        .charAt(0)
                        .toLowerCase() +
                      enumStrings.bannerPicker.minSizeRequirementForBanner.slice(
                        1
                      )
                    } are recommended.\n\nThe position of each banner in Chamber Perks App™ will be the same as shown here.\n\nClick the right and left arrows under each banner to change its positioning.`}
                    showIt
                  />
                </div>
              </div>
            )}
            <DiscountOfferedToRadioGroup
              formikSetFieldValue={formik.setFieldValue}
              formikErrors={formik.errors}
              formikValues={formik.values}
              dataToExportAsCSV={dataToExportAsCSV}
            />
            <DiscountRedemptionOptionRadioGroup
              formikSetFieldValue={formik.setFieldValue}
              formikErrors={formik.errors}
              formikValues={formik.values}
            />
            <FormGroup>
              <div className={classes.toggleSwitchWithTitle}>
                <div
                  className={classes.companyInputWithToolTipContainer}
                  style={{ marginRight: "10px" }}
                >
                  <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
                    {enumStrings.discountText.offersAvailableonCertainDaysText}{" "}
                  </h1>
                  <ShowInAppSwitch
                    checked={isDayToggleChecked}
                    onChange={handleDayToggleSwitch}
                    id="isDayToggle"
                    name="isDayToggle"
                  />
                  <div
                    style={{
                      marginTop: "0px",
                    }}
                  >
                    <HoverToolTip
                      description={enumStrings.discountText.tooltip.dayToggle}
                      showIt
                    />
                  </div>
                </div>
              </div>
              {isDayToggleChecked ? (
                <div style={{ textAlign: "center", alignItems: "center" }}>
                  {Object.values(DayEnum).map((val, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleDaySelection(e.target.checked, val)
                            }
                            checked={formik.values.daysAvailable?.includes(val)}
                            className={classes.dayCheckbox}
                          />
                        }
                        label={val}
                        labelPlacement="bottom"
                      />
                    );
                  })}
                  <FormHelperText
                    component="legend"
                    style={{
                      color: "#f44336",
                      paddingLeft: "10px",
                      paddingTop: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formik.errors.daysAvailable}
                  </FormHelperText>{" "}
                </div>
              ) : null}
            </FormGroup>
            <OnlyOfferDuringCertianMonthsForm
              formikSetFieldValue={formik.setFieldValue}
              formikValuesMonthsAvailable={formik.values.monthsAvailable}
              formikErrors={formik.errors}
              isMonthToggleChecked={isMonthToggleChecked}
              setMonthToggle={setMonthToggle}
              formikValuesExpiryTimeUtcMilli={formik.values.expiryTimeUtcMilli}
              formikValuesStartTimeUtcMilli={formik.values.startTimeUtcMilli}
            />
            <RedeemOfferForm
              formikSetFieldValue={formik.setFieldValue}
              formikVal={formik.values.redeemSettings}
              formikErrors={formik.errors}
            />
            {wasUpdatedTodayWithNotification && (
              <div
                style={{
                  marginTop: "45px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "-40px",
                }}
              >
                <span
                  style={{
                    color: "#E1A731",
                    fontWeight: "bold",
                    fontSize: "20px",
                    maxWidth: "750px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Only one perk notification can be sent per day.
                </span>
              </div>
            )}
            <PerkNotificationSwitch
              chamberName={chamberInfo?.name || ""}
              daysAvailable={formik.values.daysAvailable}
              monthsAvailable={formik.values.monthsAvailable}
              startTimeUtcMilli={formik.values.startTimeUtcMilli}
              expiryTimeUtcMilli={formik.values.expiryTimeUtcMilli}
              formikNotify={formik.values.notify}
              formikSetFieldValue={formik.setFieldValue}
              disabled={wasUpdatedTodayWithNotification}
            />
            <br />
            <br />
            <br />
            <span style={{ textAlign: "center", fontSize: "15px" }}>
              {enumStrings.UpdateDiscount.UpdatedPerksDescription}
            </span>
            <div>
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
            </div>
            {Object.keys(formik?.errors || {}).length > 0 ? (
              <div>
                <div className={classes.invalidError}>
                  {enumStrings.errorMessages.addressIssues}
                </div>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "90%",
                maxWidth: "450px",
              }}
            >
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#E1A731",
                  fontSize: "23px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "200px",
                  marginTop: "20px",
                  marginBottom: "39px",
                  height: "65px",
                  marginRight: "50px",
                }}
                disabled={buttonDisabled || loader}
              >
                {loader ? <CircularProgress /> : updateButtonText}
              </Button>
              {discountId ? (
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: "#FF0000",
                    fontSize: "23px",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    width: "200px",
                    marginTop: "20px",
                    marginLeft: "15px",
                    marginBottom: "39px",
                    height: "65px",
                  }}
                  onClick={handleOpen}
                >
                  {enumStrings.deleteText}
                </Button>
              ) : null}
            </div>
          </form>
        ) : (
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </span>
  );
};

export default ChamberMemberUpdateDiscount;
