import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useCallback, useState } from "react";
import { useMemo } from "react";
import { useEnumStrings } from "../../enumStrings";

const useStyles = makeStyles((theme: any) => ({
  chamberInput: {
    marginTop: "50px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    borderRadius: "15px",
  },
  selectedValue: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const ProvincePicker = ({
  formikVal,
  formikErrors,
  formikSetFieldValue,
  isOptional = false,
  formikStringError,
}: {
  formikVal: any;
  formikErrors: any;
  formikSetFieldValue: (a: string, b: boolean | any[]) => void;
  isOptional?: boolean;
  formikStringError: any;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const [loading, setLoading] = useState(true);

  const { codeToDropdownOption } = useMemo(() => {
    const codeToDropdownOption = {} as {
      [
        key: string
      ]: typeof enumStrings.provinceDropDownSelect.dropdownOptions[0];
    };
    for (const provinceOption of enumStrings.provinceDropDownSelect
      .dropdownOptions) {
      codeToDropdownOption[provinceOption.code] = provinceOption;
    }

    setLoading(false);
    return { codeToDropdownOption };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProvinceChange = useCallback(
    (_, selectedDropdownOption) => {
      formikSetFieldValue(
        "address.province",
        selectedDropdownOption ? selectedDropdownOption.code : ""
      );
    },
    [formikSetFieldValue]
  );

  if (loading) {
    return <></>;
  }

  return (
    <FormControl className={classes.chamberInput}>
      <Autocomplete
        id="address.province"
        value={
          formikVal.length === 0
            ? { full: "", code: "", country: "" }
            : codeToDropdownOption[formikVal]
        }
        onChange={handleProvinceChange}
        className={classes.selectedValue}
        options={enumStrings.provinceDropDownSelect.dropdownOptions.sort(
          (a, b) => {
            if (a.country === b.country) {
              return a.full.localeCompare(b.full);
            }
            return a.country.localeCompare(b.country);
          }
        )}
        groupBy={(option) => option.country}
        getOptionLabel={(option) => option.full}
        renderInput={(params) => (
          <TextField
            {...params}
            error={formikErrors}
            label={`${enumStrings.provinceDropDownSelect.SelectProvinceText}${
              isOptional ? "" : " *"
            }`}
          />
        )}
      />
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          textAlign: "left",
        }}
      >
        {formikErrors ? formikStringError : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default ProvincePicker;
