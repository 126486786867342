import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#F6F6F6",
    marginTop: "25px",
    justifyContent: "center",
  },
  content: {
    background: "#FFFFFF",
    borderRadius: "16px",
    margin: "10px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    paddingRight: "10px",
    paddingLeft: "10px",
    overflow: "scroll",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "35px",
    textAlign: "center",
    marginTop: "11px",
  },
  boxSubTitle: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "center",
    marginTop: "5px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "130px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "280px",
  },
  smallBoxTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  smallBoxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "70px",
    textAlign: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
}));

const ViewStatsBoxes = ({
  boxes,
  maxBoxesPerRow,
  disableTopMargin,
}: {
  disableTopMargin?: boolean;
  boxes: Array<{
    smallBox?: boolean;
    mediumBox?: boolean;
    hideBox?: boolean;
    boxTitle: ReactElement | string;
    boxSubTitle?: string;
    boxContent: ReactElement;
    fullWidth?: boolean;
    smallText?: boolean;
    contentFullHeightAndWidth?: boolean;
  }>;
  maxBoxesPerRow?: number;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div
      className={classes.contentContainer}
      style={{ ...(disableTopMargin ? { marginTop: 0 } : {}) }}
    >
      {boxes.map(
        (
          {
            boxTitle,
            boxSubTitle,
            boxContent,
            fullWidth = false,
            smallBox,
            mediumBox,
            smallText,
            contentFullHeightAndWidth,
            hideBox,
          },
          index
        ) => (
          <div
            key={index}
            className={classes.content}
            style={{
              ...(hideBox ? { display: "none" } : {}),
              ...(smallBox
                ? {
                    width: "310px",
                    minWidth: "310px",
                    maxWidth: "310px",
                    height: "300px",
                  }
                : mediumBox
                ? {
                    minWidth: "350px",
                    maxWidth: "500px",
                    height: "525px",
                  }
                : {}),
              ...(maxBoxesPerRow
                ? {
                    flex: `0 0 calc(${100 / maxBoxesPerRow - 4}% - 10px)`,
                  }
                : { flex: `1 0 calc(${fullWidth ? "9" : "4"}6% - 10px` }),
            }}
          >
            {smallBox ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  padding: contentFullHeightAndWidth
                    ? "15px 0px 0px 0px"
                    : "15px",
                  overflow: "hidden",
                }}
              >
                <div className={classes.smallBoxTitle}>{boxTitle}</div>
                <div
                  className={classes.smallBoxValue}
                  style={{
                    ...(contentFullHeightAndWidth
                      ? { height: "100%", width: "100%" }
                      : {}),
                    ...(smallText ? { fontSize: "50px" } : {}),
                  }}
                >
                  {boxContent}
                </div>
              </div>
            ) : (
              <>
                <div
                  className={classes.boxTitle}
                  style={{
                    ...(mediumBox
                      ? { fontSize: "26px", whiteSpace: "pre-wrap" }
                      : {}),
                  }}
                >
                  {boxTitle}
                </div>
                {boxSubTitle ? (
                  <div className={classes.boxSubTitle}>{boxSubTitle}</div>
                ) : null}
                <div className={classes.boxValue}>{boxContent}</div>
              </>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default ViewStatsBoxes;
