import { Auth } from "aws-amplify";
import { useContext, useEffect } from "react";
import { UserType } from "../../../graphql/types";
import { UserTypeContext } from "../../../UserTypeContext";
import enumStrings, { RedirectUri } from "../../enumStrings";

const useInitialAuth = (setIsLoggedIn: (val: any) => void) => {
  const {
    setters: { setUserType },
  } = useContext(UserTypeContext);

  return useEffect(() => {
    const checkAccessToken = async (): Promise<void> => {
      let accessTokenIsValid = false;
      let userType = "";
      try {
        const res = await Auth.currentSession();
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        if (authenticatedUser) {
          accessTokenIsValid = true;
        }
        userType = res.getAccessToken().decodePayload()["cognito:groups"][0];
      } catch (error) {
        if (error !== "No current user") {
          throw error;
        }
      }

      const urlPath = window.location.pathname;
      if (!accessTokenIsValid) {
        setIsLoggedIn(false);
        setUserType("");
        await Auth.signOut();

        const loggedInWithTemporaryPassword = sessionStorage.getItem(
          enumStrings.sessionStorageKey.loggedInWithTemporaryPassword
        );

        if (loggedInWithTemporaryPassword) {
          if (urlPath !== RedirectUri.newPassword) {
            window.location.href = RedirectUri.newPassword;
            return;
          }
        }

        // user isn't logged in and isn't trying to access the cpa delete account page or isn't setting their permanent password... so force them to the login page
        if (
          urlPath !== RedirectUri.cpaPrivacyPolicy &&
          urlPath !== RedirectUri.deleteCpaData &&
          urlPath !== RedirectUri.appStoreSupport &&
          urlPath !== RedirectUri.login &&
          !loggedInWithTemporaryPassword
        ) {
          window.location.href = RedirectUri.login;
        }
      } else {
        setUserType(userType as UserType);
        setIsLoggedIn(true);
        localStorage.setItem("accessToken", `${accessTokenIsValid}`);

        if (urlPath === RedirectUri.login) {
          window.location.href = RedirectUri.rootAdmin;
          return;
        }
      }
    };
    checkAccessToken();
  }, [setIsLoggedIn, setUserType]);
};

export default useInitialAuth;
