import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  FormControl,
  makeStyles,
  SwitchProps,
  TextField,
  styled,
  Switch,
  createStyles,
  Theme,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import defaultEnumStrings, {
  RedirectUri,
  useEnumStrings,
} from "../../enumStrings";
import {
  ChamberInput,
  ChamberPerksAppSubscriptionPlanTypesEnum,
  useDeleteChamberMutation,
  useGetAdminConsoleLoginEmailForChamberQuery,
  useGetChamberQuery,
  useGetPremiumChamberMarketerLinkQuery,
  useGetSubscriptionPlanTypeQuery,
  UserType,
  useUpdateChamberMutation,
} from "../../../graphql/types";
import * as yup from "yup";
import { useFormik } from "formik";
import { CircularProgress } from "@material-ui/core";
import ConfirmationModal, {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { useLocation } from "react-router-dom";
import useRedirect from "../../common/Hooks/useRedirect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import LogoPicker from "../../common/utilities/LogoPicker";
import ProvincePicker from "../../common/utilities/ProvincePicker";
import { UserTypeContext } from "../../../UserTypeContext";
import PhoneNumberFormat from "../../common/utilities/PhoneNumberFormat";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import ChamberChangeEmailUsernameModal from "../../common/ConfirmationModal/ChamberChangeEmailUsernameModal";
import BannerPicker from "../../common/BannerPicker";
import useIsChamber from "../../common/Hooks/useIsChamber";
import omitDeep from "omit-deep-lodash";
import { validWebsiteRegEx } from "../../common/utilities/regexes";
import ChamberSubscriptionPlanRadioGroup from "../../common/ChamberSubscriptionPlanRadioGroup";

const CHARACTER_LIMIT_DESCRIPTION = 2000;

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginRight: "24px",
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },

  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "36px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    border: "1px solid black",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "10px",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    minHeight: "85vh",
    marginTop: "33px",
    paddingBottom: "33px",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 15px 15px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  companyDescription: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyDescriptionInput: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
}));
const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

// Internal modal component for plan limitation
const PlanLimitationModal = ({
  open,
  handleClose,
  chamberName,
}: {
  open: boolean;
  handleClose: () => void;
  chamberName: string;
}) => {
  const modalStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        border: "unset",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      paper: {
        height: "fit-content",
        borderRadius: "5px",
        textAlign: "center",
        width: "600px",
        maxWidth: "90%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#cfd8dc",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        position: "relative",
      },
      okButton: {
        margin: "10px",
        marginTop: "20px",
        width: "120px",
        backgroundColor: "#37474f",
        color: "#e9e9e9",
        fontWeight: "bold",
        height: "40px",
      },
      content: {
        display: "flex",
        padding: "10px",
        borderRadius: "15px",
        textAlign: "left",
      },
    })
  )();

  const enumStrings = useEnumStrings();

  return (
    <Modal className={modalStyles.modal} open={open}>
      <div className={modalStyles.paper}>
        <h2 style={{ fontWeight: "bold" }}>
          {
            enumStrings.ChamberAdminManage
              .directoryBannersSubscriptionPlanLimitationModal.title
          }
        </h2>
        <div className={modalStyles.content}>
          <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
            {enumStrings.ChamberAdminManage.directoryBannersSubscriptionPlanLimitationModal.description.replace(
              "{chamberName}",
              chamberName
            )}{" "}
            <a href={`mailto:${defaultEnumStrings.subscriptionsContactEmail}`}>
              {defaultEnumStrings.subscriptionsContactEmail}
            </a>
            .
          </p>
        </div>
        <Button
          className={modalStyles.okButton}
          onClick={handleClose}
          variant="contained"
        >
          {
            enumStrings.ChamberAdminManage
              .directoryBannersSubscriptionPlanLimitationModal.okButton
          }
        </Button>
      </div>
    </Modal>
  );
};

const ManageChamberAsChamberAdmin = (): ReactElement => {
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [invalidError, setInvalidError] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const locationStateChamberId = useMemo(() => {
    return (
      (location.state || { chamberId: undefined }) as {
        chamberId: string | undefined;
      }
    ).chamberId;
  }, [location.state]);

  // this is to get chamber when navigating from chamber list while loggged in as root admin
  const { data: locationStateChamberInfo } = useGetChamberQuery({
    variables: { chamberId: locationStateChamberId || "" },
    skip: !locationStateChamberId,
  });

  const {
    data: premiumChamberMarketerLinkData,
    loading: loadingPremiumChamberMarketerLinkData,
  } = useGetPremiumChamberMarketerLinkQuery({
    variables: { chamberId: locationStateChamberId || "" },
    skip: !locationStateChamberId || !(userType === UserType.RootAdmin),
  });

  const { data: subscriptionPlanTypeData } = useGetSubscriptionPlanTypeQuery({
    variables: { chamberId: locationStateChamberId || "" },
    skip: !locationStateChamberId || !(userType === UserType.RootAdmin),
  });

  // this is to get chamber when logged in as chamber admin and we can just pull info using logged in user's info
  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();
  const chamberInfo = useMemo(() => {
    if (locationStateChamberId) {
      return locationStateChamberInfo?.getChamber;
    }
    return userIdInAuthChamberInfo;
  }, [
    locationStateChamberId,
    locationStateChamberInfo,
    userIdInAuthChamberInfo,
  ]);
  const [customError, setCustomError] = useState("");

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const redirectToManageChamber = useRedirect(RedirectUri.listAllChamberAdmin);

  const [deleteChamber] = useDeleteChamberMutation({
    variables: { id: chamberInfo?.id || "" },
  });

  const adminConsoleLoginEmailForChamber =
    useGetAdminConsoleLoginEmailForChamberQuery({
      variables: {
        chamberId: locationStateChamberId || "",
      },
      skip: !locationStateChamberId || !(userType === UserType.RootAdmin),
    });

  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      await deleteChamber();
      redirectToManageChamber();
    } catch (error) {
      setInvalidError(true);
      setCustomError(error?.message);
      setLoader(false);
      setButtonDisabled(false);
    }

    setOpen(false);
  }, [deleteChamber, redirectToManageChamber]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleClose,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const [updateChamber] = useUpdateChamberMutation();

  const validationSchema = yup.object({
    name: yup.string().required(enumStrings.validationMessages.nameRequired),
    website: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite)
      .required(enumStrings.validationMessages.websiteRequired),
    address: yup.object({
      province: yup
        .string()
        .required(enumStrings.validationMessages.provinceRequired),
      city: yup.string().required(enumStrings.validationMessages.cityRequired),
      postalCode: yup
        .string()
        .required(enumStrings.validationMessages.postalCodeRequired),
      streetAddress: yup
        .string()
        .required(enumStrings.validationMessages.streetAddressRequired),
    }),
    supportContactEmail: yup
      .string()
      .required(enumStrings.validationMessages.supportEmailRequired),
    supportLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    jobPostingLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    eventsLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    newsletterLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    surveyLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    advocacyWinsLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    memberSignupLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    premiumChamberMarketerLinkData: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
    sponsorLogoLink: yup
      .string()
      .matches(validWebsiteRegEx, enumStrings.validationMessages.validWebsite),
  });

  const intitalValuesMemo = useMemo(() => {
    const initialValues: ChamberInput = {
      id: chamberInfo?.id || "",
      name: chamberInfo?.name || "",
      description: chamberInfo?.description || "",
      website: chamberInfo?.website || "",
      supportContactEmail: chamberInfo?.supportContactEmail || "",
      supportContactName: chamberInfo?.supportContactName || "",
      supportLink: chamberInfo?.supportLink || "",
      phoneNumber: chamberInfo?.phoneNumber || "",
      facebook: chamberInfo?.facebook || "",
      instagram: chamberInfo?.instagram || "",
      threads: chamberInfo?.threads || "",
      linkedin: chamberInfo?.linkedin || "",
      twitter: chamberInfo?.twitter || "",
      tiktok: chamberInfo?.tiktok || "",
      youtube: chamberInfo?.youtube || "",
      jobPostingLink: chamberInfo?.jobPostingLink || "",
      address: {
        city: chamberInfo?.address.city || "",
        postalCode: chamberInfo?.address.postalCode || "",
        province: chamberInfo?.address.province || "",
        streetAddress: chamberInfo?.address.streetAddress || "",
      },
      eventsLink: chamberInfo?.eventsLink || "",
      newsletterLink: chamberInfo?.newsletterLink || "",
      surveyLink: chamberInfo?.surveyLink || "",
      advocacyWinsLink: chamberInfo?.advocacyWinsLink || "",
      memberSignupLink: chamberInfo?.memberSignupLink || "",
      logo: chamberInfo?.logo || undefined,
      sponsorLogo: chamberInfo?.sponsorLogo || undefined,
      sponsorLogoLink: chamberInfo?.sponsorLogoLink || undefined,
      ...(userType === UserType.RootAdmin
        ? {
            premiumChamberMarketerLink:
              premiumChamberMarketerLinkData?.getChamber
                ?.premiumChamberMarketerLink || undefined,
            hideInApp: chamberInfo?.hideInApp || false,
            subscriptionPlanType:
              subscriptionPlanTypeData?.getChamber?.subscriptionPlanType ||
              ChamberPerksAppSubscriptionPlanTypesEnum.Standard,
          }
        : {}),
      directoryBanner: chamberInfo?.directoryBanner || undefined,
      profileBanner: chamberInfo?.profileBanner || undefined,
    };
    return omitDeep(initialValues, "__typename");
  }, [
    chamberInfo,
    premiumChamberMarketerLinkData?.getChamber?.premiumChamberMarketerLink,
    subscriptionPlanTypeData?.getChamber?.subscriptionPlanType,
    userType,
  ]) as unknown as ChamberInput;

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (input: ChamberInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);
        await updateChamber({
          variables: {
            id: input.id,
            input: {
              address: {
                city: input.address.city,
                postalCode: input.address.postalCode,
                province: input.address.province,
                streetAddress: input.address.streetAddress,
              },
              eventsLink: input.eventsLink,
              jobPostingLink: input.jobPostingLink,
              newsletterLink: input.newsletterLink,
              surveyLink: input.surveyLink,
              advocacyWinsLink: input.advocacyWinsLink,
              memberSignupLink: input.memberSignupLink,
              name: input.name,
              description: input.description,
              supportContactEmail: input.supportContactEmail
                .trim()
                .toLowerCase(),
              supportContactName: input.supportContactName,
              supportLink: input.supportLink,
              phoneNumber: input.phoneNumber,
              facebook: input.facebook,
              instagram: input.instagram,
              threads: input.threads,
              linkedin: input.linkedin,
              twitter: input.twitter,
              tiktok: input.tiktok,
              youtube: input.youtube,
              website: input.website,
              logo: input.logo,
              sponsorLogo: input.sponsorLogo,
              sponsorLogoLink: input.sponsorLogoLink,
              id: input.id,
              ...(userType === UserType.RootAdmin
                ? {
                    premiumChamberMarketerLink:
                      input.premiumChamberMarketerLink,
                    hideInApp: input.hideInApp,
                    subscriptionPlanType: input.subscriptionPlanType,
                  }
                : {}),
              directoryBanner: input.directoryBanner,
              profileBanner: input.profileBanner,
            },
          },
          refetchQueries: [
            "getUser",
            "getUser_RootAdminChamberAdmin",
            "getChamber",
            "getAllChambers",
          ],
        });
        setLoader(false);
        setButtonDisabled(false);

        toast.success(enumStrings.updatedSuccessfully);
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });
  const handleCreateBase64 = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("logo", base64);
    },
    [formik]
  );

  const deleteLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("logo", undefined);
  };

  const handleCreateBase64SponsorLogo = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("sponsorLogo", base64);
    },
    [formik]
  );

  const deleteSponsorLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("sponsorLogo", undefined);
    formik.setFieldValue("sponsorLogoLink", undefined);
  };

  const [
    chamberChangeAdminConsoleLoginEmailModalIsOpen,
    setChamberChangeAdminConsoleLoginEmailModalIsOpen,
  ] = useState(false);

  const [showPlanLimitationModal, setShowPlanLimitationModal] = useState(false);

  const openChamberChangeAdminConsoleLoginEmailModal = useCallback(() => {
    setChamberChangeAdminConsoleLoginEmailModalIsOpen(true);
  }, []);

  const closeChamberChangeAdminConsoleLoginEmailModal = useCallback(() => {
    setChamberChangeAdminConsoleLoginEmailModalIsOpen(false);
  }, []);

  const handlePlanLimitationModalOpen = useCallback(() => {
    setShowPlanLimitationModal(true);
  }, []);

  const handlePlanLimitationModalClose = useCallback(() => {
    setShowPlanLimitationModal(false);
  }, []);

  const isChamber = useIsChamber();

  const chamberName = isChamber ? enumStrings.chamber : enumStrings.board;

  const rootAdminObj =
    userType === UserType.ChamberAdmin
      ? enumStrings.groups[UserType.ChamberAdmin][
          RedirectUri.manageYourChamberChamberAdmin
        ]
      : enumStrings.groups[UserType.RootAdmin][RedirectUri.updateAChamberRoute];

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        <div>
          <form
            className={classes.formContainer}
            onSubmit={formik.handleSubmit}
          >
            {open && (
              <ConfirmationModal
                open={open}
                loading={loading}
                handleClose={handleClose}
                message={enumStrings.perkPartnerAdmin.deleteConfirmation}
                buttonConfig={buttonConfig}
              />
            )}
            {chamberInfo ? (
              <>
                <>
                  {userType === UserType.RootAdmin ? (
                    <>
                      <ChamberSubscriptionPlanRadioGroup
                        formikSetFieldValue={formik.setFieldValue}
                        formikValues={formik.values}
                        formikErrors={formik.errors}
                      />
                      <TextField
                        type="text"
                        label={enumStrings.createChamberText.emailUsername}
                        disabled
                        className={classes.companyInput}
                        variant="outlined"
                        id="adminConsoleLoginEmail"
                        name="adminConsoleLoginEmail"
                        value={
                          adminConsoleLoginEmailForChamber.data
                            ?.getAdminConsoleLoginEmailForChamber
                            .adminConsoleLoginEmail || ""
                        }
                      />
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#E1A731",
                          textDecoration: "underline",
                          padding: "5px",
                          paddingBottom: 0,
                          marginBottom: 0,
                          cursor: "pointer",
                        }}
                        onClick={openChamberChangeAdminConsoleLoginEmailModal}
                      >
                        {enumStrings.changeEmailUsername}
                      </div>
                    </>
                  ) : null}
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.ChamberAdminManage.chamberBoardName}
                      className={classes.companyInput}
                      variant="outlined"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={enumStrings.createChamberText.tooltip.name}
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <FormControl className={classes.companyDescription}>
                      <TextField
                        type="text"
                        label={
                          enumStrings.ChamberAdminManage.chamberBoardDescription
                        }
                        className={classes.companyDescriptionInput}
                        maxRows={6}
                        multiline
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            maxLength: CHARACTER_LIMIT_DESCRIPTION,
                          },
                        }}
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </FormControl>
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.description
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.ChamberAdminManage.chamberWebsite}
                      className={classes.companyInput}
                      variant="outlined"
                      id="website"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.website && Boolean(formik.errors.website)
                      }
                      helperText={
                        formik.touched.website && formik.errors.website
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.website
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberSupportContactName
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="supportContactName"
                      name="supportContactName"
                      value={formik.values.supportContactName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.supportContactName &&
                        Boolean(formik.errors.supportContactName)
                      }
                      helperText={
                        formik.touched.supportContactName &&
                        formik.errors.supportContactName
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip
                            .supportContactName
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage
                          .chamberSupportContactEmail
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="supportContactEmail"
                      name="supportContactEmail"
                      value={formik.values.supportContactEmail}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.supportContactEmail &&
                        Boolean(formik.errors.supportContactEmail)
                      }
                      helperText={
                        formik.touched.supportContactEmail &&
                        formik.errors.supportContactEmail
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip
                            .supportContactEmail
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberSupportPageURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="supportLink"
                      name="supportLink"
                      value={formik.values.supportLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.supportLink &&
                        Boolean(formik.errors.supportLink)
                      }
                      helperText={
                        formik.touched.supportLink && formik.errors.supportLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.supportLink
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <PhoneNumberFormat
                      formikErrors={formik.errors.phoneNumber}
                      formikVal={formik.values.phoneNumber}
                      handleChange={formik.handleChange}
                      label={enumStrings.ChamberAdminManage.chamberPhoneNumber}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.phoneNumber
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberStreetAddress
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.streetAddress"
                      name="address.streetAddress"
                      value={formik.values.address?.streetAddress}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.streetAddress &&
                        Boolean(formik.errors.address?.streetAddress)
                      }
                      helperText={
                        formik.touched.address?.streetAddress &&
                        formik.errors.address?.streetAddress
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.streetAddress
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <ProvincePicker
                      formikVal={formik.values.address?.province}
                      formikErrors={
                        formik.touched.address?.province &&
                        Boolean(formik.errors.address?.province)
                      }
                      formikStringError={formik.errors.address?.province}
                      formikSetFieldValue={formik.setFieldValue}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.province
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.ChamberAdminManage.chamberCity}
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.city"
                      name="address.city"
                      value={formik.values.address?.city}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.city &&
                        Boolean(formik.errors.address?.city)
                      }
                      helperText={
                        formik.touched.address?.city &&
                        formik.errors.address?.city
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={enumStrings.createChamberText.tooltip.city}
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberPostalZipCode
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.postalCode"
                      name="address.postalCode"
                      value={formik.values.address?.postalCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.postalCode &&
                        Boolean(formik.errors.address?.postalCode)
                      }
                      helperText={
                        formik.touched.address?.postalCode &&
                        formik.errors.address?.postalCode
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.postalCode
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage
                          .chamberFacebookBusinessPageURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="facebook"
                      name="facebook"
                      value={formik.values.facebook}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.facebook &&
                        Boolean(formik.errors.facebook)
                      }
                      helperText={
                        formik.touched.facebook && formik.errors.facebook
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.facebook
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage
                          .chamberInstagramAccountURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="instagram"
                      name="instagram"
                      value={formik.values.instagram}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instagram &&
                        Boolean(formik.errors.instagram)
                      }
                      helperText={
                        formik.touched.instagram && formik.errors.instagram
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.instagram
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberThreadsAccountURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="threads"
                      name="threads"
                      value={formik.values.threads}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.threads && Boolean(formik.errors.threads)
                      }
                      helperText={
                        formik.touched.threads && formik.errors.threads
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.threads
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.createChamberText.linkedinUrl}
                      className={classes.companyInput}
                      variant="outlined"
                      id="linkedin"
                      name="linkedin"
                      value={formik.values.linkedin}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.linkedin &&
                        Boolean(formik.errors.linkedin)
                      }
                      helperText={
                        formik.touched.linkedin && formik.errors.linkedin
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.linkedin
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberTwitterAccountURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="twitter"
                      name="twitter"
                      value={formik.values.twitter}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.twitter && Boolean(formik.errors.twitter)
                      }
                      helperText={
                        formik.touched.twitter && formik.errors.twitter
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.twitter
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberTikTokAccountURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="tiktok"
                      name="tiktok"
                      value={formik.values.tiktok}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tiktok && Boolean(formik.errors.tiktok)
                      }
                      helperText={formik.touched.tiktok && formik.errors.tiktok}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.tiktok
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberYoutubeChannelURL
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="youtube"
                      name="youtube"
                      value={formik.values.youtube}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.youtube && Boolean(formik.errors.youtube)
                      }
                      helperText={
                        formik.touched.youtube && formik.errors.youtube
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.youtube
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberJobPostingLink
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="jobPostingLink"
                      name="jobPostingLink"
                      value={formik.values.jobPostingLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.jobPostingLink &&
                        Boolean(formik.errors.jobPostingLink)
                      }
                      helperText={
                        formik.touched.jobPostingLink &&
                        formik.errors.jobPostingLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.jobPostingLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.ChamberAdminManage.chamberEventsLink}
                      className={classes.companyInput}
                      variant="outlined"
                      id="eventsLink"
                      name="eventsLink"
                      value={formik.values.eventsLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.eventsLink &&
                        Boolean(formik.errors.eventsLink)
                      }
                      helperText={
                        formik.touched.eventsLink && formik.errors.eventsLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.eventsLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage.chamberNewsletterLink
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="newsletterLink"
                      name="newsletterLink"
                      value={formik.values.newsletterLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.newsletterLink &&
                        Boolean(formik.errors.newsletterLink)
                      }
                      helperText={
                        formik.touched.newsletterLink &&
                        formik.errors.newsletterLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.newsletterLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.ChamberAdminManage.chamberSurveyLink}
                      className={classes.companyInput}
                      variant="outlined"
                      id="surveyLink"
                      name="surveyLink"
                      value={formik.values.surveyLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.surveyLink &&
                        Boolean(formik.errors.surveyLink)
                      }
                      helperText={
                        formik.touched.surveyLink && formik.errors.surveyLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.surveyLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label="Advocacy Wins Link"
                      className={classes.companyInput}
                      variant="outlined"
                      id="advocacyWinsLink"
                      name="advocacyWinsLink"
                      value={formik.values.advocacyWinsLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.advocacyWinsLink &&
                        Boolean(formik.errors.advocacyWinsLink)
                      }
                      helperText={
                        formik.touched.advocacyWinsLink &&
                        formik.errors.advocacyWinsLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.advocacyWinsLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.ChamberAdminManage
                          .chamberNewMemberSignupLink
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="memberSignupLink"
                      name="memberSignupLink"
                      value={formik.values.memberSignupLink}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.memberSignupLink &&
                        Boolean(formik.errors.memberSignupLink)
                      }
                      helperText={
                        formik.touched.memberSignupLink &&
                        formik.errors.memberSignupLink
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.memberSignupLink
                        }
                        showIt
                      />
                    </div>
                  </div>

                  {userType === UserType.RootAdmin &&
                  !loadingPremiumChamberMarketerLinkData ? (
                    <div className={classes.companyInputWithToolTipContainer}>
                      <TextField
                        type="text"
                        label={"Premium Chamber Marketer Dropbox Folder Link"}
                        className={classes.companyInput}
                        variant="outlined"
                        id="premiumChamberMarketerLink"
                        name="premiumChamberMarketerLink"
                        value={formik.values.premiumChamberMarketerLink}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.premiumChamberMarketerLink &&
                          Boolean(formik.errors.premiumChamberMarketerLink)
                        }
                        helperText={
                          formik.touched.premiumChamberMarketerLink &&
                          formik.errors.premiumChamberMarketerLink
                        }
                      />
                      <div
                        style={{
                          marginTop: "50px",
                        }}
                      >
                        <HoverToolTip
                          description={
                            enumStrings.createChamberText.tooltip
                              .premiumChamberMarketer
                          }
                          showIt
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className={classes.companyInputWithToolTipContainer}>
                    <LogoPicker
                      logo={formik.values.logo}
                      deleteLogo={deleteLogo}
                      base64={(e: any) => handleCreateBase64(e)}
                    />
                    <div>
                      <HoverToolTip
                        description={enumStrings.createChamberText.tooltip.logo}
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <LogoPicker
                      logo={formik.values.sponsorLogo}
                      label={enumStrings.profile.sponsorLogoTitle}
                      deleteLogo={deleteSponsorLogo}
                      base64={(e: any) => handleCreateBase64SponsorLogo(e)}
                    />
                    <div>
                      <HoverToolTip
                        description={
                          enumStrings.createChamberText.tooltip.sponsorLogo
                        }
                        showIt
                      />
                    </div>
                  </div>

                  {formik.values.sponsorLogo &&
                  formik.values.sponsorLogo.length > 0 ? (
                    <div className={classes.companyInputWithToolTipContainer}>
                      <TextField
                        type="text"
                        label={
                          enumStrings.ChamberAdminManage.chamberSponsorLogoLink
                        }
                        className={classes.companyInput}
                        variant="outlined"
                        id="sponsorLogoLink"
                        name="sponsorLogoLink"
                        value={formik.values.sponsorLogoLink}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.sponsorLogoLink &&
                          Boolean(formik.errors.sponsorLogoLink)
                        }
                        helperText={
                          formik.touched.sponsorLogoLink &&
                          formik.errors.sponsorLogoLink
                        }
                      />
                      <div
                        style={{
                          marginTop: "50px",
                        }}
                      >
                        <HoverToolTip
                          description={
                            enumStrings.createChamberText.tooltip
                              .sponsorLogoLink
                          }
                          showIt
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className={classes.companyInputWithToolTipContainer}>
                    <BannerPicker
                      key="profileBanner"
                      formikVal={formik.values.profileBanner}
                      formikSetFieldValue={(updatedBanner) =>
                        formik.setFieldValue("profileBanner", updatedBanner)
                      }
                      pickerLabel={enumStrings.ChamberAdminManage.chamberProfileBanners.replace(
                        "{{chamberName}}",
                        chamberName
                      )}
                    />
                    <div>
                      <HoverToolTip
                        description={enumStrings.createChamberMemberText.tooltip.profileScreenBanner(
                          {
                            chamberName: chamberInfo?.name || "",
                            minSizeRequirementForBanner:
                              enumStrings.bannerPicker
                                .minSizeRequirementForBanner,
                            isChamber,
                          }
                        )}
                        showIt
                      />
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <div style={{ position: "relative" }}>
                      <BannerPicker
                        disabled={
                          subscriptionPlanTypeData?.getChamber
                            ?.subscriptionPlanType ===
                          ChamberPerksAppSubscriptionPlanTypesEnum.Starter
                        }
                        key="directoryBanner"
                        formikVal={
                          subscriptionPlanTypeData?.getChamber
                            ?.subscriptionPlanType ===
                          ChamberPerksAppSubscriptionPlanTypesEnum.Starter
                            ? []
                            : formik.values.directoryBanner
                        }
                        formikSetFieldValue={(updatedBanner) =>
                          formik.setFieldValue("directoryBanner", updatedBanner)
                        }
                        pickerLabel={
                          enumStrings.ChamberAdminManage
                            .chamberMainDirectoryBanners
                        }
                      />
                      {subscriptionPlanTypeData?.getChamber
                        ?.subscriptionPlanType ===
                        ChamberPerksAppSubscriptionPlanTypesEnum.Starter && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            cursor: "pointer",
                          }}
                          onClick={handlePlanLimitationModalOpen}
                        />
                      )}
                    </div>
                    <div>
                      <HoverToolTip
                        description={enumStrings.createChamberMemberText.tooltip.directoryScreenBanner(
                          {
                            minSizeRequirementForBanner:
                              enumStrings.bannerPicker
                                .minSizeRequirementForBanner,
                          }
                        )}
                        showIt
                      />
                    </div>
                  </div>

                  {userType === UserType.RootAdmin && (
                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        alignItems: "baseline",
                        marginTop: "35px",
                      }}
                    >
                      <div
                        className={classes.companyInputWithToolTipContainer}
                        style={{ marginRight: "10px" }}
                      >
                        <h1 style={{ margin: 0 }}>
                          {enumStrings.createChamberText.hideChamberPerksApp}
                        </h1>
                        <ShowInAppSwitch
                          checked={formik.values.hideInApp ? true : false}
                          onChange={(e, checked) => {
                            formik.setFieldValue("hideInApp", checked);
                          }}
                          id="hideInAppToggle"
                          name="hideInAppToggle"
                        />
                        <div
                          style={{
                            marginTop: "0px",
                          }}
                        >
                          <HoverToolTip
                            description={
                              enumStrings.ChamberAdminMangeMember.description
                            }
                            showIt
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    {invalidError ? (
                      <div className={classes.invalidError}>{customError}</div>
                    ) : null}
                  </div>
                </>
                <div className={classes.buttonContainers}>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    style={{
                      backgroundColor: "#E1A731",
                      fontSize: "25px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      width: "200px",
                    }}
                    disabled={buttonDisabled || loader}
                  >
                    {loader ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      enumStrings.shopLocal.updateChamberShopLocalCampaign
                        .update
                    )}
                  </Button>
                  {locationStateChamberId ? (
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      style={{
                        backgroundColor: "#FF0000",
                        fontSize: "25px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        width: "200px",
                        marginLeft: "25px",
                      }}
                      onClick={handleOpen}
                    >
                      {enumStrings.deleteText}
                    </Button>
                  ) : null}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </form>
        </div>
      </div>
      {chamberChangeAdminConsoleLoginEmailModalIsOpen && (
        <ChamberChangeEmailUsernameModal
          chamberId={chamberInfo?.id}
          open={chamberChangeAdminConsoleLoginEmailModalIsOpen}
          handleClose={closeChamberChangeAdminConsoleLoginEmailModal}
          chamberName={chamberInfo?.name || ""}
          currentAdminConsoleLoginEmail={
            adminConsoleLoginEmailForChamber.data
              ?.getAdminConsoleLoginEmailForChamber.adminConsoleLoginEmail
          }
        />
      )}
      {showPlanLimitationModal && (
        <PlanLimitationModal
          open={showPlanLimitationModal}
          handleClose={handlePlanLimitationModalClose}
          chamberName={chamberInfo?.name || ""}
        />
      )}
    </span>
  );
};

export default ManageChamberAsChamberAdmin;
