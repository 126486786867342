import React, { ReactElement, useState } from "react";
import { makeStyles, Button, Modal } from "@material-ui/core";
import theme from "../../themes";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { CircularProgress } from "@material-ui/core";
import { useEnumStrings } from "../enumStrings";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  exportButton: {
    fontSize: "12px",
    backgroundColor: "white",
    fontWeight: "bold",
    color: "#000000",
    borderRadius: "10px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "5px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "white",
    position: "relative",
    width: "800px",
    height: "525px",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90%",
    },
  },
  okButton: {
    width: "230px",
    color: "white",
    fontWeight: "bold",
    height: "40px",
    fontSize: "16px",
    backgroundColor: "#37474f",
  },
}));

const VideoTutorialButton = ({
  label = "Watch Video",
  tutorialUrl,
}: {
  label: string;
  tutorialUrl: string;
}): ReactElement => {
  const classes = useStyles();

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [showIFrameLoader, setShowIFrameLoader] = useState(true);

  const enumStrings = useEnumStrings();

  return (
    <div className={classes.buttonContainer} style={{ padding: 0, margin: 0 }}>
      {videoModalOpen && (
        <Modal className={classes.modal} open={videoModalOpen}>
          <div
            className={classes.paper}
            style={{
              backgroundColor: "#cfd8dc",
              padding: theme.spacing(2),
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              <iframe
                src={tutorialUrl}
                onLoad={() => {
                  setShowIFrameLoader(false);
                }}
                title={label}
                allowFullScreen
                allow="encrypted-media; fullscreen;"
                style={{
                  height: "100%",
                  width: "100%",
                  border: "none",
                  overflow: "hidden",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  zIndex: 1,
                }}
              />
              {showIFrameLoader && (
                <div
                  style={{
                    zIndex: 2,
                    height: "100%",
                    width: "100%",
                    border: "none",
                    overflow: "hidden",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    size={50}
                    style={{
                      color: "#E1A731",
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                paddingTop: theme.spacing(1.8),
              }}
            >
              <Button
                className={classes.okButton}
                style={{
                  maxWidth: "200px",
                }}
                onClick={() => setVideoModalOpen(false)}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Button
        type="submit"
        size="large"
        color="primary"
        variant="contained"
        className={classes.exportButton}
        onClick={() => setVideoModalOpen(true)}
        style={{
          backgroundColor: "white",
          margin: 0,
        }}
      >
        <PlayCircleOutlineIcon
          style={{
            color: "#E1A731",
            marginRight: "10px",
            marginLeft: "-5px",
            fontSize: "1.8rem",
          }}
        />
        {label}
      </Button>
    </div>
  );
};

export default VideoTutorialButton;
