import React, { useMemo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { useEnumStrings } from "../enumStrings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "100%",
      borderRadius: "5px",
      textAlign: "center",
      maxHeight: "680px",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
    okButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

const ChamberMemberMemberProfileAndPerksBannersAgreementModal = ({
  open,
  handleClose,
  handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
  chamberName,
  isCategoryBanner = false,
}: {
  open: boolean;
  handleClose: () => void;
  handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement: () => void;
  chamberName: string;
  isCategoryBanner?: boolean;
}) => {
  const classes = useStyles();

  const chamberOrBoardText = useMemo(() => {
    if (chamberName.toLowerCase().includes("chamber")) {
      return "Chamber of Commerce";
    }
    return "Board of Trade";
  }, [chamberName]);

  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h2>{enumStrings.chamberMember.modal.title}</h2>
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "left",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
            backgroundColor: "white",
            borderRadius: "15px",
            overflow: "scroll",
          }}
        >
          <div className="container">
            <h1>
              {enumStrings.chamberMember.modal.advertisingDisclaimerTitle}{" "}
              {isCategoryBanner
                ? enumStrings.chamberMember.modal.category
                : enumStrings.chamberMember.modal.memberProfile}
            </h1>
            <div className="agreement">
              <p>
                {enumStrings.chamberMember.modal.advertisingDisclaimerTitle}
              </p>

              <h2>{enumStrings.chamberMember.agreement.section1Title}</h2>
              <p>
                {enumStrings.chamberMember.agreement.section1Text.replace(
                  "{chamberOrBoardText}",
                  chamberOrBoardText
                )}
              </p>

              <h2>{enumStrings.chamberMember.agreement.section2Title}</h2>
              <p>
                {enumStrings.chamberMember.agreement.section2Text.replace(
                  "{chamberOrBoardText}",
                  chamberOrBoardText
                )}
              </p>

              <h2>{enumStrings.chamberMember.agreement.section3Title}</h2>
              <p>
                {enumStrings.chamberMember.agreement.section3Text.replace(
                  "{chamberOrBoardText}",
                  chamberOrBoardText
                )}
              </p>

              <h2>{enumStrings.chamberMember.agreement.section4Title}</h2>
              <p>
                {enumStrings.chamberMember.agreement.section4Text.replace(
                  "{chamberOrBoardText}",
                  chamberOrBoardText
                )}
              </p>

              <h2>{enumStrings.chamberMember.agreement.section5Title}</h2>
              <p>{enumStrings.chamberMember.agreement.section5Text}</p>

              <p>
                {enumStrings.chamberMember.agreement.effectiveDisclaimerText}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.yesButton}
            onClick={() => {
              handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement();
              handleClose();
            }}
            variant="contained"
          >
            {enumStrings.chamberMember.buttons.accept}
          </Button>
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.chamberMember.buttons.cancel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChamberMemberMemberProfileAndPerksBannersAgreementModal;
