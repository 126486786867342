import React, { ReactElement } from "react";
import {
  makeStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import HoverToolTip from "./utilities/HoverToolTip";
import { ChamberPerksAppSubscriptionPlanTypesEnum } from "../../graphql/types";

const useStyles = makeStyles(() => ({
  subscriptionRadioGroup: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "5px",
    width: "100%",
    maxWidth: "475px",
  },
  radio: {
    color: "#E1A731!important",
  },
  container: {
    display: "flex",
    maxWidth: "475px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "50px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0px",
  },
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: "0px",
    textAlign: "center",
  },
}));

interface ChamberSubscriptionPlanRadioGroupProps {
  formikSetFieldValue: (
    fieldName: string,
    value: ChamberPerksAppSubscriptionPlanTypesEnum
  ) => void;
  formikValues: any;
  formikErrors: any;
}

const ChamberSubscriptionPlanRadioGroup = ({
  formikSetFieldValue,
  formikValues,
  formikErrors,
}: ChamberSubscriptionPlanRadioGroupProps): ReactElement | null => {
  const classes = useStyles();

  // Default values for labels
  const labels = {
    title: "Subscription Plan",
    starter: "Starter",
    standard: "Standard",
    titleTooltip: "Select the appropriate subscription plan for this chamber",
    starterTooltip: "* $200 Featured Member Activation Fee",
    standardTooltip: "* $50 Featured Member Activation Fee",
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h1 className={classes.title}>{labels.title}</h1>
        <HoverToolTip description={labels.titleTooltip} showIt />
      </div>
      <RadioGroup
        row
        aria-label="subscriptionPlanType"
        name="subscriptionPlanType"
        id="subscriptionPlanType"
        onChange={(e) => {
          formikSetFieldValue(
            "subscriptionPlanType",
            e.target.value as ChamberPerksAppSubscriptionPlanTypesEnum
          );
        }}
        className={classes.subscriptionRadioGroup}
        value={formikValues.subscriptionPlanType}
      >
        {formikValues.subscriptionPlanType === undefined &&
          formikErrors.subscriptionPlanType && (
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingTop: "0px",
                textAlign: "center",
              }}
            >
              {formikErrors.subscriptionPlanType}
            </FormHelperText>
          )}
        <div className={classes.optionContainer}>
          <FormControlLabel
            value={ChamberPerksAppSubscriptionPlanTypesEnum.Starter}
            labelPlacement="end"
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {labels.starter}
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={labels.starterTooltip}
                  showIt
                />
              </div>
            }
          />
        </div>
        <div className={classes.optionContainer}>
          <FormControlLabel
            value={ChamberPerksAppSubscriptionPlanTypesEnum.Standard}
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                {labels.standard}
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={labels.standardTooltip}
                  showIt
                />
              </div>
            }
            labelPlacement="end"
          />
        </div>
      </RadioGroup>
    </div>
  );
};

export default ChamberSubscriptionPlanRadioGroup;
