import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import "./App.css";
import { Route, useLocation } from "react-router-dom";
import { RedirectUri, useEnumStrings } from "./components/enumStrings";
import ResponsiveDrawer from "./components/common/Navbar/Nav";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { UserTypeContext } from "./UserTypeContext";
import { UserType } from "./graphql/types";
import RootAdmin from "./components/pages/Root/RootAdmin";
import ListAllChambers from "./components/pages/Root/ListAllChambers";
import CreateChamberAdmin from "./components/pages/Root/CreateChamber";
import RootChamberAdmin from "./components/pages/ChamberAdmin/RootChamberAdmin";
import ChamberAdminListAllChamberMembers from "./components/pages/ChamberAdmin/ChamberAdminListAllChambers";
import CreateChamberMember from "./components/pages/ChamberAdmin/CreateChamberMember";
import ManageChamberMember from "./components/pages/ChamberAdmin/ManageAChamberMember";
import ManageChamberAsChamberAdmin from "./components/pages/ChamberAdmin/ChamberAdminManageChamber";
import ChamberMemberListDiscounts from "./components/pages/ChamberMemberAdmin/ListDiscount";
import ChamberMemberCreateDiscount from "./components/pages/ChamberMemberAdmin/CreateDiscount";
import ChamberMemberUpdateDiscount from "./components/pages/ChamberMemberAdmin/UpdateDiscount";
import ChamberMemberAdminSettings from "./components/pages/ChamberMemberAdmin/Settings";
import ForgotPassword from "./components/pages/Login/ForgotPassword";
import NewPassword from "./components/pages/Login/NewPassword";
import ChamberAdminListAllDiscounts from "./components/pages/ChamberAdmin/ListAllChamberDiscounts";
import CPAMarketingToolkit from "./components/pages/ChamberMemberAdmin/CPAMarketingToolkit";
import Support from "./components/pages/Support/Support";
// import General404 from "./components/common/ErrorPages/General404";
import CreatePerkPartner from "./components/pages/Root/CreatePerkPartner";
import ViewPerkPartners from "./components/pages/Root/ViewPerkPartners";
import EditPerkPartner from "./components/pages/Root/EditPerkPartner";
import CPACreatePerk from "./components/common/CPACreatePerk";
import { useHandleLogout } from "./components/common/Hooks/useHandleLogout";
import PerksLink from "./components/pages/PerksLink/PerksLink";
import ListAllChamberShopLocalCampaigns from "./components/pages/ShopLocal/ListAllChamberShopLocalCampaigns";
import CreateChamberShopLocalCampaign from "./components/pages/ShopLocal/CreateChamberShopLocalCampaign";
import UpdateChamberShopLocalCampaign from "./components/pages/ShopLocal/updateChamberShopLocalCampaign";
import ChamberMemberShopLocalCampaign from "./components/pages/ShopLocal/ChamberMemberShopLocalCampaign";
import ListAllChamberRewardCampaigns from "./components/pages/Reward/ListAllChamberRewardCampaigns";
import CreateChamberRewardCampaign from "./components/pages/Reward/CreateChamberRewardCampaign";
import UpdateChamberRewardCampaign from "./components/pages/Reward/updateChamberRewardCampaign";
import RootAdminManageChamberEngage from "./components/pages/Root/ManageChamberEngage";
import RootAdminChamberEngageInvoicing from "./components/pages/Root/ChamberEngageInvoicing";
import ManageChamberEngageCategoryAdvertisingBanners from "./components/pages/ChamberAdmin/ManageChamberEngageCategoryAdvertisingBanners";
import ManageMemberProfileAndPerksBanners from "./components/pages/ChamberAdmin/ManageMemberProfileAndPerksBanners";
import useGetChamberMemberFromUserIdInAuth from "./components/common/Hooks/useGetChamberMemberFromUserIdInAuth";
import useGetChamberFromUserIdInAuth from "./components/common/Hooks/useGetChamberFromUserIdInAuth";
import ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersChamberMember from "./components/pages/ChamberMemberAdmin/ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersChamberMember";
import GeneralSettings from "./components/pages/Settings/Settings";

import { Auth } from "aws-amplify";
import useCheckIfChamberIsPartOfOldBatch from "./components/common/Hooks/useCheckIfChamberIsPartOfOldBatch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      "@media (min-width:1280px)": { marginLeft: "310px" },
      height: "100%",
      background:
        "linear-gradient(to bottom, #E1A731 18%, #E1A731 299px, #F5F5F5 -13%, #F5F5F5 79%)",
    },
  })
);

const useSuspensionStyles = makeStyles(() => ({
  root: {
    "@media (min-width:1280px)": { marginLeft: "-310px", paddingLeft: "310px" },
  },
}));

const LogoutManual = (): ReactElement => {
  const handleLogout = useHandleLogout();

  useEffect(() => {
    handleLogout();
    // only run on page load that's why ignoring
    // eslint-disable-next-line
  }, []);

  return <></>;
};

interface UserTypeRoutesType {
  [key: string]: RedirectUri;
}
const userTypeRoutes = {
  [RedirectUri.rootAdmin]: <RootAdmin />,
  [RedirectUri.listAllChamberAdmin]: <ListAllChambers />,
  [RedirectUri.createAChamber]: <CreateChamberAdmin />,
  [RedirectUri.updateAChamberRoute]: <ManageChamberAsChamberAdmin />,
  [RedirectUri.chamberAdmin]: <RootChamberAdmin />,
  [RedirectUri.manageAMemberChamberAdmin]: (
    <ChamberAdminListAllChamberMembers />
  ),
  [RedirectUri.createAMemberChamberAdmin]: <CreateChamberMember />,
  [RedirectUri.cpaMarketingToolkit]: <CPAMarketingToolkit />,
  [RedirectUri.settingsOfChamberChamberAdmin]: <ChamberMemberAdminSettings />,

  [RedirectUri.manageYourChamberChamberAdmin]: <ManageChamberAsChamberAdmin />,
  [RedirectUri.updateAMemberChamberAdmin]: <ManageChamberMember />,

  [RedirectUri.createDiscountChamberMemberAdmin]: (
    <ChamberMemberCreateDiscount />
  ),
  [RedirectUri.manageDiscountChamberMemberAdmin]: (
    <ChamberMemberListDiscounts />
  ),
  [RedirectUri.editDiscountChamberMemberAdmin]: <ChamberMemberUpdateDiscount />,
  [RedirectUri.settingsChamberMemberAdmin]: <ChamberMemberAdminSettings />,
  [RedirectUri.forgotPassword]: <ForgotPassword />,
  [RedirectUri.newPassword]: <NewPassword />,
  [RedirectUri.memberDiscountPageChamberAdmin]: (
    <ChamberAdminListAllDiscounts />
  ),
  [RedirectUri.chamberShopLocal]: (
    <ListAllChamberShopLocalCampaigns key="inactive" mode={"inactive"} />
  ),
  [RedirectUri.activeChamberShopLocal]: (
    <ListAllChamberShopLocalCampaigns key="active" mode={"active"} />
  ),
  [RedirectUri.chamberMemberShopLocal]: <ChamberMemberShopLocalCampaign />,
  [RedirectUri.createShopLocal]: <CreateChamberShopLocalCampaign />,
  [RedirectUri.updateShopLocal]: <UpdateChamberShopLocalCampaign />,
  [RedirectUri.chamberReward]: (
    <ListAllChamberRewardCampaigns key="inactive" mode={"inactive"} />
  ),
  [RedirectUri.activeChamberReward]: (
    <ListAllChamberRewardCampaigns key="active" mode={"active"} />
  ),
  [RedirectUri.createReward]: <CreateChamberRewardCampaign />,
  [RedirectUri.updateReward]: <UpdateChamberRewardCampaign />,
  [RedirectUri.editDiscountPageChamberAdmin]: <ChamberMemberUpdateDiscount />,
  [RedirectUri.memberDiscountCreatePageChamberAdmin]: (
    <ChamberMemberCreateDiscount />
  ),
  [RedirectUri.createAPerkPartner]: <CreatePerkPartner />,
  [RedirectUri.listAllPerkPartner]: <ViewPerkPartners />,
  [RedirectUri.editperkPartnerDiscountPageChamberAdmin]: <EditPerkPartner />,
  [RedirectUri.cpacreateperk]: <CPACreatePerk />,
  [RedirectUri.perksLink]: <PerksLink />,
  [RedirectUri.rootManageChamberEngage]: <RootAdminManageChamberEngage />,
  [RedirectUri.rootChamberEngageInvoicing]: <RootAdminChamberEngageInvoicing />,
  [RedirectUri.chamberEngageManageMemberProfileAndPerkBanners]: (
    <ManageMemberProfileAndPerksBanners />
  ),
  [RedirectUri.chamberEngageManageCategoryAdvertisingBanners]: (
    <ManageChamberEngageCategoryAdvertisingBanners />
  ),
  [RedirectUri.chamberEngageManageCategoryAdvertisingBannersChamberMember]: (
    <ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersChamberMember />
  ),
  [RedirectUri.support]: <Support />,
} as unknown as UserTypeRoutesType;

const NavigateToSuspensionPageForSuspendedChamberMemberAdmin =
  (): ReactElement => {
    const location = useLocation();
    useEffect(() => {
      if (location.pathname !== RedirectUri.suspendedChamberMember) {
        window.location.href = RedirectUri.suspendedChamberMember;
      }
    }, [location]);

    return <></>;
  };

const LogoutForDisabledAdmin = (): ReactElement => {
  const handleLogout = useHandleLogout();

  useEffect(() => {
    const runAtPageLoad = async () => {
      const user = await Auth.currentAuthenticatedUser();

      if (
        user.signInUserSession &&
        user.signInUserSession.idToken &&
        user.signInUserSession.idToken.payload &&
        user.signInUserSession.idToken.payload["custom:isDisabled"] === "true"
      ) {
        handleLogout();
      }
    };

    runAtPageLoad();

    // only run on page load that's why ignoring
    // eslint-disable-next-line
  }, []);

  return <></>;
};

const LoggedInApp = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const suspensionClasses = useSuspensionStyles();

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const chamberMemberInfo = useGetChamberMemberFromUserIdInAuth();
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const userRoutesToShow = useMemo(() => {
    if (!userType || userType.length === 0) {
      return [];
    }

    // check for suspended chamber member if user type is chamber_member_admin
    if (userType === UserType.ChamberMemberAdmin) {
      if (!(chamberMemberInfo && chamberInfo)) {
        return [];
      }

      if (chamberMemberInfo.isSuspended) {
        return [
          <>
            <NavigateToSuspensionPageForSuspendedChamberMemberAdmin />
            <Route exact path={RedirectUri.suspendedChamberMember}>
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                className={suspensionClasses.root}
              >
                <h2
                  style={{
                    maxWidth: 400,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Your Member Dashboard is currently suspended.{"\n\n"}To
                  activate your Member Dashboard, contact {chamberInfo?.name} at{" "}
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={() =>
                      (window.location.href = `mailto:${chamberInfo?.supportContactEmail}?subject=Member Dashboard Suspension Inquiry by ${chamberMemberInfo?.name} &body=Please%20type%20your%inquiry%20here.`)
                    }
                  >
                    {chamberInfo?.supportContactEmail}
                  </a>
                </h2>
              </div>
            </Route>
          </>,
        ];
      }
    }

    // @ts-ignore
    const routeObjs = Object.values(enumStrings.groups[userType as UserType]);
    return routeObjs.map((route: any, index) => (
      <Route exact path={route.route} key={index}>
        {userTypeRoutes[route.route]}
      </Route>
    ));
  }, [
    chamberInfo,
    chamberMemberInfo,
    suspensionClasses.root,
    userType,
    enumStrings.groups,
  ]);

  const isPartOfOldBatch = useCheckIfChamberIsPartOfOldBatch();

  if (isPartOfOldBatch === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.drawer}>
      <LogoutForDisabledAdmin />
      <ResponsiveDrawer />
      <Route exact path={RedirectUri.settings}>
        <GeneralSettings />
      </Route>
      <Route exact path={RedirectUri.logout}>
        <LogoutManual />
      </Route>
      {userRoutesToShow}
      {/* <Route path="*">
                <General404 />
              </Route> */}
    </div>
  );
};

export default LoggedInApp;
