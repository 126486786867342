import React, { createContext, ReactElement, useMemo, useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetLoggedInUserLanguageFromUserIdInAuth from "../components/common/Hooks/useGetLoggedInUserLanguageFromUserIdInAuth";
import { localStorageGetItem } from "../components/common/utilities/storage-available";
import { allLangs, defaultLang } from "./config-lang";


// Define initial state
interface LocalizationState {
  currentLang: (typeof allLangs)[0];
  lastUpdatedLang: string | null;
}

const initialState: LocalizationState = {
  currentLang: defaultLang,
  lastUpdatedLang: null,
};

// Define setters interface
interface LocalizationSetters {
  setCurrentLang: React.Dispatch<React.SetStateAction<(typeof allLangs)[0]>>;
  setLastUpdatedLang: React.Dispatch<React.SetStateAction<string | null>>;
  onChangeLang: (newlang: string) => void;
}

// Helper functions interface
interface LocalizationHelpers {
  t: (key: string, options?: any) => string;
  allLangs: typeof allLangs;
}

// Create the context with proper default values
const LocalizationContext = createContext<{
  state: LocalizationState;
  setters: LocalizationSetters;
  helpers: LocalizationHelpers;
}>({
  state: initialState,
  setters: {
    setCurrentLang: () => {},
    setLastUpdatedLang: () => {},
    onChangeLang: () => {},
  },
  helpers: {
    t: (key) => key, // Default implementation just returns the key
    allLangs,
  },
});

// Create the provider component
const LocalizationProvider = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const { i18n, t } = useTranslation();
  const loggedInUserLanguageCode = useGetLoggedInUserLanguageFromUserIdInAuth();
  
  // Initialize state
  const langStorage = localStorageGetItem("i18nextLng");
  const storedLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;
  
  const [currentLang, setCurrentLang] = useState<(typeof allLangs)[0]>(storedLang);
  const [lastUpdatedLang, setLastUpdatedLang] = useState<string | null>(null);

  // Change language function
  const onChangeLang = useCallback(
    (newlang: string) => {
      const langObj = allLangs.find((lang) => lang.value === newlang) || defaultLang;
      i18n.changeLanguage(newlang);
      setCurrentLang(langObj);
    },
    [i18n]
  );

  // Update language based on user preferences
  useEffect(() => {
    if (loggedInUserLanguageCode) {
      console.log("loggedInUserLanguageCode before", loggedInUserLanguageCode);
      
      const newLang = loggedInUserLanguageCode.toLowerCase();
      
      // Only update if it's different from the last updated language
      if (newLang !== currentLang.value && newLang !== lastUpdatedLang) {
        onChangeLang(newLang);
        setLastUpdatedLang(newLang); // Store the last updated language
      }
    }
  }, [loggedInUserLanguageCode, currentLang.value, lastUpdatedLang, onChangeLang]);

  // Memoize the context value
  const value = useMemo(() => {
    return {
      state: {
        currentLang,
        lastUpdatedLang,
      },
      setters: {
        setCurrentLang,
        setLastUpdatedLang,
        onChangeLang,
      },
      helpers: {
        t,
        allLangs,
      },
    };
  }, [currentLang, lastUpdatedLang, onChangeLang, t]);

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

// Create a hook to use the context
const useLocales = () => {
  const context = useContext(LocalizationContext);
  
  if (!context) {
    throw new Error("useLocales must be used within a LocalizationProvider");
  }
  
  // Create a simpler interface for components to consume
  return {
    t: context.helpers.t,
    allLangs: context.helpers.allLangs,
    currentLang: context.state.currentLang.value, // Return just the value string for simplicity
    onChangeLang: context.setters.onChangeLang,
  };
};

export { LocalizationContext, LocalizationProvider, useLocales };