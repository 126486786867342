import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  GetAllRewardCampaignsForChamberDocument,
  RewardCampaignInput,
  useCreateRewardCampaignMutation,
  useGetAllChamberMembersQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useRedirect from "../../common/Hooks/useRedirect";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import RewardCampaignEndDatePicker from "./RewardCampaignEndDatePicker";
import Loader from "../../common/Loader/Loader";
import ChamberMemberSelector from "../ChamberMemberAdmin/ChamberMemberSelector";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  descriptionPreviewText: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const initialValues: RewardCampaignInput = {
  startDateUtcMilli: 0,
  endDateUtcMilli: 0,
  campaignName: "",
  prizeDescription: "",
  sponsoringChamberMemberId: "",
};

const CreateChamberRewardCampaign = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const CHARACTER_LIMIT_DESCRIPTION = 250;

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    enumStrings.shopLocal.updateChamberShopLocalCampaign.anErrorOccurred
  );
  const [invalidError, setInvalidError] = useState(false);
  const [createRewardCampaign] = useCreateRewardCampaignMutation();
  const redirectToRewardCampaignList = useRedirect(
    RedirectUri.activeChamberReward,
    {
      showToast: true,
    }
  );

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const validationSchema = yup.object({
    sponsoringChamberMemberId: yup
      .string()
      .required(
        enumStrings.createChamberRewardCampaignValidation
          .sponsoringChamberMemberId
      ),
    campaignName: yup
      .string()
      .required(enumStrings.createChamberRewardCampaignValidation.campaignName),
    prizeDescription: yup
      .string()
      .required(
        enumStrings.createChamberRewardCampaignValidation.prizeDescription
      ),
    startDateUtcMilli: yup
      .number()
      .required(
        enumStrings.createChamberRewardCampaignValidation.startDateUtcMilli
      )
      .min(
        0,
        enumStrings.createChamberRewardCampaignValidation.endDateUtcMillimin
      ),
    endDateUtcMilli: yup
      .number()
      .required(
        enumStrings.createChamberRewardCampaignValidation.endDateUtcMilli
      )
      .min(
        Date.now(),
        enumStrings.createChamberRewardCampaignValidation.endDateUtcMilli
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: RewardCampaignInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        const rewardCampaignCreation = await createRewardCampaign({
          variables: {
            input: {
              startDateUtcMilli: input.startDateUtcMilli,
              endDateUtcMilli: input.endDateUtcMilli,
              campaignName: input.campaignName,
              prizeDescription: input.prizeDescription,
              sponsoringChamberMemberId: input.sponsoringChamberMemberId,
            },
          },
          refetchQueries: [
            { query: GetAllRewardCampaignsForChamberDocument },
            "getAllRewardCampaignsForChamber",
          ],
          awaitRefetchQueries: true,
        });
        const createionResult =
          rewardCampaignCreation.data?.createRewardCampaign;
        if (createionResult?.campaignCreatedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(enumStrings.perkPartnerAdmin.unableToCreateReward);
          setLoader(false);
          setButtonDisabled(false);
          return;
        }

        setLoader(false);
        redirectToRewardCampaignList();
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const createChamberRewardCampaignObj = useMemo(() => {
    // @ts-ignore
    return enumStrings.groups[UserType.ChamberAdmin][RedirectUri.createReward];
  }, [enumStrings.groups]);

  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (allChamberMemberData?.getAllChamberMembers) {
      setShowLoader(false);
    }
  }, [allChamberMemberData?.getAllChamberMembers]);

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{createChamberRewardCampaignObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {createChamberRewardCampaignObj.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
      </div>
      <div className={classes.content}>
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              <ChamberMemberSelector
                allChamberMemberData={allChamberMemberData}
                chamberMemberId={
                  formik.values.sponsoringChamberMemberId === ""
                    ? null
                    : formik.values.sponsoringChamberMemberId
                }
                setChamberMemberId={(chamberMemberId: string) => {
                  formik.setFieldValue(
                    "sponsoringChamberMemberId",
                    chamberMemberId || ""
                  );
                }}
                isRewardCampaignSelector
              />
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "0px",
                  textAlign: "center",
                }}
              >
                {formik.touched.sponsoringChamberMemberId &&
                  formik.errors.sponsoringChamberMemberId}
              </FormHelperText>
              <div className={classes.companyInputWithToolTipContainer}>
                <div>
                  <TextField
                    type="text"
                    label={enumStrings.rewardCampaign.label.campaignName}
                    className={classes.companyInput}
                    variant="outlined"
                    id="campaignName"
                    name="campaignName"
                    value={formik.values.campaignName}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText
                    component="legend"
                    style={{
                      color: "#f44336",
                      paddingLeft: "0px",
                      paddingTop: "5px",
                      textAlign: "center",
                    }}
                  >
                    {formik.touched.campaignName && formik.errors.campaignName}
                  </FormHelperText>
                </div>
                <div
                  style={{
                    marginTop: "25px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.rewardCampaign.tooltip.campaignName
                    }
                    showIt
                  />
                </div>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <FormControl className={classes.notificationDescriptionInput}>
                  <TextField
                    className={classes.selectedValue}
                    maxRows={5}
                    label={enumStrings.rewardCampaign.label.prizeDescription}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        maxLength: CHARACTER_LIMIT_DESCRIPTION,
                      },
                    }}
                    value={formik.values.prizeDescription}
                    onChange={formik.handleChange}
                    multiline
                    id="prizeDescription"
                    name="prizeDescription"
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.rewardCampaign.tooltip.prizeDescription
                    }
                    showIt
                  />
                </div>
              </div>
              <InputLabel style={{ margin: "auto" }}>
                {`${
                  (formik.values.prizeDescription || "").length
                }/${CHARACTER_LIMIT_DESCRIPTION} ${enumStrings.shopLocal.createShopLocalCampaign.charRemaining}`}
              </InputLabel>
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "0px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {formik.touched.prizeDescription &&
                  formik.errors.prizeDescription}
              </FormHelperText>
              <div className={classes.descriptionPreviewText}>
                <h3>
                  <span style={{ fontWeight: "bold", marginBottom: "15px" }}>
                    {enumStrings.rewardsPage.rewardsPreview}
                    <br />
                  </span>
                  "{enumStrings.rewardsPage.descriptionText1}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {formik.values.prizeDescription.length > 0
                      ? formik.values.prizeDescription
                      : enumStrings.rewardsPage.enterText}
                  </span>
                  "
                </h3>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <RewardCampaignEndDatePicker
                  formikErrors={formik.errors.startDateUtcMilli}
                  formikVal={formik.values.startDateUtcMilli}
                  formikSetFieldValue={formik.setFieldValue}
                  startAfterDate={Date.now()}
                  isForStartDate
                />
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.rewardCampaign.tooltip.startDateUtcMilli
                    }
                    showIt
                  />
                </div>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <RewardCampaignEndDatePicker
                  formikErrors={formik.errors.endDateUtcMilli}
                  formikVal={formik.values.endDateUtcMilli}
                  formikSetFieldValue={formik.setFieldValue}
                  disabled={!(formik.values.startDateUtcMilli > 0)}
                  startAfterDate={formik.values.startDateUtcMilli}
                />
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.rewardCampaign.tooltip.endDateUtcMilli
                    }
                    showIt
                  />
                </div>
              </div>
              <br />
              <br />
              <div>
                {invalidError ? (
                  <div className={classes.invalidError}>{customError}</div>
                ) : null}
              </div>{" "}
              {Object.keys(formik?.errors || {}).length > 0 ? (
                <div>
                  <br />
                  <div className={classes.invalidError}>
                    {enumStrings.ChamberMemberSelector.pleaseAddressAnyIssues}
                  </div>
                </div>
              ) : null}
              <br />
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#E1A731",
                  fontSize: "23px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "200px",
                  marginTop: "59px",
                  marginBottom: "39px",
                  margin: "auto",
                  height: "65px",
                }}
                disabled={buttonDisabled || loader}
              >
                {loader ? (
                  <CircularProgress
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  enumStrings.rewardsPage.create
                )}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateChamberRewardCampaign;
