import React, { ReactElement } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Loader from "../Loader/Loader";
import { CSVLink } from "react-csv";
import { useEnumStrings } from "../../enumStrings";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => {};
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      height: "351px",
      width: "500px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    okButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
  })
);

const ButtonForConfig = ({
  buttonConfig,
  password,
  username,
  companyName,
  handleClose,
}: {
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
  password: string;
  username: string;
  companyName: string;
  handleClose: () => void;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const csvData = [
    ["Dashboard Email Username", "Dashboard Temporary Password"],
    [username, password],
  ];
  if (buttonConfig.buttonType === ButtonConfigType.YesNoConfig) {
    return (
      <div>
        <CSVLink
          data={csvData}
          filename={`${companyName} dashboard credentials.csv`}
          style={{
            textDecoration: "none",
          }}
          onClick={buttonConfig.yesButtonAction}
        >
          <Button className={classes.yesButton} variant="contained">
            {enumStrings.chamberModal.downloadText}
          </Button>
        </CSVLink>
        <Button
          className={classes.yesButton}
          variant="contained"
          onClick={() => {
            handleClose();
          }}
        >
          {enumStrings.shopLocal.createShopLocalCampaign.close}
        </Button>
      </div>
    );
  }

  return (
    <Button
      onClick={buttonConfig.okButtonAction}
      className={classes.okButton}
      variant="contained"
    >
      {enumStrings.chamberModal.okText}
    </Button>
  );
};

const ModalContent = ({
  message,
  buttonConfig,
  loading,
  password,
  username,
  companyName,
  handleClose,
}: {
  message: string;
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
  loading: boolean;
  password: string;
  username: string;
  companyName: string;
  handleClose: () => void;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h2>{message}</h2>
          <ButtonForConfig
            password={password}
            username={username}
            companyName={companyName}
            buttonConfig={buttonConfig}
            handleClose={handleClose}
          />
        </div>
      )}
    </div>
  );
};

const ChamberMemberInfoModal = ({
  open,
  loading,
  handleClose,
  message,
  buttonConfig,
  password,
  username,
  companyName,
}: {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  message: string;
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
  password: string;
  username: string;
  companyName: string;
}): ReactElement => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ModalContent
        loading={loading}
        message={message}
        buttonConfig={buttonConfig}
        password={password}
        username={username}
        companyName={companyName}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default ChamberMemberInfoModal;
