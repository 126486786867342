import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, IconButton } from "@material-ui/core";
import {
  LoginInfoEmailTypes,
  useGetSampleLoginInfoEmailQuery,
  useSendLoginInfoEmailToChamberMemberMutation,
  useSetNewTemporaryChamberMemberPasswordMutation,
} from "../../graphql/types";
import { CSVLink } from "react-csv";
import { useEnumStrings } from "../enumStrings";
import useGetChamberFromUserIdInAuth from "./Hooks/useGetChamberFromUserIdInAuth";
import { removePlusAddress } from "./utilities/removePlusAddress";

type ModalType =
  | "memberTable"
  | "changeLoginEmailUsername"
  | "resetPassword"
  | "createChamberMember";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "100%",
      borderRadius: "5px",
      textAlign: "center",
      maxHeight: ({ modalType }: { modalType: ModalType }) =>
        modalType === "resetPassword"
          ? "700px"
          : modalType === "memberTable"
          ? "945px"
          : "680px",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
    okButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

const DownloadLoginInfoConfirmationModal = ({
  open,
  handleClose,
  memberAdminPortalEmail,
  memberCompnayName,
  newPassword,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  memberAdminPortalEmail: string;
  memberCompnayName: string;
  newPassword: string;
  modalType: ModalType;
}): ReactElement => {
  const classes = useStyles({ modalType: modalType });

  const enumStrings = useEnumStrings();

  const csvData = [
    [
      enumStrings.sendLoginModal.memberEmail,
      enumStrings.sendLoginModal.memberPassword,
    ],
    [memberAdminPortalEmail, newPassword],
  ];

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <h2>{enumStrings.sendLoginModal.memberTitle}</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <CSVLink
            data={csvData}
            filename={`${memberCompnayName} member dashboard credentials.csv`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button className={classes.yesButton} variant="contained">
              {enumStrings.sendLoginModal.download}
            </Button>
          </CSVLink>

          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.sendLoginModal.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const EmailLoginInfoConfirmationModal = ({
  open,
  handleClose,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  modalType: ModalType;
}) => {
  const classes = useStyles({ modalType: modalType });
  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <h2>{enumStrings.sendLoginModal.memberTitle2} </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.shopLocal.createShopLocalCampaign.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const SampleLoginInfoEmailModal = ({
  open,
  handleClose,
  emailHtml,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  emailHtml: null | undefined | string;
  modalType: ModalType;
}) => {
  const classes = useStyles({ modalType: modalType });
  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "left",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "20px",
            backgroundColor: "white",
            borderRadius: "15px",
            overflow: "scroll",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: emailHtml || "" }} />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.sendLoginModal.goBack}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const SendLoginInformationModal = ({
  open,
  handleClose,
  redirectToMemberProfile,
  memberCompanyName,
  memberAdminPortalEmail,
  chamberMemberId,
  type,
  credentialsSentAtUtcMilli,
  isSuspended,
}: {
  open: boolean;
  handleClose: () => void;
  redirectToMemberProfile?: () => void;
  memberCompanyName: string;
  memberAdminPortalEmail: string;
  chamberMemberId: string | null | undefined;
  type: ModalType;
  isSuspended: boolean;
  credentialsSentAtUtcMilli?: number | null | undefined;
}): ReactElement => {
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const enumStrings = useEnumStrings();
  const classes = useStyles({ modalType: type });

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const [
    downloadLoginInfoConfirmationModalOpen,
    setDownloadLoginInfoConfirmationModalOpen,
  ] = useState(false);
  const closeDownloadLoginInfoConfirmationModal = useCallback(() => {
    setDownloadLoginInfoConfirmationModalOpen(false);
    handleClose();
  }, [handleClose]);

  const [
    emailLoginInfoConfirmationModalOpen,
    setEmailLoginInfoConfirmationModalOpen,
  ] = useState(false);
  const closeEmailLoginInfoConfirmationModal = useCallback(() => {
    setEmailLoginInfoConfirmationModalOpen(false);
    handleClose();
  }, [handleClose]);

  const [sampleLoginInfoEmailModalOpen, setSampleLoginInfoEmailModalOpen] =
    useState(false);
  const closeSampleLoginInfoEmailModal = useCallback(() => {
    setSampleLoginInfoEmailModalOpen(false);
  }, []);

  const [setNewTemporaryChamberMemberPassowrd] =
    useSetNewTemporaryChamberMemberPasswordMutation();

  const executeDownloadLoginInfo = useCallback(async () => {
    setLoading(true);

    const { data } = await setNewTemporaryChamberMemberPassowrd({
      variables: {
        adminConsoleLoginEmail: memberAdminPortalEmail || "",
        chamberMemberId: chamberMemberId || "",
      },
    });

    setNewPassword(data?.setNewTemporaryChamberMemberPassword.password || "");

    setDownloadLoginInfoConfirmationModalOpen(true);
    setLoading(false);
    const button = document.getElementById(
      "login-info-modal-csv-download-button"
    );
    button?.click();
  }, [
    chamberMemberId,
    memberAdminPortalEmail,
    setNewTemporaryChamberMemberPassowrd,
  ]);

  const csvData = useMemo(
    () => [
      [
        enumStrings.sendLoginModal.memberEmail,
        enumStrings.sendLoginModal.memberPassword,
      ],
      [memberAdminPortalEmail, newPassword],
    ],
    [
      enumStrings.sendLoginModal.memberEmail,
      enumStrings.sendLoginModal.memberPassword,
      memberAdminPortalEmail,
      newPassword,
    ]
  );

  const [sendLoginInfoEmailToChamberMember] =
    useSendLoginInfoEmailToChamberMemberMutation();

  const executeSendLoginInfoEmail = useCallback(async () => {
    setLoading(true);

    let apiType = LoginInfoEmailTypes.BrandNew;

    if (type === "changeLoginEmailUsername") {
      apiType = LoginInfoEmailTypes.ChangeEmailUsername;
    }
    if (type === "resetPassword") {
      apiType = LoginInfoEmailTypes.ResetPassword;
    }

    const { data } = await sendLoginInfoEmailToChamberMember({
      variables: {
        adminConsoleLoginEmail: memberAdminPortalEmail || "",
        chamberMemberId: chamberMemberId || "",
        type: apiType,
      },
      refetchQueries: ["getChamberMembers"],
    });

    if (
      data?.sendLoginInfoEmailToChamberMember?.loginInfoEmailSentSuccessfully
    ) {
      setEmailLoginInfoConfirmationModalOpen(true);
    } else {
      handleClose();
    }

    setLoading(false);
  }, [
    chamberMemberId,
    handleClose,
    memberAdminPortalEmail,
    sendLoginInfoEmailToChamberMember,
    type,
  ]);

  const sampleType: LoginInfoEmailTypes = useMemo(() => {
    if (type === "changeLoginEmailUsername") {
      return LoginInfoEmailTypes.ChangeEmailUsername;
    }
    if (type === "resetPassword") {
      return LoginInfoEmailTypes.ResetPassword;
    }
    return LoginInfoEmailTypes.BrandNew;
  }, [type]);

  const { data: getSampleLoginEmail } = useGetSampleLoginInfoEmailQuery({
    variables: {
      type: sampleType,
      chamberMemberId: chamberMemberId || "",
    },
  });

  const modalTitle = useMemo(() => {
    if (type === "memberTable") {
      if (credentialsSentAtUtcMilli) {
        return enumStrings.sendLoginModal.resendLoginCredentials;
      }
      return enumStrings.sendLoginModal.sendLoginCredentials;
    }

    if (type === "changeLoginEmailUsername") {
      return enumStrings.sendLoginModal.changeSuccess;
    }

    if (type === "resetPassword") {
      return enumStrings.sendLoginModal.newTempPassword;
    }

    if (type === "createChamberMember") {
      return enumStrings.loginInormationModal.titles.member;
    }

    return "";
  }, [
    credentialsSentAtUtcMilli,
    enumStrings.loginInormationModal.titles.member,
    enumStrings.sendLoginModal.changeSuccess,
    enumStrings.sendLoginModal.newTempPassword,
    enumStrings.sendLoginModal.resendLoginCredentials,
    enumStrings.sendLoginModal.sendLoginCredentials,
    type,
  ]);

  return (
    <>
      <SampleLoginInfoEmailModal
        open={sampleLoginInfoEmailModalOpen}
        handleClose={closeSampleLoginInfoEmailModal}
        emailHtml={getSampleLoginEmail?.getSampleLoginInfoEmail?.emailHtml}
        modalType={type}
      />
      <DownloadLoginInfoConfirmationModal
        open={downloadLoginInfoConfirmationModalOpen}
        handleClose={closeDownloadLoginInfoConfirmationModal}
        memberAdminPortalEmail={memberAdminPortalEmail}
        memberCompnayName={memberCompanyName}
        newPassword={newPassword}
        modalType={type}
      />
      <EmailLoginInfoConfirmationModal
        open={emailLoginInfoConfirmationModalOpen}
        handleClose={closeEmailLoginInfoConfirmationModal}
        modalType={type}
      />
      <Modal
        className={classes.modal}
        style={{
          ...(sampleLoginInfoEmailModalOpen ||
          downloadLoginInfoConfirmationModalOpen ||
          emailLoginInfoConfirmationModalOpen
            ? { display: "none" }
            : {}),
        }}
        open={open}
        onClose={loading || type !== "memberTable" ? undefined : handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={classes.paper}
          style={{
            ...(isSuspended
              ? { height: "380px" }
              : { maxHeight: type === "memberTable" ? "700px" : "760px" }),
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              color: "#37474f",
              fontWeight: "bold",
              borderRadius: "100px",
              padding: 5,
              ...(loading || type !== "memberTable" ? { display: "none" } : {}),
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {loading ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color: "#E1A731",
                }}
              />
            </div>
          ) : (
            <>
              <h1 style={{ fontWeight: "bold" }}>{modalTitle}</h1>
              <div
                style={{
                  margin: "auto",
                  overflow: "scroll",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  ...(isSuspended ? { flex: 1 } : {}),
                }}
              >
                {isSuspended ? (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      overflow: "scroll",
                      alignItems: "center",
                      paddingRight: "25px",
                      paddingLeft: "25px",
                      paddingTop: "30px",
                      height: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <h3
                        style={{
                          textAlign: "left",
                          margin: 0,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {enumStrings.sendLoginModal.description}
                      </h3>
                    </div>
                    <Button
                      className={classes.yesButton}
                      onClick={handleClose}
                      variant="contained"
                    >
                      {enumStrings.shopLocal.createShopLocalCampaign.close}
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      overflow: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "19px",
                        textAlign: "center",
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      {type === "resetPassword"
                        ? enumStrings.sendLoginModal.option1HeaderReset
                        : enumStrings.sendLoginModal.option1Header}
                    </p>
                    <span>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        {enumStrings.sendLoginModal.description2}{" "}
                        {type === "resetPassword"
                          ? "noreply@chamberperksapp.com"
                          : "hello@chamberperksapp.com"}{" "}
                        {enumStrings.sendLoginModal.to} {memberCompanyName}{" "}
                        {enumStrings.sendLoginModal.at}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {removePlusAddress({
                            adminConsoleLoginEmail: memberAdminPortalEmail,
                            chamberName: chamberInfo?.name || "",
                          })}
                        </span>{" "}
                        {enumStrings.sendLoginModal.with}{" "}
                        {type === "resetPassword"
                          ? `${enumStrings.sendLoginModal.instruction1}`
                          : `${enumStrings.sendLoginModal.instruction2}`}
                        .
                      </h3>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className={classes.yesButton}
                        onClick={executeSendLoginInfoEmail}
                        variant="contained"
                      >
                        {enumStrings.sendLoginModal.sendEmail}
                      </Button>
                      <span
                        onClick={() => {
                          setSampleLoginInfoEmailModalOpen(true);
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {enumStrings.sendLoginModal.preview}
                      </span>
                    </div>
                    <br />
                    <br />
                    <p
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "19px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {type === "resetPassword"
                        ? enumStrings.sendLoginModal.option2HeaderReset
                        : enumStrings.sendLoginModal.option2Header}
                    </p>
                    <span>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        {enumStrings.sendLoginModal.downloadCredentialsInfo.replace(
                          "{memberCompanyName}",
                          memberCompanyName
                        )}{" "}
                        <span
                          onClick={() => {
                            setSampleLoginInfoEmailModalOpen(true);
                          }}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {enumStrings.sendLoginModal.clickHere}
                        </span>{" "}
                        {enumStrings.sendLoginModal.info2}
                      </h3>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "-10px",
                      }}
                    >
                      <Button
                        className={classes.okButton}
                        onClick={executeDownloadLoginInfo}
                        variant="contained"
                      >
                        {enumStrings.sendLoginModal.downloadCredentials}
                      </Button>
                    </div>

                    <br />
                    <br />
                    {type === "memberTable" ||
                    type === "changeLoginEmailUsername" ? (
                      <span
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {enumStrings.sendLoginModal.noteTitle}
                        </span>{" "}
                        {enumStrings.sendLoginModal.temporaryPasswordNote}
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
      <CSVLink
        data={csvData}
        filename={`${memberCompanyName} member dashboard credentials.csv`}
        id="login-info-modal-csv-download-button"
        style={{ display: "none" }}
      />
    </>
  );
};

export default SendLoginInformationModal;
