import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { ChamberMemberReturn, UserType } from "../../graphql/types";
import { RedirectUri, useEnumStrings } from "../enumStrings";
import { useHistory } from "react-router-dom";
import { TextField, makeStyles, Button, Chip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../themes";
import LogoImage from "./utilities/LogoImage";
import useGetChamberFromUserIdInAuth from "./Hooks/useGetChamberFromUserIdInAuth";
import SendLoginInformationModal from "./SendLoginInformationModal";
import { getLocalTimestamp } from "../pages/Root/RootAdmin";
import useCheckIfChamberIsPartOfOldBatch from "./Hooks/useCheckIfChamberIsPartOfOldBatch";

type OmittedRedeemSettingsChamberMember = ChamberMemberReturn & {
  numberOfPerksOffered: number;
};
type AllChamberMemberDataType = OmittedRedeemSettingsChamberMember[];
type TableChamberMemberDataType = OmittedRedeemSettingsChamberMember & {
  name: string;
  numberOfPerksOffered: number;
};
type AllTableChamberMemberDataType = TableChamberMemberDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: AllTableChamberMemberDataType,
  comparator: (a: any, b: any) => number
): AllTableChamberMemberDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageChamberMemberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageChamberMemberTableHead(
  props: FullPageChamberMemberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// @ts-ignore
const useStyles = makeStyles((themes: any) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  createPerkText: {
    fontWeight: "bold !important",
    color: "#E1A731 !important",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const useHeadCells = ({
  isPartOfOldBatch,
}: {
  isPartOfOldBatch: boolean | undefined;
}): HeadCell[] => {
  const enumStrings = useEnumStrings();

  return [
    {
      id: "memberLogo",
      label: "",
    },
    {
      id: "name",
      label: enumStrings.chamberMemberTableLabel.name,
    },
    {
      id: "numberOfPerksOffered",
      label: enumStrings.chamberMemberTableLabel.numberOfPerksOffered,
      alignCenter: true,
    },
    ...(isPartOfOldBatch
      ? [
          {
            id: "numberOfUsers",
            label: enumStrings.chamberMemberTableLabel.numberOfUsers,
            alignCenter: true,
          },
        ]
      : []),
    {
      id: "hasSignedUp",
      label: enumStrings.chamberMemberTableLabel.hasSignedUp,
      alignCenter: true,
    },
  ];
};

const MemoizedLoggedInToMemberDashboardTableCell = React.memo(
  ({
    chamberMemberInfo,
    redirectToMemberProfile,
    isSandbox,
  }: {
    chamberMemberInfo: TableChamberMemberDataType;
    redirectToMemberProfile: () => void;
    isSandbox: boolean | null | undefined;
  }) => {
    const [showSendLoginInfoModal, setShowLoginInfoModal] =
      React.useState(false);

    const openShowSendLoginInfoModal = React.useCallback(() => {
      setShowLoginInfoModal(true);
    }, []);
    const closeShowSendLoginInfoModal = React.useCallback(() => {
      setShowLoginInfoModal(false);
    }, []);

    // For sandbox mode, use a deterministic value based on ID instead of random
    const hasSignedUp = isSandbox
      ? parseInt(chamberMemberInfo.id.replace(/\D/g, "0") || "0") % 100 < 44
      : chamberMemberInfo.hasSignedUp;

    const enumStrings = useEnumStrings();
    const hasLoggedInText = enumStrings.chamberMemberTableLabel.hasLoggedIn;
    const neverLoggedInText = enumStrings.chamberMemberTableLabel.neverLoggedIn;
    const sendCredentialsText =
      enumStrings.chamberMemberTableLabel.sendCredentials;
    const resendCredentialsText =
      enumStrings.chamberMemberTableLabel.resendCredentials;

    return (
      <>
        {showSendLoginInfoModal ? (
          <SendLoginInformationModal
            isSuspended={chamberMemberInfo.isSuspended ? true : false}
            handleClose={closeShowSendLoginInfoModal}
            open={showSendLoginInfoModal}
            memberAdminPortalEmail={
              chamberMemberInfo.adminConsoleLoginEmail || ""
            }
            memberCompanyName={chamberMemberInfo.name}
            redirectToMemberProfile={redirectToMemberProfile}
            chamberMemberId={chamberMemberInfo.id}
            type="memberTable"
            credentialsSentAtUtcMilli={
              chamberMemberInfo?.credentialsSentAtUtcMilli
            }
          />
        ) : null}
        <TableCell
          align="center"
          style={{ fontSize: "16px", cursor: "default" }}
        >
          {hasSignedUp ? (
            <Chip
              label={hasLoggedInText}
              variant="outlined"
              size="small"
              style={{
                color: "#34694F",
                backgroundColor: "#DCEEDC",
                borderColor: "#34694F",
              }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip
                label={neverLoggedInText}
                variant="outlined"
                size="small"
                style={{
                  color: "#AF2F2E",
                  backgroundColor: "#FFE2DE",
                  borderColor: "#AF2F2E",
                }}
              />
              {chamberMemberInfo.isSuspended ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                    maxWidth: 150,
                    marginTop: 5,
                  }}
                >
                  {enumStrings.chamberMemberTableLabel.setMemberShip}
                </span>
              ) : (
                <>
                  <Button
                    type="submit"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={openShowSendLoginInfoModal}
                    style={{
                      backgroundColor: "#E1A731",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      marginTop: "10px",
                      whiteSpace: "pre-wrap",
                      width: "165px",
                    }}
                  >
                    {chamberMemberInfo?.credentialsSentAtUtcMilli
                      ? resendCredentialsText
                      : sendCredentialsText}
                  </Button>
                  {chamberMemberInfo?.credentialsSentAtUtcMilli ? (
                    <span
                      style={{
                        color: "grey",
                        marginTop: "5px",
                        fontSize: "12.5px",
                        textAlign: "center",
                      }}
                    >
                      {enumStrings.chamberMemberTableLabel.lastSent}{" "}
                      {getLocalTimestamp(
                        chamberMemberInfo?.credentialsSentAtUtcMilli || 0,
                        true
                      )}
                    </span>
                  ) : null}
                </>
              )}
            </div>
          )}
        </TableCell>
      </>
    );
  }
);

// Memoized row component to prevent unnecessary re-renders
const MemoizedTableRow = React.memo(
  ({
    chamberMemberInfo,
    handleClick,
    userIdInAuthChamberInfo,
    isPartOfOldBatch,
  }: {
    chamberMemberInfo: TableChamberMemberDataType;
    handleClick: (chamberMemberInfo: TableChamberMemberDataType) => void;
    userIdInAuthChamberInfo: any;
    isPartOfOldBatch: boolean | undefined;
  }) => {
    // Make sandbox values deterministic instead of random to prevent re-renders
    const getRandomPerks = (id: string) => {
      const seed = parseInt(id.replace(/\D/g, "0") || "0");
      return (seed % 5) + 1; // 1-5
    };

    const getRandomUsers = (id: string) => {
      const seed = parseInt(id.replace(/\D/g, "0") || "0");
      return (seed % 150) + 50; // 50-199
    };

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={chamberMemberInfo.id}
        sx={{ cursor: "pointer" }}
      >
        <TableCell
          style={{ fontSize: "16px" }}
          component="th"
          scope="row"
          onClick={() => handleClick(chamberMemberInfo)}
        >
          <LogoImage
            logoSource={chamberMemberInfo.logo}
            size="large"
            squared
            key={chamberMemberInfo.id}
            chamberName={chamberMemberInfo.name}
          />
        </TableCell>
        <TableCell
          style={{ fontSize: "16px" }}
          component="th"
          scope="row"
          onClick={() => handleClick(chamberMemberInfo)}
        >
          {chamberMemberInfo.name}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontSize: "16px" }}
          onClick={() => handleClick(chamberMemberInfo)}
        >
          {userIdInAuthChamberInfo?.isSandbox
            ? getRandomPerks(chamberMemberInfo.id)
            : chamberMemberInfo.numberOfPerksOffered}
        </TableCell>
        {isPartOfOldBatch && (
          <TableCell
            align="center"
            style={{ fontSize: "16px" }}
            onClick={() => handleClick(chamberMemberInfo)}
          >
            {userIdInAuthChamberInfo?.isSandbox
              ? getRandomUsers(chamberMemberInfo.id)
              : chamberMemberInfo.numberOfUsers}
          </TableCell>
        )}
        <MemoizedLoggedInToMemberDashboardTableCell
          chamberMemberInfo={chamberMemberInfo}
          redirectToMemberProfile={() => handleClick(chamberMemberInfo)}
          isSandbox={userIdInAuthChamberInfo?.isSandbox}
        />
      </TableRow>
    );
  }
);

export default function FullPageChamberMemberTable({
  userType,
  allChamberMemberData,
}: {
  userType: UserType;
  allChamberMemberData: AllChamberMemberDataType;
}) {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const history = useHistory();
  const tableRef = React.useRef<HTMLDivElement>(null);

  // For manual virtualization
  const [visibleStartIndex, setVisibleStartIndex] = React.useState(0);
  const [visibleEndIndex, setVisibleEndIndex] = React.useState(20); // Initial batch size
  const rowHeight = 80; // Approximate height of each row

  // Debounced search implementation
  const [searchInput, setSearchInput] = React.useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState("");
  const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchInput);
    }, 300); // 300ms debounce

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchInput]);

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleClick = React.useCallback(
    (chamberMemberInfo: TableChamberMemberDataType) => {
      if (userType === UserType.ChamberAdmin) {
        history.push(RedirectUri.updateAMemberChamberAdmin, {
          chamberMemberId: chamberMemberInfo.id,
        });
      }
    },
    [history, userType]
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();

  const totalNumberOfMemberAppUser = React.useMemo(() => {
    let users = 0;

    for (const chamberMember of allChamberMemberData) {
      users += chamberMember.numberOfUsers || 0;
    }

    return userIdInAuthChamberInfo?.isSandbox ? 1322 : users;
  }, [allChamberMemberData, userIdInAuthChamberInfo?.isSandbox]);

  const isPartOfOldBatch = useCheckIfChamberIsPartOfOldBatch();

  const allTableChamberMemberData: AllTableChamberMemberDataType =
    React.useMemo(() => {
      if (!allChamberMemberData) {
        return [];
      }

      const allChamberMembersArr = allChamberMemberData.map(
        (chamberMemberData) => {
          return {
            ...chamberMemberData,
            numberOfUsers: chamberMemberData.numberOfUsers || 0,
          };
        }
      );

      // // UNCOMMENT BELOW TO SIMULATE 800 MEMBERS
      // // Create base array of chamber members
      // const baseChamberMembersArr = allChamberMemberData.map(
      //   (chamberMemberData) => ({
      //     ...chamberMemberData,
      //     numberOfUsers: chamberMemberData.numberOfUsers || 0,
      //   })
      // );

      // // Multiply the array by 50 with unique IDs
      // const allChamberMembersArr = Array.from({ length: 50 }, (_, index) =>
      //   baseChamberMembersArr.map((member) => ({
      //     ...member,
      //     id: `${member.id}_${index}`, // Make ID unique
      //     name: `${member.name} ${index + 1}`, // Make name unique
      //     // Keep other fields the same but ensure they're unique where needed
      //     adminConsoleLoginEmail: member.adminConsoleLoginEmail
      //       ? `${member.adminConsoleLoginEmail.split("@")[0]}_${index + 1}@${
      //           member.adminConsoleLoginEmail.split("@")[1]
      //         }`
      //       : null,
      //     customContacts: member.customContacts?.map((contact) => ({
      //       ...contact,
      //       id: `${contact.id}_${index}`,
      //     })),
      //   }))
      // ).flat(); // Flatten the array of arrays
      // // UNCOMMENT ABOVE TO SIMULATE 800 MEMBERS

      if (debouncedSearchTerm.length > 0) {
        return allChamberMembersArr.filter((i) => {
          return i.name
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase());
        });
      }

      return allChamberMembersArr;
    }, [allChamberMemberData, debouncedSearchTerm]);

  const visibleRows = React.useMemo(
    () => stableSort(allTableChamberMemberData, getComparator(order, orderBy)),
    [allTableChamberMemberData, order, orderBy]
  );

  // Handle scroll events to update visible rows
  const handleScroll = React.useCallback(() => {
    if (!tableRef.current) return;

    const scrollTop = tableRef.current.scrollTop;
    const tableHeight = tableRef.current.clientHeight;

    // Calculate which rows should be visible
    const firstVisibleRow = Math.max(0, Math.floor(scrollTop / rowHeight));
    const numVisibleRows = Math.ceil(tableHeight / rowHeight);

    // Add buffer rows above and below for smoother scrolling
    const buffer = 5;
    const newStartIndex = Math.max(0, firstVisibleRow - buffer);
    const newEndIndex = Math.min(
      visibleRows.length,
      firstVisibleRow + numVisibleRows + buffer
    );

    if (
      newStartIndex !== visibleStartIndex ||
      newEndIndex !== visibleEndIndex
    ) {
      setVisibleStartIndex(newStartIndex);
      setVisibleEndIndex(newEndIndex);
    }
  }, [visibleRows.length, visibleStartIndex, visibleEndIndex, rowHeight]);

  // Attach scroll event listener
  React.useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
      // Initial setup of visible rows
      handleScroll();

      return () => {
        tableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // Reset visible indices when data changes
  React.useEffect(() => {
    setVisibleStartIndex(0);
    setVisibleEndIndex(20);
  }, [visibleRows]);

  const getHeadCells = useHeadCells({
    isPartOfOldBatch,
  });

  // Get only the currently visible rows
  const currentlyVisibleRows = visibleRows.slice(
    visibleStartIndex,
    visibleEndIndex
  );

  // Calculate spacer heights
  const topSpacerHeight = visibleStartIndex * rowHeight;
  const bottomSpacerHeight = Math.max(
    0,
    (visibleRows.length - visibleEndIndex) * rowHeight
  );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder={enumStrings.chamberMemberTableLabel.searchPlaceholder}
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }} ref={tableRef}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
              tableLayout: "fixed", // Helps maintain consistent column widths
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageChamberMemberTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={getHeadCells}
            />
            <TableBody>
              {visibleRows.length > 0 ? (
                <>
                  {/* Top spacer to maintain scroll position */}
                  {topSpacerHeight > 0 && (
                    <TableRow style={{ height: topSpacerHeight }}></TableRow>
                  )}

                  {/* Only render visible rows */}
                  {currentlyVisibleRows.map((chamberMemberInfo) => (
                    <MemoizedTableRow
                      key={chamberMemberInfo.id}
                      chamberMemberInfo={chamberMemberInfo}
                      handleClick={handleClick}
                      userIdInAuthChamberInfo={userIdInAuthChamberInfo}
                      isPartOfOldBatch={isPartOfOldBatch}
                    />
                  ))}

                  {/* Bottom spacer */}
                  {bottomSpacerHeight > 0 && (
                    <TableRow style={{ height: bottomSpacerHeight }}></TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {enumStrings.chamberList.primary}
                  </TableCell>
                </TableRow>
              )}

              {/* Footer row for total */}
              {isPartOfOldBatch && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "17px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", marginLeft: "20px" }}>
                        {enumStrings.chamberMemberTableLabel.totalApp}
                      </span>{" "}
                      {totalNumberOfMemberAppUser}
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
