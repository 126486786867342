import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useCallback } from "react";
import dayjs from "dayjs";
import { useEnumStrings } from "../../enumStrings";

const useStyles = makeStyles((theme: any) => ({
  formInput: {
    marginTop: "50px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    borderRadius: "15px",
  },
  selectedValue: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const RewardCampaignEndDatePicker = ({
  formikSetFieldValue,
  formikErrors,
  formikVal,
  disabled,
  isForStartDate,
  startAfterDate,
}: {
  formikSetFieldValue: (a: string, b: number) => void;
  formikErrors: any;
  formikVal: number;
  disabled?: boolean;
  isForStartDate?: boolean;
  startAfterDate?: number;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const handleDateChange = useCallback(
    (val) => {
      formikSetFieldValue(
        isForStartDate ? "startDateUtcMilli" : "endDateUtcMilli",
        isForStartDate
          ? dayjs(val).startOf("day").valueOf()
          : dayjs(val).valueOf()
      );
    },
    [formikSetFieldValue, isForStartDate]
  );

  return (
    <FormControl className={classes.formInput}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled={disabled}
          minDate={
            (startAfterDate || 0) > 0 ? dayjs(startAfterDate) : undefined
          }
          label={
            isForStartDate
              ? enumStrings.rewardsPage.rewardStartDate
              : enumStrings.rewardsPage.rewardEndDate
          }
          className={classes.selectedValue}
          format="ll"
          onChange={handleDateChange}
          value={formikVal > 0 ? dayjs(formikVal) : undefined}
        />
      </LocalizationProvider>
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          marginBottom: "-5px",
          textAlign: "center",
        }}
      >
        {formikErrors}
      </FormHelperText>
    </FormControl>
  );
};

export default RewardCampaignEndDatePicker;
