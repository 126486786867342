import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import Logo from "../../images/Gold_Logo.png";
import enumStrings from "../enumStrings";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%",
    background: "white",
    padding: "2rem 1rem",
    boxSizing: "border-box",
  },
  logo: {
    width: "80%",
    maxWidth: "300px",
    marginBottom: "2rem",
  },
  contentBox: {
    width: "90%",
    maxWidth: "500px",
    background: "#F8F8F8",
    borderRadius: "12px",
    padding: "1.5rem",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "#333333",
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  email: {
    color: "#E1A731", // Using the gold color that matches the app's theme
    fontSize: "1.25rem",
    fontWeight: 500,
    marginBottom: "1.5rem",
    textDecoration: "none",
  },
  text: {
    color: "#666666",
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: "1rem",
    lineHeight: "1.5",
  },
}));

const AppStoreSupport: React.FC = (): ReactElement => {
  const classes = useStyles();
  const supportEmail = enumStrings.supportContactEmail;

  return (
    <div className={classes.container}>
      <img src={Logo} alt="Chamber Perks App Logo" className={classes.logo} />
      <div className={classes.contentBox}>
        <h1 className={classes.title}>Need App Support?</h1>
        <p className={classes.text}>
          If you're experiencing any issues with the Chamber Perks App or have
          questions about how to use it, please contact our support team at:
        </p>
        <a
          href={`mailto:${supportEmail}?subject=Chamber Perks App Support Request`}
          className={classes.email}
        >
          {supportEmail}
        </a>
      </div>
    </div>
  );
};

export default AppStoreSupport;
