import React, { ReactElement, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserTypeContext } from "../../UserTypeContext";
import { UserType } from "../../graphql/types";
import { RedirectUri, useEnumStrings } from "../enumStrings";

// User can only access this if logged in so no need to check if logged in here
const CPACreatePerk = (): ReactElement => {
  const history = useHistory();
  const enumStrings = useEnumStrings();
  const {
    state: { userType },
  } = useContext(UserTypeContext);
  if (userType === UserType.ChamberMemberAdmin) {
    history.push(RedirectUri.createDiscountChamberMemberAdmin);
  } else if (userType === UserType.ChamberAdmin) {
    history.push(RedirectUri.manageAMemberChamberAdmin);
  } else {
    history.push(RedirectUri.home);
  }

  return <div>{enumStrings.plaseWait}</div>;
};

export default CPACreatePerk;
