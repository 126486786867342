import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import { useGetRootAdminStatsQuery, UserType } from "../../../graphql/types";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import Loader from "../../common/Loader/Loader";
import ViewStatsBoxes from "../../common/ViewStatsBoxes";
import { DateTime } from "luxon";
import RootAdminStatTable from "../../common/RootAdminStatTable";
import CommonLineChart from "../../common/utilities/CommonLineChart";
import CustomChamberEngageIcon from "../../../images/CustomChamberEngageIcon.png";
import CommonBarChart from "../../common/utilities/CommonBarChart";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
}));

export const getLocalTimestamp = (
  utcMilliseconds: number,
  removeHours?: boolean
) => {
  if (utcMilliseconds === 0) {
    return "";
  }

  // Create a Luxon DateTime object from UTC milliseconds
  const utcDateTime = DateTime.fromMillis(utcMilliseconds, { zone: "utc" });

  // Convert to the local time zone
  const localDateTime = utcDateTime.toLocal();

  // Format the local date and time
  const formattedDate = localDateTime.toFormat(
    removeHours ? "LLL dd, yyyy" : "LLL dd, yyyy, hh:mm a"
  );

  return formattedDate;
};

// Define interfaces for the component props and data structures
interface ChamberEngageRevenueData {
  activationFeeRevenue: number;
  featuredMemberActivationFeeRevenue: number;
  categoryAdvertisingActivationFeeRevenue: number;
  chamberRevenue: number;
  chamberFeaturedMemberRevenue: number;
  chamberCategoryAdvertisingRevenue: number;
}

interface ChamberData {
  chamberId: string;
  chamberName: string;
  activationFeeRevenueData: ChamberEngageRevenueData;
}

interface YearlyChamberEngageData {
  year: string;
  chamberData: ChamberData[];
}

interface MonthlyChamberEngageData {
  monthAndYear: string;
  chamberData: ChamberData[];
}

type PeriodChamberEngageData =
  | YearlyChamberEngageData
  | MonthlyChamberEngageData;

interface ChamberEngageAccordionProps {
  data?: PeriodChamberEngageData[] | null;
  loading: boolean;
  type: "yearly" | "monthly";
}

// Modified component to include a year selector for monthly data
const ChamberEngageAccordion = ({
  data,
  loading,
  type,
}: ChamberEngageAccordionProps): ReactElement => {
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = DateTime.now().toFormat("MMM yyyy");
  const defaultCurrentPeriod = type === "yearly" ? currentYear : currentMonth;
  const [expandedPeriods, setExpandedPeriods] = useState<Set<string>>(
    new Set()
  );
  const [selectedYear, setSelectedYear] = useState<string>(currentYear);

  // Format the period display
  const formatPeriodDisplay = useCallback(
    (period: string): string => {
      if (type === "yearly") {
        return period;
      } else {
        // Try to parse the monthAndYear format and convert to full month name
        try {
          // Check if it's already in MMM yyyy format
          if (period.includes(" ")) {
            // Parse MMM yyyy format like "Jan 2023"
            const dt = DateTime.fromFormat(period, "MMM yyyy");
            return dt.toFormat("yyyy MMMM");
          } else if (period.includes("-")) {
            // Parse yyyy-M format like "2023-3"
            const [part1, part2] = period.split("-");

            // Try to determine if it's yyyy-mm or mm-yyyy
            if (parseInt(part1) > 12) {
              // Likely yyyy-mm
              const dt = DateTime.fromObject({
                year: parseInt(part1),
                month: parseInt(part2),
              });
              if (dt.isValid) return dt.toFormat("yyyy MMMM");
            } else {
              // Likely mm-yyyy
              const dt = DateTime.fromObject({
                year: parseInt(part2),
                month: parseInt(part1),
              });
              if (dt.isValid) return dt.toFormat("yyyy MMMM");
            }
          }
        } catch (e) {
          console.error("Error formatting period:", e);
        }
        // Return original if parsing fails
        return period;
      }
    },
    [type]
  );

  // Get year from period string (for monthly data)
  const getYearFromPeriod = useCallback((period: string): string => {
    try {
      if (period.includes(" ")) {
        // Format like "Jan 2023"
        const dt = DateTime.fromFormat(period, "MMM yyyy");
        return dt.year.toString();
      } else if (period.includes("-")) {
        // Format like "2023-1" or "1-2023"
        const [part1, part2] = period.split("-");
        // Determine if it's yyyy-mm or mm-yyyy
        if (parseInt(part1) > 12) {
          // Likely yyyy-mm
          return part1;
        } else {
          // Likely mm-yyyy
          return part2;
        }
      }
    } catch (e) {
      console.error("Error extracting year from period:", e);
    }
    return "";
  }, []);

  // Find the period that matches the current date
  const findCurrentPeriod = useCallback(
    (dataArray: PeriodChamberEngageData[]): string => {
      const now = DateTime.now();

      if (type === "yearly") {
        // For yearly, find the current year
        const currentYearItem = dataArray.find(
          (item) =>
            (item as YearlyChamberEngageData).year === now.year.toString()
        );
        if (currentYearItem) {
          return (currentYearItem as YearlyChamberEngageData).year;
        }
      } else {
        // For monthly, check multiple possible formats
        const currentMonthFormats = [
          now.toFormat("MMM yyyy"), // e.g. "Jan 2023"
          `${now.year}-${now.month}`, // e.g. "2023-1"
          now.toFormat("M-yyyy"), // e.g. "1-2023"
          now.toFormat("yyyy-M"), // e.g. "2023-1"
        ];

        // Try to find any period that matches current month in any format
        for (const format of currentMonthFormats) {
          const found = dataArray.find((item) => {
            const monthYear = (item as MonthlyChamberEngageData).monthAndYear;
            return (
              monthYear === format ||
              formatPeriodDisplay(monthYear) === now.toFormat("yyyy MMMM")
            );
          });
          if (found) {
            return (found as MonthlyChamberEngageData).monthAndYear;
          }
        }
      }

      // If nothing matches current date, return the most recent period
      if (dataArray.length > 0) {
        // Sort periods with newest first
        const sortedPeriods = [...dataArray].sort((a, b) => {
          if (type === "yearly") {
            return (
              parseInt((b as YearlyChamberEngageData).year) -
              parseInt((a as YearlyChamberEngageData).year)
            );
          } else {
            // Convert period strings to dates for comparison
            try {
              const periodA = (a as MonthlyChamberEngageData).monthAndYear;
              const periodB = (b as MonthlyChamberEngageData).monthAndYear;

              // Try different parsing approaches
              let dateA: DateTime | null = null;
              let dateB: DateTime | null = null;

              if (periodA.includes(" ")) {
                dateA = DateTime.fromFormat(periodA, "MMM yyyy");
              } else if (periodA.includes("-")) {
                const [yearA, monthA] = periodA.split("-");
                if (parseInt(yearA) > 12) {
                  // Assuming it's yyyy-mm format
                  dateA = DateTime.fromObject({
                    year: parseInt(yearA),
                    month: parseInt(monthA),
                  });
                } else {
                  // Assuming it's mm-yyyy format
                  dateA = DateTime.fromObject({
                    year: parseInt(monthA),
                    month: parseInt(yearA),
                  });
                }
              }

              if (periodB.includes(" ")) {
                dateB = DateTime.fromFormat(periodB, "MMM yyyy");
              } else if (periodB.includes("-")) {
                const [yearB, monthB] = periodB.split("-");
                if (parseInt(yearB) > 12) {
                  // Assuming it's yyyy-mm format
                  dateB = DateTime.fromObject({
                    year: parseInt(yearB),
                    month: parseInt(monthB),
                  });
                } else {
                  // Assuming it's mm-yyyy format
                  dateB = DateTime.fromObject({
                    year: parseInt(monthB),
                    month: parseInt(yearB),
                  });
                }
              }

              if (dateA && dateB && dateA.isValid && dateB.isValid) {
                return dateB.valueOf() - dateA.valueOf();
              }
            } catch (e) {
              console.error("Error parsing date:", e);
            }

            // Fallback to string comparison if date parsing fails
            return String(b).localeCompare(String(a));
          }
        });

        // Return the first (most recent) period
        if (type === "yearly") {
          return (sortedPeriods[0] as YearlyChamberEngageData).year;
        } else {
          return (sortedPeriods[0] as MonthlyChamberEngageData).monthAndYear;
        }
      }

      // Fallback: if all else fails, return default current period
      return defaultCurrentPeriod;
    },
    [defaultCurrentPeriod, formatPeriodDisplay, type]
  );

  // Ensure the current month/year is expanded by default when data changes
  useEffect(() => {
    if (data && data.length > 0) {
      // Find the appropriate period to expand
      const periodToExpand = findCurrentPeriod(data);

      // Add to set of expanded periods instead of replacing
      setExpandedPeriods((prev) => {
        const newSet = new Set(prev);
        newSet.add(periodToExpand);
        return newSet;
      });

      console.log("Setting expanded period to:", periodToExpand, "type:", type);
    }
  }, [data, findCurrentPeriod, type]);

  if (!data) {
    return <Loader />;
  }

  // Extract all unique years from the data (for monthly view only)
  const availableYears =
    type === "monthly"
      ? Array.from(
          new Set(
            data
              .map((item) => {
                const monthYear = (item as MonthlyChamberEngageData)
                  .monthAndYear;
                return getYearFromPeriod(monthYear);
              })
              .filter((year) => year) // Remove empty strings
          )
        ).sort((a, b) => parseInt(b) - parseInt(a)) // Sort descending
      : [];

  // Sort periods by recency but DO NOT sort by expanded state to maintain position
  const allPeriods = [...data]
    .map((item) => {
      if (type === "yearly") {
        return (item as YearlyChamberEngageData).year;
      } else {
        return (item as MonthlyChamberEngageData).monthAndYear;
      }
    })
    .sort((a, b) => {
      // Sort by recency (assuming numeric years or parseable dates)
      if (type === "yearly") {
        return parseInt(b) - parseInt(a);
      } else {
        // Try to parse as dates
        try {
          const aDate = a.includes("-")
            ? DateTime.fromFormat(a, "yyyy-M")
            : DateTime.fromFormat(a, "MMM yyyy");
          const bDate = b.includes("-")
            ? DateTime.fromFormat(b, "yyyy-M")
            : DateTime.fromFormat(b, "MMM yyyy");

          if (aDate.isValid && bDate.isValid) {
            return bDate.valueOf() - aDate.valueOf();
          }
        } catch (e) {
          // Fallback to string comparison
        }
        return b.localeCompare(a);
      }
    });

  // Filter periods by selected year (for monthly view only)
  const filteredPeriods =
    type === "monthly"
      ? allPeriods.filter(
          (period) => getYearFromPeriod(period) === selectedYear
        )
      : allPeriods;

  // Toggle expansion function for a period
  const togglePeriod = (period: string) => {
    setExpandedPeriods((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(period)) {
        newSet.delete(period);
      } else {
        newSet.add(period);
      }
      return newSet;
    });
  };

  return (
    <div>
      {/* Year selector for monthly view */}
      {type === "monthly" && availableYears.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0px",
            marginBottom: "20px",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          {availableYears.map((year) => (
            <button
              key={year}
              onClick={() => setSelectedYear(year)}
              style={{
                padding: "5px 15px",
                cursor: "pointer",
                borderRadius: "4px",
                border:
                  selectedYear === year
                    ? "2px solid #E1A731"
                    : "1px solid #C8C8C8",
                backgroundColor: selectedYear === year ? "#f8f1e0" : "#f5f5f5",
                fontWeight: selectedYear === year ? "bold" : "normal",
                color: "black",
              }}
            >
              {year}
            </button>
          ))}
        </div>
      )}

      {/* Render accordions with filtered data */}
      {filteredPeriods.map((period) => {
        const periodData = data.find((item) => {
          if (type === "yearly") {
            return (item as YearlyChamberEngageData).year === period;
          } else {
            return (item as MonthlyChamberEngageData).monthAndYear === period;
          }
        });

        if (!periodData) return null;

        const formattedPeriod = formatPeriodDisplay(period);
        const title = `${formattedPeriod}`;
        const isExpanded = expandedPeriods.has(period);

        return (
          <div
            key={period}
            style={{
              marginBottom: "20px",
              border: "1px solid #C8C8C8",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={() => togglePeriod(period)}
              style={{
                backgroundColor: "#f5f5f5",
                padding: "10px 15px",
                cursor: "pointer",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "black",
                fontSize: "20px",
                gap: "10px",
              }}
            >
              <span>{title}</span>
              <span>{isExpanded ? "▼" : "►"}</span>
            </div>

            {isExpanded && (
              <div style={{ marginTop: "10px" }}>
                <RootAdminStatTable
                  chartSize="large"
                  headCells={[
                    {
                      id: "chamberName",
                      label: "Chamber Name",
                      sticky: true,
                    },
                    {
                      id: "featuredMemberActivationFeeRevenue",
                      label: "Featured Member CPA Revenue",
                      isCurrency: true,
                      disableRightBorder: true,
                    },
                    {
                      id: "categoryAdvertisingActivationFeeRevenue",
                      label: "Category Advertising CPA Revenue",
                      isCurrency: true,
                      disableRightBorder: true,
                    },
                    {
                      id: "activationFeeRevenue",
                      label: "Total CPA Revenue",
                      defaultSort: true,
                      sortOrder: "desc",
                      isCurrency: true,
                    },
                    {
                      id: "chamberFeaturedMemberRevenue",
                      label: "Featured Member Chamber Non-Dues Revenue",
                      isCurrency: true,
                      disableRightBorder: true,
                    },
                    {
                      id: "chamberCategoryAdvertisingRevenue",
                      label: "Category Advertising Chamber Non-Dues Revenue",
                      isCurrency: true,
                      disableRightBorder: true,
                    },
                    {
                      id: "chamberRevenue",
                      label: "Total Chamber Non-Dues Revenue",
                      isCurrency: true,
                    },
                  ]}
                  loading={loading}
                  allChartData={periodData.chamberData.map((chamber) => ({
                    chamberName: chamber.chamberName,
                    activationFeeRevenue:
                      chamber.activationFeeRevenueData.activationFeeRevenue,
                    featuredMemberActivationFeeRevenue:
                      chamber.activationFeeRevenueData
                        .featuredMemberActivationFeeRevenue,
                    categoryAdvertisingActivationFeeRevenue:
                      chamber.activationFeeRevenueData
                        .categoryAdvertisingActivationFeeRevenue,
                    chamberRevenue:
                      chamber.activationFeeRevenueData.chamberRevenue,
                    chamberFeaturedMemberRevenue:
                      chamber.activationFeeRevenueData
                        .chamberFeaturedMemberRevenue,
                    chamberCategoryAdvertisingRevenue:
                      chamber.activationFeeRevenueData
                        .chamberCategoryAdvertisingRevenue,
                  }))}
                  overflowHorizontally
                />
              </div>
            )}
          </div>
        );
      })}

      {type === "monthly" && filteredPeriods.length === 0 && (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            border: "1px solid #C8C8C8",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
          }}
        >
          No data available for {selectedYear}
        </div>
      )}
    </div>
  );
};

const RootAdmin = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const rootAdminObj =
    enumStrings.groups[UserType.RootAdmin][RedirectUri.rootAdmin];

  const { data: rootAdminStatsData, loading } = useGetRootAdminStatsQuery({
    fetchPolicy: "network-only",
  });

  const [rootAdminStatsLoading, setRootAdminStatsLoading] = useState(true);

  useEffect(() => {
    if (rootAdminStatsData?.getRootAdminStats && !loading) {
      setRootAdminStatsLoading(false);
    }
  }, [loading, rootAdminStatsData?.getRootAdminStats]);

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
      </div>

      <ViewStatsBoxes
        boxes={[
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.totalUsersSignedUp,
            boxContent: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {rootAdminStatsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {
                      rootAdminStatsData?.getRootAdminStats
                        ?.totalNumberOfAppUsers
                    }
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "5px",
                        padding: "30px",
                        paddingTop: "0",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          {enumStrings.rootChamberAdmin.withAnEmployerSelected}:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithAnEmployer
                            }{" "}
                            (
                            {Math.round(
                              ((rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithAnEmployer ||
                                0) /
                                (rootAdminStatsData?.getRootAdminStats
                                  ?.totalNumberOfAppUsers || 0)) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          {enumStrings.rootChamberAdmin.withOutSelect}:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithoutAnEmployer
                            }{" "}
                            (
                            {Math.round(
                              ((rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithoutAnEmployer ||
                                0) /
                                (rootAdminStatsData?.getRootAdminStats
                                  ?.totalNumberOfAppUsers || 0)) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                      <br />
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With New Perk Notification Enabled:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersWithNewPerkNotificationEnabled
                            }{" "}
                            (
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalPercentageOfAppUsersWithNewPerkNotificationEnabled
                            }
                            %)
                          </span>
                        </span>
                      </div>

                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With Nearby Perk Notification Enabled:{" "}
                          <span style={{ color: "#E1A731" }}>
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersWithNearbyPerkNotificationEnabled
                            }{" "}
                            (
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalPercentageOfAppUsersWithNearbyPerkNotificationEnabled
                            }
                            %)
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: "New Chamber Perks App™ User Accounts Per Month",
            boxContent: (
              <div style={{ width: "100%" }}>
                {!rootAdminStatsData?.getRootAdminStats?.timeseriesAppUsersData
                  ?.monthly ? (
                  <Loader />
                ) : (
                  <CommonLineChart
                    series={[
                      {
                        id: "appUsers",
                        name: "New App User Accounts",
                        data: rootAdminStatsData?.getRootAdminStats?.timeseriesAppUsersData?.monthly?.map(
                          (point) => ({
                            timestamp: point.monthAndYear,
                            value: point.numberOfAppUsers,
                          })
                        ),
                        color: "#E1A731", // Using your theme color
                      },
                    ]}
                    isMonthly={true}
                    showLegend={true}
                    enableSeriesSelector={false}
                    height={300}
                  />
                )}
              </div>
            ),
          },
          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.membersInCPAText,
            boxContent: (
              <div style={{ width: "100%" }}>
                {rootAdminStatsLoading ? (
                  <Loader />
                ) : (
                  rootAdminStatsData?.getRootAdminStats?.totalChambers
                )}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminText.viewStatBoxes
                          .totalMemberCompanies
                      }
                      :{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalChamberMembers
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Number of Suspended Member Companies:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalSuspendedChamberMembers
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {enumStrings.rootChamberAdmin.companiesOfferingLivePerks}:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.numberOfChamberMembersOfferingLiveDiscounts
                        }{" "}
                        (
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.percentOfChamberMembersOfferingLiveDiscounts
                        }
                        %)
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.newestChamberMembers.length
                      }{" "}
                      Newest Members
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "name",
                          label: "Member Name",
                        },
                        {
                          id: "chamberName",
                          label: enumStrings.rootChamberAdmin.chamberName,
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: enumStrings.rootChamberAdmin.creationDate,
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                      ]}
                      loading={rootAdminStatsLoading}
                      allChartData={
                        rootAdminStatsData?.getRootAdminStats
                          ?.newestChamberMembers
                      }
                      overflowHorizontally
                      chartSize="medium"
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.totalPerks,
            boxContent: (
              <div style={{ width: "100%" }}>
                {
                  rootAdminStatsData?.getRootAdminStats
                    ?.totalNumberOfLiveDiscounts
                }
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Views:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalDiscountViews
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Redemptions:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalDiscountRedemptions
                        }
                      </span>
                    </span>
                  </div>

                  <br />
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminChamberText
                          .numberOfLivePerksOfferedTo
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        {enumStrings.rootChamberAdmin.allMember}:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveAllMemberDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        {enumStrings.rootChamberAdmin.localMembersOnly}:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveLocalMemberOnlyDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        {enumStrings.rootChamberAdmin.anyone}:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveGeneralPublicDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        {enumStrings.rootChamberAdmin.memberCompanyEmployees}:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveCompanyEmployeesOnlyDiscounts
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      {
                        rootAdminStatsData?.getRootAdminStats?.newestDiscounts
                          .length
                      }{" "}
                      Newest Perks
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "title",
                          label: "Title",
                        },
                        {
                          id: "offeredTo",
                          label: enumStrings.rootChamberAdmin.offeredTo,
                        },
                        {
                          id: "chamberName",
                          label: enumStrings.rootChamberAdmin.chamberName2,
                        },
                        {
                          id: "views",
                          label: "Views",
                        },
                        {
                          id: "numberOfRedemptions",
                          label: "Redemptions",
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: enumStrings.rootChamberAdmin.createdAtUtcMilli,
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                        {
                          id: "deletedAtUtcMilli",
                          label:
                            enumStrings.rootChamberAdmin.deletionTimeUtcMilli,
                          convertUtcToTimestamp: true,
                        },
                      ]}
                      loading={rootAdminStatsLoading}
                      allChartData={
                        rootAdminStatsData?.getRootAdminStats?.newestDiscounts
                      }
                      overflowHorizontally
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            fullWidth: true,
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.chamberDetails,
            boxContent: (
              <RootAdminStatTable
                overflowHorizontally
                chartSize="large"
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead
                      .split("/")
                      .join("/ "),
                    sticky: true,
                  },
                  {
                    id: "subscriptionUTCMilli",
                    label: "Subscription Start Date",
                    utcMilliTimestampObjKey: "subscriptionDate",
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "numberOfAppUsers",
                    label: enumStrings.rootChamberAdmin.appUsersSelectedChamber,
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersSignedUpWithAnEmployer",
                    label:
                      enumStrings.rootChamberAdmin.appUsersSelectedEmployer,
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersSignedUpWithoutAnEmployer",
                    label:
                      "App users who've selected this chamber and not any chamber's employer",
                  },
                  {
                    id: "numberOfAppUsersWithNewPerkNotificationEnabled",
                    label: "App Users With New Perk Notification Enabled",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfAppUsersWithNewPerkNotificationEnabled",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfAppUsersWithNearbyPerkNotificationEnabled",
                    label: "App Users With Nearby Perk Notification Enabled",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfAppUsersWithNearbyPerkNotificationEnabled",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfLiveDiscounts",
                    label: "Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfDiscountViews",
                    label: "Live Perk Views",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfDiscountRedemptions",
                    label: "Live Perk Redemptions",
                  },
                  {
                    id: "numberOfMembersFromInitialSubscriptionAgreement",
                    label: "Initial Member Companies",
                  },
                  {
                    id: "numberOfChamberMembersLiveOnApp",
                    label: "Active Member Companies",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberChangeInNumberOfChamberMembersBetweenNowAndInitial",
                    label: "#",
                    disableRightBorder: true,
                    indicateDeltaFor:
                      "numberChangeInNumberOfChamberMembersBetweenNowAndInitial",
                  },
                  {
                    id: "percentageChangeInNumberOfChamberMembersBetweenNowAndInitial",
                    label: "%",
                    isPercent: true,
                    indicateDeltaFor:
                      "percentageChangeInNumberOfChamberMembersBetweenNowAndInitial",
                  },
                  {
                    id: "numberOfSuspendedChamberMembers",
                    label: "Suspended Member Companies",
                  },
                  {
                    id: "numberOfChamberMembersOfferingLiveDiscounts",
                    label: "Companies Offering Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfChamberMembersOfferingLiveDiscounts",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfChamberMembersLoggedInToAdminPortal",
                    label: "Companies Logged In To Dashboard",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfChamberMembersLoggedInToAdminPortal",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin",
                    label:
                      "Companies Sent Credentials Through Dashboard Auto Emailing",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin",
                    label: "%",
                    isPercent: true,
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats?.chambersSortedByNewest
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.perkPerMemberCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "categoryName",
                    label: enumStrings.rootAdminText.viewStatBoxes.categoryName,
                    splitOn: "/",
                  },
                  {
                    id: "numberOfDiscounts",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.numberOfPerks,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "percentageVersusOtherCategories",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.percentOfPerks,
                    isPercent: true,
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.discountsPerCategoryBreakdown
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.chamberPerksPerCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: rootAdminStatsData?.getRootAdminStats
              ?.discountsPerCategoryBreakdown ? (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                    sticky: true,
                  },
                  ...[
                    ...rootAdminStatsData?.getRootAdminStats?.discountsPerCategoryBreakdown.map(
                      ({ categoryName }) => ({
                        id: categoryName,
                        label: categoryName.split("/").join("/ "),
                        defaultValue: 0,
                      })
                    ),
                  ],
                ]}
                spreadDataIntoAllChartData={{
                  createObjFromId: "discountsPerCategoryBreakdown",
                  idType: "arrOfObj",
                  objKey: "categoryName",
                  objValue: "numberOfDiscounts",
                }}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats?.chambersSortedByNewest
                }
                overflowHorizontally
              />
            ) : (
              <></>
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.contactableChambersSortedByNewest
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberMemberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.viewStatBoxes.chamberName,
                  },
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.memberCompanyLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.contactableCompaniesSortedByNewest
                }
              />
            ),
          },
        ]}
      />
      <div
        style={{
          borderTop: "1px solid #C8C8C8",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 50,
          paddingTop: 15,
          paddingBottom: 0,
        }}
      >
        <img
          alt="CustomChamberEngageIcon"
          src={CustomChamberEngageIcon}
          style={{
            maxWidth: "250px",
            objectFit: "contain",
          }}
        />
      </div>
      <ViewStatsBoxes
        boxes={[
          {
            boxTitle: "Chamber Engage Total Overview",
            fullWidth: true,
            boxContent: (
              <div style={{ width: "100%" }}>
                {rootAdminStatsLoading ||
                !rootAdminStatsData?.getRootAdminStats
                  ?.timeseriesChamberEngageData?.lifetimeTotalPerChamber ? (
                  <Loader />
                ) : (
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "40px",
                        marginBottom: "20px",
                        padding: "30px",
                        paddingTop: "0",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ color: "black" }}>
                        Total Chambers Engaging:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData
                              ?.lifetimeTotalPerChamber.length
                          }
                        </span>
                      </div>
                      <br />
                      <div style={{ color: "black" }}>
                        Total CPA Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.activationFeeRevenue
                          }
                        </span>
                      </div>
                      <div style={{ color: "black" }}>
                        Total Featured Member CPA Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.featuredMemberActivationFeeRevenue
                          }
                        </span>
                      </div>
                      <div style={{ color: "black" }}>
                        Total Category Advertising CPA Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.categoryAdvertisingActivationFeeRevenue
                          }
                        </span>
                      </div>
                      <br />
                      <div style={{ color: "black" }}>
                        Total Chamber Non-Dues Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.chamberRevenue
                          }
                        </span>
                      </div>
                      <div style={{ color: "black" }}>
                        Total Featured Member Chamber Non-Dues Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.chamberFeaturedMemberRevenue
                          }
                        </span>
                      </div>
                      <div style={{ color: "black" }}>
                        Total Category Advertising Chamber Non-Dues Revenue:{" "}
                        <span
                          style={{
                            color: "#E1A731",
                          }}
                        >
                          $
                          {
                            rootAdminStatsData?.getRootAdminStats
                              ?.timeseriesChamberEngageData?.lifetimeTotal
                              ?.chamberCategoryAdvertisingRevenue
                          }
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        padding: "10px 30px",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        Per Chamber Overview
                      </span>
                      <RootAdminStatTable
                        chartSize="large"
                        headCells={[
                          {
                            id: "chamberName",
                            label: "Chamber Name",
                            sticky: true,
                          },
                          {
                            id: "featuredMemberActivationFeeRevenue",
                            label: "Featured Member CPA Revenue",
                            isCurrency: true,
                            disableRightBorder: true,
                          },
                          {
                            id: "categoryAdvertisingActivationFeeRevenue",
                            label: "Category Advertising CPA Revenue",
                            isCurrency: true,
                            disableRightBorder: true,
                          },
                          {
                            id: "activationFeeRevenue",
                            label: "Total CPA Revenue",
                            defaultSort: true,
                            sortOrder: "desc",
                            isCurrency: true,
                          },
                          {
                            id: "chamberFeaturedMemberRevenue",
                            label: "Featured Member Chamber Non-Dues Revenue",
                            isCurrency: true,
                            disableRightBorder: true,
                          },
                          {
                            id: "chamberCategoryAdvertisingRevenue",
                            label:
                              "Category Advertising Chamber Non-Dues Revenue",
                            isCurrency: true,
                            disableRightBorder: true,
                          },
                          {
                            id: "chamberRevenue",
                            label: "Total Chamber Non-Dues Revenue",
                            isCurrency: true,
                          },
                        ]}
                        loading={rootAdminStatsLoading}
                        allChartData={rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.lifetimeTotalPerChamber.map(
                          (chamber) => ({
                            chamberName: chamber.chamberName,
                            activationFeeRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .activationFeeRevenue,
                            featuredMemberActivationFeeRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .featuredMemberActivationFeeRevenue,
                            categoryAdvertisingActivationFeeRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .categoryAdvertisingActivationFeeRevenue,
                            chamberRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .chamberRevenue,
                            chamberFeaturedMemberRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .chamberFeaturedMemberRevenue,
                            chamberCategoryAdvertisingRevenue:
                              chamber.lifetimeTotalActivationFeeRevenueData
                                .chamberCategoryAdvertisingRevenue,
                          })
                        )}
                        overflowHorizontally
                      />
                    </div>
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: "Monthly Chamber Engage Overview",
            fullWidth: true,
            boxContent: (
              <div style={{ width: "100%" }}>
                {!rootAdminStatsData?.getRootAdminStats
                  ?.timeseriesChamberEngageData?.monthlyTotal ? (
                  <Loader />
                ) : (
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {new Date().toLocaleString("en-US", { month: "long" })}{" "}
                      Total CPA Revenue:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        $
                        {rootAdminStatsData?.getRootAdminStats
                          ?.timeseriesChamberEngageData?.monthlyTotal[
                          rootAdminStatsData?.getRootAdminStats
                            ?.timeseriesChamberEngageData?.monthlyTotal
                            ?.length - 1
                        ]?.activationFeeRevenueData.activationFeeRevenue || 0}
                      </span>
                    </div>
                    <CommonLineChart
                      series={[
                        {
                          id: "chamberRevenue",
                          name: "Chamber Non-Dues Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.monthlyTotal?.map(
                            (point) => ({
                              timestamp: point.monthAndYear,
                              value:
                                point.activationFeeRevenueData.chamberRevenue,
                            })
                          ),
                          color: "purple", // Using your theme color
                        },
                        {
                          id: "totalActivationFee",
                          name: "Total CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.monthlyTotal?.map(
                            (point) => ({
                              timestamp: point.monthAndYear,
                              value:
                                point.activationFeeRevenueData
                                  .activationFeeRevenue,
                            })
                          ),
                          color: "#E1A731", // Using your theme color
                        },
                        {
                          id: "featuredMemberFee",
                          name: "Featured Member CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.monthlyTotal?.map(
                            (point) => ({
                              timestamp: point.monthAndYear,
                              value:
                                point.activationFeeRevenueData
                                  .featuredMemberActivationFeeRevenue,
                            })
                          ),
                          color: "#4CAF50", // Green color for featured member
                        },
                        {
                          id: "categoryAdvertisingFee",
                          name: "Category Advertising CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.monthlyTotal?.map(
                            (point) => ({
                              timestamp: point.monthAndYear,
                              value:
                                point.activationFeeRevenueData
                                  .categoryAdvertisingActivationFeeRevenue,
                            })
                          ),
                          color: "#2196F3", // Blue color for category advertising
                        },
                      ]}
                      isMonthly={true}
                      showLegend={true}
                      enableSeriesSelector={false}
                      showAverageLineFor="totalActivationFee"
                      isCurrency={true}
                    />
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: "Monthly Chamber Engage Overview Per Chamber",
            fullWidth: true,
            boxContent: (
              <div style={{ width: "100%", marginTop: "20px" }}>
                <ChamberEngageAccordion
                  data={
                    rootAdminStatsData?.getRootAdminStats
                      ?.timeseriesChamberEngageData?.monthlyPerChamber
                  }
                  loading={rootAdminStatsLoading}
                  type="monthly"
                />
              </div>
            ),
          },
          {
            boxTitle: "Yearly Chamber Engage Overview",
            fullWidth: true,
            boxContent: (
              <div style={{ width: "100%" }}>
                {!rootAdminStatsData?.getRootAdminStats
                  ?.timeseriesChamberEngageData?.yearlyTotal ? (
                  <Loader />
                ) : (
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      {new Date().getFullYear()} Year to Date Total CPA Revenue:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        $
                        {rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.yearlyTotal?.find(
                          (point) =>
                            point.year === new Date().getFullYear().toString()
                        )?.activationFeeRevenueData.activationFeeRevenue || 0}
                      </span>
                    </div>
                    <CommonBarChart
                      series={[
                        {
                          id: "chamberRevenue",
                          name: "Chamber Non-Dues Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.yearlyTotal?.map(
                            (point) => ({
                              timestamp: point.year,
                              value:
                                point.activationFeeRevenueData.chamberRevenue,
                            })
                          ),
                          color: "purple",
                        },
                        {
                          id: "totalActivationFee",
                          name: "Total CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.yearlyTotal?.map(
                            (point) => ({
                              timestamp: point.year,
                              value:
                                point.activationFeeRevenueData
                                  .activationFeeRevenue,
                            })
                          ),
                          color: "#E1A731",
                        },
                        {
                          id: "featuredMemberFee",
                          name: "Featured Member CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.yearlyTotal?.map(
                            (point) => ({
                              timestamp: point.year,
                              value:
                                point.activationFeeRevenueData
                                  .featuredMemberActivationFeeRevenue,
                            })
                          ),
                          color: "#4CAF50",
                        },
                        {
                          id: "categoryAdvertisingFee",
                          name: "Category Advertising CPA Revenue",
                          data: rootAdminStatsData?.getRootAdminStats?.timeseriesChamberEngageData?.yearlyTotal?.map(
                            (point) => ({
                              timestamp: point.year,
                              value:
                                point.activationFeeRevenueData
                                  .categoryAdvertisingActivationFeeRevenue,
                            })
                          ),
                          color: "#2196F3",
                        },
                      ]}
                      isYearly={true}
                      showLegend={true}
                      enableSeriesSelector={false}
                      showAverageLineFor="totalActivationFee"
                      isCurrency={true}
                    />
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: "Yearly Chamber Engage Overview Per Chamber",
            fullWidth: true,
            boxContent: (
              <div style={{ width: "100%", marginTop: "20px" }}>
                <ChamberEngageAccordion
                  data={
                    rootAdminStatsData?.getRootAdminStats
                      ?.timeseriesChamberEngageData?.yearlyTotalPerChamber
                  }
                  loading={rootAdminStatsLoading}
                  type="yearly"
                />
              </div>
            ),
          },
        ]}
      />
    </span>
  );
};

export default RootAdmin;
