import React, { ReactElement, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  useGetAllCategoriesQuery,
  useGetAllDeletedDiscountsFromChamberIdQuery,
  useGetAllDiscountsFromChamberIdQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FullPageDiscountTable from "../../common/FullPageDiscountTable";
import ExportButton from "../../common/ExportButton";
import { useGetOfferedToText } from "../../common/Hooks/useGetOfferedToText";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    marginTop: "33px",
    flexDirection: "column",
  },
}));

const ChamberAdminListAllDiscounts = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const getOfferedToText = useGetOfferedToText();
  const location = useLocation();
  const bingo = useMemo(() => {
    return (location.state || { showToast: undefined }) as {
      showToast: boolean | undefined;
    };
  }, [location.state]);
  useEffect(() => {
    if (bingo.showToast) {
      toast.success(enumStrings.toastPerkText);
    }
  }, [bingo.showToast, enumStrings.toastPerkText]);

  let chamberInfo = useGetChamberFromUserIdInAuth();
  let { data: discountData, loading: discountDataLoading } =
    useGetAllDiscountsFromChamberIdQuery({
      variables: { chamberId: chamberInfo?.id || "" },
      skip: !chamberInfo?.id,
      fetchPolicy: "cache-and-network",
    });

  let { data: deletedDiscountData, loading: deletedDiscountDataLoading } =
    useGetAllDeletedDiscountsFromChamberIdQuery({
      variables: { chamberId: chamberInfo?.id || "" },
      skip: !chamberInfo?.id,
      fetchPolicy: "cache-and-network",
    });

  const { data: categoriesData } = useGetAllCategoriesQuery();

  let exportPerksCsvDiscountListData = useMemo(() => {
    const nonDeletedDiscounts = discountData?.getAllDiscountsFromChamberId?.map(
      (item) => ({
        memberName: item.chamberMemberInfo.name,
        discountName: item.title,
        offeredTo: getOfferedToText(item as any),
        discountViews: item.views || 0,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        deletionTimeUtc: "",
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberInfo.categoryId[0]
          )?.name || "",
        category2: item.chamberMemberInfo.categoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.chamberMemberInfo.categoryId[1]
            )?.name || ""
          : "",
      })
    );

    const deletedDiscounts =
      deletedDiscountData?.getAllDeletedDiscountsFromChamberId?.map((item) => ({
        memberName: item.chamberMemberInfo.name,
        discountName: item.title,
        offeredTo: getOfferedToText(item as any),
        discountViews: item.views || 0,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        deletionTimeUtc: item.deletedAtUtcMilli
          ? new Date(item.deletedAtUtcMilli).toUTCString()
          : "",
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberInfo.categoryId[0]
          )?.name || "",
        category2: item.chamberMemberInfo.categoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.chamberMemberInfo.categoryId[1]
            )?.name || ""
          : "",
      }));

    const nonDeletedAndDeletedDiscounts = [
      ...(nonDeletedDiscounts || []),
      ...(deletedDiscounts || []),
    ];

    return [...nonDeletedAndDeletedDiscounts];
  }, [
    categoriesData?.getAllCategories,
    deletedDiscountData?.getAllDeletedDiscountsFromChamberId,
    discountData?.getAllDiscountsFromChamberId,
    getOfferedToText,
  ]);

  let exportPerksCsvDiscountListLabel = [
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.memberName,
      key: "memberName",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.category1,
      key: "category1",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.category2,
      key: "category2",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.discountName,
      key: "discountName",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.offeredTo,
      key: "offeredTo",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.discountViews,
      key: "discountViews",
    },
    {
      label: enumStrings.rootAdminText.creationTimeGmt,
      key: "creationTimeUtc",
    },
    {
      label: enumStrings.exportPerksCsvDiscountListLabel.deletionTimeUtc,
      key: "deletionTimeUtc",
    },
  ];

  const allDiscountData = useMemo(() => {
    return (
      discountData?.getAllDiscountsFromChamberId
        ?.slice()
        .sort((a, b) =>
          a.chamberMemberInfo.name > b.chamberMemberInfo.name ? 1 : -1
        ) || []
    ).map((discountMapData) => ({
      ...discountMapData,
      title: discountMapData.title.trim(),
      chamberMemberInfo: {
        ...discountMapData.chamberMemberInfo,
        name: discountMapData.chamberMemberInfo.name.trim(),
      },
    }));
  }, [discountData?.getAllDiscountsFromChamberId]);

  const rootAdminObj = // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][
      RedirectUri.memberDiscountPageChamberAdmin
    ];

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
          <div style={{ paddingTop: "12px" }} />
          <ExportButton
            loading={discountDataLoading || deletedDiscountDataLoading}
            csvData={exportPerksCsvDiscountListData}
            csvHeaders={exportPerksCsvDiscountListLabel}
            label={enumStrings.ChamberAdminMangeMember.exportMemberLabel}
            disablePadding
          />
          <div style={{ paddingTop: "10px" }} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {!discountData || discountDataLoading ? (
          <Loader />
        ) : (
          <>
            {allDiscountData && allDiscountData.length > 0 ? (
              <FullPageDiscountTable
                allDiscountData={allDiscountData}
                userType={UserType.ChamberAdmin}
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                {enumStrings.discountText.discountUnavailableText}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChamberAdminListAllDiscounts;
