import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  useGetActiveShopLocalCampaignForChamberMemberQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { getLocalTimestamp } from "../Root/RootAdmin";
import useGetChamberMemberFromUserIdInAuth from "../../common/Hooks/useGetChamberMemberFromUserIdInAuth";

export type ShopLocalPageType = "createNew" | "seePrevious";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    marginTop: "33px",
    flexDirection: "column",
  },
  buttonGroupRoot: {
    paddingBottom: "0px",
    "& .MuiToggleButton-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "white",
      color: "#737373",
      fontWeight: "bold",
      fontSize: "17px",
      "&:hover": {
        boxShadow: "3px 2px 5px 0px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      },
      "&.Mui-selected": {
        backgroundColor: "#E0A731",
        color: "white",
        "&:hover": {
          boxShadow: "0px 2px 5px 3px rgba(0,0,0,0.1)",
          backgroundColor: "#E0A731",
        },
      },
    },
  },
}));

const ChamberMemberShopLocalCampaign = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const headerInfo = // @ts-ignore
    enumStrings?.groups[UserType.ChamberMemberAdmin][
      RedirectUri.chamberMemberShopLocal
    ];

  const { data: activeShopLocalCampaignForChamberMemberData } =
    useGetActiveShopLocalCampaignForChamberMemberQuery();

  const userChamberInfo = useGetChamberFromUserIdInAuth();
  const userChamberMemberInfo = useGetChamberMemberFromUserIdInAuth();

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{headerInfo.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {headerInfo.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber ===
          undefined ||
        (!activeShopLocalCampaignForChamberMemberData && !userChamberInfo) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : !activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <h2>
              {userChamberMemberInfo?.name}{" "}
              {enumStrings.shopLocal.shopLocalCampaign.isParticipating}{" "}
              {userChamberInfo?.name}.
            </h2>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                maxWidth: "860px",
              }}
            >
              <h2>
                {Date.now() <
                activeShopLocalCampaignForChamberMemberData
                  .getActiveShopLocalCampaignForChamber.startDateUtcMilli
                  ? `${enumStrings.shopLocal.shopLocalCampaign.activeCompanyParticipating} ${userChamberInfo?.name}!`
                  : `${enumStrings.shopLocal.shopLocalCampaign.upcomingCompanyParticipating} ${userChamberInfo?.name}!`}
              </h2>
              <br />
              <h2>
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.shopLocal.shopLocalCampaign.prizeDescription}
                </span>
                :{" "}
                {
                  activeShopLocalCampaignForChamberMemberData
                    .getActiveShopLocalCampaignForChamber.prizeDescription
                }
              </h2>
              <h2>
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.shopLocal.shopLocalCampaign.startDate}
                </span>
                :{" "}
                {getLocalTimestamp(
                  activeShopLocalCampaignForChamberMemberData
                    .getActiveShopLocalCampaignForChamber.startDateUtcMilli,
                  true
                )}
              </h2>
              <h2>
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.shopLocal.shopLocalCampaign.endDate}
                </span>
                :{" "}
                {getLocalTimestamp(
                  activeShopLocalCampaignForChamberMemberData
                    .getActiveShopLocalCampaignForChamber.endDateUtcMilli,
                  true
                )}
              </h2>
              <h2>
                {enumStrings.shopLocal.shopLocalCampaign.chamberPerksCode}{" "}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {
                    activeShopLocalCampaignForChamberMemberData
                      .getActiveShopLocalCampaignForChamber
                      .participatingMembers[0].participationCode
                  }
                </span>
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChamberMemberShopLocalCampaign;
