import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  InputLabel,
  makeStyles,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery,
  GetPerkPartnerDiscountQuery,
  PerkPartnerDiscountInput,
  UserType,
  useDeletePerkPartnerDiscountMutation,
  useGetPerkPartnerDiscountAdminUserQuery,
  useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery,
  useGetPerkPartnerDiscountQuery,
  useUpdatePerkPartnerDiscountMutation,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useRedirect from "../../common/Hooks/useRedirect";
import LogoPicker from "../../common/utilities/LogoPicker";
import ChooseChambers from "../../common/utilities/ChooseChamber";
import { useLocation } from "react-router-dom";
import omitDeep from "omit-deep-lodash";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import ConfirmationModal, {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../../common/ConfirmationModal/ConfirmationModal";
import PerkPartnerDiscountAdminUsers from "../../common/utilities/PerkPartnerDiscountAdminUsers";
import { UserTypeContext } from "../../../UserTypeContext";
import PerkPartnerStatsTable from "../../common/utilities/PerkPartnerStatsTable";
import BannerPicker from "../../common/BannerPicker";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import { validWebsiteRegEx } from "../../common/utilities/regexes";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  notificationDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#00000000",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      transform: "scaleX(0)",
    },
    "& .MuiFilledInput-underline:before": {
      content: "none",
    },
    "& .MuiFilledInput-underline:after": {
      content: "none",
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: "yellow",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  descriptionNotificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "288px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "288px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  descriptionSelectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "262px!important",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },

  templateSubtitle: {
    textAlign: "center",
    fontSize: "2.5vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5vw",
    },
  },

  notificationRadioGroup: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "5px",
  },
  radio: {
    color: "#E1A731!important",
  },
  notificationCreationContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    background: "#F2F2F6",
    alignContent: "center",
    textAlign: "center",
    width: "265px",
    borderRadius: "7px 7px 0px 0px",
    margin: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  individualNotificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    background: "#F2F2F6",
  },
  individualNotificationType: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationDate: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationTrashIcon: {
    fontSize: "21px",
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    height: "52px",
    width: "56px",
  },
  notificationTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  notificationContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "35px",
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  toggleSwitchWithTitle: {
    display: "flex",
    margin: "auto",
    alignItems: "baseline",
    marginTop: "35px",
  },
  dayCheckbox: {
    color: "#E1A731!important",
    alignItems: "center",
    textAlign: "center",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const RootAdminEditPerkPartner = (): ReactElement => {
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const { data: perkPartnerDiscountAdminUserData } =
    useGetPerkPartnerDiscountAdminUserQuery({
      skip: userType !== UserType.PerkPartnerDiscountAdmin,
    });

  const location = useLocation();
  const perkPartnerDiscountId = useMemo(() => {
    if (userType === UserType.PerkPartnerDiscountAdmin) {
      return perkPartnerDiscountAdminUserData?.getPerkPartnerDiscountAdminUser
        ?.perkPartnerDiscountId;
    }

    return (
      location.state as GetPerkPartnerDiscountQuery["getPerkPartnerDiscount"]
    ).id;
  }, [
    location.state,
    perkPartnerDiscountAdminUserData?.getPerkPartnerDiscountAdminUser
      ?.perkPartnerDiscountId,
    userType,
  ]);

  const { data } = useGetPerkPartnerDiscountQuery({
    variables: {
      id: perkPartnerDiscountId || "",
    },
    skip:
      !perkPartnerDiscountId || userType === UserType.PerkPartnerDiscountAdmin,
  });
  const { data: discountForPerkPartnerAdminUser } =
    useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery({
      variables: {
        id: perkPartnerDiscountId || "",
      },
      skip: !perkPartnerDiscountId || userType === UserType.RootAdmin,
    });

  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const CHARACTER_LIMIT_TITLE = 50;
  const CHARACTER_LIMIT_DESCRIPTION = 500;

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const customError =
    enumStrings.shopLocal.updateChamberShopLocalCampaign.anErrorOccurred;
  const [invalidError, setInvalidError] = useState(false);
  const [updatePerkPartnerDiscount] = useUpdatePerkPartnerDiscountMutation();
  const [updateButtonText, setUpdateButtonText] = useState("Update");
  const redirectToDiscountList = useRedirect(RedirectUri.listAllPerkPartner);

  const intitalValuesMemo = useMemo(() => {
    const dataObj =
      discountForPerkPartnerAdminUser?.getPerkPartnerDiscount ||
      data?.getPerkPartnerDiscount;
    const initialValues:
      | GetPerkPartnerDiscountQuery["getPerkPartnerDiscount"]
      | GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery["getPerkPartnerDiscount"] =
      {
        id: dataObj?.id || "",
        companyName: dataObj?.companyName || "",
        title: dataObj?.title || "",
        description: dataObj?.description || "",
        unverifiedChamberMemberCapUserRedirectLink:
          dataObj?.unverifiedChamberMemberCapUserRedirectLink || "",
        verifiedChamberMemberCapUserRedirectLink:
          dataObj?.verifiedChamberMemberCapUserRedirectLink || "",
        logo: dataObj?.logo || "",
        createdAtUtcMilli: dataObj?.createdAtUtcMilli || 0,
        updatedAtUtcMilli: dataObj?.updatedAtUtcMilli,
        ...(userType === UserType.RootAdmin && data?.getPerkPartnerDiscount
          ? {
              availableToChamberIds:
                data?.getPerkPartnerDiscount.availableToChamberIds || undefined,
            }
          : {}),
        banner: dataObj?.banner || undefined,
      };
    return omitDeep(initialValues, "__typename");
  }, [
    data?.getPerkPartnerDiscount,
    discountForPerkPartnerAdminUser?.getPerkPartnerDiscount,
    userType,
  ]) as unknown as
    | GetPerkPartnerDiscountQuery["getPerkPartnerDiscount"]
    | GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery["getPerkPartnerDiscount"];

  const validationSchema = yup.object({
    companyName: yup
      .string()
      .required(enumStrings.createPerkPartnerValidation.title),
    title: yup.string().required(enumStrings.createPerkPartnerValidation.title),
    description: yup
      .string()
      .required(enumStrings.createPerkPartnerValidation.description),
    verifiedChamberMemberCapUserRedirectLink: yup
      .string()
      .matches(
        validWebsiteRegEx,
        enumStrings.createPerkPartnerValidation
          .verifiedChamberMemberCapUserRedirectLink
      )
      .required(enumStrings.linkIsRequired),
    unverifiedChamberMemberCapUserRedirectLink: yup
      .string()
      .matches(
        validWebsiteRegEx,
        enumStrings.createPerkPartnerValidation
          .verifiedChamberMemberCapUserRedirectLink
      )
      .required(enumStrings.linkIsRequired),
    logo: yup.string().required(enumStrings.createPerkPartnerValidation.logo),
  });

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (input: PerkPartnerDiscountInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        await updatePerkPartnerDiscount({
          variables: {
            id: perkPartnerDiscountId || "",
            input: {
              id: perkPartnerDiscountId || "",
              companyName: input.companyName.trim(),
              title: input.title.trim(),
              description: input.description.trim(),
              unverifiedChamberMemberCapUserRedirectLink:
                input.unverifiedChamberMemberCapUserRedirectLink.trim(),
              verifiedChamberMemberCapUserRedirectLink:
                input.verifiedChamberMemberCapUserRedirectLink.trim(),
              logo: input.logo,
              ...(userType === UserType.RootAdmin
                ? {
                    availableToChamberIds: input.availableToChamberIds,
                  }
                : {}),
              banner: input.banner,
            },
          },
          refetchQueries: [
            "getAllPerkPartnerDiscounts",
            "getAllDeletedPerkPartnerDiscounts",
          ],
        });

        setLoader(false);
        setButtonDisabled(false);
        toast.success(enumStrings.toastPerkUpdatedText);

        if (userType === UserType.RootAdmin) {
          redirectToDiscountList();
        }
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
        setUpdateButtonText("Error");
      }
    },
  });

  const handleCreateBase64 = useCallback(
    async (e: any) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("logo", base64);
    },
    [formik]
  );
  const deleteLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("logo", undefined);
  };

  const handleChambersChange = (selectedChamberIds: string[]) => {
    formik.setFieldValue("availableToChamberIds", selectedChamberIds);
  };

  const updatePerkPartnerDiscountObj = useMemo(() => {
    if (userType === UserType.PerkPartnerDiscountAdmin) {
      return enumStrings.groups[UserType.PerkPartnerDiscountAdmin][
        RedirectUri.editperkPartnerDiscountPageChamberAdmin
      ];
    }
    return enumStrings.groups[UserType.RootAdmin][
      RedirectUri.editperkPartnerDiscountPageChamberAdmin
    ];
  }, [enumStrings.groups, userType]);

  const [deletePerkPartnerDiscount] = useDeletePerkPartnerDiscountMutation({
    variables: { id: perkPartnerDiscountId || "" },
  });

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoading(true);
    await deletePerkPartnerDiscount();
    setOpen(false);
    redirectToDiscountList();
  }, [deletePerkPartnerDiscount, redirectToDiscountList]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleClose,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const [fullPageIsLoading, setFullPageIsLoading] = useState(true);

  useEffect(() => {
    if (discountForPerkPartnerAdminUser || data) {
      setFullPageIsLoading(false);
    }
  }, [data, discountForPerkPartnerAdminUser]);

  if (fullPageIsLoading) {
    return (
      <span>
        <div className={classes.titleContent}>
          <span className={classes.title}>
            <span>{updatePerkPartnerDiscountObj?.title}</span>
            <span
              style={{
                marginLeft: "1px",
                fontWeight: "normal",
                fontSize: "16px",
              }}
            >
              {updatePerkPartnerDiscountObj?.description}
            </span>
          </span>
        </div>
        <div className={classes.content}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        </div>
      </span>
    );
  }

  return (
    <span>
      <div className={classes.titleContent}>
        <span className={classes.title}>
          <span>{updatePerkPartnerDiscountObj?.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {updatePerkPartnerDiscountObj?.description}
          </span>
        </span>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className={classes.content}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          {open && (
            <ConfirmationModal
              open={open}
              loading={loading}
              handleClose={handleClose}
              message={enumStrings.perkPartnerAdmin.deleteConfirmation}
              buttonConfig={buttonConfig}
            />
          )}
          <div className={classes.companyInputWithToolTipContainer}>
            <PerkPartnerStatsTable
              perkPartnerDiscountId={perkPartnerDiscountId || ""}
            />
          </div>
          <div className={classes.companyInputWithToolTipContainer}>
            <TextField
              type="text"
              label={enumStrings.perkPartnerAdmin.companyName}
              className={classes.companyInput}
              variant="outlined"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </div>
          <div className={classes.companyInputWithToolTipContainer}>
            <FormControl className={classes.notificationDescriptionInput}>
              <TextField
                className={classes.selectedValue}
                maxRows={5}
                label={enumStrings.perkPartnerAdmin.perkTitle}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    maxLength: CHARACTER_LIMIT_TITLE,
                  },
                }}
                value={formik.values.title}
                onChange={formik.handleChange}
                multiline
                name="title"
                id="title"
                error={formik.touched.title && Boolean(formik.errors.title)}
              />
            </FormControl>
          </div>
          <InputLabel style={{ margin: "auto" }}>
            {`${formik.values.title.length}/${CHARACTER_LIMIT_TITLE} ${enumStrings.perkPartnerAdmin.charRemaining}`}
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingLeft: "10px",
                paddingTop: "5px",
                textAlign: "center",
              }}
            >
              {formik.touched.title && formik.errors.title}
            </FormHelperText>{" "}
          </InputLabel>{" "}
          <div className={classes.companyInputWithToolTipContainer}>
            <FormControl
              className={classes.descriptionNotificationDescriptionInput}
            >
              <TextField
                className={classes.descriptionSelectedValue}
                maxRows={5}
                label={enumStrings.perkPartnerAdmin.perkDescription}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    maxLength: CHARACTER_LIMIT_DESCRIPTION,
                  },
                }}
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                id="description"
                name="description"
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
              />
            </FormControl>
          </div>
          <InputLabel style={{ margin: "auto" }}>
            {`${
              (formik.values.description || "").length
            }/${CHARACTER_LIMIT_DESCRIPTION} ${enumStrings.perkPartnerAdmin.charRemaining}`}
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingLeft: "10px",
                paddingTop: "5px",
                textAlign: "center",
              }}
            >
              {formik.touched.description && formik.errors.description}
            </FormHelperText>{" "}
          </InputLabel>{" "}
          <div className={classes.companyInputWithToolTipContainer}>
            <TextField
              type="text"
              label={enumStrings.perkPartnerAdmin.chamberRedirectLink}
              className={classes.companyInput}
              variant="outlined"
              id="verifiedChamberMemberCapUserRedirectLink"
              name="verifiedChamberMemberCapUserRedirectLink"
              value={formik.values.verifiedChamberMemberCapUserRedirectLink}
              onChange={formik.handleChange}
              error={
                formik.touched.verifiedChamberMemberCapUserRedirectLink &&
                Boolean(formik.errors.verifiedChamberMemberCapUserRedirectLink)
              }
              helperText={
                formik.touched.verifiedChamberMemberCapUserRedirectLink &&
                formik.errors.verifiedChamberMemberCapUserRedirectLink
              }
            />
          </div>
          <div className={classes.companyInputWithToolTipContainer}>
            <TextField
              type="text"
              label={enumStrings.perkPartnerAdmin.unverifiedChamberRedirectLink}
              className={classes.companyInput}
              variant="outlined"
              id="unverifiedChamberMemberCapUserRedirectLink"
              name="unverifiedChamberMemberCapUserRedirectLink"
              value={formik.values.unverifiedChamberMemberCapUserRedirectLink}
              onChange={formik.handleChange}
              error={
                formik.touched.unverifiedChamberMemberCapUserRedirectLink &&
                Boolean(
                  formik.errors.unverifiedChamberMemberCapUserRedirectLink
                )
              }
              helperText={
                formik.touched.unverifiedChamberMemberCapUserRedirectLink &&
                formik.errors.unverifiedChamberMemberCapUserRedirectLink
              }
            />
          </div>
          <LogoPicker
            label={enumStrings.perkPartnerAdmin.perkLogo}
            logo={formik.values.logo}
            deleteLogo={deleteLogo}
            base64={(e: any) => handleCreateBase64(e)}
          />
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              paddingLeft: "10px",
              paddingTop: "5px",
              textAlign: "center",
            }}
          >
            {formik.touched.logo && formik.errors.logo}
          </FormHelperText>
          {userType === UserType.RootAdmin ? (
            <>
              <div className={classes.companyInputWithToolTipContainer}>
                <ChooseChambers
                  selectedChamberIds={formik.values.availableToChamberIds}
                  handleChambersChange={handleChambersChange}
                />
              </div>
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {formik.touched.availableToChamberIds &&
                  formik.errors.availableToChamberIds}
              </FormHelperText>
              <div className={classes.companyInputWithToolTipContainer}>
                <PerkPartnerDiscountAdminUsers
                  perkPartnerDiscountId={perkPartnerDiscountId || ""}
                  perkPartnerCompanyName={
                    data?.getPerkPartnerDiscount?.companyName || ""
                  }
                />
              </div>
            </>
          ) : null}
          <div
            className={classes.companyInputWithToolTipContainer}
            style={{
              paddingLeft: "33px",
            }}
          >
            <BannerPicker
              key="banner"
              pickerType="perk"
              formikVal={formik.values.banner}
              formikSetFieldValue={(updatedBanner) =>
                formik.setFieldValue("banner", updatedBanner)
              }
              pickerLabel={enumStrings.perkPartnerAdmin.perkBanners}
            />
            <div>
              <HoverToolTip
                description={`${enumStrings.createDiscount.bannersDiscrption} ${
                  enumStrings.bannerPicker.minSizeRequirementForBanner
                    .charAt(0)
                    .toLowerCase() +
                  enumStrings.bannerPicker.minSizeRequirementForBanner.slice(1)
                } ${enumStrings.createDiscount.bannersDiscrption2}`}
                showIt
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <div>
            {invalidError ? (
              <div className={classes.invalidError}>{customError}</div>
            ) : null}
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              maxWidth: "450px",
            }}
          >
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "23px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                marginTop: userType === UserType.RootAdmin ? "59px" : "0px",
                marginBottom: userType === UserType.RootAdmin ? "39px" : "0px",
                marginRight: userType === UserType.RootAdmin ? "50px" : "0px",
                height: "65px",
              }}
              disabled={buttonDisabled || loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                updateButtonText
              )}
            </Button>
            {userType === UserType.RootAdmin ? (
              <Button
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#FF0000",
                  fontSize: "23px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "200px",
                  marginTop: "59px",
                  marginLeft: "15px",
                  marginBottom: "39px",
                  height: "65px",
                }}
                onClick={handleOpen}
              >
                {enumStrings.deleteText}
              </Button>
            ) : null}
          </div>
        </form>
      </div>
    </span>
  );
};

export default RootAdminEditPerkPartner;
