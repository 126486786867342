import { useEffect, useState } from "react";
import useGetChamberFromUserIdInAuth from "./useGetChamberFromUserIdInAuth";

const breakPoint = 1742006962000; // march 15, 2025... any new chambers created BEFORE this date will be part of the old batch

const useCheckIfChamberIsPartOfOldBatch = (): boolean | undefined => {
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const [isPartOfOldBatch, setIsPartOfOldBatch] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (chamberInfo?.isSandbox) {
      setIsPartOfOldBatch(false);
      return;
    }

    // if the chamber was created after the break point, it is part of the NEW batch
    if (
      chamberInfo?.createdAtUtcMilli &&
      chamberInfo.createdAtUtcMilli >= breakPoint
    ) {
      setIsPartOfOldBatch(false);
      return;
    }

    // if the chamber was created before the break point, it is part of the OLD batch
    if (
      chamberInfo?.createdAtUtcMilli &&
      chamberInfo.createdAtUtcMilli < breakPoint
    ) {
      setIsPartOfOldBatch(true);
      return;
    }
  }, [chamberInfo]);

  return isPartOfOldBatch;
};

export default useCheckIfChamberIsPartOfOldBatch;
