import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import useRedirect from "../../common/Hooks/useRedirect";
import LogoImage from "../../common/utilities/LogoImage";
import { useEnumStrings } from "../../enumStrings";

const useStyles = makeStyles((themes: any) => ({
  chamberContainer: {
    display: "flex",
    alignItems: "center",
    width: "471px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "341px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "471px",
    },
    cursor: "pointer",
  },
  imageContainer: {
    margin: 15,
  },
  chamberTitle: {
    width: "100%",
    marginLeft: "15px",
    marginRight: "15px",
    maxWidth: "230px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50px",
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "230px",
      fontSize: "2em",
    },
  },

  chamberList: {
    background: "#F6F6F6",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));
const ChamberCard = ({
  chamberName,
  chamberLogo,
  chamberURL,
  redirectStates = {},
  offersPerk,
}: {
  indexVal: number;
  chamberName: string;
  chamberLogo?: any;
  chamberURL: string;
  redirectStates?: { [key: string]: any };
  offersPerk?: any;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const redirectToChamber = useRedirect(chamberURL, redirectStates);

  return (
    <div className={classes.chamberContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row-reverse",
          alignItems: "center",
          width: "100%",
        }}
        onClick={redirectToChamber}
      >
        <div className={classes.chamberTitle}>
          <span>{chamberName}</span>
          {!offersPerk ? null : (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#E1A731",
              }}
            >
              {enumStrings.offersPerkText}
            </div>
          )}
        </div>
        <div className={classes.imageContainer}>
          {/* @ts-ignore */}
          <LogoImage
            logoSource={chamberLogo}
            size="large"
            squared
            chamberName={chamberName}
          />
        </div>
      </div>
    </div>
  );
};

export default ChamberCard;
