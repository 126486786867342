import React, { ReactElement } from "react";
import {
  makeStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import HoverToolTip from "./utilities/HoverToolTip";
import PrivatePerk from "./PrivatePerk";
// import enumStrings from "../enumStrings";
import { FormikErrors } from "formik";
import { DiscountInput } from "../../graphql/types";
import { DeleteButtonProps } from "./CSVButtons";
import useGetChamberFromUserIdInAuth from "./Hooks/useGetChamberFromUserIdInAuth";
import { useEnumStrings } from "../enumStrings";

const useStyles = makeStyles((theme) => ({
  notificationRadioGroup: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "5px",
    width: "100%",
  },
  radio: {
    color: "#E1A731!important",
  },
  leftBump: {
    width: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "10px",
    },
  },
}));

const DiscountOfferedToRadioGroup = ({
  formikSetFieldValue,
  formikValues,
  formikErrors,
  dataToExportAsCSV,
}: {
  formikSetFieldValue: (a: string, b: boolean | any[]) => void;
  formikValues: DiscountInput;
  formikErrors: FormikErrors<DiscountInput>;
  dataToExportAsCSV?: DeleteButtonProps["dataToExportAsCSV"];
}): ReactElement => {
  const classes = useStyles();
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const enumStrings =  useEnumStrings();

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "675px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <h1
          style={{
            marginBottom: "0px",
          }}
        >
          {enumStrings.discountText.availableToText}
        </h1>
        <HoverToolTip
          description={enumStrings.discountText.offeredToPopup}
          showIt
        />
      </div>
      <RadioGroup
        row
        aria-label="AvailableToGroup"
        name="AvailableToGroup"
        className={classes.notificationRadioGroup}
        onChange={(e) => {
          formikSetFieldValue(
            "isAvailableToAllMembers",
            e.target.value === "0" ? true : false
          );
          formikSetFieldValue(
            "memberOnly",
            e.target.value === "1" ? true : false
          );
          formikSetFieldValue(
            "isPrivate",
            e.target.value === "3" ? true : false
          );
        }}
        value={
          // this check is here cuz on discount create, we don't want a default set value... so we set memberOnly to undefined making the radio group have nothing selected as default
          formikValues.memberOnly === undefined
            ? null
            : // make sure the check for "formikValues.isAvailableToAllMembers" remains at top cuz both "memberOnly" and "isAvailableToAllMembers" are both true when "isAvailableToAllMembers" is true
              formikValues.isAvailableToAllMembers
              ? 0
              : formikValues.isPrivate
                ? 3
                : formikValues.memberOnly
                  ? 1
                  : 2
        }
      >
        {formikValues.memberOnly === undefined ? (
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              paddingTop: "0px",
              textAlign: "center",
            }}
          >
            {formikErrors.memberOnly}
          </FormHelperText>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "30px",
            marginRight: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div className={classes.leftBump} />
              <FormControlLabel
                value={2}
                labelPlacement="end"
                control={<Radio className={classes.radio} />}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {enumStrings.discountText.radioGroupText.generalPublic}
                    <div style={{ width: "10px" }} />
                    <HoverToolTip
                      disablePadding
                      description={enumStrings.discountText.generalPublicPopup}
                      showIt
                    />
                  </div>
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flex: 1,
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio className={classes.radio} />}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {enumStrings.discountText.radioGroupText.allChamberMembers}
                    <div style={{ width: "10px" }} />
                    <HoverToolTip
                      disablePadding
                      description={
                        enumStrings.discountText.allChamberMembersPopup
                      }
                      showIt
                    />
                  </div>
                }
                labelPlacement="end"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div className={classes.leftBump} />
              <FormControlLabel
                value={1}
                control={<Radio className={classes.radio} />}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {
                      enumStrings.discountText.radioGroupText
                        .localChamberMembers
                    }
                    <div style={{ width: "10px" }} />
                    <HoverToolTip
                      overrideWeirdStyling
                      disablePadding
                      description={enumStrings.discountText.localChamberMembersPopup(
                        chamberInfo?.name || ""
                      )}
                      showIt
                    />
                  </div>
                }
                labelPlacement="end"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flex: 1,
              }}
            >
              <FormControlLabel
                value={3}
                control={<Radio className={classes.radio} />}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {enumStrings.discountText.radioGroupText.private}
                    <div style={{ width: "10px" }} />
                    <HoverToolTip
                      overrideWeirdStyling
                      disablePadding
                      description={enumStrings.discountText.privatePopup}
                      showIt
                    />
                  </div>
                }
                labelPlacement="end"
              />
            </div>
          </div>
        </div>
      </RadioGroup>
      <PrivatePerk
        show={formikValues.isPrivate}
        formikSetFieldValue={formikSetFieldValue}
        formikValuesPrivateEmails={formikValues.privateEmails}
        dataToExportAsCSV={dataToExportAsCSV}
        key="privatePerk"
      />
    </div>
  );
};

export default DiscountOfferedToRadioGroup;
