import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  GetAllChamberMembersQuery,
  ShopLocalCampaignPrizeDrawParticipantsReturn,
  ShopLocalCampaignReturn,
  ShopLocalCampaignStatusEnum,
} from "../../../graphql/types";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { Button, Chip, makeStyles, Modal, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import theme from "../../../themes";
import { EndCampaignModal } from "./updateChamberShopLocalCampaign";
import { CSVLink } from "react-csv";

type OmittedRedeemSettingsShopLocalCampaign = ShopLocalCampaignReturn;
type AllShopLocalCampaignDataType =
  | ShopLocalCampaignReturn[]
  | undefined
  | null;
type TableShopLocalCampaignDataType = OmittedRedeemSettingsShopLocalCampaign;
type AllTableShopLocalCampaignDataType = TableShopLocalCampaignDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableShopLocalCampaignDataType,
  comparator: (a: any, b: any) => number
): AllTableShopLocalCampaignDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
  preventTextWrap?: boolean;
}

interface FullPageShopLocalCampaignTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  sortedOrders: { ascending: string; descending: string };
}

function FullPageShopLocalCampaignTableHead(
  props: FullPageShopLocalCampaignTableProps
) {
  const { order, orderBy, onRequestSort, headCells, sortedOrders } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              whiteSpace: headCell.preventTextWrap ? "nowrap" : undefined,
            }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc"
                    ? sortedOrders.descending
                    : sortedOrders.ascending}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(() => ({
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));

// Reusable table component for modal displays
interface ReusableTableProps<T> {
  data: T[];
  columns: {
    id: string;
    label: string;
    renderer: (item: T) => React.ReactNode;
    align?: "left" | "right" | "center";
  }[];
}

function ReusableTable<T>({ data, columns }: ReusableTableProps<T>) {
  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <Paper
        style={{
          borderRadius: "8px",
          boxShadow: "0 1px 2px rgba(0,0,0,0.08)",
          overflow: "auto",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "16px 24px",
                    borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
                    textAlign: column.align || "left",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: "#ffffff",
                  borderBottom: "1px solid rgba(224, 224, 224, 0.3)",
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      fontSize: "16px",
                      padding: "16px 24px",
                      color: "rgba(0, 0, 0, 0.87)",
                      borderBottom: "1px solid rgba(168, 168, 168, 0.3)",
                      textAlign: column.align || "left",
                    }}
                  >
                    {column.renderer(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default function FullPageShopLocalCampaignsTable({
  allShopLocalCampaignData,
  allChamberMemberData,
  mode,
}: {
  allChamberMemberData: GetAllChamberMembersQuery | undefined;
  allShopLocalCampaignData: AllShopLocalCampaignDataType;
  mode: "active" | "inactive";
}) {
  const classes = useStyles();
  const history = useHistory();
  const enumStrings = useEnumStrings();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setOrder("desc");
    setOrderBy("startDateUtcMilli");
  }, [allShopLocalCampaignData]);

  const allTableShopLocalCampaignData: AllTableShopLocalCampaignDataType =
    React.useMemo(() => {
      if (!allShopLocalCampaignData) {
        return [];
      }

      const allShopLocalCampaignsArr = (allShopLocalCampaignData || []).map(
        (shopLocalCampaignData) => ({
          ...shopLocalCampaignData,
        })
      );

      if (mode === "active") {
        return allShopLocalCampaignsArr.filter(
          (campaign) => campaign.status === ShopLocalCampaignStatusEnum.Active
        );
      }

      return allShopLocalCampaignsArr.filter(
        (campaign) => campaign.status === ShopLocalCampaignStatusEnum.Inactive
      );
    }, [allShopLocalCampaignData, mode]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(allTableShopLocalCampaignData, getComparator(order, orderBy)),
    [allTableShopLocalCampaignData, order, orderBy]
  );

  const [memberModalOpenedForCampaignsId, setMemberModalOpenedForCampaignsId] =
    React.useState<string>("");

  const [memberModalOpenedIsActive, setMemberModalOpenedIsActive] =
    React.useState<boolean>(false);

  const [participatingMembersModalOpen, setParticipatingMembersModalOpen] =
    React.useState<boolean>(false);

  const [participantModalOpen, setParticipantModalOpen] =
    React.useState<boolean>(false);

  const [randomWinnerModalOpen, setRandomWinnerModalOpen] =
    React.useState<boolean>(false);
  const [randomWinnerEmail, setRandomWinnerEmail] = React.useState<string>("");

  // Add state for sorted members
  const [sortedMembersData, setSortedMembersData] = React.useState<
    Array<{
      memberName: string;
      numberOfParticipants: number;
      participationCode: string;
    }>
  >([]);

  // Helper function to get participant emails for a member
  const getParticipantEmailsForMember = React.useCallback(
    (memberParticipationCode: string): string[] => {
      if (!memberParticipationCode || !memberModalOpenedForCampaignsId) {
        return [];
      }

      const participantEmails: string[] = [];

      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign?.id === memberModalOpenedForCampaignsId) {
          const participatingMembers = campaign.participatingMembers || [];
          const prizeDrawParticipants = campaign.prizeDrawParticipants || [];

          const memberId =
            participatingMembers.find(
              (m) => m?.participationCode === memberParticipationCode
            )?.memberId || "";

          if (!memberId) {
            continue;
          }

          for (const participant of prizeDrawParticipants) {
            const redeemedIds = participant?.redeemedAtMemberIds || [];
            // Check if this participant redeemed at this member
            if (redeemedIds.includes(memberId)) {
              if (participant?.userEmail) {
                participantEmails.push(participant.userEmail);
              }
            }
          }
          break;
        }
      }

      return participantEmails;
    },
    [allShopLocalCampaignData, memberModalOpenedForCampaignsId]
  );

  const participatingMemberCompaniesTable = React.useMemo(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      // create object for member id to number of participants
      const participatingMembersMap: Record<string, number> = {};
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          for (const participantObj of campaign.prizeDrawParticipants || []) {
            for (const memberId of participantObj.redeemedAtMemberIds || []) {
              if (participatingMembersMap[memberId]) {
                participatingMembersMap[memberId] += 1;
              } else {
                participatingMembersMap[memberId] = 1;
              }
            }
          }
          break;
        }
      }

      const listOfParticipatingMembers: {
        memberName: string;
        numberOfParticipants: number;
        participationCode: string;
      }[] = [];

      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          for (const participatingMemebrObj of campaign.participatingMembers) {
            for (const realMember of allChamberMemberData?.getAllChamberMembers ||
              []) {
              if (participatingMemebrObj.memberId === realMember.id) {
                listOfParticipatingMembers.push({
                  memberName: realMember.name,
                  numberOfParticipants:
                    participatingMembersMap[realMember.id] || 0,
                  participationCode: participatingMemebrObj.participationCode,
                });
                continue;
              }
            }
          }
          break;
        }
      }

      // 1. Filter and sort members with numberOfParticipants > 0 alphabetically
      const positiveParticipants = listOfParticipatingMembers
        .filter((member) => member.numberOfParticipants > 0)
        .sort((a, b) => a.memberName.localeCompare(b.memberName));

      // 2. Filter and sort members with numberOfParticipants === 0 alphabetically
      const zeroParticipants = listOfParticipatingMembers
        .filter((member) => member.numberOfParticipants === 0)
        .sort((a, b) => a.memberName.localeCompare(b.memberName));

      // 3. Join the two lists
      const sortedMembers = [...positiveParticipants, ...zeroParticipants];

      // Store sortedMembers in state for CSV export
      setSortedMembersData(sortedMembers);

      if (sortedMembers.length === 0) {
        return <div>{enumStrings.fullPageShopLocal.noneSoFar}</div>;
      }

      return (
        <ReusableTable
          data={sortedMembers}
          columns={[
            {
              id: "memberName",
              label:
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .memberCompanyName,
              renderer: (member) => member.memberName,
            },
            {
              id: "participationCode",
              label:
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .participatingCode,
              renderer: (member) => member.participationCode,
            },
            {
              id: "numberOfParticipants",
              label:
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .usersInPrizeDraw,
              renderer: (member) => {
                // If there are no participants, just show 0
                if (member.numberOfParticipants === 0) {
                  return 0;
                }

                // Get participant emails and render as a bullet list
                const participantEmails = getParticipantEmailsForMember(
                  member.participationCode
                );

                // Return a bullet point list of emails
                return (
                  <ul
                    style={{
                      margin: 0,
                      paddingLeft: "20px",
                      textAlign: "left",
                    }}
                  >
                    <li key="total">
                      <strong>Total {participantEmails.length} users</strong>
                    </li>
                    {participantEmails.map((email) => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                );
              },
            },
          ]}
        />
      );
    }
    return null;
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    enumStrings.fullPageShopLocal.noneSoFar,
    enumStrings.shopLocal.fullPageShopLocalCampaign.memberCompanyName,
    enumStrings.shopLocal.fullPageShopLocalCampaign.participatingCode,
    enumStrings.shopLocal.fullPageShopLocalCampaign.usersInPrizeDraw,
    memberModalOpenedForCampaignsId,
    getParticipantEmailsForMember,
  ]);

  // Add a new useCallback for sorting prize draw participants
  const sortPrizeDrawParticipantsByRedemptions = React.useCallback(
    (participants: ShopLocalCampaignPrizeDrawParticipantsReturn[]) => {
      if (participants.length === 0) return participants;

      return [...participants].sort(
        (a, b) => b.redeemedAtMemberIds.length - a.redeemedAtMemberIds.length
      );
    },
    []
  );

  const cpaUsersParticipatingInDrawList = React.useMemo(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      let prizeDrawParticipantArr: ShopLocalCampaignPrizeDrawParticipantsReturn[] =
        [];
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          prizeDrawParticipantArr = campaign.prizeDrawParticipants || [];
          break;
        }
      }
      if (prizeDrawParticipantArr.length > 0) {
        // Sort participants using the callback
        prizeDrawParticipantArr = sortPrizeDrawParticipantsByRedemptions(
          prizeDrawParticipantArr
        );
      }

      let memberIdsToPullNamesFor: string[] = [];

      for (const participantObj of prizeDrawParticipantArr) {
        for (const memberId of participantObj.redeemedAtMemberIds || []) {
          memberIdsToPullNamesFor.push(memberId);
        }
      }

      // @ts-ignore
      memberIdsToPullNamesFor = [...new Set(memberIdsToPullNamesFor)];

      const memberIdToName: {
        [key: string]: string;
      } = {};
      for (const id of memberIdsToPullNamesFor) {
        for (const member of allChamberMemberData?.getAllChamberMembers || []) {
          if (member.id === id) {
            memberIdToName[id] = member.name;
          }
        }
      }

      if (prizeDrawParticipantArr.length === 0) {
        return <div>{enumStrings.fullPageShopLocal.noneSoFar}</div>;
      }

      return (
        <ReusableTable
          data={prizeDrawParticipantArr}
          columns={[
            {
              id: "userEmail",
              label: enumStrings.fullPageShopLocal.EntrantEmail,
              renderer: (participant) => participant.userEmail,
            },
            {
              id: "redeemedAtMemberIds",
              label: enumStrings.fullPageShopLocal.enteredPize,
              renderer: (participant) => (
                <ul style={{ margin: 0, paddingLeft: "20px" }}>
                  {participant.redeemedAtMemberIds.map((memberId) => (
                    <li key={memberId}>{memberIdToName[memberId]}</li>
                  ))}
                </ul>
              ),
            },
          ]}
        />
      );
    }

    return <div>{enumStrings.fullPageShopLocal.noneSoFar}</div>;
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    enumStrings.fullPageShopLocal.EntrantEmail,
    enumStrings.fullPageShopLocal.enteredPize,
    enumStrings.fullPageShopLocal.noneSoFar,
    memberModalOpenedForCampaignsId,
    sortPrizeDrawParticipantsByRedemptions,
  ]);

  const setRandomWinnerEmailFunc = React.useCallback(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      let prizeDrawParticipantArr: ShopLocalCampaignPrizeDrawParticipantsReturn[] =
        [];
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          prizeDrawParticipantArr = campaign.prizeDrawParticipants || [];
          break;
        }
      }

      if (prizeDrawParticipantArr.length > 0) {
        // create weighted array to favour entrants with higher number of member perks redeemed
        const weightedArray: {
          userEmail: string;
          redeemedAtMemberIds: string[];
        }[] = [];

        for (const entry of prizeDrawParticipantArr) {
          for (let i = 0; i < (entry.redeemedAtMemberIds || []).length; i++) {
            weightedArray.push({
              userEmail: entry.userEmail,
              redeemedAtMemberIds: entry.redeemedAtMemberIds,
            });
          }
        }

        // set random winner emial
        const randomWinner =
          weightedArray[Math.floor(Math.random() * weightedArray.length)];
        setRandomWinnerEmail(
          `${randomWinner.userEmail}\n\n(${enumStrings.fullPageShopLocal.description2} ${randomWinner.redeemedAtMemberIds.length} ${enumStrings.fullPageShopLocal.description3})`
        );
      } else {
        setRandomWinnerEmail("");
      }
    }
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    enumStrings.fullPageShopLocal.description2,
    enumStrings.fullPageShopLocal.description3,
    memberModalOpenedForCampaignsId,
  ]);

  const [endCampaignModalOpen, setEndCampaignModalOpen] = React.useState(false);
  const [endModalCampaignId, setEndModalCampaignId] = React.useState("");

  if ((allTableShopLocalCampaignData || []).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {mode === "active"
            ? enumStrings.shopLocal.fullPageShopLocalCampaign.noActiveContests
            : enumStrings.shopLocal.fullPageShopLocalCampaign
                .noPreviousContests}
        </Typography>
      </div>
    );
  }

  const headCells: HeadCell[] = [
    {
      id: "campaignName",
      label: enumStrings.fullPageShopLocal.contestName,
    },
    {
      id: "prizeDescription",
      label: enumStrings.fullPageShopLocal.prizeDescription,
    },
    {
      id: "startDateUtcMilli",
      label: enumStrings.fullPageShopLocal.startDate,
      preventTextWrap: true,
    },
    {
      id: "endDateUtcMilli",
      label: enumStrings.fullPageShopLocal.endDate,
      preventTextWrap: true,
    },
    {
      id: "participatingMembers",
      label: enumStrings.fullPageShopLocal.participatingMembers,
    },
    {
      id: "prizeDrawParticipants",
      label: enumStrings.fullPageShopLocal.prizeDrawParticipants,
    },
    {
      id: "status",
      label: enumStrings.fullPageShopLocal.status,
    },
    {
      id: "updateActiveCampaign",
      label: "",
    },
  ];

  const sortedOrders = {
    ascending: enumStrings.shopLocal.fullPageShopLocalCampaign.sortedAscending,
    descending:
      enumStrings.shopLocal.fullPageShopLocalCampaign.sortedDescending,
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {endCampaignModalOpen && (
        <EndCampaignModal
          campaignId={endModalCampaignId}
          closeModal={() => setEndCampaignModalOpen(false)}
        />
      )}
      {participatingMembersModalOpen && (
        <Modal className={classes.modal} open={participatingMembersModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "800px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              {
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .participatingMemberCompanies
              }
            </h1>
            <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>
              {enumStrings.fullPageShopLocal.description}
            </h3>
            <div
              style={{
                padding: "20px 50px 0",
                textAlign: "left",
                fontSize: "16px",
                height: "calc(100% - 180px)",
                overflow: "hidden",
              }}
            >
              {participatingMemberCompaniesTable}
            </div>
            {sortedMembersData.length > 0 && (
              <>
                <CSVLink
                  data={[
                    [
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .memberCompanyName,
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .participatingCode,
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .usersInPrizeDraw,
                    ],
                    ...(sortedMembersData || []).map((member) => {
                      if (!member) return ["", "", ""];

                      const participantEmails = getParticipantEmailsForMember(
                        member.participationCode || ""
                      );

                      return [
                        member.memberName || "",
                        member.participationCode || "",
                        participantEmails.length > 0
                          ? `• Total ${
                              participantEmails.length
                            } users\n${participantEmails
                              .map((email) => `• ${email || ""}`)
                              .join("\n")}`
                          : member.numberOfParticipants || 0,
                      ];
                    }),
                  ]}
                  filename={`Shop Local Campaign Participating Member Companies.csv`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    className={classes.modalButton}
                    style={{
                      backgroundColor: "#37474f",
                      color: "#e9e9e9",
                      marginBottom: "-5px",
                    }}
                    variant="contained"
                  >
                    {
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .downloadCsv
                    }
                  </Button>
                </CSVLink>
              </>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {memberModalOpenedIsActive && (
                <Button
                  className={classes.modalButton}
                  onClick={() =>
                    history.push(RedirectUri.updateShopLocal, {
                      shopLocalCampaignId: memberModalOpenedForCampaignsId,
                    })
                  }
                  variant="contained"
                >
                  {enumStrings.fullPageShopLocal.updateMemberList}
                </Button>
              )}
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setParticipatingMembersModalOpen(false)}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {participantModalOpen && (
        <Modal className={classes.modal} open={participantModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "800px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              {
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .chamberPerksAppUsersTitle
              }
              <br />
              {
                enumStrings.shopLocal.fullPageShopLocalCampaign
                  .participatingInPrizeDraw
              }
            </h1>
            <div
              style={{
                padding: "20px 50px 0",
                textAlign: "left",
                fontSize: "16px",
                height: "calc(100% - 180px)",
                overflow: "hidden",
              }}
            >
              {cpaUsersParticipatingInDrawList}
            </div>
            {memberModalOpenedForCampaignsId &&
              Boolean(
                allShopLocalCampaignData?.find(
                  (campaign) => campaign.id === memberModalOpenedForCampaignsId
                )?.prizeDrawParticipants?.length
              ) && (
                <CSVLink
                  data={[
                    [
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .entrantEmail,
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .enteredPrizeDrawByRedeeming,
                    ],
                    ...(sortPrizeDrawParticipantsByRedemptions(
                      (allShopLocalCampaignData || []).find(
                        (campaign) =>
                          campaign.id === memberModalOpenedForCampaignsId
                      )?.prizeDrawParticipants || []
                    ).map((participant) => {
                      // Convert member IDs to names with bullet points
                      const memberNames = participant.redeemedAtMemberIds
                        .map((id) => {
                          const member =
                            allChamberMemberData?.getAllChamberMembers?.find(
                              (m) => m.id === id
                            );
                          return member
                            ? `• ${member.name}`
                            : `• Unknown Member (${id})`;
                        })
                        .join("\n");

                      return [participant.userEmail, memberNames];
                    }) || []),
                  ]}
                  filename={`Prize Draw Participants.csv`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    className={classes.modalButton}
                    style={{
                      backgroundColor: "#37474f",
                      color: "#e9e9e9",
                      marginBottom: "-5px",
                    }}
                    variant="contained"
                  >
                    {
                      enumStrings.shopLocal.fullPageShopLocalCampaign
                        .downloadCsv
                    }
                  </Button>
                </CSVLink>
              )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  setRandomWinnerEmailFunc();
                  setRandomWinnerModalOpen(true);
                }}
                variant="contained"
              >
                {
                  enumStrings.shopLocal.fullPageShopLocalCampaign
                    .pickRandomWinner
                }
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setParticipantModalOpen(false)}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {randomWinnerModalOpen && (
        <Modal className={classes.modal} open={randomWinnerModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "300px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              {enumStrings.fullPageShopLocal.randomWinnerIs}
            </h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "center",
                fontSize: "16px",
                height: "100%",
                overflowY: "scroll",
                whiteSpace: "pre-wrap",
              }}
            >
              {randomWinnerEmail}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setRandomWinnerModalOpen(false)}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageShopLocalCampaignTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              sortedOrders={sortedOrders}
            />
            <TableBody>
              {visibleRows.map((shopLocalCampaignInfo) => {
                return (
                  <TableRow
                    hover
                    style={{
                      cursor: "unset",
                    }}
                    onClick={undefined}
                    role="checkbox"
                    tabIndex={-1}
                    key={shopLocalCampaignInfo.id}
                  >
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: mode === "active" ? "pointer" : "default",
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        if (mode === "active") {
                          history.push(RedirectUri.updateShopLocal, {
                            shopLocalCampaignId: shopLocalCampaignInfo.id,
                          });
                        }
                      }}
                    >
                      {shopLocalCampaignInfo.campaignName}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: mode === "active" ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (mode === "active") {
                          history.push(RedirectUri.updateShopLocal, {
                            shopLocalCampaignId: shopLocalCampaignInfo.id,
                          });
                        }
                      }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.prizeDescription}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: mode === "active" ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (mode === "active") {
                          history.push(RedirectUri.updateShopLocal, {
                            shopLocalCampaignId: shopLocalCampaignInfo.id,
                          });
                        }
                      }}
                      component="th"
                      scope="row"
                    >
                      {getLocalTimestamp(
                        shopLocalCampaignInfo.startDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: mode === "active" ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (mode === "active") {
                          history.push(RedirectUri.updateShopLocal, {
                            shopLocalCampaignId: shopLocalCampaignInfo.id,
                          });
                        }
                      }}
                      component="th"
                      scope="row"
                    >
                      {getLocalTimestamp(
                        shopLocalCampaignInfo.endDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.participatingMembers.length}
                      <br />
                      <br />
                      {shopLocalCampaignInfo.participatingMembers.length >
                        0 && (
                        <Button
                          className={classes.modalButton}
                          style={{
                            backgroundColor: "#37474f",
                            color: "#e9e9e9",
                            width: "100px",
                            height: "30px",
                            fontSize: "11.5px",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            setParticipatingMembersModalOpen(true);
                            setMemberModalOpenedIsActive(
                              shopLocalCampaignInfo.status ===
                                ShopLocalCampaignStatusEnum.Active
                            );
                            setMemberModalOpenedForCampaignsId(
                              shopLocalCampaignInfo.id
                            );
                          }}
                          variant="contained"
                        >
                          {
                            enumStrings.shopLocal.fullPageShopLocalCampaign
                              .openList
                          }
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {
                        (shopLocalCampaignInfo?.prizeDrawParticipants || [])
                          .length
                      }
                      <br />
                      <br />
                      {(shopLocalCampaignInfo?.prizeDrawParticipants || [])
                        .length > 0 && (
                        <Button
                          className={classes.modalButton}
                          style={{
                            backgroundColor: "#37474f",
                            color: "#e9e9e9",
                            width: "120px",
                            height: "50px",
                            fontSize: "11.5px",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            setMemberModalOpenedForCampaignsId(
                              shopLocalCampaignInfo.id
                            );
                            setParticipantModalOpen(true);
                          }}
                          variant="contained"
                        >
                          {
                            enumStrings.shopLocal.fullPageShopLocalCampaign
                              .openListAndPickWinner
                          }
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.status ===
                      ShopLocalCampaignStatusEnum.Active ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            label={
                              Date.now() <
                              shopLocalCampaignInfo.startDateUtcMilli
                                ? enumStrings.shopLocal
                                    .fullPageShopLocalCampaign.upcoming
                                : enumStrings.shopLocal
                                    .fullPageShopLocalCampaign.active
                            }
                            variant="outlined"
                            size="medium"
                            style={{
                              color:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",

                              backgroundColor:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#FEF9E8"
                                  : "#DCEEDC",
                              borderColor:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",
                            }}
                          />
                          <Button
                            className={classes.modalButton}
                            style={{
                              backgroundColor: "#37474f",
                              color: "#e9e9e9",
                              width: "70px",
                              height: "30px",
                              fontSize: "11.5px",
                              margin: 0,
                              padding: 0,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              setEndCampaignModalOpen(() => {
                                setEndModalCampaignId(shopLocalCampaignInfo.id);
                                return true;
                              });
                            }}
                            variant="contained"
                          >
                            {
                              enumStrings.shopLocal.fullPageShopLocalCampaign
                                .end
                            }
                          </Button>
                        </div>
                      ) : (
                        <Chip
                          label={
                            enumStrings.shopLocal.fullPageShopLocalCampaign
                              .ended
                          }
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#727272",
                            backgroundColor: "#E3E4E6",
                            borderColor: "#727272",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.status ===
                      ShopLocalCampaignStatusEnum.Active ? (
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#E1A731",
                            fontSize: "15px",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            width: "90px",
                            height: "40px",
                          }}
                          onClick={() => {
                            history.push(RedirectUri.updateShopLocal, {
                              shopLocalCampaignId: shopLocalCampaignInfo.id,
                            });
                          }}
                        >
                          {enumStrings.shopLocal.fullPageShopLocalCampaign.edit}
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
