import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  Modal,
  Radio,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  CategoryAdvertisingBannersFeeOptionsInput,
  CategoryAdvertisingBannersFeeOptionsReturn,
  CategoryAdvertisingBannerActivationInfoReturn,
  ChamberMemberCategory,
  ChamberMemberReturn,
  GetAllChamberMembersQuery,
  UserType,
  useGetAllChamberMembersQuery,
  useActivateBannerForCategoryAdvertisingBannerMutation,
  useReactivateBannerForCategoryAdvertisingBannerMutation,
  GetAllCategoryAdvertisingBannersForChamberDocument,
  useGetAllCategoryAdvertisingBannersForChamberQuery,
  GetAllCategoryAdvertisingBannersForChamberQuery,
  GetUser_RootAdminChamberAdminQuery,
  BannerInput,
  useUpdateBannerContentForCategoryAdvertisingBannerMutation,
  useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LogoImage from "../../common/utilities/LogoImage";
import { useGetCategoryIdToHumanReadableName } from "../../common/Hooks/useGetCategoryIdToHumanReadableName";
import ArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import RESTAURANT from "../../../images/RESTAURANT.png";
import RETAIL from "../../../images/RETAIL.png";
import PERSONAL_SERVICES from "../../../images/PERSONAL_SERVICES.png";
import COMMUNICATION_TECHNOLOGY from "../../../images/COMMUNICATION_TECHNOLOGY.png";
import CONSTRUCTION_SUPPLIERS from "../../../images/CONSTRUCTION_SUPPLIERS.png";
import FINANCIAL from "../../../images/FINANCIAL.png";
import ACCOMMODATIONS_ATTRACTIONS from "../../../images/ACCOMMODATIONS_ATTRACTIONS.png";
import MARKETING_ADVERTISING from "../../../images/MARKETING_ADVERTISING.png";
import INDUSTRIES from "../../../images/INDUSTRIES.png";
import AUTOMOTIVE_TRANSPORTATION from "../../../images/AUTOMOTIVE_TRANSPORTATION.png";
import PROFESSIONAL_SERVICES from "../../../images/PROFESSIONAL_SERVICES.png";
import HEALTH_SAFETY from "../../../images/HEALTH_SAFETY.png";
import SPORTS_RECREATION from "../../../images/SPORTS_RECREATION.png";
import GOVERNMENT_EDUCATION from "../../../images/GOVERNMENT_EDUCATION.png";
import NON_PROFIT from "../../../images/NON_PROFIT.png";
import REAL_ESTATE from "../../../images/REAL_ESTATE.png";
import FARMING_AGRICULTURE from "../../../images/FARMING_AGRICULTURE.png";
import CONSTRUCTION_INDUSTRIAL from "../../../images/CONSTRUCTION_INDUSTRIAL.png";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import ChamberMemberSelector from "../ChamberMemberAdmin/ChamberMemberSelector";
import omitDeep from "omit-deep-lodash";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { useHistory, useLocation } from "react-router-dom";
import { UserTypeContext } from "../../../UserTypeContext";
import ExportButton from "../../common/ExportButton";
import BannerPicker from "../../common/BannerPicker";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import cloneDeep from "lodash.clonedeep";
import VideoTutorialButton from "../../common/VideoTutorialButton";
import AutoApproveBannerContentToggle from "../../common/utilities/AutoApproveBannerContentToggle";

const categoryIdToLogo = {
  [ChamberMemberCategory.Restaurant]: RESTAURANT,
  [ChamberMemberCategory.Retail]: RETAIL,
  [ChamberMemberCategory.PersonalServices]: PERSONAL_SERVICES,
  [ChamberMemberCategory.CommunicationTechnology]: COMMUNICATION_TECHNOLOGY,
  [ChamberMemberCategory.ConstructionSuppliers]: CONSTRUCTION_SUPPLIERS, // delete when category migration done
  [ChamberMemberCategory.Financial]: FINANCIAL,
  [ChamberMemberCategory.AccommodationsAttractions]: ACCOMMODATIONS_ATTRACTIONS,
  [ChamberMemberCategory.MarketingAdvertising]: MARKETING_ADVERTISING,
  [ChamberMemberCategory.Industries]: INDUSTRIES, // delete when category migration done
  [ChamberMemberCategory.AutomotiveTransportation]: AUTOMOTIVE_TRANSPORTATION,
  [ChamberMemberCategory.ProfessionalServices]: PROFESSIONAL_SERVICES,
  [ChamberMemberCategory.HealthSafety]: HEALTH_SAFETY,
  [ChamberMemberCategory.SportsRecreation]: SPORTS_RECREATION,
  [ChamberMemberCategory.GovernmentEducation]: GOVERNMENT_EDUCATION,
  [ChamberMemberCategory.NonProfit]: NON_PROFIT,
  [ChamberMemberCategory.RealEstate]: REAL_ESTATE,
  [ChamberMemberCategory.FarmingAgriculture]: FARMING_AGRICULTURE,
  [ChamberMemberCategory.ConstructionIndustrial]: CONSTRUCTION_INDUSTRIAL,
};

enum SellModalTypeEnum {
  Sell = "Sell",
  Resell = "Resell",
  ViewResell = "ViewResell",
}

type AdvertisingFeesTableProps = {
  selectedAdvertisingOptionId: string | null;
  updatedCategoryAdvertisingBannerFeeOptions: FeeOptionsWithId[] | null;
  chamberInfo?: GetUser_RootAdminChamberAdminQuery["getUser"]["chamberInfo"];
};

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    marginTop: "33px",
    flexDirection: "column",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: "850px",
    width: "740px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  yesButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  okButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#37474f",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  radio: {
    color: "#E1A731!important",
    padding: 0,
    marginRight: 10,
    backgroundColor: "white",
  },
  filledDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    height: "55px",
    width: "100%",
    "& .MuiInputBase-root": {
      backgroundColor: "white !important",
      borderRadius: "15px !important",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "#37474f",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
      color: "black",
      opacity: 1,
      "-webkit-text-fill-color": "black",
    },
  },
  dropDownTableCell: {
    fontSize: "16px",
    borderBottom: "0px !important",
  },
}));

export const useGetGraphqlChamberIdVariableObjForRootAdmin = () => {
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const location = useLocation();
  const chamberIdInLocationState = useMemo(() => {
    if (userType === UserType.RootAdmin) {
      return (
        (location.state || { chamberId: undefined }) as {
          chamberId: string | undefined;
        }
      ).chamberId;
    }
    return undefined;
  }, [location?.state, userType]);

  return userType === UserType.RootAdmin
    ? { chamberId: chamberIdInLocationState }
    : {};
};

type CustomCategoryAdvertisingBannerActivationInfoReturnForTable = Omit<
  CategoryAdvertisingBannerActivationInfoReturn,
  "sentEmailNotificationOfUpcomingExpiry" | "chamberMemberHasAcceptedAgreement"
>;

type SingleTableAllCategoryAdvertisingBannersForChammberDataType =
  GetAllCategoryAdvertisingBannersForChamberQuery["getAllCategoryAdvertisingBannersForChamber"]["categoryAdvertisingBanners"][0] & {
    id: string;
    humanReadableCategoryName: string;
    logo: string;
    availability: "sold" | "unsold" | "soldAndResold";
    waitingForApproval: boolean;
  };

export type AllTableAllCategoryAdvertisingBannersForChammberDataType =
  SingleTableAllCategoryAdvertisingBannersForChammberDataType[];

export const getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli =
  ({
    advertisingStartDateUtcMilli,
    months,
  }: {
    advertisingStartDateUtcMilli: number;
    months: number;
  }) => {
    // Extend dayjs with UTC plugin
    dayjs.extend(utc);

    // Convert Unix timestamp to a UTC dayjs object
    const date = dayjs.unix(advertisingStartDateUtcMilli / 1000).utc(); // Divide by 1000 to convert milliseconds to seconds

    // Add specified months, to the date
    // this is done because we can assume that the provided advertisingStartDateUtcMilli is at 00:00:00 of user's day in their local timezone, so to get expiry time we just get day after the specified months... adding 1 hour to ensure its on the exact day after specified months
    const futureDate = date.add(months, "month").add(1, "hour");

    // Convert the modified date back to Unix timestamp (in milliseconds)
    const convertedTimestamp = futureDate.valueOf();
    return convertedTimestamp;
  };

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableAllCategoryAdvertisingBannersForChammberDataType,
  comparator: (a: any, b: any) => number
): AllTableAllCategoryAdvertisingBannersForChammberDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageAllCategoryAdvertisingBannersForChammberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageAllCategoryAdvertisingBannersForChammberTableHead(
  props: FullPageAllCategoryAdvertisingBannersForChammberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function formatYearMonthDuration(months: number): string {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years === 0) {
    return `${months} Month${months !== 1 ? "s" : ""}`;
  } else if (remainingMonths === 0) {
    return `${years} Year${years !== 1 ? "s" : ""}`;
  } else {
    return `${years} Year${
      years !== 1 ? "s" : ""
    } and ${remainingMonths} Month${remainingMonths !== 1 ? "s" : ""}`;
  }
}

const commonDropDownTableHeaderRowCellStyle: TableCellProps = {
  style: { fontSize: "17px", fontWeight: "bold" },
  component: "th",
  scope: "row",
  align: "center",
};

const commonDropDownTableBodyRowCellStyle: TableCellProps = {
  style: {
    fontSize: "16px",
  },
  component: "th",
  scope: "row",
  align: "center",
};

type FeeOptionsWithId = CategoryAdvertisingBannersFeeOptionsReturn & {
  id: string;
};

const getInternalIdOfAdvertisingOption = ({
  timeLengthInMonths,
  bannerFeeInDollars,
}: {
  timeLengthInMonths: number;
  bannerFeeInDollars: number;
}) => `${timeLengthInMonths}_${bannerFeeInDollars}`;

function AdvertisingFeesTable({
  selectedAdvertisingOptionId,
  updatedCategoryAdvertisingBannerFeeOptions,
  chamberInfo,
}: AdvertisingFeesTableProps) {
  const enumStrings = useEnumStrings();

  const { nonDuesRevenue, activationFee } = useMemo(() => {
    const selectedOption = updatedCategoryAdvertisingBannerFeeOptions?.find(
      (o) => o.id === selectedAdvertisingOptionId
    );

    const bannerFee = selectedOption?.bannerFeeInDollars || 0;
    const splitPercentage =
      chamberInfo?.chamberEngageSettings
        .categoryAdvertisingBannersActivationFeeCpaSplitPercentage ?? 1;

    const activationFee =
      Number((bannerFee * (splitPercentage / 100)).toFixed(0)) || 0;

    const nonDuesRevenue = bannerFee - activationFee;

    return { nonDuesRevenue, activationFee };
  }, [
    chamberInfo?.chamberEngageSettings
      .categoryAdvertisingBannersActivationFeeCpaSplitPercentage,
    selectedAdvertisingOptionId,
    updatedCategoryAdvertisingBannerFeeOptions,
  ]);

  return (
    <table
      style={{
        width: "100%",
        maxWidth: "400px",
        borderCollapse: "collapse",
        border: "1px solid #d0d0d0",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "white",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: "8px",
              color: "#333",
              fontWeight: "bold",
              borderBottom: "1px solid #d0d0d0",
              borderRight: "1px solid #d0d0d0",
              fontSize: "17px",
              backgroundColor: "white",
            }}
          >
            {enumStrings.manageChamberEngage.nonDuesRevenue}
          </th>
          <th
            style={{
              padding: "8px",
              color: "#333",
              fontWeight: "bold",
              borderBottom: "1px solid #d0d0d0",
              fontSize: "17px",
              position: "relative",
              backgroundColor: "white",
            }}
          >
            {enumStrings.manageChamberEngage.activationFee}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            style={{
              padding: "8px",
              borderRight: "1px solid #d0d0d0",
              fontSize: "16px",
              backgroundColor: "white",
            }}
          >
            {selectedAdvertisingOptionId ? `$${nonDuesRevenue}` : "Select Term"}
          </td>
          <td
            style={{
              padding: "8px",
              fontSize: "16px",
              backgroundColor: "white",
            }}
          >
            {selectedAdvertisingOptionId ? `$${activationFee}` : "Select Term"}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SellResellModal({
  modalIsOpen,
  handleModalClose,
  categoryAdvertisingBanner,
  allChamberMemberData,
  sellModalType,
  activationInfo,
  reactivationInfo,
  openDropDownTable,
}: {
  modalIsOpen: boolean;
  handleModalClose: () => void;
  categoryAdvertisingBanner: SingleTableAllCategoryAdvertisingBannersForChammberDataType;
  allChamberMemberData: GetAllChamberMembersQuery;
  sellModalType: SellModalTypeEnum;
  activationInfo?:
    | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
    | undefined
    | null;
  reactivationInfo?:
    | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
    | undefined
    | null;
  openDropDownTable: () => void;
}) {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const chamberInfo = useGetChamberFromUserIdInAuth() as
    | GetUser_RootAdminChamberAdminQuery["getUser"]["chamberInfo"]
    | undefined;

  const [affirmativeActionInProgress, setAffirmativeActionInProgress] =
    useState(false);

  const [showMemberCompanyError, setShowMemberCompanyError] = useState(false);
  const [showAdvertisingOptionError, setShowAdvertisingOptionError] =
    useState(false);

  const [selectedChamberMemberId, setSelectedChamberMemberId] = useState<
    string | null
  >(null);

  const handleSetSelectedChamberMemberId = useCallback((id: string) => {
    setShowMemberCompanyError(false);
    setSelectedChamberMemberId(id);
  }, []);

  const [advertisingStartDateChange, setAdvertisingStartDateChange] =
    useState(0);

  const handleAdvdertisingStartDateChange = useCallback((val) => {
    setShowAdvertisingOptionError(false);
    setAdvertisingStartDateChange(dayjs(val).startOf("day").valueOf());
  }, []);

  const [selectedAdvertisingOptionId, setSelectedAdvertisingOptionId] =
    useState<string | null>(null);

  const handleAdvertisingOptionSelect = useCallback(
    (id: string) => {
      setShowAdvertisingOptionError(false);

      // when reselling or viewing resell, user needs to see the start date as the currently active sell's expiry date
      if (sellModalType === SellModalTypeEnum.Sell) {
        setAdvertisingStartDateChange(0);
      }
      setSelectedAdvertisingOptionId(id);
    },
    [sellModalType]
  );

  const [
    updatedCategoryAdvertisingBannerFeeOptions,
    setUpdatedCategoryAdvertisingBannerFeeOptions,
  ] = useState<FeeOptionsWithId[] | null>(null);

  // attach id to each fee option to keep track of what is selected
  useEffect(() => {
    if (
      !chamberInfo?.chamberEngageSettings.categoryAdvertisingBannersFeeOptions
    ) {
      return;
    }

    const res = [];

    for (
      let i = 0;
      i <
      chamberInfo.chamberEngageSettings.categoryAdvertisingBannersFeeOptions
        .length;
      i += 1
    ) {
      const advertisingOption =
        chamberInfo.chamberEngageSettings.categoryAdvertisingBannersFeeOptions[
          i
        ];
      res.push({
        ...advertisingOption,
        id: getInternalIdOfAdvertisingOption({
          bannerFeeInDollars: advertisingOption.bannerFeeInDollars,
          timeLengthInMonths: advertisingOption.timeLengthInMonths,
        }), // assuming two options cant exist with the same timeLengthInMonths and bannerFeeInDollars
      });
    }

    setUpdatedCategoryAdvertisingBannerFeeOptions(res);
  }, [chamberInfo?.chamberEngageSettings.categoryAdvertisingBannersFeeOptions]);

  const [autoApproveBannerContent, setAutoApproveBannerContent] =
    useState(false);

  useEffect(() => {
    if (updatedCategoryAdvertisingBannerFeeOptions) {
      // fill form for a resell update
      if (sellModalType === SellModalTypeEnum.ViewResell && reactivationInfo) {
        const internalIdOfAdvertisingOption = getInternalIdOfAdvertisingOption({
          bannerFeeInDollars:
            reactivationInfo.bannerFeeOptionSetAtActivationTime
              .bannerFeeInDollars,
          timeLengthInMonths:
            reactivationInfo.bannerFeeOptionSetAtActivationTime
              .timeLengthInMonths,
        });

        // check if the advertising option that was selected at the time of sale is still available
        const advertisingOptionFound =
          updatedCategoryAdvertisingBannerFeeOptions.find(
            (option) => option.id === internalIdOfAdvertisingOption
          );

        if (advertisingOptionFound) {
          setSelectedAdvertisingOptionId(advertisingOptionFound.id);
          setAdvertisingStartDateChange(
            reactivationInfo.activationStartDateUtcMilli
          );
        }

        setSelectedChamberMemberId(reactivationInfo.chamberMemberId);
        setAutoApproveBannerContent(
          reactivationInfo.autoApproveBannerContentUpdate ?? false
        );
        return;
      }

      if (sellModalType === SellModalTypeEnum.Resell && activationInfo) {
        setSelectedAdvertisingOptionId(null);
        setAdvertisingStartDateChange(
          getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
            {
              advertisingStartDateUtcMilli:
                activationInfo?.activationStartDateUtcMilli || 0,
              months:
                activationInfo.bannerFeeOptionSetAtActivationTime
                  .timeLengthInMonths,
            }
          )
        );
        setSelectedChamberMemberId(null);
        return;
      }

      // reset form for a new resell or sell
      setSelectedAdvertisingOptionId(null);
      setAdvertisingStartDateChange(0);
      setSelectedChamberMemberId(null);
    }
  }, [
    activationInfo,
    reactivationInfo,
    sellModalType,
    updatedCategoryAdvertisingBannerFeeOptions,
  ]);

  const [activateBannerForCategoryAdvertisingBanner] =
    useActivateBannerForCategoryAdvertisingBannerMutation();

  const [reactivateBannerForCategoryAdvertisingBanner] =
    useReactivateBannerForCategoryAdvertisingBannerMutation();

  const graphqlChamberIdVariableObjForRootAdmin =
    useGetGraphqlChamberIdVariableObjForRootAdmin();

  const handleAffirmativeAction = useCallback(async () => {
    setAffirmativeActionInProgress(true);

    //validate data
    if (
      !(
        selectedChamberMemberId &&
        advertisingStartDateChange > 0 &&
        updatedCategoryAdvertisingBannerFeeOptions
      )
    ) {
      if (!selectedChamberMemberId) {
        setShowMemberCompanyError(true);
      }

      if (advertisingStartDateChange <= 0) {
        setShowAdvertisingOptionError(true);
      }

      setAffirmativeActionInProgress(false);
      return;
    }

    // execute sale
    const bannerFeeOptionSetAtActivationTime =
      updatedCategoryAdvertisingBannerFeeOptions.find(
        (option) => option.id === selectedAdvertisingOptionId
      );

    if (bannerFeeOptionSetAtActivationTime) {
      const bannerFeeOptionSetAtActivationTimeForMutation = omitDeep(
        bannerFeeOptionSetAtActivationTime,
        ["id", "__typename"]
      ) as CategoryAdvertisingBannersFeeOptionsInput;
      if (sellModalType === SellModalTypeEnum.Sell) {
        await activateBannerForCategoryAdvertisingBanner({
          variables: {
            ...graphqlChamberIdVariableObjForRootAdmin,
            categoryId: categoryAdvertisingBanner.categoryId,
            activationInfo: {
              activationStartDateUtcMilli: advertisingStartDateChange,
              bannerFeeOptionSetAtActivationTime:
                bannerFeeOptionSetAtActivationTimeForMutation,
              chamberMemberId: selectedChamberMemberId,
              autoApproveBannerContentUpdate: autoApproveBannerContent,
            },
          },
          refetchQueries: [
            {
              query: GetAllCategoryAdvertisingBannersForChamberDocument,
              variables: {
                ...graphqlChamberIdVariableObjForRootAdmin,
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        openDropDownTable();
        toast.success(enumStrings.manageChamberEngage.activatedSuccessfully);
      }

      if (sellModalType === SellModalTypeEnum.Resell && activationInfo) {
        await reactivateBannerForCategoryAdvertisingBanner({
          variables: {
            ...graphqlChamberIdVariableObjForRootAdmin,
            categoryId: categoryAdvertisingBanner.categoryId,
            reactivationInfo: {
              bannerFeeOptionSetAtActivationTime:
                bannerFeeOptionSetAtActivationTimeForMutation,
              chamberMemberId: selectedChamberMemberId,
              autoApproveBannerContentUpdate: autoApproveBannerContent,
            },
          },
          refetchQueries: [
            {
              query: GetAllCategoryAdvertisingBannersForChamberDocument,
              variables: {
                ...graphqlChamberIdVariableObjForRootAdmin,
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        toast.success(enumStrings.manageChamberEngage.activatedSuccessfully);
      }

      handleModalClose();
    }
  }, [
    selectedChamberMemberId,
    advertisingStartDateChange,
    updatedCategoryAdvertisingBannerFeeOptions,
    selectedAdvertisingOptionId,
    sellModalType,
    activationInfo,
    handleModalClose,
    activateBannerForCategoryAdvertisingBanner,
    graphqlChamberIdVariableObjForRootAdmin,
    categoryAdvertisingBanner.categoryId,
    autoApproveBannerContent,
    openDropDownTable,
    enumStrings.manageChamberEngage.activatedSuccessfully,
    reactivateBannerForCategoryAdvertisingBanner,
  ]);

  const modalTitleForType = useMemo(() => {
    if (categoryAdvertisingBanner) {
      if (sellModalType === SellModalTypeEnum?.Sell) {
        return `Activate ${categoryAdvertisingBanner.humanReadableCategoryName} Banner`;
      }

      if (sellModalType === SellModalTypeEnum.Resell) {
        return `Resell ${categoryAdvertisingBanner.humanReadableCategoryName} Banner`;
      }

      if (sellModalType === SellModalTypeEnum.ViewResell) {
        return `View Resell of ${categoryAdvertisingBanner.humanReadableCategoryName} Banner`;
      }
    }

    return "";
  }, [categoryAdvertisingBanner, sellModalType]);

  const modalDescriptionForType = useMemo(() => {
    if (categoryAdvertisingBanner) {
      if (sellModalType === SellModalTypeEnum.Sell) {
        return (
          <h3 style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}>
            {enumStrings.manageChamberEngage.fillInformation}
            <div style={{ height: 10 }} />
            {enumStrings.manageChamberEngage.emailWarning}
          </h3>
        );
      }

      if (sellModalType === SellModalTypeEnum.Resell && activationInfo) {
        return (
          <h3 style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}>
            {enumStrings.manageChamberEngage.selectCompany}{" "}
            <span style={{ fontWeight: "bold" }}>
              (
              {activationInfo.activationStartDateUtcMilli > 0
                ? getLocalTimestamp(
                    getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                      {
                        advertisingStartDateUtcMilli:
                          activationInfo.activationStartDateUtcMilli,
                        months:
                          activationInfo.bannerFeeOptionSetAtActivationTime
                            .timeLengthInMonths,
                      }
                    ),
                    true
                  )
                : "not found"}
              )
            </span>
            <div style={{ height: 10 }} />
            {enumStrings.manageChamberEngage.sellModal.fillInfoForMember.title}
            <div style={{ height: 10 }} />
            {
              enumStrings.manageChamberEngage.sellModal.fillInfoForMember
                .notificationMessage
            }
          </h3>
        );
      }

      if (sellModalType === SellModalTypeEnum.ViewResell) {
        return (
          <h3 style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}>
            {enumStrings.manageChamberEngage.sellModal.viewResell.title}
            <div style={{ height: 10 }} />
            {
              enumStrings.manageChamberEngage.sellModal.viewResell
                .notificationMessage
            }
          </h3>
        );
      }
    }

    return "";
  }, [
    categoryAdvertisingBanner,
    sellModalType,
    activationInfo,
    enumStrings.manageChamberEngage.fillInformation,
    enumStrings.manageChamberEngage.emailWarning,
    enumStrings.manageChamberEngage.selectCompany,
    enumStrings.manageChamberEngage.sellModal.fillInfoForMember.title,
    enumStrings.manageChamberEngage.sellModal.fillInfoForMember
      .notificationMessage,
    enumStrings.manageChamberEngage.sellModal.viewResell.title,
    enumStrings.manageChamberEngage.sellModal.viewResell.notificationMessage,
  ]);

  const affirmativeButtonTextForModalType = useMemo(() => {
    if (sellModalType === SellModalTypeEnum.Sell) {
      return enumStrings.manageChamberEngage.active;
    }

    if (sellModalType === SellModalTypeEnum.Resell) {
      return enumStrings.manageChamberEngage.reactivate;
    }

    return "";
  }, [
    enumStrings.manageChamberEngage.active,
    enumStrings.manageChamberEngage.reactivate,
    sellModalType,
  ]);

  return (
    <Modal className={classes.modal} open={modalIsOpen}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold", margin: 0 }}>{modalTitleForType}</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "scroll",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "20px",
          }}
        >
          {modalDescriptionForType}
          <div style={{ height: 35 }} />
          <ChamberMemberSelector
            allChamberMemberData={allChamberMemberData}
            chamberMemberId={selectedChamberMemberId}
            setChamberMemberId={handleSetSelectedChamberMemberId}
            isFilledVariant
            disabled={sellModalType === SellModalTypeEnum.ViewResell}
          />
          {showMemberCompanyError && (
            <h3
              style={{
                margin: 0,
                marginTop: theme.spacing(1),
                color: "red",
              }}
            >
              {enumStrings.manageChamberEngage.selectMemberCompany}
            </h3>
          )}
          <div style={{ height: 35 }} />
          <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
            {enumStrings.manageChamberEngage.advertisingTerm}
          </h1>
          {showAdvertisingOptionError && (
            <h3
              style={{
                margin: 0,
                marginTop: theme.spacing(1),
                color: "red",
              }}
            >
              {enumStrings.manageChamberEngage.selectAdvertisingOption}
            </h3>
          )}
          <div style={{ height: 15 }} />
          {/* loop through default chamber engage settings herrr */}
          <div
            style={{
              width: "100%",
              maxWidth: 250,
            }}
          >
            {(updatedCategoryAdvertisingBannerFeeOptions || []).map(
              (advertisingOption, index) => {
                const optionIsChecked =
                  selectedAdvertisingOptionId === advertisingOption.id;

                if (
                  sellModalType === SellModalTypeEnum.ViewResell &&
                  !optionIsChecked
                ) {
                  return null;
                }

                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: index === 0 ? 0 : 20,
                      }}
                      key={advertisingOption.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          className={classes.radio}
                          onClick={() => {
                            handleAdvertisingOptionSelect(advertisingOption.id);
                          }}
                          checked={optionIsChecked}
                          style={{
                            cursor:
                              sellModalType === SellModalTypeEnum.ViewResell
                                ? "unset"
                                : "pointer",
                          }}
                        />
                        <h3 style={{ whiteSpace: "nowrap", margin: 0 }}>
                          {formatYearMonthDuration(
                            advertisingOption.timeLengthInMonths
                          )}
                        </h3>
                      </div>

                      <div style={{ width: 10 }} />
                      <Chip
                        label={
                          <h3
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            ${advertisingOption.bannerFeeInDollars}
                          </h3>
                        }
                        style={{
                          backgroundColor: "#E1A731",
                          borderColor: "#E1A731",
                          display: "flex",
                        }}
                      />
                    </div>
                    {optionIsChecked && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div style={{ height: 5 }} />
                        <LocalizationProvider
                          key={`advertisingStartDate_${advertisingOption.id}`}
                          dateAdapter={AdapterDayjs}
                        >
                          <MobileDatePicker
                            slotProps={{
                              textField: {
                                disabled:
                                  sellModalType === SellModalTypeEnum.Resell ||
                                  sellModalType === SellModalTypeEnum.ViewResell
                                    ? true
                                    : false, // prevent user from changing this
                                InputProps: {
                                  disableUnderline: true,
                                },
                                className: classes.filledDatePicker,
                                variant: "filled",
                                sx: {
                                  "& .MuiFilledInput-root:before": {
                                    borderBottom: "none",
                                  },
                                  "& .MuiFilledInput-root:after": {
                                    borderBottom: "none",
                                  },
                                  "& .MuiFilledInput-root:hover:before": {
                                    borderBottom: "none",
                                  },
                                  "& .MuiInputBase-input.MuiFilledInput-input.Mui-readOnly.MuiInputBase-readOnly":
                                    {
                                      cursor:
                                        sellModalType === SellModalTypeEnum.Sell
                                          ? "pointer"
                                          : "unset",
                                    },
                                },
                              },
                            }}
                            minDate={dayjs(Date.now())}
                            label={
                              sellModalType === SellModalTypeEnum.Sell
                                ? "Set Advertising Start Date"
                                : "Advertising Start Date"
                            }
                            format="ll"
                            onChange={handleAdvdertisingStartDateChange}
                            value={
                              advertisingStartDateChange > 0
                                ? dayjs(advertisingStartDateChange)
                                : undefined
                            }
                          />
                        </LocalizationProvider>
                        <div style={{ height: 10 }} />
                        <LocalizationProvider
                          key={`advertisingEndDate_${advertisingOption.id}`}
                          dateAdapter={AdapterDayjs}
                        >
                          <MobileDatePicker
                            slotProps={{
                              textField: {
                                disabled: true, // prevent user from changing this
                                InputProps: {
                                  disableUnderline: true,
                                },
                                className: classes.filledDatePicker,
                                variant: "filled",
                                sx: {
                                  cursor: "not-allowed",
                                  "& .MuiFilledInput-root:before": {
                                    borderBottom: "none",
                                  },
                                  "& .MuiFilledInput-root:after": {
                                    borderBottom: "none",
                                  },
                                  "& .MuiFilledInput-root:hover:before": {
                                    borderBottom: "none",
                                  },
                                },
                              },
                            }}
                            label={"Advertising Expiry Date"}
                            format="ll"
                            value={
                              advertisingStartDateChange > 0
                                ? dayjs(
                                    getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                                      {
                                        advertisingStartDateUtcMilli:
                                          advertisingStartDateChange,
                                        months:
                                          advertisingOption.timeLengthInMonths,
                                      }
                                    )
                                  )
                                : undefined
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
          <div style={{ height: 40 }} />

          <AdvertisingFeesTable
            selectedAdvertisingOptionId={selectedAdvertisingOptionId}
            updatedCategoryAdvertisingBannerFeeOptions={
              updatedCategoryAdvertisingBannerFeeOptions
            }
            chamberInfo={chamberInfo}
          />

          <div style={{ height: 35 }} />
          <AutoApproveBannerContentToggle
            checked={autoApproveBannerContent}
            onChange={(checked) => setAutoApproveBannerContent(checked)}
            disabled={sellModalType === SellModalTypeEnum.ViewResell}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {sellModalType !== SellModalTypeEnum.ViewResell && (
            <Button
              className={classes.yesButton}
              onClick={handleAffirmativeAction}
              variant="contained"
              disabled={affirmativeActionInProgress}
            >
              {affirmativeActionInProgress ? (
                <CircularProgress
                  size={30}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                <span>{affirmativeButtonTextForModalType}</span>
              )}
            </Button>
          )}
          <Button
            className={classes.okButton}
            onClick={handleModalClose}
            variant="contained"
            disabled={affirmativeActionInProgress}
          >
            {sellModalType === SellModalTypeEnum.ViewResell
              ? "Close"
              : "Cancel"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function IndividualTableRow({
  humanReadableCategoryName,
  categoryAdvertisingBanner,
  allChamberMemberData,
  allChamberMembersIdToName,
}: {
  humanReadableCategoryName: string;
  categoryAdvertisingBanner: SingleTableAllCategoryAdvertisingBannersForChammberDataType;
  allChamberMemberData: GetAllChamberMembersQuery;
  allChamberMembersIdToName: { [key: string]: ChamberMemberReturn };
}) {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [dropDownTableOpen, setDropDownTableOpen] = useState(false);

  const openDropDownTable = useCallback(() => {
    setDropDownTableOpen(true);
  }, []);

  const closeDropDownTable = useCallback(() => {
    setDropDownTableOpen(false);
  }, []);

  const toggleDropDownTable = useCallback(() => {
    if (dropDownTableOpen) {
      closeDropDownTable();
      return;
    }

    openDropDownTable();
  }, [closeDropDownTable, dropDownTableOpen, openDropDownTable]);

  const [sellModalType, setSellModalType] = useState<SellModalTypeEnum>(
    SellModalTypeEnum.Sell
  );

  const [sellModalIsOpen, setSellModalIsOpen] = useState(false);
  const handleSellModalClose = useCallback(() => {
    setSellModalIsOpen(false);
  }, []);

  const [sellModalActivationInfo, setSellModalActivationInfo] = useState<
    | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
    | undefined
    | null
  >(undefined);
  const [sellModalReactivationInfo, setSellModalReactivationInfo] = useState<
    | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
    | undefined
    | null
  >(undefined);

  const handleSellModalOpen = useCallback(
    ({
      sellModalType,
      activationInfo,
      reactivationInfo,
    }: {
      sellModalType: SellModalTypeEnum;
      activationInfo?:
        | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
        | undefined
        | null;
      reactivationInfo?:
        | CustomCategoryAdvertisingBannerActivationInfoReturnForTable
        | undefined
        | null;
    }) => {
      setSellModalActivationInfo(activationInfo);
      setSellModalReactivationInfo(reactivationInfo);
      setSellModalType(sellModalType);
      setSellModalIsOpen(true);
    },
    []
  );

  const dropDownTableRow = useMemo(() => {
    if (categoryAdvertisingBanner?.bannerSettings) {
      const bannerSettings = categoryAdvertisingBanner.bannerSettings;
      return (
        <TableRow key={`${categoryAdvertisingBanner.id}_dropDownTableRow`}>
          <TableCell
            {...commonDropDownTableBodyRowCellStyle}
            className={classes.dropDownTableCell}
          >
            {
              allChamberMembersIdToName[
                bannerSettings.activationInfo.chamberMemberId
              ]?.name
            }
          </TableCell>
          <TableCell
            {...commonDropDownTableBodyRowCellStyle}
            className={classes.dropDownTableCell}
          >
            $
            {
              bannerSettings.activationInfo.bannerFeeOptionSetAtActivationTime
                .bannerFeeInDollars
            }
          </TableCell>
          <TableCell
            {...commonDropDownTableBodyRowCellStyle}
            className={classes.dropDownTableCell}
          >
            {getLocalTimestamp(
              bannerSettings.activationInfo.activationStartDateUtcMilli,
              true
            )}
          </TableCell>
          <TableCell
            {...commonDropDownTableBodyRowCellStyle}
            className={classes.dropDownTableCell}
          >
            {getLocalTimestamp(
              getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                {
                  advertisingStartDateUtcMilli:
                    bannerSettings.activationInfo.activationStartDateUtcMilli,
                  months:
                    bannerSettings.activationInfo
                      .bannerFeeOptionSetAtActivationTime.timeLengthInMonths,
                }
              ),
              true
            )}
          </TableCell>
          <TableCell
            {...commonDropDownTableBodyRowCellStyle}
            className={classes.dropDownTableCell}
            style={{
              paddingLeft: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 50,
              }}
            >
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#4CB230",
                  fontSize: bannerSettings.reactivationInfo ? "12.5px" : "14px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: 140,
                  height: 30,
                }}
                onClick={() => {
                  handleSellModalOpen({
                    sellModalType: bannerSettings.reactivationInfo
                      ? SellModalTypeEnum.ViewResell
                      : SellModalTypeEnum.Resell,
                    activationInfo: bannerSettings.activationInfo,
                    reactivationInfo: bannerSettings.reactivationInfo,
                  });
                }}
              >
                {bannerSettings.reactivationInfo ? "View Resell" : "Resell"}
              </Button>
              {bannerSettings?.reactivationInfo && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 5,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize:
                        commonDropDownTableBodyRowCellStyle.style?.fontSize ||
                        15,
                    }}
                  >
                    {enumStrings.manageChamberEngage.resellStartDate}:
                  </span>
                  {"\n"}
                  <span>
                    {getLocalTimestamp(
                      bannerSettings.reactivationInfo
                        .activationStartDateUtcMilli,
                      true
                    )}
                  </span>
                </div>
              )}
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return null;
  }, [
    allChamberMembersIdToName,
    categoryAdvertisingBanner.bannerSettings,
    categoryAdvertisingBanner.id,
    classes.dropDownTableCell,
    enumStrings.manageChamberEngage.resellStartDate,
    handleSellModalOpen,
  ]);

  const [
    parentOverrideForceMediaUploadModalClosed,
    setParentOverrideForceMediaUploadModalClosed,
  ] = useState(false);

  const [bannerUpdateInProgress, setBannerUpdateInProgress] = useState(false);

  const graphqlChamberIdVariableObjForRootAdmin =
    useGetGraphqlChamberIdVariableObjForRootAdmin();

  const [updateBannerContentForCategoryAdvertisingBanner] =
    useUpdateBannerContentForCategoryAdvertisingBannerMutation();

  const handleBannerUpdate = useCallback(
    async (updatedBanner: BannerInput[]) => {
      setBannerUpdateInProgress(true);

      await updateBannerContentForCategoryAdvertisingBanner({
        variables: {
          ...graphqlChamberIdVariableObjForRootAdmin,
          categoryId: categoryAdvertisingBanner?.categoryId || "",
          banner: omitDeep(updatedBanner, ["__typename"]) as BannerInput[],
        },
        refetchQueries: [
          {
            query: GetAllCategoryAdvertisingBannersForChamberDocument,
            variables: {
              ...graphqlChamberIdVariableObjForRootAdmin,
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      setBannerUpdateInProgress(false);
      setParentOverrideForceMediaUploadModalClosed(true);

      toast.success(enumStrings.manageChamberEngage.updatedSuccessfully);
    },
    [
      categoryAdvertisingBanner?.categoryId,
      enumStrings.manageChamberEngage.updatedSuccessfully,
      graphqlChamberIdVariableObjForRootAdmin,
      updateBannerContentForCategoryAdvertisingBanner,
    ]
  );

  const [
    changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner,
    {
      loading:
        changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading,
    },
  ] =
    useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation();

  const handleAutoApproveBannerContentUpdate = useCallback(
    async (autoApproveBannerContentUpdate: boolean) => {
      try {
        await changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner({
          variables: {
            ...graphqlChamberIdVariableObjForRootAdmin,
            categoryId: categoryAdvertisingBanner.categoryId,
            autoApproveBannerContentUpdate,
          },
          refetchQueries: [
            {
              query: GetAllCategoryAdvertisingBannersForChamberDocument,
              variables: {
                ...graphqlChamberIdVariableObjForRootAdmin,
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        toast.success(
          `${
            enumStrings.manageChamberEngage.categoryAdvertisingBanners
              .autoApproval
          } ${
            autoApproveBannerContentUpdate
              ? enumStrings.manageChamberEngage.categoryAdvertisingBanners
                  .enabled
              : enumStrings.manageChamberEngage.categoryAdvertisingBanners
                  .disabled
          } ${
            enumStrings.manageChamberEngage.categoryAdvertisingBanners
              .successfully
          }`
        );
      } catch (error) {
        toast.error(
          `${
            enumStrings.manageChamberEngage.categoryAdvertisingBanners.failedTo
          } ${
            autoApproveBannerContentUpdate
              ? enumStrings.manageChamberEngage.categoryAdvertisingBanners
                  .enable
              : enumStrings.manageChamberEngage.categoryAdvertisingBanners
                  .disable
          } ${
            enumStrings.manageChamberEngage.categoryAdvertisingBanners
              .autoApprovalTwo
          }`
        );
        console.error(error);
      }
    },
    [
      categoryAdvertisingBanner.categoryId,
      changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.autoApproval,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners
        .autoApprovalTwo,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.disable,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.disabled,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.enable,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.enabled,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.failedTo,
      enumStrings.manageChamberEngage.categoryAdvertisingBanners.successfully,
      graphqlChamberIdVariableObjForRootAdmin,
    ]
  );

  return (
    <>
      {sellModalIsOpen && (
        <SellResellModal
          key={`sellResellModal_${categoryAdvertisingBanner.categoryId}`}
          categoryAdvertisingBanner={categoryAdvertisingBanner}
          modalIsOpen={sellModalIsOpen}
          handleModalClose={handleSellModalClose}
          allChamberMemberData={allChamberMemberData}
          sellModalType={sellModalType}
          activationInfo={sellModalActivationInfo}
          reactivationInfo={sellModalReactivationInfo}
          openDropDownTable={openDropDownTable}
        />
      )}
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={categoryAdvertisingBanner.categoryId}
        sx={{
          cursor: "unset",
        }}
      >
        <TableCell
          style={{
            fontSize: "16px",
            width: 20,
            textAlign: "center",
            paddingRight: 0,
          }}
          component="th"
          scope="row"
          sx={{
            cursor: categoryAdvertisingBanner?.bannerSettings
              ? "pointer"
              : "unset",
          }}
          onClick={() => {
            if (categoryAdvertisingBanner?.bannerSettings) {
              toggleDropDownTable();
            }
          }}
        >
          {categoryAdvertisingBanner?.bannerSettings && (
            <>
              {dropDownTableOpen ? (
                <ArrowUp style={{ fontSize: 35 }} />
              ) : (
                <ArrowDown style={{ fontSize: 35 }} />
              )}
            </>
          )}
        </TableCell>
        <TableCell
          style={{ fontSize: "16px", width: 50 }}
          component="th"
          scope="row"
        >
          <LogoImage
            logoSource={categoryAdvertisingBanner.logo}
            size="smallNormal"
            squared
            key={categoryAdvertisingBanner.humanReadableCategoryName}
            chamberName={categoryAdvertisingBanner.humanReadableCategoryName}
          />
        </TableCell>
        <TableCell
          style={{ fontSize: "16px", width: 260 }}
          component="th"
          scope="row"
        >
          {categoryAdvertisingBanner.humanReadableCategoryName}
        </TableCell>
        <TableCell
          style={{ fontSize: "16px", width: 100 }}
          component="th"
          scope="row"
        >
          {categoryAdvertisingBanner?.bannerSettings && (
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: dropDownTableOpen ? "#7F7F7F" : "#E1A731",
                fontSize: "14px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: 100,
                height: 35,
              }}
              onClick={toggleDropDownTable}
            >
              {dropDownTableOpen ? "Close" : "View"}
            </Button>
          )}
        </TableCell>
        <TableCell
          style={{
            fontSize: "16px",
            textAlign: "center",
            width: 160,
          }}
          component="th"
          scope="row"
        >
          {categoryAdvertisingBanner.availability === "unsold" ? (
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#4CB230",
                fontSize: "14px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: 160,
                marginRight: 20,
                height: 35,
              }}
              onClick={() => {
                handleSellModalOpen({
                  sellModalType: SellModalTypeEnum.Sell,
                });
              }}
            >
              {enumStrings.manageChamberEngage.sellbanner}
            </Button>
          ) : (
            <span
              style={{
                paddingRight: 20,
                fontWeight: "bold",
                color: "#4CB230",
              }}
            >
              {categoryAdvertisingBanner.availability === "sold"
                ? enumStrings.manageChamberEngage.sold
                : enumStrings.manageChamberEngage.soldAndResold}
            </span>
          )}
        </TableCell>
        <TableCell
          style={{
            fontSize: "16px",
            textAlign: "center",
            paddingLeft: 0,
            paddingRight: 0,
            width: 190,
          }}
          component="th"
          scope="row"
        >
          <span
            style={{
              paddingRight: 20,
            }}
          >
            {categoryAdvertisingBanner?.bannerSettings && (
              <>
                {categoryAdvertisingBanner.waitingForApproval ? (
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    style={{
                      backgroundColor: "#E1A731",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      width: 195,
                      height: 35,
                    }}
                    onClick={toggleDropDownTable}
                  >
                    {enumStrings.manageChamberEngage.waitingApproval}
                  </Button>
                ) : (
                  enumStrings.manageChamberEngage.approved
                )}
              </>
            )}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse
            in={dropDownTableOpen}
            timeout="auto"
            unmountOnExit
            style={{
              backgroundColor: "#fafafa",
            }}
          >
            <Box
              sx={{
                marginRight: 4,
                marginLeft: 4,
              }}
            >
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell {...commonDropDownTableHeaderRowCellStyle}>
                      {enumStrings.manageChamberEngage.memberCompanyName}
                    </TableCell>
                    <TableCell {...commonDropDownTableHeaderRowCellStyle}>
                      {enumStrings.manageChamberEngage.pricing}
                    </TableCell>
                    <TableCell {...commonDropDownTableHeaderRowCellStyle}>
                      {enumStrings.manageChamberEngage.startDate}
                    </TableCell>
                    <TableCell {...commonDropDownTableHeaderRowCellStyle}>
                      {enumStrings.manageChamberEngage.expiryDate}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{dropDownTableRow}</TableBody>
              </Table>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 100,
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              <BannerPicker
                key={`bannerPicker_${categoryAdvertisingBanner.categoryId}`}
                pickerType="categoryAdvertisingChamberAdmin"
                formikVal={
                  categoryAdvertisingBanner?.bannerSettings?.activationInfo
                    ?.banner
                    ? cloneDeep(
                        categoryAdvertisingBanner?.bannerSettings
                          ?.activationInfo?.banner
                      )
                    : null
                }
                previewLogo={
                  categoryAdvertisingBanner.bannerSettings?.activationInfo
                    ?.chamberMemberId
                    ? allChamberMembersIdToName[
                        categoryAdvertisingBanner.bannerSettings?.activationInfo
                          ?.chamberMemberId
                      ]?.logo
                    : null
                }
                previewLogoNameAlt={
                  categoryAdvertisingBanner.bannerSettings?.activationInfo
                    ?.chamberMemberId
                    ? allChamberMembersIdToName[
                        categoryAdvertisingBanner.bannerSettings?.activationInfo
                          ?.chamberMemberId
                      ]?.name
                    : null
                }
                formikSetFieldValue={handleBannerUpdate}
                pickerLabel={`${humanReadableCategoryName} Advertising Banners`}
                bannerUpdateInProgress={bannerUpdateInProgress}
                parentOverrideForceMediaUploadModalClosed={
                  parentOverrideForceMediaUploadModalClosed
                }
                setParentOverrideForceMediaUploadModalClosed={
                  setParentOverrideForceMediaUploadModalClosed
                }
                handleAutoApproveBannerContentUpdate={
                  handleAutoApproveBannerContentUpdate
                }
                autoApproveBannerContentUpdate={
                  categoryAdvertisingBanner?.bannerSettings?.activationInfo
                    ?.autoApproveBannerContentUpdate
                }
                changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading={
                  changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerLoading
                }
              />
              <HoverToolTip
                description={`${enumStrings.manageChamberEngage.bannersDiscrption.replace(
                  "humanReadableCategoryName",
                  humanReadableCategoryName
                )} ${
                  enumStrings.bannerPicker.minSizeRequirementForBanner
                    .charAt(0)
                    .toLowerCase() +
                  enumStrings.bannerPicker.minSizeRequirementForBanner.slice(1)
                } ${enumStrings.manageChamberEngage.bannersDiscrption2}`}
                showIt
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function FullPageManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersTable({
  allCategoryAdvertisingBannersForChammberData,
  allChamberMemberData,
  allChamberMembersIdToName,
}: {
  allCategoryAdvertisingBannersForChammberData: AllTableAllCategoryAdvertisingBannersForChammberDataType;
  allChamberMemberData: GetAllChamberMembersQuery;
  allChamberMembersIdToName: { [key: string]: ChamberMemberReturn };
}) {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const allTableAllCategoryAdvertisingBannersForChammberData: AllTableAllCategoryAdvertisingBannersForChammberDataType =
    React.useMemo(() => {
      if (!allCategoryAdvertisingBannersForChammberData) {
        return [];
      }

      const allCategoryAdvertisingBannersForChammberArr =
        allCategoryAdvertisingBannersForChammberData.slice();

      if (searchInput.length > 0) {
        return allCategoryAdvertisingBannersForChammberData.filter((i) => {
          return i.humanReadableCategoryName
            .toLowerCase()
            .match(searchInput.toLowerCase());
        });
      }

      return allCategoryAdvertisingBannersForChammberArr;
    }, [allCategoryAdvertisingBannersForChammberData, searchInput]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        allTableAllCategoryAdvertisingBannersForChammberData,
        getComparator(order, orderBy)
      ),
    [allTableAllCategoryAdvertisingBannersForChammberData, order, orderBy]
  );

  const headCells: HeadCell[] = [
    {
      id: "openCloseIcon",
      label: "",
    },
    {
      id: "categorylogo",
      label: "",
    },
    {
      id: "humanReadableCategoryName",
      label: enumStrings.rootChamberAdmin.categoryId,
    },
    {
      id: "viewCloseButton",
      label: "",
    },
    {
      id: "availability",
      label: enumStrings.manageChamberEngage.availability,
      alignCenter: true,
    },
    {
      id: "waitingForApproval",
      label: enumStrings.waitingForApproval,
      alignCenter: true,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder={enumStrings.manageChamberEngage.searchByCategory}
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageAllCategoryAdvertisingBannersForChammberTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((categoryAdvertisingBanner) => (
                <IndividualTableRow
                  key={categoryAdvertisingBanner.id}
                  humanReadableCategoryName={
                    categoryAdvertisingBanner.humanReadableCategoryName
                  }
                  categoryAdvertisingBanner={categoryAdvertisingBanner}
                  allChamberMemberData={allChamberMemberData}
                  allChamberMembersIdToName={allChamberMembersIdToName}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

const ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBanners =
  (): ReactElement => {
    const classes = useStyles();

    const graphqlChamberIdVariableObjForRootAdmin =
      useGetGraphqlChamberIdVariableObjForRootAdmin();

    let { data: allCategoryAdvertisingBannersForChammber, loading } =
      useGetAllCategoryAdvertisingBannersForChamberQuery({
        fetchPolicy: "network-only",
        variables: {
          ...graphqlChamberIdVariableObjForRootAdmin,
        },
      });

    const [
      allCategoryAdvertisingBannersForChammberData,
      setAllCategoryAdvertisingBannersForChammberData,
    ] =
      React.useState<AllTableAllCategoryAdvertisingBannersForChammberDataType>(
        []
      );

    const [allChamberMemberData, setAllChamberMemberData] =
      useState<GetAllChamberMembersQuery>({});

    const [allChamberMembersIdToName, setAllChamberMembersIdToName] = useState<{
      [key: string]: ChamberMemberReturn;
    }>({});

    const [isLoading, setIsLoading] = useState(true);

    const { categoryIdToHumanReadableName, allCategories } =
      useGetCategoryIdToHumanReadableName();

    const {
      state: { userType },
    } = useContext(UserTypeContext);
    const chamberInfo = useGetChamberFromUserIdInAuth();
    const location = useLocation();
    const chamberIdToUse = useMemo(() => {
      if (userType === UserType.RootAdmin) {
        return (
          (location.state || { chamberId: undefined }) as {
            chamberId: string | undefined;
          }
        ).chamberId;
      }
      return chamberInfo?.id;
    }, [chamberInfo?.id, location?.state, userType]);
    const { data: allChamberMemberDataFromServer } =
      useGetAllChamberMembersQuery({
        variables: { chamberId: chamberIdToUse || "" },
        skip: !chamberIdToUse,
        fetchPolicy: "cache-and-network",
      });

    useEffect(() => {
      const getAllCategoryAdvertisingBannersForChammberstats = async () => {
        if (
          !allChamberMemberDataFromServer?.getAllChamberMembers ||
          !allCategoryAdvertisingBannersForChammber?.getAllCategoryAdvertisingBannersForChamber ||
          !categoryIdToHumanReadableName ||
          !allCategories
        ) {
          setIsLoading(true);
          setAllCategoryAdvertisingBannersForChammberData([]);
          setAllChamberMemberData({});
          setAllChamberMembersIdToName({});
          return;
        }

        const allCategoryAdvertisingBannersForChammberArr: AllTableAllCategoryAdvertisingBannersForChammberDataType =
          [];

        for (const categoryAdvertisingBanner of allCategoryAdvertisingBannersForChammber
          .getAllCategoryAdvertisingBannersForChamber
          .categoryAdvertisingBanners) {
          allCategoryAdvertisingBannersForChammberArr.push({
            id: categoryAdvertisingBanner.categoryId,
            ...categoryAdvertisingBanner,
            humanReadableCategoryName:
              categoryIdToHumanReadableName[
                categoryAdvertisingBanner.categoryId
              ],
            logo: categoryIdToLogo[categoryAdvertisingBanner.categoryId],
            availability: categoryAdvertisingBanner?.bannerSettings
              ?.activationInfo
              ? categoryAdvertisingBanner?.bannerSettings?.reactivationInfo
                ? "soldAndResold"
                : "sold"
              : "unsold",
            waitingForApproval:
              (
                categoryAdvertisingBanner?.bannerSettings?.activationInfo
                  ?.banner || []
              ).some((banner) => banner?.waitingForApprovalByChamberAdmin) ||
              false,
          });
        }

        setAllCategoryAdvertisingBannersForChammberData(
          allCategoryAdvertisingBannersForChammberArr
            .slice()
            // sort bsed on order of categoryIds in allCategories
            .sort((a, b) => {
              return (
                allCategories.findIndex(
                  (category) => category.id === a.categoryId
                ) -
                allCategories.findIndex(
                  (category) => category.id === b.categoryId
                )
              );
            })
            // then put availability = "soldAndResold" first
            .sort((a, b) => {
              if (
                a.availability === "soldAndResold" &&
                b.availability !== "soldAndResold"
              ) {
                return -1;
              }
              if (
                a.availability !== "soldAndResold" &&
                b.availability === "soldAndResold"
              ) {
                return 1;
              }
              return 0;
            })
            // then put availability = "sold" first
            .sort((a, b) => {
              if (a.availability === "sold" && b.availability !== "sold") {
                return -1;
              }
              if (a.availability !== "sold" && b.availability === "sold") {
                return 1;
              }
              return 0;
            })
            // then put the "waitingForApproval=true" first
            .sort((a, b) => {
              if (a.waitingForApproval && !b.waitingForApproval) {
                return -1;
              }
              if (!a.waitingForApproval && b.waitingForApproval) {
                return 1;
              }
              return 0;
            })
        );

        // set allChamberMemberData
        setAllChamberMemberData(allChamberMemberDataFromServer);

        // set allChamberMembersIdToName
        const allChamberMembersIdToNameObj = {} as {
          [key: string]: Omit<
            ChamberMemberReturn,
            "activeMemberProfileAndPerksBannersSettings"
          >;
        };
        for (const chamberMemberObj of allChamberMemberDataFromServer.getAllChamberMembers) {
          allChamberMembersIdToNameObj[chamberMemberObj.id] = chamberMemberObj;
        }
        setAllChamberMembersIdToName(allChamberMembersIdToNameObj);

        setIsLoading(false);
      };

      getAllCategoryAdvertisingBannersForChammberstats();
    }, [
      loading,
      allCategories,
      allCategoryAdvertisingBannersForChammber?.getAllCategoryAdvertisingBannersForChamber,
      allChamberMemberDataFromServer,
      allChamberMemberDataFromServer?.getAllChamberMembers,
      categoryIdToHumanReadableName,
    ]);
    const enumStrings = useEnumStrings();

    const rootAdminObj = // @ts-ignore
      enumStrings?.groups[userType][
        RedirectUri.chamberEngageManageCategoryAdvertisingBanners
      ];

    const history = useHistory();

    return (
      <div>
        <div className={classes.titleContent}>
          <div
            className={classes.title}
            style={{
              flexDirection: "column",
            }}
          >
            <span>
              {rootAdminObj.title}
              {(location?.state as any)?.chamberName &&
                ` for ${(location?.state as any)?.chamberName}`}
            </span>
            <span
              style={{
                marginLeft: "1px",
                fontWeight: "normal",
                fontSize: "16px",
              }}
            >
              {rootAdminObj.description} {enumStrings.watchVideoToLearnHow}
            </span>
            <div style={{ height: 10 }} />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <div style={{ paddingBottom: "10px" }}>
                <VideoTutorialButton
                  label={enumStrings.manageChamberEngage.howToActivate}
                  tutorialUrl="https://share.synthesia.io/embeds/videos/5bf66689-ccd5-4b6d-8f49-d05f809cef74"
                />
              </div>
              {userType !== UserType.RootAdmin && (
                <div style={{ paddingBottom: "10px" }}>
                  <ExportButton
                    customOnClick={() => {
                      history.push(
                        `${RedirectUri.chamberAdmin}#scrollToChamberEngage`
                      );
                    }}
                    disablePadding
                    label={enumStrings.manageChamberEngage.viewStatus}
                  />
                </div>
              )}
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
        </div>
        <div className={classes.content}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {allCategoryAdvertisingBannersForChammberData &&
              allCategoryAdvertisingBannersForChammberData.length > 0 ? (
                <FullPageManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersTable
                  allCategoryAdvertisingBannersForChammberData={
                    allCategoryAdvertisingBannersForChammberData
                  }
                  allChamberMemberData={allChamberMemberData}
                  allChamberMembersIdToName={allChamberMembersIdToName}
                />
              ) : (
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  {enumStrings.manageChamberEngage.loadingChamberMembers}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBanners;
