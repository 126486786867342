import { useEnumStrings } from "../../enumStrings";

// Define the type if not already defined elsewhere
interface DiscountReturn {
  isPrivate: boolean;
  memberOnly: boolean;
  isAvailableToAllMembers: boolean;
}

// Create a custom hook for the translated text
export const useGetOfferedToText = () => {
  const enumStrings = useEnumStrings();
  
  return (discount: DiscountReturn, isChamberMemberAdmin?: boolean) => {
    if (discount.isPrivate) {
      return isChamberMemberAdmin
        ? enumStrings.discountText.radioGroupText.private
        : enumStrings.rootChamberAdmin.memberCompanyEmployees;
    } else if (!discount.memberOnly) {
      return enumStrings.rootChamberAdmin.anyone;
    } else if (discount.memberOnly && !discount.isAvailableToAllMembers) {
      return enumStrings.rootChamberAdmin.localMembersOnly;
    } else if (discount.memberOnly && discount.isAvailableToAllMembers) {
      return enumStrings.rootChamberAdmin.allMember;
    }
    
    return "";
  };
};