import React, { ReactElement } from "react";
import { Button, makeStyles } from "@material-ui/core";
import theme from "../../themes";
import { RedirectUri, useEnumStrings } from "../enumStrings";
import useRedirect from "../common/Hooks/useRedirect";

const useStyles = makeStyles((themes: any) => ({
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  errrorTextContainer: {
    maxWidth: "600px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const DeleteCPAData = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  // @ts-ignore
  const redirectToHomePage = useRedirect(RedirectUri.home);

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.errrorTextContainer}>
          <h1>{enumStrings.accountDeletionGuide.title}</h1>
          <p
            style={{
              textAlign: "left",
              maxWidth: "500px",
            }}
          >
            <p>{enumStrings.accountDeletionGuide.instruction}</p>

            <ol>
              <li>
                {enumStrings.accountDeletionGuide.deleteThroughAppTitle}
                <ol>
                  <li>
                    {enumStrings.accountDeletionGuide.deleteThroughAppStep1}
                  </li>
                  <li>
                    {enumStrings.accountDeletionGuide.deleteThroughAppStep2}
                  </li>
                  <li>
                    {enumStrings.accountDeletionGuide.deleteThroughAppStep3}
                  </li>
                  <li>
                    {enumStrings.accountDeletionGuide.deleteThroughAppStep4}
                  </li>
                  <li>
                    {enumStrings.accountDeletionGuide.deleteThroughAppStep5}
                  </li>
                </ol>
              </li>

              <li>
                {enumStrings.accountDeletionGuide.requestByEmailTitle}
                <ol>
                  <li>
                    {enumStrings.accountDeletionGuide.requestByEmailStep1}{" "}
                    <a
                      href={`mailto:${enumStrings.supportContactEmail}?subject=Chamber Perks App Account Deletion Reques&body=Please%20state%20your%20Chamber%20Perks%20App%20account%20email%20here.`}
                    >
                      {enumStrings.supportContactEmail}
                    </a>{" "}
                    {enumStrings.accountDeletionGuide.requestByEmailStep2}
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <p>{enumStrings.accountDeletionGuide.dataRetentionNote}</p>

            <ul>
              <li>{enumStrings.accountDeletionGuide.perkRedemptionDataNote}</li>
            </ul>
          </p>
        </div>
        <div className={classes.settingsButtonContainer}>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#E1A731",
              fontSize: "23px",
              fontWeight: "bold",
              borderRadius: "10px",
              width: "307px",
              margin: "39px",
              height: "116px",
            }}
            onClick={redirectToHomePage}
          >
            {enumStrings.errorPageText.homeButtonText}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default DeleteCPAData;
