import React, { ReactElement, useState } from "react";
import { ThemeProvider, Button, TextField, Grid } from "@material-ui/core";
import theme from "../../../themes";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Logo from "../../../images/Gold_Logo.png";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import { Link } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import { Auth } from "aws-amplify";
interface ForgotPasswordInput {
  email: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  loginForm: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "250px",
    display: "block",
    margin: "auto",
    paddingTop: "9.2px",
  },
  heading: {},
  formFields: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    height: "550px",
    background: "white",
    padding: "29px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "22px",
    paddingBottom: "9px",
  },
  textField: {
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "18px",
  },
  formBody: {
    width: "90%",
    maxWidth: "585px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  icon: {
    margin: "auto",
    marginBottom: "20px",
  },
}));

const ForgotPassword = (): ReactElement => {
  const initialValues: ForgotPasswordInput = {
    email: "",
  };
  const enumStrings = useEnumStrings();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .required(enumStrings.validationMessages.emailIsRequired),
  });
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage

  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState("");
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: ForgotPasswordInput) => {
      setLoader(true);
      try {
        setEmail(input.email.trim().toLowerCase());
        Auth.forgotPassword(input.email.trim().toLowerCase())
          .then(() => {
            setStage(2);
          })
          .catch((err) => {
            setInvalidError(true);
            if (err.name === "UserNotFoundException") {
              setCustomError("Email does not exist please try again");
            }
            if (err.name === "InvalidParameterException") {
              setCustomError("Email does not exist please try again");
            }
            if (err.name === "LimitExceededException") {
              setCustomError("Too many attempts, please try again later.");
            }
            setLoader(false);
          });
      } catch (error) {
        setInvalidError(true);
        if (error?.code === "NetworkError") {
          setCustomError("Please connect to the internet");
        }
        setLoader(false);
      }
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {stage === 1 && (
        <form onSubmit={formik.handleSubmit} className={classes.formFields}>
          <img
            src={Logo}
            width="180px"
            height="180px"
            className={classes.icon}
            alt="capIcon"
          />
          <div>
            <h3>{enumStrings.forgotPasswordText.pleaseEnterEmailText}</h3>
          </div>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              InputProps={{
                className: classes.textField,
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
              id="email"
              name="email"
              label="Email Username *"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
            >
              {loader ? (
                <CircularProgress
                  style={{
                    color: "black",
                  }}
                />
              ) : (
                "Recover"
              )}
            </Button>
          </div>
          {invalidError ? (
            <div className={classes.invalidError}>{customError}</div>
          ) : null}{" "}
        </form>
      )}
      {stage === 2 && <ResetPassword email={email} />}
      <Link
        style={{ textAlign: "center", margin: "auto" }}
        to={RedirectUri.login}
      >
        {enumStrings.forgotPasswordText.goBacktoLoginText}
      </Link>
    </ThemeProvider>
  );
};
export default ForgotPassword;
