import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  GetAllShopLocalCampaignsForChamberDocument,
  ShopLocalCampaignInput,
  ShopLocalCampaignParticipatingMembersInput,
  useCreateShopLocalCampaignMutation,
  useGetAllChamberMembersQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useRedirect from "../../common/Hooks/useRedirect";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import ShopLocalCampaignEndDatePicker from "./ShopLocalCampaignEndDatePicker";
import Loader from "../../common/Loader/Loader";
import ChooseParticipatingMembers, {
  ParticipationCodeExplanationText,
} from "./ChooseParticipatingMembers";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  descriptionPreviewText: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "600px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const initialValues: ShopLocalCampaignInput = {
  startDateUtcMilli: 0,
  endDateUtcMilli: 0,
  participatingMembers: [],
  campaignName: "",
  prizeDescription: "",
};

const CreateChamberShopLocalCampaign = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const CHARACTER_LIMIT_DESCRIPTION = 250;

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    enumStrings.shopLocal.updateChamberShopLocalCampaign.anErrorOccurred
  );
  const [invalidError, setInvalidError] = useState(false);
  const [createShopLocalCampaign] = useCreateShopLocalCampaignMutation();
  const redirectToShopLocalCampaignList = useRedirect(
    RedirectUri.activeChamberShopLocal,
    {
      showToast: true,
    }
  );

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const validationSchema = yup.object({
    campaignName: yup
      .string()
      .required(
        enumStrings.updateChamberShopLocalCompaignValidation.contestName
      ),
    prizeDescription: yup
      .string()
      .required(
        enumStrings.updateChamberShopLocalCompaignValidation.contestDescription
      ),
    participatingMembers: yup
      .array()
      .required()
      .min(
        1,
        enumStrings.updateChamberShopLocalCompaignValidation
          .oneParticipationPerMember
      )
      .required(
        enumStrings.updateChamberShopLocalCompaignValidation
          .participantIsRequired
      ),
    startDateUtcMilli: yup
      .number()
      .required(
        enumStrings.updateChamberShopLocalCompaignValidation.startDateUtcMilli
      )
      .min(
        0,
        enumStrings.updateChamberShopLocalCompaignValidation.startDateUtcMilli
      ),
    endDateUtcMilli: yup
      .number()
      .required(
        enumStrings.updateChamberShopLocalCompaignValidation.endDateUtcMilli
      )
      .min(
        Date.now(),
        enumStrings.updateChamberShopLocalCompaignValidation.endDateUtcMilli
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: ShopLocalCampaignInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        const shopLocalCampaignCreation = await createShopLocalCampaign({
          variables: {
            input: {
              startDateUtcMilli: input.startDateUtcMilli,
              endDateUtcMilli: input.endDateUtcMilli,
              participatingMembers: input.participatingMembers.map(
                (member) => ({ id: member.id })
              ),
              campaignName: input.campaignName,
              prizeDescription: input.prizeDescription,
            },
          },
          refetchQueries: [
            { query: GetAllShopLocalCampaignsForChamberDocument },
            "getAllShopLocalCampaignsForChamber",
          ],
          awaitRefetchQueries: true,
        });
        const createionResult =
          shopLocalCampaignCreation.data?.createShopLocalCampaign;
        if (createionResult?.shopLocalCampaignCreatedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(
            `${enumStrings.perkPartnerAdmin.unableToCreateLocalContest}`
          );
          setLoader(false);
          setButtonDisabled(false);
          return;
        }

        setLoader(false);
        setCreateModalOpen(true);
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const createChamberShopLocalCampaignObj = useMemo(() => {
    // @ts-ignore
    return enumStrings.groups[UserType.ChamberAdmin][
      RedirectUri.createShopLocal
    ];
  }, [enumStrings.groups]);

  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (allChamberMemberData?.getAllChamberMembers) {
      setShowLoader(false);
    }
  }, [allChamberMemberData?.getAllChamberMembers]);

  const handleChamberMembersChange = useCallback(
    (selectedChamberMembers: ShopLocalCampaignParticipatingMembersInput[]) => {
      formik.setFieldValue("participatingMembers", selectedChamberMembers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {createModalOpen && (
        <Modal className={classes.modal} open={createModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              {enumStrings.chamberShopLocal.toastText}
            </h1>
            <div
              style={{
                padding: "40px",
                paddingTop: 10,
                paddingBottom: 0,
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              <ParticipationCodeExplanationText />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  redirectToShopLocalCampaignList();
                }}
                variant="contained"
              >
                {enumStrings.shopLocal.createShopLocalCampaign.close}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{createChamberShopLocalCampaignObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {createChamberShopLocalCampaignObj.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
      </div>
      <div className={classes.content}>
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              <div className={classes.companyInputWithToolTipContainer}>
                <TextField
                  type="text"
                  label={enumStrings.chamberShopLocal.label.campaignName}
                  className={classes.companyInput}
                  variant="outlined"
                  id="campaignName"
                  name="campaignName"
                  value={formik.values.campaignName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.campaignName &&
                    Boolean(formik.errors.campaignName)
                  }
                  helperText={
                    formik.touched.campaignName && formik.errors.campaignName
                  }
                />
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.chamberShopLocal.tooltip.campaignName
                    }
                    showIt
                  />
                </div>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <FormControl className={classes.notificationDescriptionInput}>
                  <TextField
                    className={classes.selectedValue}
                    maxRows={5}
                    label={enumStrings.chamberShopLocal.label.prizeDescription}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        maxLength: CHARACTER_LIMIT_DESCRIPTION,
                      },
                    }}
                    value={formik.values.prizeDescription}
                    onChange={formik.handleChange}
                    multiline
                    id="prizeDescription"
                    name="prizeDescription"
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.chamberShopLocal.tooltip.prizeDescription
                    }
                    showIt
                  />
                </div>
              </div>
              <InputLabel style={{ margin: "auto" }}>
                {`${
                  (formik.values.prizeDescription || "").length
                }/${CHARACTER_LIMIT_DESCRIPTION} ${
                  enumStrings.shopLocal.createShopLocalCampaign.charRemaining
                }`}
              </InputLabel>
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "0px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {formik.touched.prizeDescription &&
                  formik.errors.prizeDescription}
              </FormHelperText>
              <div className={classes.descriptionPreviewText}>
                <h3>
                  <span style={{ fontWeight: "bold" }}>
                    {
                      enumStrings.shopLocal.updateChamberShopLocalCampaign
                        .contestPreviewMsg
                    }{" "}
                    <br />
                  </span>
                  {enumStrings.shopLocaldescription} {chamberInfo?.name}{" "}
                  {enumStrings.shopLocaldescription2}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {formik.values.prizeDescription.length > 0
                      ? formik.values.prizeDescription
                      : enumStrings.shopLocal.createShopLocalCampaign.enterText}
                  </span>
                </h3>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <ShopLocalCampaignEndDatePicker
                  formikErrors={formik.errors.startDateUtcMilli}
                  formikVal={formik.values.startDateUtcMilli}
                  formikSetFieldValue={formik.setFieldValue}
                  startAfterDate={Date.now()}
                  isForStartDate
                />
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.chamberShopLocal.tooltip.startDateUtcMilli
                    }
                    showIt
                  />
                </div>
              </div>
              <div className={classes.companyInputWithToolTipContainer}>
                <ShopLocalCampaignEndDatePicker
                  formikErrors={formik.errors.endDateUtcMilli}
                  formikVal={formik.values.endDateUtcMilli}
                  formikSetFieldValue={formik.setFieldValue}
                  disabled={!(formik.values.startDateUtcMilli > 0)}
                  startAfterDate={formik.values.startDateUtcMilli}
                />
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={
                      enumStrings.chamberShopLocal.tooltip.endDateUtcMilli
                    }
                    showIt
                  />
                </div>
              </div>
              <ChooseParticipatingMembers
                selectedChamberMembers={formik.values.participatingMembers}
                handleChamberMembersChange={handleChamberMembersChange}
                allChamberMemberData={allChamberMemberData}
                formikTouchedAvailableToChamberIds={
                  formik.touched.participatingMembers
                }
                formikErrorsAvailableToChamberIds={
                  formik.errors.participatingMembers
                }
              />
              <br />
              <br />
              <div>
                {invalidError ? (
                  <div className={classes.invalidError}>{customError}</div>
                ) : null}
              </div>{" "}
              {Object.keys(formik?.errors || {}).length > 0 ? (
                <div>
                  <div className={classes.invalidError}>
                    {enumStrings.warningToCreate}
                  </div>
                </div>
              ) : null}
              <br />
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#E1A731",
                  fontSize: "23px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "200px",
                  marginTop: "59px",
                  marginBottom: "39px",
                  margin: "auto",
                  height: "65px",
                }}
                disabled={buttonDisabled || loader}
              >
                {loader ? (
                  <CircularProgress />
                ) : (
                  enumStrings.shopLocal.createShopLocalCampaign.create
                )}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateChamberShopLocalCampaign;
