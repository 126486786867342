import React, { ReactElement, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Loader from "../Loader/Loader";
import { CSVLink } from "react-csv";
import { useEnumStrings } from "../../enumStrings";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";

import * as yup from "yup";
import { useChamberChangeAdminConsoleLoginEmailMutation } from "../../../graphql/types";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => {};
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidError: {
      textAlign: "center",
      color: "red",
    },
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      width: "500px",
      minHeight: "300px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    customButton: {
      margin: "10px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    companyInput: {
      backgroundColor: "#F2F2F6",
      paddingBottom: "10px",
      borderRadius: "15px",
      marginTop: "50px",
      width: "431px",
      height: "55px",
      maxWidth: "85%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
          borderColor: "black",
          borderRadius: "15px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#E1A731",
        },
      },
      "& label.Mui-focused": {
        color: "#E1A731",
      },
      "& label.Mui": {
        color: "black",
      },
    },
  })
);

const CSVDownloadButton = ({
  password,
  username,
  chamberName,
  handleClose,
}: {
  password: string;
  username: string;
  chamberName: string;
  handleClose: () => void;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const csvData = [
    ["Dashboard Email Username", "Dashboard Temporary Password"],
    [username, password],
  ];
  return (
    <div>
      <CSVLink
        id="change-email-username-modal"
        data={csvData}
        filename={`${chamberName} Dashboard Credentials.csv`}
        style={{
          textDecoration: "none",
        }}
      >
        <Button
          className={classes.yesButton}
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          {enumStrings.chamberModal.downloadText}
        </Button>
      </CSVLink>
      <Button
        className={classes.yesButton}
        variant="contained"
        onClick={() => {
          handleClose();
        }}
      >
        {enumStrings.shopLocal.createShopLocalCampaign.close}
      </Button>
    </div>
  );
};

const initialValues = {
  adminConsoleLoginEmail: "",
};

const ChangeEmailUsernameModal = ({
  chamberId,
  currentAdminConsoleLoginEmail,
  open,
  handleClose,
  chamberName,
}: {
  chamberId: string | undefined;
  currentAdminConsoleLoginEmail: string | undefined;
  open: boolean;
  handleClose: () => void;
  chamberName: string;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState(
    enumStrings.shopLocal.updateChamberShopLocalCampaign.anErrorOccurred
  );
  const [
    loadingChangingAdminConsoleLoginEmailModal,
    setLoadingChangingAdminConsoleLoginEmailModal,
  ] = useState(false);
  const [
    newPasswordFromAdminConsoleLoginEmailChange,
    setNewPasswordFromAdminConsoleLoginEmailChange,
  ] = useState("");
  const [
    successfullyChangedAdminConsoleLoginEmail,
    setSuccessfullyChangedAdminConsoleLoginEmail,
  ] = useState(false);

  const [chamberChangeAdminConsoleLoginEmail] =
    useChamberChangeAdminConsoleLoginEmailMutation();

  const validationSchema = yup.object({
    adminConsoleLoginEmail: yup
      .string()
      .email(enumStrings.adminConsoleLoginEmail.email)
      .required(enumStrings.adminConsoleLoginEmail.required),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input) => {
      if (!chamberId || !currentAdminConsoleLoginEmail) {
        return;
      }

      setLoadingChangingAdminConsoleLoginEmailModal(true);
      // api call here
      const { data } = await chamberChangeAdminConsoleLoginEmail({
        variables: {
          chamberId: chamberId,
          currentAdminConsoleLoginEmail: currentAdminConsoleLoginEmail,
          newAdminConsoleLoginEmail: input.adminConsoleLoginEmail
            .trim()
            .toLowerCase(),
        },
        refetchQueries: ["getAdminConsoleLoginEmailForChamber"],
        awaitRefetchQueries: true,
      });

      if (
        data?.chamberChangeAdminConsoleLoginEmail
          .adminConsoleLoginEmailChangedSuccessfully === false
      ) {
        setInvalidError(true);
        setCustomError(
          `Unable to change dashboard email username. ${
            (
              data?.chamberChangeAdminConsoleLoginEmail.failureReason || ""
            ).includes("UsernameExistsException")
              ? "An account with the given email already exists."
              : data?.chamberChangeAdminConsoleLoginEmail.failureReason || ""
          }`
        );
        setLoadingChangingAdminConsoleLoginEmailModal(false);
        return;
      }

      setNewPasswordFromAdminConsoleLoginEmailChange(
        data?.chamberChangeAdminConsoleLoginEmail.password || ""
      );
      setSuccessfullyChangedAdminConsoleLoginEmail(
        data?.chamberChangeAdminConsoleLoginEmail
          .adminConsoleLoginEmailChangedSuccessfully || false
      );
      setLoadingChangingAdminConsoleLoginEmailModal(false);

      const button = document.getElementById("change-email-username-modal");
      button?.click();
    },
  });

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={
          loadingChangingAdminConsoleLoginEmailModal ? () => null : handleClose
        }
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          {loadingChangingAdminConsoleLoginEmailModal ? (
            <Loader />
          ) : newPasswordFromAdminConsoleLoginEmailChange.length > 0 &&
            successfullyChangedAdminConsoleLoginEmail ? (
            <>
              <h3
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {
                    enumStrings.changeEmailUsernameModal.successMessage
                      .boldMessage
                  }
                </span>
                {"\n\n"}
                {
                  enumStrings.changeEmailUsernameModal.successMessage
                    .description
                }
                {"\n\n"}
                <span style={{ fontWeight: "bold" }}>
                  {enumStrings.changeEmailUsernameModal.successMessage.note}
                </span>
                {"\n\n"}
              </h3>
              <CSVDownloadButton
                username={formik.values.adminConsoleLoginEmail.trim()}
                password={newPasswordFromAdminConsoleLoginEmailChange}
                chamberName={chamberName}
                handleClose={handleClose}
              />
            </>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <h3 style={{ marginTop: "25px", marginBottom: "-15px" }}>
                {enumStrings.changeEmailUsernameModal.formMessage}
              </h3>
              <TextField
                type="text"
                label="New Dashboard Email Username *"
                className={classes.companyInput}
                variant="outlined"
                id="adminConsoleLoginEmail"
                name="adminConsoleLoginEmail"
                value={formik.values.adminConsoleLoginEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.adminConsoleLoginEmail &&
                  Boolean(formik.errors.adminConsoleLoginEmail)
                }
                helperText={
                  formik.touched.adminConsoleLoginEmail &&
                  formik.errors.adminConsoleLoginEmail
                }
              />
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
              <div style={{ paddingTop: "20px" }}>
                <Button
                  type="submit"
                  className={classes.yesButton}
                  variant="contained"
                >
                  {enumStrings.changeEmailUsernameModal.buttons.update}
                </Button>
                <Button
                  onClick={handleClose}
                  className={classes.noButton}
                  variant="contained"
                >
                  {enumStrings.changeEmailUsernameModal.buttons.cancel}
                </Button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChangeEmailUsernameModal;
