import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import {
  DiscountReturn,
  useGetAllDiscountsFromChamberMemberIdQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import useGetChamberMemberFromUserIdInAuth from "../../common/Hooks/useGetChamberMemberFromUserIdInAuth";
import { useHistory, useLocation } from "react-router";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import { useGetOfferedToText } from "../../common/Hooks/useGetOfferedToText";
import { UserTypeContext } from "../../../UserTypeContext";
import VideoTutorialButton from "../../common/VideoTutorialButton";

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginRight: "24px",
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "20px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {},
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "433px",
      justifyContent: "center",
      margin: "auto",
    },
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: "33px",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  leftSide: {
    display: "flex",
  },
  leftBox: {
    width: "40px",
    backgroundColor: "#E1A731",
    borderRadius: "10px 0px 0px 10px",
  },
  textTag: {
    padding: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "189px",
    backgroundColor: "#F2F2F6",
    fontSize: "23px",
    [theme.breakpoints.down("sm")]: {
      padding: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      width: "189px",
      backgroundColor: "#F2F2F6",
      fontSize: "17px",
    },
  },
  middle: {
    margin: "auto",
    fontSize: "27px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  rightSide: {
    display: "flex",
  },
  rightBox: {
    width: "40px",
    backgroundColor: "#737373",
    borderRadius: "10px 0px 0px 10px",
  },

  discountTicketText: {
    textAlign: "center",
    fontSize: "37px",
    wordWrap: "break-word",
    flex: 2,
    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px",
    },
  },
  DiscountContainer: {
    color: "black",
    fontWeight: "bold",
    flexDirection: "column",
    height: "82vh",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginTop: "45px",
  },
}));

const ChamberMemberListDiscounts = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const history = useHistory();
  const location = useLocation();
  const getOfferedToText = useGetOfferedToText();
  const bingo = useMemo(() => {
    return (location.state || { showToast: undefined }) as {
      showToast: boolean | undefined;
    };
  }, [location.state]);
  useEffect(() => {
    if (bingo.showToast) {
      toast.success(enumStrings.toastPerkText);
    }
  }, [bingo.showToast, enumStrings.toastPerkText]);

  let chamberMemberInfo = useGetChamberMemberFromUserIdInAuth();
  const { data: discountData, loading } =
    useGetAllDiscountsFromChamberMemberIdQuery({
      variables: { chamberMemberId: chamberMemberInfo?.id || "" },
      skip: !chamberMemberInfo?.id,
      fetchPolicy: "cache-and-network",
    });

  const updateADiscountRoute = RedirectUri.editDiscountChamberMemberAdmin;
  const redirectToUpdateDiscount = useCallback(
    (discountInfo: DiscountReturn) => {
      history.push(updateADiscountRoute, discountInfo);
    },
    [history, updateADiscountRoute]
  );

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const rootAdminObj =
    enumStrings.groups[UserType.ChamberMemberAdmin][
      RedirectUri.manageDiscountChamberMemberAdmin
    ];

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <div style={{ height: 12 }} />
          {userType === UserType.ChamberMemberAdmin && (
            <VideoTutorialButton
              label={enumStrings.CreateDiscount.HowToSearchAndRedeemPerks}
              tutorialUrl={`https://share.synthesia.io/embeds/videos/784da11f-0d07-4066-987b-2e92e95713aa`}
            />
          )}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.settingsButtonContainer}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <div className={classes.background}>
                <div className={classes.DiscountContainer}>
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></div>
                  {loading || discountData === undefined ? (
                    <Loader />
                  ) : (
                    <div
                      style={{
                        color: " black",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      {
                        // @ts-ignore
                        !discountData?.getAllDiscountsFromChamberMemberId ? (
                          <div
                            style={{
                              color: " black",
                              textAlign: "center",
                              margin: "auto",
                            }}
                          >
                            {enumStrings.discountText.discountUnavailableText}{" "}
                          </div>
                        ) : null
                      }
                    </div>
                  )}
                  {discountData ? (
                    discountData?.getAllDiscountsFromChamberMemberId?.map(
                      (discountData, index) => {
                        return (
                          <div
                            onClick={() =>
                              // @ts-ignore
                              redirectToUpdateDiscount(discountData)
                            }
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              position: "relative",
                              wordWrap: "break-word",
                              minHeight: "128px",
                              marginTop: "41px",
                              borderRadius: "17px",
                              backgroundColor: "#F2F2F6",
                              cursor: "pointer",
                              maxWidth: "559px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "20px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "15px",
                                textAlign: "start",
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                whiteSpace: "initial",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                gap: "10px",
                              }}
                            >
                              <div className={classes.discountTicketText}>
                                <div>{discountData.title}</div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>
                                    {enumStrings.discountText.viewsText}
                                    <span style={{ fontWeight: "normal" }}>
                                      {discountData.views
                                        ? discountData.views
                                        : 0}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  <span>
                                    {enumStrings.discountText.offeredToText}
                                    <span style={{ fontWeight: "normal" }}>
                                      {discountData
                                        ? getOfferedToText(
                                            discountData as DiscountReturn,
                                            true
                                          )
                                        : ""}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ChamberMemberListDiscounts;
