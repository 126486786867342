import React, { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

interface TimeSeriesDataPoint {
  timestamp: string; // ISO string for date or 'YYYY-MM' for monthly data
  value: number;
}

interface DataSeries {
  id: string;
  name: string;
  data: TimeSeriesDataPoint[];
  color?: string;
}

interface CommonLineChartProps {
  title?: string;
  series: DataSeries[];
  isMonthly?: boolean;
  height?: number;
  showLegend?: boolean;
  enableSeriesSelector?: boolean;
  defaultSelectedSeries?: string[];
  showAverageLineFor?: string;
  isCurrency?: boolean;
}

const formatDate = (timestamp: string, isMonthly: boolean) => {
  if (isMonthly) {
    const [year, month] = timestamp.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });
  }
  return new Date(timestamp).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

// Format value function to handle currency
const formatValue = (value: number, isCurrency: boolean): string => {
  if (isCurrency) {
    return `$${value.toLocaleString()}`;
  }
  return value.toString();
};

export const CommonLineChart: React.FC<CommonLineChartProps> = ({
  title,
  series,
  isMonthly = false,
  height = 400,
  showLegend = true,
  enableSeriesSelector = false,
  defaultSelectedSeries,
  showAverageLineFor,
  isCurrency = false,
}) => {
  const [selectedSeriesIds, setSelectedSeriesIds] = useState<string[]>(
    defaultSelectedSeries || series.map((s) => s.id)
  );

  // Map data with formatted values for display
  const filteredSeries = series
    .filter((s) => selectedSeriesIds.includes(s.id))
    .map((s) => ({
      data: s.data.map((d) => d.value),
      label: s.name,
      color: s.color,
      valueFormatter: (value: number) => formatValue(value, isCurrency),
    }));

  // Get x-axis labels from the first series (assuming all series have same timestamps)
  const xLabels =
    series[0]?.data.map((point) => formatDate(point.timestamp, isMonthly)) ||
    [];

  // Calculate average from all visible series
  const allValues = filteredSeries.flatMap((s) => s.data);

  // Calculate average from specific series if showAverageLineFor is provided
  const selectedSeries = showAverageLineFor
    ? series.find((s) => s.id === showAverageLineFor)
    : null;

  const averageValue = selectedSeries
    ? selectedSeries.data.reduce((sum, point) => sum + point.value, 0) /
      selectedSeries.data.length
    : allValues.length > 0
    ? allValues.reduce((sum, val) => sum + val, 0) / allValues.length
    : 0;

  const average = averageValue.toFixed(0);
  const formattedAverage = isCurrency ? `$${average}` : average;

  // Create average line series
  const averageSeries = {
    data: new Array(xLabels.length).fill(average),
    label: `Average (${formattedAverage})`,
    color: "#989898",
    showMark: false,
    connectNulls: true,
    valueFormatter: (value: number) => formatValue(value, isCurrency),
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {title && (
          <h2
            style={{
              marginBottom: "0px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {title}
          </h2>
        )}

        {/* Display legend as separate element above chart */}
        {showLegend && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 2,
              marginTop: 2,
              paddingRight: "10px",
              paddingLeft: "10px",
              width: "100%",
              fontSize: "16px",
              color: "black",
              fontWeight: "normal",
            }}
          >
            {series.map((s) => (
              <Box
                key={s.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 1,
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "16px",
                    height: "16px",
                    backgroundColor: s.color || "#ccc",
                    marginRight: "8px",
                  }}
                />
                <span>{s.name}</span>
              </Box>
            ))}
            {/* Add average line to legend */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mx: 1,
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "16px",
                  height: "3px",
                  backgroundColor: "#989898",
                  marginRight: "8px",
                }}
              />
              <span>{`Average${
                selectedSeries ? ` ${selectedSeries.name} ` : ` `
              }(${formattedAverage})`}</span>
            </Box>
          </Box>
        )}

        {enableSeriesSelector && (
          <FormControl
            sx={{
              maxWidth: 450,
              width: "100%",
              marginTop: "30px",
              marginBottom: "0px",
            }}
            size="small"
          >
            <InputLabel
              id="series-select-label"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                "&.Mui-focused": {
                  color: "#E1A731",
                },
              }}
            >
              Select Series
            </InputLabel>
            <Select
              labelId="series-select-label"
              id="series-select"
              multiple
              value={selectedSeriesIds}
              label="Select Series"
              sx={{
                minHeight: 40,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E1A731",
                },
              }}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedSeriesIds(
                  typeof value === "string" ? [value] : value
                );
              }}
            >
              {series.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <LineChart
        height={height}
        series={[...filteredSeries, averageSeries]}
        xAxis={[
          {
            scaleType: "point",
            data: xLabels,
          },
        ]}
        yAxis={[
          {
            valueFormatter: (value: number) =>
              isCurrency ? `$${value.toLocaleString()}` : `${value}`,
          },
        ]}
        sx={{
          ".MuiLineElement-root": {
            strokeWidth: 2,
          },
          ".MuiMarkElement-root": {
            stroke: "#fff",
            scale: "0.6",
            fill: "currentColor",
          },
        }}
        slotProps={{
          legend: {
            hidden: true, // Hide the built-in legend
          },
        }}
      />
    </Box>
  );
};

export default CommonLineChart;
