import React, { ReactElement, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import {
  LanguageCodeEnum,
  useUpdateLoggedInUserLanguageMutation,
} from "../../../graphql/types";

import "react-toastify/dist/ReactToastify.css";

import { Radio, RadioGroup, FormControlLabel, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useGetLoggedInUserLanguageFromUserIdInAuth from "../../common/Hooks/useGetLoggedInUserLanguageFromUserIdInAuth";

import { useLocales } from "../../../locales/LocalizationContext";
import { Box } from "@mui/system";
import { useEnumStrings } from "../../enumStrings";

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},
  languageRadioGroup: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "5px",
    width: "100%",
  },
  radio: {
    color: "#E1A731!important",
  },
  title: {
    marginRight: "24px",
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },

  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    flexGrow: 1,
    height: "85vh",
    borderRadius: "16px",
    margin: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
  },
}));
// Define Yup validation schema
const validationSchema = Yup.object({
  language: Yup.string().required(""),
});

const GeneralSettings = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [initialLanguage, setInitialLanguage] = useState<string | null>("");
  const loggedInUserLanguageCode = useGetLoggedInUserLanguageFromUserIdInAuth();
  const [updateUserLanguage] = useUpdateLoggedInUserLanguageMutation();

  const { allLangs, onChangeLang, currentLang } = useLocales();

  const handleLanguageChange = async (languageCode: string) => {
    try {
      const selectedLanguage =
        languageCode === "en" ? LanguageCodeEnum.En : LanguageCodeEnum.Fr;

      onChangeLang(languageCode);
      await updateUserLanguage({
        variables: { languageCode: selectedLanguage },
        refetchQueries: ["getUser"],
      });

      setInitialLanguage(languageCode); // Update UI state dynamically
    } catch (error) {
      console.error("Error updating language:", error);
    }
  };

  useEffect(() => {
    // Set initial language from context if available, otherwise from backend
    if (currentLang) {
      setInitialLanguage(typeof currentLang === "string" ? currentLang : "en"); // Fallback to English if currentLang is not a string
    } else if (loggedInUserLanguageCode) {
      setInitialLanguage(loggedInUserLanguageCode.toLowerCase());
    }
  }, [loggedInUserLanguageCode, currentLang]);

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{enumStrings.settings.settingTitle}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          ></span>
        </div>
      </div>
      <div className={classes.content}>
        {!initialLanguage ? (
          <div>{enumStrings.settings.loading}</div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{ language: initialLanguage }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleLanguageChange(values.language)}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <h2>{enumStrings.settings.chooseLanguage}</h2>
                </div>

                <Box sx={{ p: 3 }}>
                  <RadioGroup
                    row
                    name="language"
                    className={classes.languageRadioGroup}
                    value={values.language}
                    onChange={(event) =>
                      setFieldValue("language", event.target.value)
                    }
                  >
                    {allLangs.map((lang) => (
                      <FormControlLabel
                        key={lang.label}
                        value={lang.value}
                        label={
                          lang.translatedLabel[
                            values.language as keyof typeof lang.translatedLabel
                          ]
                        }
                        control={<Radio className={classes.radio} />}
                      />
                    ))}
                  </RadioGroup>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#E1A731",
                    fontSize: "25px",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    width: "200px",
                    margin: "39px",
                    marginTop: "10px",
                  }}
                >
                  {enumStrings.settings.update}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </span>
  );
};

export default GeneralSettings;
