import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {
  GetAllMemberLoginCredentialsDocument,
  GetAllMemberLoginCredentialsQuery,
  GetAllMemberLoginCredentialsStatusEnum,
  LoginInfoEmailTypes,
  useCreateAllMemberLoginCredentialsMutation,
  useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery,
  useGetAllMemberMassSendLoginCredentialTimestampUtcMilliQuery,
  useGetSampleLoginInfoEmailQuery,
  useMassSendLoginInfoEmailMutation,
} from "../../../graphql/types";
import { useEnumStrings } from "../../enumStrings";
import { getApolloContext } from "@apollo/client";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { CircularProgress, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SampleLoginInfoEmailModal } from "../../common/SendLoginInformationModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      overflow: "scroll",
      maxHeight: "100%",
      borderRadius: "5px",
      minHeight: "500px",
      textAlign: "center",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      color: "inherit",
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      minWidth: "200px",
      margin: "10px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
      textTransform: "none",
    },
    okButton: {
      minWidth: "200px",
      margin: "10px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
      textTransform: "none",
    },
    centeredText: {
      textAlign: "center",
      width: "100%",
    },
    linkText: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "inherit",
    },
  })
);

const DownloadSuccessModal = ({
  open,
  handleClose,
  rawCSVText,
  numOfMembersNotLoggedInYet,
}: {
  open: boolean;
  handleClose: () => void;
  rawCSVText: string;
  numOfMembersNotLoggedInYet: number;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const handleManualDownloadOfCsv = useCallback(() => {
    if (rawCSVText) {
      const blob = new Blob([rawCSVText], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        enumStrings.CSVDownloadNotification.download,
        enumStrings.CSVDownloadNotification.CredentialsCsv
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [
    enumStrings.CSVDownloadNotification.CredentialsCsv,
    enumStrings.CSVDownloadNotification.download,
    rawCSVText,
  ]);

  useEffect(() => {
    handleManualDownloadOfCsv();
    // empty dependency array cuz only wanna run this once when the modal opens ... bruh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {enumStrings.CSVDownloadNotification.downloadsuccess}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0, textAlign: "left" }}>
              {enumStrings.CSVDownloadNotification.title}{" "}
              <span style={{ fontWeight: "bold" }}>
                {numOfMembersNotLoggedInYet}
              </span>{" "}
              {enumStrings.CSVDownloadNotification.numOfMembersNotLoggedInYet}{" "}
              <span style={{ fontWeight: "bold" }}>
                {enumStrings.CSVDownloadNotification.notLoggedInYet}
              </span>{" "}
              {enumStrings.CSVDownloadNotification.activeMembershipStatus}
              <br />
              <br />
              {enumStrings.CSVDownloadNotification.downloadInfo}
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.yesButton}
            onClick={handleManualDownloadOfCsv}
            variant="contained"
          >
            {enumStrings.CSVDownloadNotification.download}
          </Button>
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.CSVDownloadNotification.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DownloadPendingModal = ({
  open,
  setAllMemberLoginCredentialsRawCSVText,
  setDownloadFailureModalOpen,
}: {
  open: boolean;
  setAllMemberLoginCredentialsRawCSVText: (value: string) => void;
  setDownloadFailureModalOpen: (value: boolean) => void;
}) => {
  const classes = useStyles();

  const enumStrings = useEnumStrings();

  const modalOpenTime = useMemo(() => {
    return new Date();
  }, []);

  const apolloClient = useContext(getApolloContext());

  const handleAllMembereLoginCredentialsDownload = useCallback(
    async ({ downloadProcessId }: { downloadProcessId: string }) => {
      // if 15 minutes have passed since the modal opened, close the modal
      if (new Date().getTime() - modalOpenTime.getTime() > 15 * 60 * 1000) {
        setDownloadFailureModalOpen(true);
        return;
      }

      if (downloadProcessId && downloadProcessId.length > 0) {
        const allMemberLoginCredentialsData = (await apolloClient.client?.query(
          {
            query: GetAllMemberLoginCredentialsDocument,
            variables: {
              processId: downloadProcessId,
            },
            fetchPolicy: "network-only",
          }
        )) as {
          data: GetAllMemberLoginCredentialsQuery | undefined;
        };

        if (
          allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
            ?.status
        ) {
          const downloadStatus =
            allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
              ?.status;

          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Processing
          ) {
            // pause for 5 seconds before trying again
            await new Promise((resolve) => setTimeout(resolve, 5000));

            await handleAllMembereLoginCredentialsDownload({
              downloadProcessId,
            });
            return;
          }
          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Success
          ) {
            if (
              allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
                ?.successCSVRawTextOfAllMemberLoginCredentials
            ) {
              setAllMemberLoginCredentialsRawCSVText(
                allMemberLoginCredentialsData?.data
                  ?.getAllMemberLoginCredentials
                  ?.successCSVRawTextOfAllMemberLoginCredentials
              );
            } else {
              setDownloadFailureModalOpen(true);
            }
            return;
          }
          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Failure
          ) {
            setDownloadFailureModalOpen(true);
            return;
          }
        }
      }
    },
    [
      apolloClient.client,
      modalOpenTime,
      setAllMemberLoginCredentialsRawCSVText,
      setDownloadFailureModalOpen,
    ]
  );

  const [createAllMemberLoginCredentialsMutation] =
    useCreateAllMemberLoginCredentialsMutation();

  useEffect(() => {
    const executeDownloadAllMemberLoginCredentials = async () => {
      const createRes = await createAllMemberLoginCredentialsMutation({
        refetchQueries: [
          "getAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli",
        ],
      });
      if (createRes.data?.createAllMemberLoginCredentials?.processId) {
        handleAllMembereLoginCredentialsDownload({
          downloadProcessId:
            createRes.data.createAllMemberLoginCredentials.processId,
        });
      }
    };
    executeDownloadAllMemberLoginCredentials();
    // empty dependency array cuz only wanna run this once when the modal opens ... bruh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {" "}
          {enumStrings.DownloadMemberCredentials.downloadInProgress}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>
              {enumStrings.DownloadMemberCredentials.description}
              <br />
              <br />

              {enumStrings.DownloadMemberCredentials.downloadIn5}
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#E1A731",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const EmailPendingModal = ({
  open,
  setEmailSuccessModalOpen,
  setEmailFailureModalOpen,
}: {
  open: boolean;
  setEmailSuccessModalOpen: (value: boolean) => void;
  setEmailFailureModalOpen: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [massSendLoginInfoEmail] = useMassSendLoginInfoEmailMutation();

  useEffect(() => {
    const executeMassEmailAllMemberLoginCredentials = async () => {
      try {
        // Get all chamber members who haven't logged in yet
        const { data } = await massSendLoginInfoEmail({
          refetchQueries: [
            "getChamberMembers",
            "getChamber",
            "getAllMemberMassSendLoginCredentialTimestampUtcMilli",
          ],
        });

        if (data?.massSendLoginInfoEmail) {
          setEmailSuccessModalOpen(true);
        } else {
          setEmailFailureModalOpen(true);
        }
      } catch (error) {
        console.error("Error sending mass emails:", error);
        setEmailFailureModalOpen(true);
      }
    };

    executeMassEmailAllMemberLoginCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {enumStrings.DownloadMemberCredentials.emailPending.title}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0, textAlign: "left" }}>
              {enumStrings.DownloadMemberCredentials.emailPending.description}
              <br />
              <br />
              {enumStrings.DownloadMemberCredentials.description}
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#E1A731",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const EmailSuccessModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {enumStrings.DownloadMemberCredentials.emailSuccess.title}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0, textAlign: "left" }}>
              {enumStrings.DownloadMemberCredentials.emailSuccess.description}
              <br />
              <br />
              {
                enumStrings.DownloadMemberCredentials.emailSuccess
                  .additionalInfo
              }
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.CSVDownloadNotification.close}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const EmailConfirmationModal = ({
  open,
  handleClose,
  handleConfirm,
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {enumStrings.DownloadMemberCredentials.emailConfirmation.title}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            {/* @ts-ignore */}
            <h3 style={{ margin: 0, textAlign: "center", textWrap: "balance" }}>
              {enumStrings.DownloadMemberCredentials.emailConfirmation.message}
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.yesButton}
            onClick={handleConfirm}
            variant="contained"
          >
            {
              enumStrings.DownloadMemberCredentials.emailConfirmation
                .confirmButton
            }
          </Button>
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {
              enumStrings.DownloadMemberCredentials.emailConfirmation
                .cancelButton
            }
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DownloadFailureModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          {enumStrings.downloadFailureModal.title}
        </h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>
              {enumStrings.downloadFailureModal.message}
              <br />
              {enumStrings.downloadFailureModal.pleaseContact}{" "}
              {enumStrings.supportContactEmail}{" "}
              {enumStrings.downloadFailureModal.thisIsTheError}
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            {enumStrings.downloadFailureModal.closeButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DownloadAllMemberLoginCredentialsModal = ({
  open,
  handleClose,
  numOfMembersNotLoggedInYet,
}: {
  open: boolean;
  handleClose: () => void;
  numOfMembersNotLoggedInYet: number;
}): ReactElement => {
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const classes = useStyles();
  const enumStrings = useEnumStrings();

  const [downloadSuccessModalOpen, setDownloadSuccessModalOpen] =
    useState(false);

  const [downloadPendingModalOpen, setDownloadPendingModalOpen] =
    useState(false);

  const [downloadFailureModalOpen, setDownloadFailureModalOpen] =
    useState(false);

  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] =
    useState(false);
  const [emailPendingModalOpen, setEmailPendingModalOpen] = useState(false);

  const [emailSuccessModalOpen, setEmailSuccessModalOpen] = useState(false);

  const [emailFailureModalOpen, setEmailFailureModalOpen] = useState(false);

  const [sampleLoginInfoEmailModalOpen, setSampleLoginInfoEmailModalOpen] =
    useState(false);

  const closeSampleLoginInfoEmailModal = useCallback(() => {
    setSampleLoginInfoEmailModalOpen(false);
  }, []);

  const [rawCSVText, setRawCSVText] = useState("");

  const setAllMemberLoginCredentialsRawCSVText = useCallback(
    (value: string) => {
      setRawCSVText(value);
      setDownloadSuccessModalOpen(true);
    },
    [setRawCSVText, setDownloadSuccessModalOpen]
  );

  const {
    data: allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData,
  } = useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery({
    variables: {
      chamberId: chamberInfo?.id || "",
    },
    skip: !chamberInfo?.id,
  });

  const { data: allMemberMassSendLoginCredentialTimestampUtcMilliData } =
    useGetAllMemberMassSendLoginCredentialTimestampUtcMilliQuery({
      variables: {
        chamberId: chamberInfo?.id || "",
      },
      skip: !chamberInfo?.id,
    });

  // Sample email preview functionality
  const { data: getSampleLoginEmail } = useGetSampleLoginInfoEmailQuery({
    variables: {
      type: LoginInfoEmailTypes.BrandNew,
      chamberMemberId: "", // For mass email preview, we don't need a specific member ID
    },
  });

  return (
    <>
      {downloadPendingModalOpen ? (
        <DownloadPendingModal
          open={downloadPendingModalOpen}
          setAllMemberLoginCredentialsRawCSVText={
            setAllMemberLoginCredentialsRawCSVText
          }
          setDownloadFailureModalOpen={setDownloadFailureModalOpen}
        />
      ) : null}
      {downloadSuccessModalOpen && rawCSVText && rawCSVText.length > 0 ? (
        <DownloadSuccessModal
          open={downloadSuccessModalOpen}
          handleClose={handleClose}
          rawCSVText={rawCSVText}
          numOfMembersNotLoggedInYet={numOfMembersNotLoggedInYet}
        />
      ) : null}
      {emailConfirmationModalOpen && (
        <EmailConfirmationModal
          open={emailConfirmationModalOpen}
          handleClose={() => setEmailConfirmationModalOpen(false)}
          handleConfirm={() => {
            setEmailConfirmationModalOpen(false);
            setEmailPendingModalOpen(true);
          }}
        />
      )}
      {emailPendingModalOpen ? (
        <EmailPendingModal
          open={emailPendingModalOpen}
          setEmailSuccessModalOpen={setEmailSuccessModalOpen}
          setEmailFailureModalOpen={setEmailFailureModalOpen}
        />
      ) : null}
      {emailSuccessModalOpen ? (
        <EmailSuccessModal
          open={emailSuccessModalOpen}
          handleClose={handleClose}
        />
      ) : null}
      <DownloadFailureModal
        open={downloadFailureModalOpen || emailFailureModalOpen}
        handleClose={handleClose}
      />
      <SampleLoginInfoEmailModal
        open={sampleLoginInfoEmailModalOpen}
        handleClose={closeSampleLoginInfoEmailModal}
        emailHtml={getSampleLoginEmail?.getSampleLoginInfoEmail?.emailHtml}
        modalType={"memberTable"}
      />
      <Modal
        className={classes.modal}
        style={{
          ...(downloadFailureModalOpen ||
          downloadSuccessModalOpen ||
          downloadPendingModalOpen ||
          emailConfirmationModalOpen ||
          emailPendingModalOpen ||
          emailSuccessModalOpen ||
          emailFailureModalOpen ||
          sampleLoginInfoEmailModalOpen
            ? { display: "none" }
            : {}),
        }}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <IconButton
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              color: "#37474f",
              fontWeight: "bold",
              borderRadius: "100px",
              padding: 5,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <h1 style={{ fontWeight: "bold" }}>
            {enumStrings.DownloadMemberCredentials.mainModal.title}
          </h1>

          {/* Primary content */}
          <div style={{ width: "100%", padding: "20px", paddingTop: "30px" }}>
            {/* Mass Email section */}
            <div style={{ textAlign: "left" }}>
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "19px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {enumStrings.sendLoginModal.option1Header}
              </p>
              <h3 style={{ margin: 0 }}>
                {
                  enumStrings.DownloadMemberCredentials.mainModal
                    .emailInstructions
                }
              </h3>
            </div>

            {/* Last Email Sent Timestamp */}
            <h3>
              {allMemberMassSendLoginCredentialTimestampUtcMilliData?.getChamber
                ?.allMemberMassSendLoginCredentialTimestampUtcMilli ? (
                <p style={{ marginTop: "20px" }}>
                  {
                    enumStrings.DownloadMemberCredentials.mainModal
                      .lastEmailSent
                  }{" "}
                  {getLocalTimestamp(
                    allMemberMassSendLoginCredentialTimestampUtcMilliData
                      .getChamber
                      .allMemberMassSendLoginCredentialTimestampUtcMilli,
                    true
                  )}
                </p>
              ) : (
                <p />
              )}
            </h3>

            {/* Email Button */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                className={classes.yesButton}
                onClick={() => {
                  setEmailConfirmationModalOpen(true);
                }}
                variant="contained"
              >
                {enumStrings.DownloadMemberCredentials.mainModal.emailButton}
              </Button>
              <span
                onClick={() => {
                  setSampleLoginInfoEmailModalOpen(true);
                }}
                className={classes.linkText}
              >
                {enumStrings.DownloadMemberCredentials.mainModal.previewLink}
              </span>
            </div>

            {/* Download Instructions */}
            <div
              style={{
                textAlign: "left",
                marginTop: "60px",
                marginBottom: "20px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "19px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {enumStrings.sendLoginModal.option2Header}
              </p>
              <h3 style={{ margin: 0 }}>
                {
                  enumStrings.DownloadMemberCredentials.mainModal
                    .downloadInstructions
                }
              </h3>
            </div>

            {/* Last Download Timestamp */}
            <h3>
              {allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData
                ?.getChamber
                ?.allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli ? (
                <p>
                  {
                    enumStrings.DownloadMemberCredentials.mainModal
                      .lastDownloaded
                  }{" "}
                  {getLocalTimestamp(
                    allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData
                      .getChamber
                      .allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli,
                    true
                  )}
                </p>
              ) : (
                <p />
              )}
            </h3>

            {/* Download Button */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.okButton}
                onClick={() => {
                  setDownloadPendingModalOpen(true);
                }}
                variant="contained"
              >
                {enumStrings.DownloadMemberCredentials.mainModal.downloadButton}
              </Button>
            </div>
            <br />
            <br />
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                textAlign: "start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {enumStrings.DownloadMemberCredentials.noteTitle}
              </span>{" "}
              {enumStrings.DownloadMemberCredentials.temporaryPasswordNote}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadAllMemberLoginCredentialsModal;
