import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Loader from "./Loader/Loader";
import { getLocalTimestamp } from "../pages/Root/RootAdmin";
import LogoImage from "./utilities/LogoImage";

type AllChamberMemberDataType = any[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number
): any[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  convertUtcToTimestamp?: boolean;
  onlyShowDate?: boolean;
  defaultSort?: boolean;
  sortOrder?: Order;
  splitOn?: string;
  isPercent?: boolean;
  isCurrency?: boolean;
  disableRightBorder?: boolean;
  utcMilliTimestampObjKey?: string;
  sticky?: boolean;
  perkDataCompanyAndLogo?: boolean;
  indicateDeltaFor?: string;
  defaultValue?: number;
}

interface FullPageChamberMemberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageChamberMemberTableHead(
  props: FullPageChamberMemberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              borderRight: headCell.disableRightBorder
                ? undefined
                : index + 1 < headCells.length
                ? "1px solid rgba(224, 224, 224, 1)"
                : undefined,
              padding: "8px",
              position: headCell.sticky ? "sticky" : undefined,
              left: headCell.sticky ? 0 : undefined,
              zIndex: headCell.sticky ? 5 : undefined,
            }}
            key={headCell.id}
            align={"center"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function RootAdminStatTable({
  allChartData,
  headCells,
  loading,
  chartSize = "default",
  overflowHorizontally,
  spreadDataIntoAllChartData,
}: {
  allChartData?: AllChamberMemberDataType | null;
  headCells: HeadCell[];
  loading: boolean;
  chartSize?: "default" | "medium" | "large" | "small";
  overflowHorizontally?: boolean;
  spreadDataIntoAllChartData?: {
    createObjFromId: string;
    idType: "arrOfObj";
    objKey: string;
    objValue: string;
  };
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>(headCells[0].id);

  React.useEffect(() => {
    for (const cell of headCells) {
      if (cell.defaultSort) {
        setOrderBy(cell.id);
        if (cell.sortOrder) {
          setOrder(cell.sortOrder);
        }
        break;
      }
    }

    return;
    // @ts-ignore
    // eslint-disable-next-line
  }, []); // this is left empty to ensure it ONLY runs on table mount and only then and never again

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(() => {
    let visibleChartData = [...(allChartData || [])];

    if (
      spreadDataIntoAllChartData &&
      spreadDataIntoAllChartData.idType === "arrOfObj"
    ) {
      const updatedVisibleChartData = [];

      for (let row of visibleChartData || []) {
        const newObjToAttach: Record<string, any> = {};
        for (const objToExtract of row[
          spreadDataIntoAllChartData.createObjFromId
        ]) {
          newObjToAttach[objToExtract[spreadDataIntoAllChartData.objKey]] =
            objToExtract[spreadDataIntoAllChartData.objValue];
        }
        row = {
          ...row,
          ...newObjToAttach,
        };
        updatedVisibleChartData.push(row);
      }
      visibleChartData = updatedVisibleChartData;
    }

    return stableSort(visibleChartData, getComparator(order, orderBy));
  }, [allChartData, order, orderBy, spreadDataIntoAllChartData]);

  const maxHeight = React.useMemo(() => {
    if (chartSize === "default") {
      return "400px";
    } else if (chartSize === "medium") {
      return "500px";
    } else if (chartSize === "small") {
      return "280px";
    }
    return "700px";
  }, [chartSize]);

  return (
    <Box
      sx={{
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "unset",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: maxHeight,
          }}
        >
          <Table
            sx={{
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
            style={{ tableLayout: overflowHorizontally ? undefined : "fixed" }}
          >
            <FullPageChamberMemberTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody
              style={{
                position: "relative",
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    width: "100%",
                    top: 20,
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <>
                  {visibleRows.map((rowData, index) => {
                    return (
                      <TableRow
                        onClick={
                          rowData?.perkDataCompanyAndLogo?.handleDiscountClick
                            ? rowData.perkDataCompanyAndLogo.handleDiscountClick
                            : undefined
                        }
                        style={{
                          cursor: rowData?.perkDataCompanyAndLogo
                            ?.handleDiscountClick
                            ? "pointer"
                            : undefined,
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {headCells.map(
                          (
                            {
                              id,
                              convertUtcToTimestamp,
                              onlyShowDate,
                              splitOn,
                              isPercent,
                              disableRightBorder,
                              utcMilliTimestampObjKey,
                              sticky,
                              perkDataCompanyAndLogo,
                              indicateDeltaFor,
                              defaultValue,
                              isCurrency,
                            },
                            index
                          ) => {
                            const rawColumnData = rowData[id];

                            let columnData = rawColumnData;
                            let logoUrl = undefined;
                            if (perkDataCompanyAndLogo) {
                              columnData = rawColumnData.name;
                              logoUrl = rawColumnData.logo;
                            }

                            if (splitOn) {
                              columnData = columnData
                                .split(splitOn)
                                .join(`${splitOn} `);
                            }

                            if (
                              defaultValue !== undefined &&
                              defaultValue !== null &&
                              (columnData === undefined || columnData === null)
                            ) {
                              columnData = defaultValue;
                            }

                            return (
                              <TableCell
                                size="medium"
                                style={{
                                  fontSize: "14px",
                                  borderRight: disableRightBorder
                                    ? undefined
                                    : index + 1 < headCells.length
                                    ? "1px solid rgba(224, 224, 224, 1)"
                                    : undefined,
                                  padding: "8px",
                                  color: indicateDeltaFor
                                    ? rowData[indicateDeltaFor] > 0
                                      ? "green"
                                      : rowData[indicateDeltaFor] < 0
                                      ? "red"
                                      : undefined
                                    : undefined,
                                  position: sticky ? "sticky" : undefined,
                                  left: sticky ? 0 : undefined,
                                  backgroundColor: sticky ? "white" : undefined,
                                }}
                                align={"center"}
                              >
                                <div
                                  style={{
                                    ...(perkDataCompanyAndLogo
                                      ? {
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                          textAlign: "left",
                                        }
                                      : {}),
                                  }}
                                >
                                  {perkDataCompanyAndLogo ? (
                                    <>
                                      <LogoImage
                                        logoSource={logoUrl}
                                        size="smallNormal"
                                        squared
                                        key={columnData}
                                        chamberName={columnData}
                                      />
                                      <div style={{ width: "15px" }} />
                                    </>
                                  ) : null}
                                  {indicateDeltaFor
                                    ? rowData[indicateDeltaFor] > 0
                                      ? "+"
                                      : ""
                                    : ""}
                                  {utcMilliTimestampObjKey
                                    ? rowData[utcMilliTimestampObjKey]
                                    : convertUtcToTimestamp
                                    ? getLocalTimestamp(
                                        columnData,
                                        onlyShowDate ? true : false
                                      )
                                    : isPercent
                                    ? columnData
                                    : isCurrency
                                    ? `$${columnData.toLocaleString()}`
                                    : columnData}
                                  {isPercent ? "%" : ""}
                                </div>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
