import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  makeStyles,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import theme from "../../../themes";
import { RedirectUri, useEnumStrings } from "../../enumStrings";
import { UserTypeContext } from "../../../UserTypeContext";
import {
  ChamberMemberInput,
  useDeleteChamberMemberMutation,
  useGetAdminConsoleLoginEmailForChamberMemberQuery,
  useGetChamberMemberQuery,
  UserType,
  useSetNewTemporaryChamberMemberPasswordMutation,
  useUpdateChamberMemberMutation,
} from "../../../graphql/types";
import * as yup from "yup";
import { useFormik } from "formik";
import { CircularProgress } from "@material-ui/core";
import ConfirmationModal, {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { useLocation } from "react-router-dom";
import useRedirect from "../../common/Hooks/useRedirect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Categories from "../../common/utilities/Categories";
import Loader from "../../common/Loader/Loader";
import useGetChamberMemberFromUserIdInAuth from "../../common/Hooks/useGetChamberMemberFromUserIdInAuth";
import PhoneNumberFormat from "../../common/utilities/PhoneNumberFormat";
import LogoPicker from "../../common/utilities/LogoPicker";
import ProvincePicker from "../../common/utilities/ProvincePicker";
import _without from "lodash/without";
import { CSVLink } from "react-csv";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import ChamberMemberChangeEmailUsernameModal from "../../common/ConfirmationModal/ChamberMemberChangeEmailUsernameModal";
import MultiContactForm, {
  getInitialStateOfCustomContacts,
} from "../../common/utilities/MultiContactForm";
import omitDeep from "omit-deep-lodash";
import SendLoginInformationModal from "../../common/SendLoginInformationModal";
import ChamberMemberIsSuspendedRadioGroup from "../../common/ChamberMemberIsSuspendedRadioGroup";
import BannerPicker from "../../common/BannerPicker";
import useIsMemberProfileAndPerksBannersActiveForChamberMember from "../../common/Hooks/useCheckIfMemberProfileAndPerksBannersActiveForChamberMember";
import BannerPickerAgreementOverlay from "../../common/BannerPickerAgreementOverlay";
import ChamberMemberMemberProfileAndPerksBannersAgreementModal from "../../common/ChamberMemberMemberProfileAndPerksBannersAgreementModal";
import useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement from "../../common/Hooks/useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import VideoTutorialButton from "../../common/VideoTutorialButton";

const CHARACTER_LIMIT_DESCRIPTION = 2000;

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    border: "1px solid black!important",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    minHeight: "85vh",
    flex: "1",
    marginTop: "33px",
    paddingBottom: "33px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "36px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 15px 15px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  switchContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
  },
  switchText: {
    margin: "10px",
  },
  companyInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  companyDescription: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyDescriptionInput: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
}));

const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const paddingLeftPx = 33;

const ManageChamberMember = (): ReactElement => {
  const classes = useStyles();
  const enumStrings = useEnumStrings();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResetPasswordModal, setLoadingResetPasswordModal] =
    useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [invalidError, setInvalidError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [successNewPasswordModalOpen, setSuccessNewPasswordModalOpen] =
    useState(false);

  const [categoryDisable, setCategoryDisable] = useState(false);
  const {
    state: { userType },
  } = useContext(UserTypeContext);
  const location = useLocation();
  const locationStateChamberMemberId = useMemo(() => {
    return (
      (location.state || { chamberMemberId: undefined }) as {
        chamberMemberId: string | undefined;
      }
    ).chamberMemberId;
  }, [location.state]);

  const { data: locationStateChamberMemberInfo } = useGetChamberMemberQuery({
    variables: {
      chamberMemberId: locationStateChamberMemberId || "",
    },
    skip: !locationStateChamberMemberId,
  });

  const userIdInAuthChamberMemberInfo = useGetChamberMemberFromUserIdInAuth();

  const chamberMemberInfo = useMemo(() => {
    if (locationStateChamberMemberId) {
      return locationStateChamberMemberInfo?.getChamberMember;
    }
    return userIdInAuthChamberMemberInfo;
  }, [
    locationStateChamberMemberId,
    locationStateChamberMemberInfo,
    userIdInAuthChamberMemberInfo,
  ]);

  let customError = "";

  const handleOpenResetPasswordModal = (): void => {
    setOpenResetPasswordModal(true);
  };

  const handleCloseResetPasswordModal = (): void => {
    setOpenResetPasswordModal(false);
  };

  const successNewPasswordModalHandleOpen = (): void => {
    setSuccessNewPasswordModalOpen(true);
  };

  const successNewPasswordModalHandleClose = (): void => {
    setSuccessNewPasswordModalOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const redirectToManageChamberMember = useRedirect(
    RedirectUri.manageAMemberChamberAdmin
  );

  const [deleteChamber] = useDeleteChamberMemberMutation({
    variables: {
      id: chamberMemberInfo?.id || "",
    },
    refetchQueries: ["GetAllChamberMembersQuery"],
    awaitRefetchQueries: true,
  });

  const adminConsoleLoginEmailForChamberMember =
    useGetAdminConsoleLoginEmailForChamberMemberQuery({
      variables: {
        chamberMemberId: locationStateChamberMemberId || "",
      },
      skip:
        !locationStateChamberMemberId || !(userType === UserType.ChamberAdmin),
    });

  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoading(true);
    await deleteChamber();
    setOpen(false);
    redirectToManageChamberMember();
  }, [deleteChamber, redirectToManageChamberMember]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleClose,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const [newPassword, setNewPassword] = useState("");

  const [setNewTemporaryChamberMemberPassowrd] =
    useSetNewTemporaryChamberMemberPasswordMutation();

  const resetPasswordYesButtonAction = useCallback(async (): Promise<void> => {
    setLoadingResetPasswordModal(true);
    // api call here
    const { data } = await setNewTemporaryChamberMemberPassowrd({
      variables: {
        adminConsoleLoginEmail:
          adminConsoleLoginEmailForChamberMember.data
            ?.getAdminConsoleLoginEmailForChamberMember
            .adminConsoleLoginEmail || "",
        chamberMemberId: chamberMemberInfo?.id || "",
      },
    });

    setNewPassword(data?.setNewTemporaryChamberMemberPassword.password || "");
    setOpenResetPasswordModal(false);
    setLoadingResetPasswordModal(false);

    successNewPasswordModalHandleOpen();
  }, [
    adminConsoleLoginEmailForChamberMember.data
      ?.getAdminConsoleLoginEmailForChamberMember.adminConsoleLoginEmail,
    chamberMemberInfo?.id,
    setNewTemporaryChamberMemberPassowrd,
  ]);

  const resetPasswordButtonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleCloseResetPasswordModal,
      yesButtonAction: resetPasswordYesButtonAction,
    };
  }, [resetPasswordYesButtonAction]);

  const csvData = useMemo(
    () => [
      [
        enumStrings.sendLoginModal.memberEmail,
        enumStrings.sendLoginModal.memberPassword,
      ],
      [
        adminConsoleLoginEmailForChamberMember.data
          ?.getAdminConsoleLoginEmailForChamberMember.adminConsoleLoginEmail,
        newPassword,
      ],
    ],
    [
      adminConsoleLoginEmailForChamberMember.data,
      newPassword,
      enumStrings.sendLoginModal.memberEmail,
      enumStrings.sendLoginModal.memberPassword,
    ]
  );

  const [updateChamberMember] = useUpdateChamberMemberMutation();

  const intitalValuesMemo = useMemo(() => {
    const initialValues: ChamberMemberInput = {
      name: chamberMemberInfo?.name || "",
      description: chamberMemberInfo?.description || "",
      address: {
        province: chamberMemberInfo?.address.province || "",
        postalCode: chamberMemberInfo?.address.postalCode || "",
        city: chamberMemberInfo?.address.city || "",
        streetAddress: chamberMemberInfo?.address.streetAddress || "",
      },
      phoneNumber: chamberMemberInfo?.phoneNumber || "",
      website: chamberMemberInfo?.website || "",
      facebook: chamberMemberInfo?.facebook || "",
      instagram: chamberMemberInfo?.instagram || "",
      threads: chamberMemberInfo?.threads || "",
      linkedin: chamberMemberInfo?.linkedin || "",
      twitter: chamberMemberInfo?.twitter || "",
      tiktok: chamberMemberInfo?.tiktok || "",
      youtube: chamberMemberInfo?.youtube || "",
      contactName: chamberMemberInfo?.contactName || "",
      email: chamberMemberInfo?.email || "",
      logo: chamberMemberInfo?.logo || undefined,
      showInApp: chamberMemberInfo?.showInApp || false,
      id: chamberMemberInfo?.id || "",
      categoryId: chamberMemberInfo?.categoryId || [],
      chamberId: chamberMemberInfo?.chamberId || "",
      customContacts: getInitialStateOfCustomContacts(
        chamberMemberInfo?.customContacts || null
      ),
      adminConsoleContactName: chamberMemberInfo?.adminConsoleContactName || "",
      ...(userType === UserType.ChamberAdmin
        ? {
            isSuspended: chamberMemberInfo?.isSuspended || undefined,
          }
        : {}),
      banner: chamberMemberInfo?.banner || undefined,
    };
    return omitDeep(initialValues, "__typename");
  }, [
    chamberMemberInfo?.address.city,
    chamberMemberInfo?.address.postalCode,
    chamberMemberInfo?.address.province,
    chamberMemberInfo?.address.streetAddress,
    chamberMemberInfo?.adminConsoleContactName,
    chamberMemberInfo?.banner,
    chamberMemberInfo?.categoryId,
    chamberMemberInfo?.chamberId,
    chamberMemberInfo?.contactName,
    chamberMemberInfo?.customContacts,
    chamberMemberInfo?.description,
    chamberMemberInfo?.email,
    chamberMemberInfo?.facebook,
    chamberMemberInfo?.id,
    chamberMemberInfo?.instagram,
    chamberMemberInfo?.isSuspended,
    chamberMemberInfo?.linkedin,
    chamberMemberInfo?.logo,
    chamberMemberInfo?.name,
    chamberMemberInfo?.phoneNumber,
    chamberMemberInfo?.showInApp,
    chamberMemberInfo?.threads,
    chamberMemberInfo?.tiktok,
    chamberMemberInfo?.twitter,
    chamberMemberInfo?.website,
    chamberMemberInfo?.youtube,
    userType,
  ]) as unknown as ChamberMemberInput;

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(enumStrings.validationSchema.companyNameRequired),
    adminConsoleContactName: yup
      .string()
      .required(enumStrings.validationSchema.dashboardContactNameRequired),
    phoneNumber: yup.string(),
    email: yup.string().email(enumStrings.validationSchema.notValidEmail),
    address: yup.object({
      province: yup.string(),
      city: yup.string(),
      postalCode: yup.string(),
      streetAddress: yup.string(),
    }),
    categoryId: yup
      .array()
      .min(1, enumStrings.validationSchema.categoryRequired),
  });

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (input: ChamberMemberInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);
        await updateChamberMember({
          variables: {
            id: chamberMemberInfo?.id || "",
            input: {
              address: {
                city: input.address.city,
                postalCode: input.address.postalCode,
                province: input.address.province,
                streetAddress: input.address.streetAddress,
              },
              categoryId: input.categoryId,
              chamberId: input.chamberId,
              description: (input.description || "").trim(),
              contactName: (input.contactName || "").trim(),
              email: (input.email || "").trim().toLowerCase(),
              phoneNumber: input.phoneNumber,
              showInApp: input.showInApp,
              name: input.name.trim(),
              website: input.website,
              facebook: input.facebook,
              instagram: input.instagram,
              threads: input.threads,
              linkedin: input.linkedin,
              twitter: input.twitter,
              tiktok: input.tiktok,
              youtube: input.youtube,
              logo: input.logo,
              customContacts: input.customContacts,
              id: chamberMemberInfo?.id || "",
              adminConsoleContactName: (
                input.adminConsoleContactName || ""
              ).trim(),
              ...(userType === UserType.ChamberAdmin
                ? {
                    isSuspended: input.isSuspended,
                  }
                : {}),
              banner: input.banner,
            },
          },
          refetchQueries: ["getChamberMember", "getAllChamberMembers"],
        });
        setLoader(false);
        setButtonDisabled(false);

        toast.success(enumStrings.memberUpdated);
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const handleCategoriesChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    formik.setFieldValue("categoryId", value);
    if (value.length >= 2) {
      setCategoryDisable(true);
    } else {
      setCategoryDisable(false);
    }
  };

  const handleDelete = (value: string) => {
    const newArray = _without(formik.values.categoryId, value);
    formik.setFieldValue("categoryId", newArray);
    if (newArray.length < 2) {
      setCategoryDisable(false);
    }
  };

  const handleCreateBase64 = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("logo", base64);
    },
    [formik]
  );
  // const handleToggleChange = useCallback(
  //   (e) => {
  //     formik.setFieldValue("showInApp", e.target.checked);
  //   },
  //   [formik]
  // );

  const deleteLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("logo", undefined);
  };

  const [
    chamberMemberChangeAdminConsoleLoginEmailModalIsOpen,
    setChamberMemberChangeAdminConsoleLoginEmailModalIsOpen,
  ] = useState(false);

  const openChamberMemberChangeAdminConsoleLoginEmailModal = useCallback(() => {
    setChamberMemberChangeAdminConsoleLoginEmailModalIsOpen(true);
  }, []);

  const closeChamberMemberChangeAdminConsoleLoginEmailModal =
    useCallback(() => {
      setChamberMemberChangeAdminConsoleLoginEmailModalIsOpen(false);
    }, []);

  const isMemberProfileAndPerksBannersActiveForChamberMember =
    useIsMemberProfileAndPerksBannersActiveForChamberMember({
      activeMemberProfileAndPerksBannersSettings:
        chamberMemberInfo?.activeMemberProfileAndPerksBannersSettings,
    });

  const {
    handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
    chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
  } = useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement({
    activeMemberProfileAndPerksBannersSettings:
      chamberMemberInfo?.activeMemberProfileAndPerksBannersSettings,
    userType,
  });

  const [
    isMemberProfileAndPerksBannersAgreementModalOpen,
    setIsMemberProfileAndPerksBannersAgreementModalOpen,
  ] = useState(false);

  const openChamberMemberMemberProfileAndPerksBannersAgreementModal =
    useCallback(() => {
      setIsMemberProfileAndPerksBannersAgreementModalOpen(true);
    }, []);

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const rootAdminObj = useMemo(() => {
    if (userType === UserType.ChamberMemberAdmin) {
      return enumStrings.groups[userType][
        RedirectUri.updateAMemberChamberAdmin
      ];
    }
    return enumStrings?.groups[UserType.ChamberAdmin][
      //  @ts-ignore
      RedirectUri.updateAMemberChamberAdmin
    ];
  }, [enumStrings.groups, userType]);
  return (
    <span>
      <ChamberMemberMemberProfileAndPerksBannersAgreementModal
        open={isMemberProfileAndPerksBannersAgreementModalOpen}
        handleClose={() =>
          setIsMemberProfileAndPerksBannersAgreementModalOpen(false)
        }
        handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement={
          handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement
        }
        chamberName={chamberInfo?.name || ""}
      />
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          {userType === UserType.ChamberMemberAdmin ? (
            <>
              <div style={{ height: 12 }} />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <VideoTutorialButton
                  label={
                    enumStrings.CreateChamberMember.howToDownloadChamberPerksApp
                  }
                  tutorialUrl={`https://share.synthesia.io/embeds/videos/e9ae72e1-71f3-44fb-a5bc-d71d35cab428`}
                />
              </div>
            </>
          ) : null}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        <div>
          <form
            className={classes.formContainer}
            onSubmit={formik.handleSubmit}
          >
            {open && (
              <ConfirmationModal
                open={open}
                loading={loading}
                handleClose={handleClose}
                message={`${enumStrings.CreateChamberMember.wantToDelete}`}
                buttonConfig={buttonConfig}
              />
            )}
            {openResetPasswordModal && (
              <ConfirmationModal
                open={openResetPasswordModal}
                loading={loadingResetPasswordModal}
                handleClose={handleCloseResetPasswordModal}
                message={`${enumStrings.CreateChamberMember.newTempPassword}`}
                buttonConfig={resetPasswordButtonConfig}
              />
            )}
            {chamberMemberInfo ? (
              <>
                <>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.companyName}
                      className={classes.companyInput}
                      variant="outlined"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.name
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.memberDescription}
                      className={classes.companyInput}
                      variant="outlined"
                      id="adminConsoleContactName"
                      name="adminConsoleContactName"
                      value={formik.values.adminConsoleContactName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.adminConsoleContactName &&
                        Boolean(formik.errors.adminConsoleContactName)
                      }
                      helperText={
                        formik.touched.adminConsoleContactName &&
                        formik.errors.adminConsoleContactName
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .adminConsoleContactName
                        }
                        showIt
                      />
                    </div>
                  </div>
                  {userType === UserType.ChamberAdmin ? (
                    <>
                      <div className={classes.companyInputWithToolTipContainer}>
                        <TextField
                          type="text"
                          label={enumStrings.CreateChamberMember.memberEmail}
                          disabled
                          className={classes.companyInput}
                          variant="outlined"
                          id="adminConsoleLoginEmail"
                          name="adminConsoleLoginEmail"
                          value={
                            adminConsoleLoginEmailForChamberMember.data
                              ?.getAdminConsoleLoginEmailForChamberMember
                              .adminConsoleLoginEmail || ""
                          }
                        />
                        <div
                          style={{
                            marginTop: "50px",
                          }}
                        >
                          <HoverToolTip
                            description={`${enumStrings.createChamberMemberText.tooltip.adminConsoleLoginEmail}. ${enumStrings.createChamberMemberText.tooltip.adminConsoleLoginEmailUpdateBlurb}`}
                            showIt
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#E1A731",
                          textDecoration: "underline",
                          padding: "5px",
                          paddingBottom: 0,
                          cursor: "pointer",
                          marginBottom: -10,
                        }}
                        onClick={
                          openChamberMemberChangeAdminConsoleLoginEmailModal
                        }
                      >
                        {enumStrings.changeEmailUsername}
                      </div>
                    </>
                  ) : null}
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.companyContactName}
                      className={classes.companyInput}
                      variant="outlined"
                      id="contactName"
                      name="contactName"
                      value={formik.values.contactName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contactName &&
                        Boolean(formik.errors.contactName)
                      }
                      helperText={
                        formik.touched.contactName && formik.errors.contactName
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .contactName
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={
                        enumStrings.CreateChamberMember.companyContactEmail
                      }
                      className={classes.companyInput}
                      variant="outlined"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .contactEmail
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <PhoneNumberFormat
                      formikErrors={formik.errors.phoneNumber}
                      formikVal={formik.values.phoneNumber}
                      handleChange={formik.handleChange}
                      label={enumStrings.CreateChamberMember.companyPhoneNumber}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .phoneNumber
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <MultiContactForm
                    formikValuesCustomContacts={formik.values.customContacts}
                    formikSetFieldValue={formik.setFieldValue}
                  />
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.StreetAddress}
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.streetAddress"
                      name="address.streetAddress"
                      value={formik.values.address?.streetAddress}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.streetAddress &&
                        Boolean(formik.errors.address?.streetAddress)
                      }
                      helperText={
                        formik.touched.address?.streetAddress &&
                        formik.errors.address?.streetAddress
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .streetAddress
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <ProvincePicker
                      formikVal={formik.values.address?.province}
                      formikErrors={
                        formik.touched.address?.province &&
                        Boolean(formik.errors.address?.province)
                      }
                      formikStringError={formik.errors.address?.province}
                      formikSetFieldValue={formik.setFieldValue}
                      isOptional
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.province
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.City}
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.city"
                      name="address.city"
                      value={formik.values.address?.city}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.city &&
                        Boolean(formik.errors.address?.city)
                      }
                      helperText={
                        formik.touched.address?.city &&
                        formik.errors.address?.city
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.city
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.PostalCode}
                      className={classes.companyInput}
                      variant="outlined"
                      id="address.postalCode"
                      name="address.postalCode"
                      value={formik.values.address?.postalCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.postalCode &&
                        Boolean(formik.errors.address?.postalCode)
                      }
                      helperText={
                        formik.touched.address?.postalCode &&
                        formik.errors.address?.postalCode
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.postalCode
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <FormControl className={classes.companyDescription}>
                      <TextField
                        type="text"
                        label={
                          enumStrings.CreateChamberMember.CompanyDescription
                        }
                        className={classes.companyDescriptionInput}
                        maxRows={6}
                        multiline
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            maxLength: CHARACTER_LIMIT_DESCRIPTION,
                          },
                        }}
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </FormControl>
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip
                            .description
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <InputLabel style={{ margin: "auto" }}>
                    {`${formik.values?.description?.length}/${CHARACTER_LIMIT_DESCRIPTION} ${enumStrings.CreateChamberMember.CharactersRemaining}`}
                    <FormHelperText
                      component="legend"
                      style={{
                        color: "#f44336",
                        paddingLeft: "10px",
                        textAlign: "center",
                        margin: 0,
                      }}
                    >
                      {formik.errors.description}
                    </FormHelperText>{" "}
                  </InputLabel>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyWebsite}
                      className={classes.companyInput}
                      variant="outlined"
                      id="website"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.website && Boolean(formik.errors.website)
                      }
                      helperText={
                        formik.touched.website && formik.errors.website
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.website
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyFacebook}
                      className={classes.companyInput}
                      variant="outlined"
                      id="facebook"
                      name="facebook"
                      value={formik.values.facebook}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.facebook &&
                        Boolean(formik.errors.facebook)
                      }
                      helperText={
                        formik.touched.facebook && formik.errors.facebook
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.facebook
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyInstagram}
                      className={classes.companyInput}
                      variant="outlined"
                      id="instagram"
                      name="instagram"
                      value={formik.values.instagram}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instagram &&
                        Boolean(formik.errors.instagram)
                      }
                      helperText={
                        formik.touched.instagram && formik.errors.instagram
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.instagram
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyThreads}
                      className={classes.companyInput}
                      variant="outlined"
                      id="threads"
                      name="threads"
                      value={formik.values.threads}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.threads && Boolean(formik.errors.threads)
                      }
                      helperText={
                        formik.touched.threads && formik.errors.threads
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.threads
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyLinkedIn}
                      className={classes.companyInput}
                      variant="outlined"
                      id="linkedin"
                      name="linkedin"
                      value={formik.values.linkedin}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.linkedin &&
                        Boolean(formik.errors.linkedin)
                      }
                      helperText={
                        formik.touched.linkedin && formik.errors.linkedin
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.linkedin
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyTwitter}
                      className={classes.companyInput}
                      variant="outlined"
                      id="twitter"
                      name="twitter"
                      value={formik.values.twitter}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.twitter && Boolean(formik.errors.twitter)
                      }
                      helperText={
                        formik.touched.twitter && formik.errors.twitter
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.twitter
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyTikTok}
                      className={classes.companyInput}
                      variant="outlined"
                      id="tiktok"
                      name="tiktok"
                      value={formik.values.tiktok}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tiktok && Boolean(formik.errors.tiktok)
                      }
                      helperText={formik.touched.tiktok && formik.errors.tiktok}
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.tiktok
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <TextField
                      type="text"
                      label={enumStrings.CreateChamberMember.CompanyYoutube}
                      className={classes.companyInput}
                      variant="outlined"
                      id="youtube"
                      name="youtube"
                      value={formik.values.youtube}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.youtube && Boolean(formik.errors.youtube)
                      }
                      helperText={
                        formik.touched.youtube && formik.errors.youtube
                      }
                    />
                    <div
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.youtube
                        }
                        showIt
                      />
                    </div>
                  </div>
                  <div className={classes.companyInputWithToolTipContainer}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Categories
                          selectedCategories={formik.values.categoryId}
                          handleCategoriesChange={handleCategoriesChange}
                          disableCategories={categoryDisable}
                          handleDelete={handleDelete}
                        />
                        <div
                          style={{
                            marginTop: "55px",
                          }}
                        >
                          <HoverToolTip
                            description={
                              enumStrings.createChamberMemberText.tooltip
                                .categories
                            }
                            showIt
                          />
                        </div>
                      </div>
                      {formik.values.categoryId.length === 0 ? (
                        <FormHelperText
                          component="legend"
                          style={{
                            color: "#f44336",
                            paddingLeft: "15px",
                            paddingTop: "5px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.categoryId}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>

                  <div className={classes.companyInputWithToolTipContainer}>
                    <LogoPicker
                      logo={formik.values.logo}
                      deleteLogo={deleteLogo}
                      base64={(e: any) => handleCreateBase64(e)}
                    />
                    <div
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <HoverToolTip
                        description={
                          enumStrings.createChamberMemberText.tooltip.logo
                        }
                        showIt
                      />
                    </div>
                  </div>

                  {isMemberProfileAndPerksBannersActiveForChamberMember && (
                    <div
                      className={classes.companyInputWithToolTipContainer}
                      style={{
                        paddingLeft: `${paddingLeftPx}px`,
                        position: "relative",
                      }}
                    >
                      <BannerPickerAgreementOverlay
                        chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted={
                          chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                        }
                        openChamberMemberMemberProfileAndPerksBannersAgreementModal={
                          openChamberMemberMemberProfileAndPerksBannersAgreementModal
                        }
                        paddingLeftPx={paddingLeftPx}
                        userType={userType}
                      />
                      <BannerPicker
                        // disable here to prevent user from going into inspector console and hiding banner agreement overlay and still being able to add banner
                        disabled={
                          !chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                        }
                        key="banner"
                        pickerType="memberProfile"
                        formikVal={formik.values.banner}
                        formikSetFieldValue={(updatedBanner) =>
                          formik.setFieldValue("banner", updatedBanner)
                        }
                        pickerLabel={`Member Profile Banners`}
                      />
                      <div>
                        <HoverToolTip
                          description={`${
                            enumStrings.HoverToolTip.description
                          } ${
                            enumStrings.bannerPicker.minSizeRequirementForBanner
                              .charAt(0)
                              .toLowerCase() +
                            enumStrings.bannerPicker.minSizeRequirementForBanner.slice(
                              1
                            )
                          } ${enumStrings.HoverToolTip.description2}`}
                          showIt
                        />
                      </div>
                    </div>
                  )}

                  {userType === UserType.ChamberAdmin && (
                    <ChamberMemberIsSuspendedRadioGroup
                      formikSetFieldValue={formik.setFieldValue}
                      formikErrors={formik.errors}
                      formikValues={formik.values}
                    />
                  )}
                  <div>
                    {/* <FormControl className={classes.switchContainer}>
                      <Typography className={classes.switchText}>
                        {enumStrings.showInAppText}{" "}
                      </Typography>
                      <ShowInAppSwitch
                        checked={formik.values.showInApp}
                        onChange={handleToggleChange}
                        id="showInApp"
                        name="showInApp"
                        value={formik.values.showInApp}
                      />
                    </FormControl> */}
                    {invalidError ? (
                      <div className={classes.invalidError}>{customError}</div>
                    ) : null}
                  </div>
                </>
                <div className={classes.buttonContainers}>
                  {Object.keys(formik?.errors || {}).length > 0 &&
                  Object.keys(formik?.touched || {}).length > 0 ? (
                    <div>
                      <div className={classes.invalidError}>
                        {enumStrings.issuesUpdateToRed}
                      </div>
                      <br />
                      <br />
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    style={{
                      backgroundColor: "#E1A731",
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      width: "200px",
                    }}
                    disabled={buttonDisabled || loader}
                  >
                    {loader ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : userType === UserType.ChamberMemberAdmin ? (
                      enumStrings.CreateChamberMember.update
                    ) : (
                      enumStrings.CreateChamberMember.updateMember
                    )}
                  </Button>
                  {locationStateChamberMemberId ? (
                    <div
                      style={{
                        marginTop: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <CSVLink
                        data={csvData}
                        filename={`${chamberMemberInfo.name} Member Dashboard Credentials.csv`}
                        id="csv-download-button"
                        style={{ display: "none" }}
                      />
                      <Button
                        size="medium"
                        color="secondary"
                        variant="contained"
                        style={{
                          backgroundColor: "#E1A731",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "200px",
                        }}
                        onClick={handleOpenResetPasswordModal}
                      >
                        {enumStrings.resetPasswordText}
                      </Button>
                      <div style={{ width: "25px" }} />
                      <Button
                        size="medium"
                        color="secondary"
                        variant="contained"
                        style={{
                          backgroundColor: "#FF0000",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "200px",
                        }}
                        onClick={handleOpen}
                      >
                        Delete Member
                      </Button>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </form>
        </div>
      </div>
      {successNewPasswordModalOpen && (
        <>
          <SendLoginInformationModal
            isSuspended={formik.values.isSuspended ? true : false}
            handleClose={successNewPasswordModalHandleClose}
            open={successNewPasswordModalOpen}
            memberAdminPortalEmail={
              adminConsoleLoginEmailForChamberMember.data
                ?.getAdminConsoleLoginEmailForChamberMember
                .adminConsoleLoginEmail || ""
            }
            memberCompanyName={chamberMemberInfo?.name || ""}
            chamberMemberId={chamberMemberInfo?.id || ""}
            type="resetPassword"
          />
        </>
      )}

      {chamberMemberChangeAdminConsoleLoginEmailModalIsOpen && (
        <ChamberMemberChangeEmailUsernameModal
          isSuspended={formik.values.isSuspended ? true : false}
          chamberMemberId={chamberMemberInfo?.id}
          open={chamberMemberChangeAdminConsoleLoginEmailModalIsOpen}
          handleClose={closeChamberMemberChangeAdminConsoleLoginEmailModal}
          chamberMemberName={chamberMemberInfo?.name || ""}
          currentAdminConsoleLoginEmail={
            adminConsoleLoginEmailForChamberMember.data
              ?.getAdminConsoleLoginEmailForChamberMember.adminConsoleLoginEmail
          }
        />
      )}
    </span>
  );
};

export default ManageChamberMember;
